import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { actionCreators, State } from "../../state";
import { useEffect, useState } from "react"
import LabelAndDescription, {AssetDescription, LabelAndInlineValue} from "../LabelAndDescription";
import TabsMenu from "../TabsMenu";
import { euroFormat, euroQtyFormat, formatDate, convertToSimpleDate, convertLegalFrameworkForFE } from "../../utils";
import { CarExtraInfo, defaultCarExtraInfo, Appraisals } from "../../AssetContext";
import { defaultPrimarySaleGet } from "../../PoolContext"
import { TitleColor1 } from "../typography/Typogtaphy";
import { PieChart } from "react-minimal-pie-chart";
import AddLPPopup from "../popup/AddLPPopup";
import RemoveLPPopup from "../popup/RemoveLPPopup";
import Popup, { DexxPopup } from '../Popup'
import { DexxButton, DisabledButton } from "../light/LightButton"
import fileImg from '../../media/file.png'
import { Link } from "react-router-dom"
import Cookies from 'universal-cookie';
import P2PTokenTab from "./tabs/P2PTokenTab";
import SecondaryMarketStatistics from "./tabs/SecondaryMarketStatistics";
import { ListedLPTab } from "./tabs/LiquidityPoolTab";
import InvestmentLimitsTab from "./tabs/InvestmentLimitsTab";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { ImagesSlideShow } from "../utils/ImagesSlideShow";

const cookies = new Cookies();

export default function CarsTokenBody (props: {
                assetInfo: any,  tokenInfo: any, lpInfo?: any, media: any, 
                tokenSymbol: String, completeView?: boolean, openedTab?: string, reload?:Function,
                psUuid?: string, origin: string, lpCount: number,
                isLogged: boolean}): JSX.Element {
    
    var carInfo: CarExtraInfo = {
        //asset_title: "",
        name: "",
        description: "",
        year: 0,
        make: "",
        model: "",
        vin: "",
        exterior_color: "",
        interior_color: "",
        odometer: {
            odometer_value : "",
            odometer_unit : ""
        },
        transmission: "",
        engine_type: "",
        engine_size: "",
        location: "",
        market_value: "",
        condition: "",
        appraisals: [], 
      
      }

    var carInfoArray = []
    const dispatch = useDispatch()

    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload
    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0

    const [documents, setDocuments] = useState<{url:string, filename: string}[]>([])
    const actions = bindActionCreators(actionCreators, dispatch)
    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")

    const [showAddLPPopUp, setShowAddLPPopUp] = useState<boolean>(false)
    const [showRemoveLPPopUp, setShowRemoveLPPopUp] = useState<boolean>(false)
    const [showActualLP, setShowActualLP] = useState<string>("")
    const [lpCount, setLpCount] = useState<number>(0)

    const [shares, setShares] = useState<any[]>([])
    const [arrayShares, setArrayShares] = useState<any[]>([])
    const [p2p, setP2p] = useState<any[]>([])
    const [onlyActiveP2p, setOnlyActiveP2p] = useState<any[]>([])
    const [refreshP2pList, setRefreshP2pList] = useState<boolean>(false)
    const [primarySaleDetails, setPSDetails] = useState(defaultPrimarySaleGet)

    //const isDev = cookies.get("env") !== "production"
    const isDev = cookies.get("env") === "develop"
    var filtered_shares:any = []

    function handleClosePopUp(){
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUp(false)
    }

    try {
        carInfoArray = props.assetInfo.extra_info.cars.sub_assets
    } catch (e) {
        carInfoArray = []
    }
    carInfo = carInfoArray.length > 0 ? carInfoArray[0] : defaultCarExtraInfo

    
    useEffect(() => {
        if(props.media)
            if(props.isLogged) actions.getDocuments(props.media, setDocuments)
    }, [props.media])
    
    useEffect(() => {
        if(props.isLogged){
            if(props.lpInfo && props.lpInfo.length > 0){
                setLpCount(props.lpInfo.length)
                props.lpInfo.forEach((lp:any, i: number) => {
                    if(lp?.uuid !== undefined){
                        actions.getInvestorShares(lp.uuid, true, setShares)
                    }
                });
            }
        }
    }, [props.lpInfo])

    useEffect(() => {
        if(shares[0] !== undefined) setArrayShares([...arrayShares, shares[0].LPWE])
    }, [shares])

    useEffect(() => {
        if(props.tokenInfo && props.tokenInfo.uuid)
            actions.getPrimarySale("token_uuid="+props.tokenInfo.uuid, setPSDetails)
    }, [props.tokenInfo])

    useEffect(() => {
        if(props.isLogged){
            if(props.tokenInfo && props.tokenInfo.uuid)
                actions.getP2P({
                    token_a_uuid: props.tokenInfo.uuid
                }, setP2p)
            setRefreshP2pList(false)
        }
    }, [props.tokenInfo, refreshP2pList])

    useEffect(() => {
        if(p2p){
            let tmp:any = p2p.filter((o: any) => o.deleted === undefined || o.deleted === false)
            setOnlyActiveP2p(tmp)
        }
    }, [p2p, p2p[1]])

    function getNameFromUrl(url:string){
        var noVars = url.split('?')[0]
        var name = noVars.substring(noVars.lastIndexOf('/')+1)
        name = name.replace("coownership___", "COOWNERSHIP AGREEMENT: ")
        name = name.replace("prospectus___", "ASSET TOKEN PROSPECTUS: ")
        name = name.replace("_", " ").replace("-", " ").split('.')[0]
        return name
    }

    function docDownload(documents: any) {
        var tmp = []
        try {
            tmp = documents.map((doc: any, i: number) => 
            <div className="" key={(i+1).toString()}>
                <label className="art-doc-item">
                    <a href={doc.url} target={"_blank"}>
                        <div className="text-tertiary font-code-pro tracking-wider uppercase hover:bg-tertiary hover:text-primary
                            font-base text-lg border-solid border-1 border-tertiary rounded-xl px-4">
                            {getNameFromUrl(doc.filename)}
                        </div>
                    </a>
                </label>
            </div>)
        } catch (e) {}
        let res = <></>
        if(props.isLogged) 
            res = tmp.length > 0 ? <div className="flex flex-wrap gap-2">{tmp}</div> : <></>
            //<div className="text-tertiary font-code-pro tracking-wider uppercase font-base text-xl">No documents provided</div>
        else
            res = <div className="text-tertiary font-code-pro tracking-wider uppercase font-base text-xl">Please Log in or register to see the token's documentation.</div>
        return res
    }

    function docListing(documents: any) {
        var tmp = []
        try {
            tmp = documents.map((doc: any, i: number) => 
            <div className="pb-5" key={(i+1).toString()}>
                    <a href={doc.url} target={"_blank"} >
                    <div className="flex items-center">    
                        <span className="inline"><img src={fileImg} alt={doc.filename} className="h-10"></img></span>
                        <span className="text-tertiary font-code-pro tracking-wider uppercase hover:text-succ2 
                            font-base text-lg px-4 inline">
                            {getNameFromUrl(doc.filename)}
                        </span>
                    </div></a>
            </div>)
        } catch (e) {}
        const res = tmp.length > 0 ? 
                <div className="mt-30 border-solid border-1 border-tertiary rounded-xl px-4 pt-5 pl-10">{tmp}</div> : 
                <div className="text-tertiary font-code-pro tracking-wider uppercase font-base text-xl">No documents provided</div>
        return res
    }
    
    function showArtDetails (info: any) {
        if(info) {
            const labels = ["Name", "Model", "Year", 
                            "Make", "VIN", "Odometer", 
                            "Exterior", "Interior", "Transmission",
                            "Engine Type", "Size", "Location",
                            "Market Value", "Conditions", "Appraisals"]
            const descr = [info.name, info.model, info.year, 
                            info.make, info.vin, info.odometer.odometer_value + " " + info.odometer.odometer_unit, 
                            info.exterior_color, info.interior_color, info.transmission, 
                            info.engine_type, info.engine_size, info.location,
                            info.market_value, info.condition, 
                    (info.appraisals  ? info.appraisals.map((i: Appraisals) => <div>{formatDate(i.date, offset)+": "+ euroFormat(i.value) + " Euro"}</div>) : undefined), info.loan_amount, info.term_in_months]
            return labels.map((l, i) => <LabelAndDescription alternate={true} label={l} description={descr[i]} className="mb-10" key={i}/>)
        }
        return <></>
    }

    function showCar() : JSX.Element {

        var description = carInfo.description ? carInfo.description : "No Description Provided"
        return <>
            <div className="grid lg:grid-cols-2 pt-5 mx-10 lg:mx-20">
                <div className="mt-10">
                    <div className="col-span-12 text-2xl font-normal tracking-wider">
                        <TitleColor1>Description:</TitleColor1></div>
                    <AssetDescription alternate={true} label={""} 
                        description={description} className="mt-5 text-xl font-roboto"/>
                </div>
                <div className="">
                    <div className="grid justify-items-center mb-2 overflow-hidden mt-10">
                        <ImagesSlideShow media={props.media} />
                    </div> 
                </div>
                
            </div>
            <div className="list-art-docs row my-10 flex mx-10 justify-center ">
                {docDownload(documents)}
            </div>
            <div className="grid grid-cols-3 my-10 mx-20">
                    {showArtDetails(carInfo)}
                </div>

            { props.assetInfo.issuer_description || props.assetInfo.issuer_web_site ? (
                <div className="text-center lg:mx-20">
                    { props.assetInfo.issuer_description ? (<>
                        <div className="text-tertiary mb-5 mt-10 text-bold"><TitleColor1>Issuer Description:</TitleColor1></div>
                        <div className="text-tertiary text-center mb-5 mt-5 whitespace-pre-wrap">{props.assetInfo.issuer_description}</div>
                    </>) : <></>
                    }
                    {/* props.assetInfo.issuer_web_site ? (<>
                        <div className="text-tertiary mb-5 mt-10">Visit issuer's website: <a className="text-succ2" href={props.assetInfo.issuer_web_site} target="_blank" >{props.assetInfo.issuer_web_site}</a></div>
                    </>) : <></>
                    */}
                </div>
            ) : <></>
            }
        </>
    }

    function showTermsConds() {
        return <>
            <div className="grid grid-cols-6 mx-20">
                <div></div>
                <div className="col-span-4">

                    <LabelAndInlineValue label={"ASSET NAME: "} value={props.tokenInfo.asset_name} />
                    <LabelAndInlineValue label={"TOKEN TICKER: "} value={props.tokenInfo.ticker} />
                    { props.tokenInfo.legal_framework && props.tokenInfo.legal_framework !== "" ?
                        <LabelAndInlineValue label={"TOKEN LEGAL FRAMEWORK: "} value={convertLegalFrameworkForFE(props.tokenInfo.legal_framework)} /> :
                        <></>
                    }
                    <LabelAndInlineValue label={"TOKEN ISSUANCE DATE: "} value={convertToSimpleDate(primarySaleDetails.date_start)} />
                    <LabelAndInlineValue label={"PERCENTAGE OF ASSET TOKENIZED: "} value={props.tokenInfo.pct_tokenized + "%"} />
                    <LabelAndInlineValue label={"TOKENIZED TOTAL VALUE: "} value={carInfo.market_value} />
                    <LabelAndInlineValue label={"TOKEN SUPPLY: "} value={props.tokenInfo.total_supply} />
                    <LabelAndInlineValue label={"TOKEN FOR SALE: "} value={primarySaleDetails.total_supply} />
                    <LabelAndInlineValue label={"INITIAL TOKEN PRICE: "} value={primarySaleDetails.price+" EUR"} />

                    <AssetDescription alternate={true} label={"TOKEN DESCRIPTION:"} 
                        description={props.tokenInfo.token_term_cond} 
                        liquidation_price={props.tokenInfo.buyback_price}
                        liquidation_date={props.tokenInfo.date_buyback}
                        className="mt-5 text-xl font-roboto text text-tertiary"/>
                </div>
                <div></div>
            </div>
        </>
    }

    function showInvestmentLimits() {
        return <>
            <div className="grid grid-cols-6 mx-20">
                <div></div>
                <InvestmentLimitsTab tokenInfo={props.tokenInfo} price={primarySaleDetails.price} />
                <div></div>
            </div>
        </>
    }

    function showLiquidityPool() {
        return <>
            { //props.lpInfo === undefined || props.lpInfo === "" || props.lpInfo.length === 0 ?
            props.origin === "1m" && props.lpCount > 0 ?
                
            <div className="grid grid-cols-6 mx-20">
                <div></div>
                <ListedLPTab tokenInfo={props.tokenInfo} />
                <div></div>
            </div>

            :

            lpCount === 0 ?
               <div className="grid grid-cols-6 mx-20">
                    <div></div>
                    <div className="col-span-4">
                        <AssetDescription alternate={true} label={""} 
                            description={"No Liquidity Pools created for the token"} className="mt-5 text-xl font-roboto text-tertiary"/>
                    </div>
                    <div></div>
                </div>

            :

            <div className="">
                <DexxPopup show={showAddLPPopUp} title={"Add Liquidity"} 
                    msg={<AddLPPopup closeFunction={() => { setShowAddLPPopUp(false); if(props.reload) props.reload()}} uuidLP={showActualLP} />}
                    helpMsg={
                        <div className="p-5 font-roboto font-semibold text-primary text-base">
                            <div className=" font-bold text-primary text-lg mb-5">
                            Add funds to a liquidity pool by entering the XEUR you will allocate. The number of asset tokens required will be calculated for you.
                            </div>
                            <div className=" font-bold text-primary text-lg mb-5">
                            The transaction has a tolerance of 1% meaning that the price of the asset token can increase up to 1% by the time you tap Submit.
                            </div>
                            <div className=" font-bold text-primary text-lg">
                            For more detailed information <Link to={"/support"} className="text-succ2">click here</Link>.
                            </div>
                        </div>
                    }
                    btnConfirm={false} close={() => { setShowAddLPPopUp(false); if(props.reload) props.reload() } } btnOk={false} />
                
                <DexxPopup show={showRemoveLPPopUp} title={"Remove Liquidity"} 
                    msg={<RemoveLPPopup closeFunction={() => { setShowRemoveLPPopUp(false); if(props.reload) props.reload()}} uuidLP={showActualLP} />}
                    type="alternate"
                    btnConfirm={false} close={() => { setShowRemoveLPPopUp(false); if(props.reload) props.reload() } } btnOk={false} />

                <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp}></Popup>
                
                {props.lpInfo.map((lp:any, i: number) => { 
                    filtered_shares = arrayShares.filter((s: any) => s.lp_uuid === lp.uuid)
                    return(
                
                <div className="grid lg:grid-cols-5 cover mt-10 mb-5 xl:px-30" key={i}>
                    <div></div>
                    <div className="font-urbanist uppercase text-tertiary text-3xl mt-16 ml-10">
                        <div className="">
                            <div className="text-succ2">Liquidity pool name</div>
                            <div className="text-2xl">{lp.lp_name}</div>
                            <div className=" my-5 text-succ2">Add / Remove Funds</div>
                            

                            <div className="flex justify-center  mb-4">
                            {
                                /*props.tokenInfo.lp_flag &&*/ filtered_shares[0]?.qty_lp_token && parseFloat(filtered_shares[0].qty_lp_token) > 0 ? (
                                    <DexxButton label={"Remove"} className="rounded-full px-16 py-2 ml-10 mr-10 font-bold text-xs md:text-sm bg-succ2 z-50" 
                                        color1="succ2" color2="primary"
                                        onClick={() => {setShowRemoveLPPopUp(true); setShowActualLP(lp.uuid)}}/>
                                ) : (
                                    <DisabledButton label={"Remove"} className="rounded-full px-16 py-2 mr-10 font-bold bg-succ2 z-50"
                                        fontClasses=" text-xs md:text-sm " />
                                )
                            }

                            {
                                props.tokenInfo.lp_flag ?
                                <DexxButton label={"Add"} className="rounded-full px-16 py-2 ml-10 font-bold text-xs md:text-sm bg-succ2 z-50" 
                                    color1="succ2" color2="primary"
                                    onClick={() => {setShowAddLPPopUp(true); setShowActualLP(lp.uuid)}}/>
                            :
                                <DisabledButton label={"Add"} className="rounded-full px-16 py-2 mr-10 font-bold text-xs md:text-sm bg-succ2 z-50" />
                            }
                        </div>
                        </div>
                    </div>
                    <div className="flex place-content-center xl:ml-20">
                        <div className="w-48 grid justify-items-center text-tertiary self-center">
                            <div className={"relative text-5xl font-base inset-0 top-30"}>
                                LP
                            </div>
                            <PieChart className=""
                                data={[
                                    { title: lp.token_a_ticker, value: parseFloat(lp.amount_a), color: '#80C8C4' },
                                    { title: lp.token_b_ticker, value: parseFloat(lp.amount_b), color: '#4494CE' },
                                ]}
                                lineWidth={4}
                                paddingAngle={10}
                                animate={true}
                                animationDuration={1000}
                            />
                        </div>
                    </div>
                    
                    <div className="font-urbanist uppercase text-tertiary my-10 text-4xl 
                            flex flex-col text-left place-content-center items-center">
                        <div>
                            <div className=" my-5">{euroQtyFormat(lp.amount_a)}
                                <div className="text-seafoam text-xl">{lp.token_a_ticker}</div>
                            </div>
                            <div className="my-5">{euroFormat(lp.amount_b)}
                                <div className="text-succ4 text-xl">{lp.token_b_ticker}</div>
                            </div>
                            <div className="my-5">{euroQtyFormat(lp.lp_token_supply)}
                                <div className="text-succ5 text-xl">Total LP Shares</div>
                            </div>
                        </div>
                    </div>

                    <div></div>
                </div>
                )})}
            </div>
        }
        </>
    }

    function showDataRoom() {
        return <>
            <div className="grid grid-cols-6 mx-20">
                <div></div>
                <div className="col-span-4">
                    {docListing(documents)}
                </div>
                <div></div>
            </div>
        </>
    }

    function showNoContentForNotLogged(){
        return <>
            <div className="text-center text-tertiary font-code-pro tracking-wider uppercase font-base text-xl">
                Please login or register to see this section.
            </div>
        </>
    }

    var headers:any = []
    var labels:any = []
    var contents:any = []

    var tabManager:any = [
        {h: "Asset Overview", l:"", c: showCar(), show: true },
        {h: "Token Overview", l:"token", c: showTermsConds(), show: true },
        {h: "Investment Restrictions", l:"restr", c: showInvestmentLimits(), show: true },
        {h: "Market Statistics", l:"stats", show: isDev,
            c: <SecondaryMarketStatistics tokenUuid={props.tokenInfo.uuid} /> },
        {h: "Liquidity Pool", l:"lp", show: props.completeView, 
            c: props.isLogged ? showLiquidityPool() : showNoContentForNotLogged() },
        {h: "P2P", l:"p2p", show: props.completeView /*&& isDev && props.tokenInfo.p2p_flag*/, 
            c: props.isLogged ? <P2PTokenTab p2ps={onlyActiveP2p} tokenInfo={props.tokenInfo} refreshList={setRefreshP2pList}/> : showNoContentForNotLogged()},
        {h: "Data room", l:"docs", show: true, 
            c: props.isLogged ? showDataRoom(): showNoContentForNotLogged() },
    ]

    tabManager.forEach( (tab: any) => {
        if(tab.show){
            headers.push(tab.h)
            labels.push(tab.l)
            contents.push(tab.c)
        }
    });

    return <>
            <div className="">
                <TabsMenu headers={headers} labels={labels} contents={contents} openedTab={props.openedTab}/>
            </div>
        </>

}
