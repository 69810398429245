import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import arrowUp from "../media/arrow2.png";
import { actionCreators, State } from "../state";
import Popup from './Popup'
import fileImg from '../media/file.png'
import videoImg from '../media/play-button.png'
import { FileSrc } from "../AssetContext";
import deleteimg from '../media/delete.png'
import Compressor from "compressorjs";
import { ImagesToBeUploaded, isImagesToBeUploaded } from "../routes/NewAssetExtraInfo.route";
import { getImagePosition, getFilesPositions } from "../functions/images";

export default function LARF(props: {
    names: FileSrc[], setNames: any, type: string, tbr: any[], setTbr: any,
    tbu: (ImagesToBeUploaded | [File, number])[], setTbu: any, isSubmitted?: boolean, onlyOne?: boolean,
    documents?: boolean
}): JSX.Element {
    var acceptedFormat: string[], alertMsg: string, uploadName: string
    const acceptedDocFormats = ["application/pdf", "text/plain"]
    const acceptedImgFormats = ["image/png", "image/jpeg", "image/svg+xml"]
    const acceptedVidFormats = ["video/mp4", "video/quicktime"]

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const types = useSelector((state: State) => state.mediaTypes)
    const [allTypes, setAllTypes] = useState<number[]>([])
    let t = 0

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")

    var list: any[] = []

    function handleClosePopUp() {
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUp(false)
    }

    useEffect(() => {
        actions.getAllMediaTypes()
    }, [])

    useEffect(() => {
        if (types.payload && types.payload[1] && types.payload[1].media_types) {
            setAllTypes(types.payload[1].media_types.map((i: any) => {
                return i
            }))
        }
    }, [types])

    function formatName(name: string) {
        if (name.includes("coownership___"))
            name = name.replace("coownership___", "")
        if (name.includes("prospectus___"))
            name = name.replace("prospectus___", "")

        name = name.substring(name.lastIndexOf("/") + 1, name.length)
        return name
    }

    let submitted = props.isSubmitted !== undefined && props.isSubmitted ? true : false
    let onlyOne = props.onlyOne !== undefined && props.onlyOne ? true : false

    switch (props.type) {
        case "img":
            acceptedFormat = acceptedImgFormats
            alertMsg = "Accepted formats are .png, .jpg and .svg"
            uploadName = "Images"
            if (allTypes.length > 0) t = allTypes[0]
            list = props.names.map((i) => { return i.src })
            break
        case "vid":
            acceptedFormat = acceptedVidFormats
            alertMsg = "Accepted formats are .mp4 and .mov"
            uploadName = "Videos"
            if (allTypes.length > 1) t = allTypes[1]
            list = props.names.map((i) => { return i.src })
            break
        case "doc":
            acceptedFormat = acceptedDocFormats
            alertMsg = "Accepted format are .pdf and .txt"
            uploadName = "Documents"
            if (allTypes.length > 2) t = allTypes[2]
            list = props.names.length > 0 ? props.names.map((i) => { return i.src }) : []
            break
        default:
            acceptedFormat = []
            alertMsg = "We encountered an error uploading your media."
            uploadName = "Picture"
    }

    return <>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp} ></Popup>
        {submitted || (onlyOne && props.names.length > 0) ? <></> :
            <div className="uppercase flex py-2 items-center">
                <label>
                    <img alt="" src={arrowUp} className="transform -rotate-90 cursor-pointer" />
                    <input className="hidden" type="file" onChange={(e) => {
                        if (e.target.files && e.target.files.length) {
                            var tmp = [...props.names]
                            var file = e.target.files[0]
                            if (acceptedFormat.indexOf(file.type) > -1) {
                                if (props.names.filter((s: { name: string, uploaded: boolean }) => formatName(s.name) === file.name).length < 1) {
                                    if (props.documents) {
                                        var tmp_ = [...props.tbu]
                                        tmp_.push([file, t])
                                        props.setTbu(tmp_)
                                        tmp.push({ name: file.name, uploaded: false, src: URL.createObjectURL(file) })
                                        props.setNames(tmp)
                                    } else {
                                        var indexes: number[] = getFilesPositions(tmp.map((i) => i.filename_big))
                                        let index: number = indexes ? (indexes.length > 0 ? 
                                            (((indexes[indexes.length - 1] === - 1) ? 
                                                (indexes.length + 1) : (indexes[indexes.length - 1] + 1))) : 1) : 1
                                        new Compressor(file, {
                                            quality: 0.1,
                                            maxWidth: 300,
                                            maxHeight: 300,
                                            success(result) {
                                                const formData = new FormData();

                                                // The third parameter is required for server
                                                formData.append('file', result);
                                                var tmp_ = [...props.tbu]
                                                tmp_.push({ big: file, small: new File([result], file.name), index: index, type: t })
                                                props.setTbu(tmp_)
                                                tmp.push({
                                                    name: file.name, uploaded: false, index: index,
                                                    src: URL.createObjectURL(file), small: URL.createObjectURL(result)
                                                })
                                                props.setNames(tmp)
                                            }
                                        })
                                    }

                                } else {
                                    setTitlePopUp("Sorry!")
                                    setMsgPopUp("Please use a filename that has not already been used.")
                                    setShowPopUp(true)
                                }
                            } else {
                                setTitlePopUp("Sorry!")
                                setMsgPopUp(alertMsg)
                                setShowPopUp(true)
                            }
                        }
                    }} />
                </label>
                <label className="text-white">{"Upload " + uploadName}</label>
            </div>}
        <div className="text-blue mb-5 py-2 flex gap-4 w-full flex-wrap">

            {props.names.length === 0 ?

                <label className="text-white">{"No " + (onlyOne ? "Document" : uploadName) + " uploaded"}</label>

                :

                props.names.map((s: FileSrc, i: number) => {
                    const urlFile = Array.isArray(list) ?
                        list.filter((file: any) => file.includes(s.name)) :
                        []
                    var url = ""
                    if (urlFile.length > 0) {
                        try {
                            url = URL.createObjectURL(urlFile[0])
                        } catch (e) {
                            url = urlFile[0]
                        }
                    } else {
                        url = s.src ? s.src : ""
                    }
                    return (
                        (getImagePosition(s.filename_big ? s.filename_big : "") === 0) ? <></> :
                            <div key={i} className={"flex items-center p-2 flex-col w-fit bg-white rounded-md"}>
                                <div className="flex">
                                    <div className="w-5"></div>
                                    <div className="w-44 bg-black_light flex place-content-center">
                                        {url ?
                                            <a href={url} target="_blank" className="h-24">
                                                {props.type === "img" ? <img src={url} className="h-24" /> :
                                                    props.type === "vid" ? <img src={videoImg} className="h-24" /> :
                                                        <img src={fileImg} className="h-24"></img>}
                                            </a>
                                            :
                                            <img src={fileImg} className="h-24"></img>}
                                    </div>
                                    <div className="h-4 w-5 text-right">
                                        {submitted ? <div className="w-3"></div> :
                                            <img src={deleteimg} className="h-3 w-3 cursor-pointer"
                                                onClick={() => {
                                                    if (s.uploaded) {
                                                        var tmp_ = [...props.tbr]
                                                        if (props.documents) {
                                                            tmp_.push(s.name)
                                                        } else {
                                                            if (s.index) {
                                                                tmp_.push(s.index + "_big_" + s.name)
                                                                tmp_.push(s.index + "_small_" + s.name)
                                                            } else
                                                                tmp_.push(s.filename_big)
                                                            tmp_.push(s.filename_small)
                                                        }
                                                        props.setTbr(tmp_)
                                                    } else {
                                                        var tmp__ = [...props.tbu]
                                                        tmp_ = tmp__.filter((i) => {
                                                            if (isImagesToBeUploaded(i))
                                                                return i.big.name !== s.name
                                                            else
                                                                return i[0].name !== s.name
                                                        })
                                                        props.setTbu(tmp_)
                                                    }
                                                    var tmp = [...props.names]
                                                    tmp.splice(i, 1)
                                                    props.setNames(tmp)
                                                }} />}
                                    </div>
                                </div>
                                <div className="flex pt-2">
                                    <div className={"mx-2 w-44 cursor-pointer flex place-content-center items-center text-sm " +
                                        (s.uploaded ? "text-blue" : "text-purple-600")} >
                                        {formatName(s.name)}
                                    </div>
                                </div>
                            </div>)
                })}
        </div>
    </>
}