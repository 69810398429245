import { FaqQuestion, FaqAnswer } from "../components/typography/Typogtaphy";
import {DescriptiveTable} from "../components/light/LightTable";

function ColoredTab(props: {label: string, color: string, index?: number, headers: any, rows: any}): JSX.Element {
    return <div>
            <DescriptiveTable headers={props.headers} rows={props.rows}
                colorHd={"gradient-to-r from-"+props.color+" to-gray3"}
                color1={"gradient-to-r from-"+props.color+"_lighter  to-gray1"}
                color2={"gradient-to-r from-"+props.color+"_light to-gray2"} /> 
    </div>
}

export default function PrivPolRoute(): JSX.Element {

    return <>
        <div className="md:pl-20 pl-5 md:pr-20 pr-5">
            <div className="font-roboto font-thin text-left">
                <div className="text-succ2 text-6xl text-center mt-10 mb-10">PRIVACY POLICY</div>

                <div className="text-tertiary text-xl mt-3">
                LL DEXX SA, ("Company") collects personal data (as defined below) in accordance with applicable laws and regulations, in particular the Swiss Federal Act on Data Protection ("<b>FADP</b>") and the General Data Protection Regulation (EU/2016/679) (General Data Protection Regulation, "<b>GDPR</b>"), both defined as "<b>privacy regulations</b>".
                </div>

                <FaqQuestion content="Definitions" />
                <FaqAnswer content="In accordance with the privacy regulations, the terms below have different meanings:" />
                <ul className="text-tertiary text-xl mt-3">
                    <li><b>Personal Data</b> includes any information relating to an identified or identifiable natural or legal person;</li>
                    <li><b>Processing</b> shall mean any operation or set of operations, whether or not by automatic means, which applies to (groups of) personal data, including collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, diffusion, communication by transmission, dissemination or any other form of disclosure, alignment or combination, restriction, erasure or destruction.</li>
                    <li>The <b>Navigation data</b> are acquired by the computer systems and procedural software used to operate this site during their normal operation. This is personal data whose transmission is implicit in the use of Internet communication protocols. This category includes IP addresses, addresses in URI/URL (Uniform Resource Identifier/Locator) notation, the time of the request, the type of request, the size of the outgoing packet, the status of the response given by the server (received, error, etc.) and other parameters relating to the operating system.</li>
                </ul>

                <FaqQuestion content="Data controller" />
                <FaqAnswer content="The data controller is LL DEXX SA" />
                <FaqAnswer content="The representative for the EU is Andrea Visconti." />

                <FaqQuestion content="Type of personal data acquired, purpose, legal basis and data retention" />
                <FaqAnswer content="The data are collected and managed for the purposes and within the times indicated below:" />
                <ColoredTab 
                    label={""} 
                    color={"succ4"} 
                    headers={["Data", "Mode of data acquisition", "Purpose of the treatment", "Legal basis of the treatment", "Data retention time"]} 
                    rows={[
                        ["Technical data, such as IP address, browser characteristics (type, language, installed plug-ins, etc.).", 
                        "Automatic during the platform visit", 
                        "Visit of the platform by the user", 
                        "Predominant private interest", 
                        ""/*"[to be completed by the data controller]."*/],
                        ["Identity information such as name, address, company name, photograph, email address]",
                        "Entered by the user when registering for the platform or using the contact forms",
                        "Registration of personal data for AML purposes and accounting reasons",
                        "Fulfilment of contractual obligations",
                        "10 years"],
                        ["Information on services purchased, means of payment used and price paid].",
                        "Entered by the user during the use of the platform",
                        "Recording financial data for accounting reasons",
                        "Art. 958f Code of Obligations",
                        "10 years"],
                        ["E-mail",
                        "Entered by the user",
                        "Newsletter registration",
                        "Predominant private interest",
                        "Until cancellation request"],
                    ]} />

                <div className="text-tertiary text-xl mt-3">
                The platform is not designed to store “special categories”, including race or ethnicity, religious or philosophical beliefs, life and sexual orientation, political opinions, trade union membership, health status information. {/*<mark>We do not use tracking cookies and do not monitor user behaviour within the site</mark>[SL1].*/}
                </div>

                <FaqQuestion content="To whom will the data collected be communicated?" />
                <FaqAnswer content="We will only disclose your personal data if we are obliged to do so in order to comply with our legal or regulatory obligations, for business or administrative reasons or because you have asked us to do so. This probably also includes disclosure:" />
                <ul className="text-tertiary text-xl mt-3">
                    <li>within the Company;</li>
                    <li>to third parties who process personal data on our behalf (IT system providers and other service providers);</li>
                    <li>to any government, regulatory agency, enforcement or exchange body or court that so requires under applicable law or regulation.</li>
                </ul>
                
                <FaqQuestion content="Can the Company transfer data to a third country and/or international organisations?" />
                <FaqAnswer content="The data collected by the Company are stored in Switzerland. Should we transfer your data outside Switzerland, we will ensure that in the first instance the data is stored in countries that guarantee adequate protection of personal data, such as member states of the European Economic Area. If this is not possible, we will ensure that the provisions of the applicable legislation on the transfer of personal data to third countries are complied with in all cases." />

                <FaqQuestion content="How we protect your personal data" />
                <FaqAnswer content="The security of your personal data is important to us and we use various technical and organisational measures to protect it." />
                <FaqAnswer content="We are committed to safeguarding and protecting personal data by taking appropriate measures against accidental or unlawful destruction, loss, alteration or unauthorised disclosure." />
                
                <FaqQuestion content="Rights of the interested parties" />
                <FaqAnswer content="Under the privacy regulations, users have a number of rights in relation to their personal data. They have the right to request access, correction or deletion of such information, the right to limit or oppose the processing and, under certain circumstances, the right to data portability." />
                <div className="text-tertiary text-xl">
                If a user has given his or her consent to the processing of his or her data, he or she has the right (under certain circumstances) to revoke this consent at any time. If you wish to exercise the above rights, please send an e-mail to <a className="text-succ2" href="mailto:privacy@dexx.finance">privacy@dexx.finance</a> describing your situation and we will help you resolve the issue.
                </div>
                <FaqAnswer content="We try to answer you within a maximum of one month, although we reserve the right to extend this period for more complex requests. We also reserve the right to charge a reasonable administration fee for any manifestly unfounded or excessive requests for access to personal data and for any additional copies of personal data requested." />

                <div className="mb-20"></div>
            </div>
        </div>
    </>
}