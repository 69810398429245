import arrow from "../media/arrow2.png"

export function HeaderButton(props: {click: any, className: string, name: string, showArror?: boolean, 
        color1: string, color2: string, id?: any, shown?: boolean}) {
    const toBeShown = props.shown ? props.shown : false 
    return <div onClick={props.click} id={props.id} key={props.id}
        className={"uppercase py-4 flex items-center "+
        //bg-seafoam text-white ": " text-tertiary hover:text-seafoam"
             
            (" hover:text-"+props.color1+" text-tertiary ") +
            props.className}
        >
            {props.showArror ? 
                <img src={arrow} className={"transform rotate-45 "+(toBeShown ? "" : " invisible ")}/>
            :
            <></>
            }
            
            {props.name}</div>
}

export function DexxTableTitle(props: {label: string, active: boolean, dark?: boolean,
        className?: string, onClick?: Function}): JSX.Element {
    return <>
        <div className={"uppercase py-5 flex items-center "+
            " font-normal text-xs md:text-base cursor-pointer "+
            (props.active ? " bg-platinum text-dark_blue " : (" text-white ")+
            (props.dark ? " bg-dark_blue_lighter " : " "))
            +props.className}
            onClick={() => {if(props.onClick) props.onClick()}}
        >   
            {props.label}</div>
    </>
}
export function DexxHeaderElement(props: {label: string, active: boolean, onClick: Function,
        color?: string}): JSX.Element {
    const color = props.color ? props.color : "pastel_blue"
    return <>
        <div className={"pr-8 rounded-l-full bg-gradient-to-r to-transparent cursor-pointer"+
                " pl-4 py-2 max-w-xl text-sm md:text-lg uppercase text-white bg-opacity-10 flex items-center "+
                "from-"+(props.active ? color : "dark_blue")}
            onClick={() => props.onClick()}>
            <img src={arrow} className={"transform rotate-45 "+(props.active ? "" : " invisible ")}/>
            {props.label}
        </div>
    </>
}