import { Actions } from "../actions";
import { ActionType } from "../action-types"

interface removeMediaState {
    loading: boolean
    payload?: any
    card?: boolean
}

const defaultRemoveMediaState: removeMediaState = {
    loading: false,
}

const removeMediaReducer = (state: removeMediaState = defaultRemoveMediaState, action: Actions): removeMediaState => {
    switch (action.type) {
        case (ActionType.REMOVE_MEDIA_REQUEST):
            return {
                loading: true
            }
        case (ActionType.REMOVE_MEDIA_FAILURE):
            return {
                loading: false,
                payload: action.error
            }
        case (ActionType.REMOVE_MEDIA_SUCCESS):
            return {
                loading: false,
                payload: action.payload,
            }
        case (ActionType.REMOVE_CARD_MEDIA_SUCCESS):
            return {
                loading: false,
                payload: action.payload,
                card: true
            }
        case (ActionType.REMOVE_MEDIA_RESULT_SHOWN):
            return {
                loading: false
            }
        case (ActionType.CLEAR_ALL):
            return {
                loading: false,
                payload: undefined
            }
        default:
            return state
    }
}

export default removeMediaReducer