import { Actions } from "../actions";
import { defaultIssuerTransactionsList, IssuerTransaction, IssuerTransactionList } from "../../PoolContext"
import { ActionType } from "../action-types"

interface issuerTransactionsState {
    loading: boolean
    payload?: IssuerTransactionList
}

const defaultIssuerTransactionsState: issuerTransactionsState = {
    loading: false,
    payload: defaultIssuerTransactionsList
}

const issuerTransactionsReducer = (state: issuerTransactionsState = defaultIssuerTransactionsState, action: Actions): issuerTransactionsState => {
    switch (action.type) {
        case (ActionType.GET_ISSUER_TRANSACTIONS_REQUEST):
            return {
                loading: true
            }
        case (ActionType.GET_ISSUER_TRANSACTIONS_FAILURE):
            return {
                loading: false,
                payload: action.error
            }
        case (ActionType.GET_ISSUER_TRANSACTIONS_SUCCESS):
            return {
                loading: false,
                payload: action.payload
            }
        case (ActionType.CLEAR_ALL):
            return {
                loading: false,
                payload: undefined
            }
        default:
            return state
    }
}

export default issuerTransactionsReducer