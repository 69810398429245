import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { DexxTable } from "../components/light/LightTable";
import { SelectFilter, InputFilter } from "../components/light/TableFilters";
import { defaultBalance, defaultTransactionsList, defaultBankTransactionsList } from "../PoolContext";
import { actionCreators, State } from "../state";
import { euroFormat, euroQtyFormat, euroFeesFormat, formatDate, formatDatetimeOffset } from "../utils";
import { CSVLink } from 'react-csv';
import DepositWithdrawInterface from "../components/DepositWithdrawInterface";
import { DexxTableTitle } from "../components/HeaderButton";
import { BackButton, DexxButton } from "../components/light/LightButton";
import { Link } from 'react-router-dom'
import { DexxPopup } from "../components/Popup";
import { BlueLine, DexxPieChart } from "../components/utils/DexxElements";
import { DexxBalance, DexxSubBalance } from "../components/typography/Typogtaphy";
import { SelectChangeEvent } from '@mui/material/Select'

export default function PortfolioRoute(): JSX.Element {
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const transactionsListState = useSelector((state: State) => state.transactions)
    const [primarySaleList, setPrimarySaleList] = useState<any>(defaultTransactionsList.primary_sales)
    const [liquidationsList, setLiquidationsList] = useState<any>(defaultTransactionsList.buybacks)
    const [liquidityPoolsList, setLiquidityPoolsList] = useState<any>(defaultTransactionsList.liquidity_pools)
    const [p2pList, setP2pList] = useState<any>(defaultTransactionsList.peer_to_peer)
    const [bankTxnList, setBankTxnList] = useState(defaultBankTransactionsList.bank_transactions)
    const [balance, setBalance] = useState(defaultBalance)
    const [shares, setShares] = useState<any[]>([])
    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [resetAmountPopup, setResetAmountPopup] = useState<boolean>(false)
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload
    let isDefaultPopup = userCtx?.popup_withdraw_type ? false : true
    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0
    
    const [rsSecurities, setRsSecurities] = useState<any[]>([])
    const [rsTxn, setRsTxn] = useState<any[]>([])
    const [rsFilteredTxn, setRsFilteredTxn] = useState<any[]>([])
    const [rsBank, setRsBank] = useState<any[]>([])
    const [rsLiquidated, setRsLiquidated] = useState<any[]>([])
    const [rsLiquidity, setRsLiquidity] = useState<any[]>([])

    const [rsSecuritiesFormatted, setRsSecuritiesFormatted] = useState<any[]>([])
    const [rsTxnFormatted, setRsTxnFormatted] = useState<any[]>([])
    const [rsBankFormatted, setRsBankFormatted] = useState<any[]>([])
    const [rsLiquidatedFormatted, setRsLiquidatedFormatted] = useState<any[]>([])
    const [rsLiquidityFormatted, setRsLiquidityFormatted] = useState<any[]>([])
    
    const [sortTrxBy, setSortTrxtBy] = useState<number>(0)
    const [sortTrxDESC, setSortTrxDESC] = useState<boolean>(true)
    const [sortBankBy, setSortBanktBy] = useState<number>(0)
    const [sortBankDESC, setSortBankDESC] = useState<boolean>(true)

    const [callsControl, setCallsControl] = useState<boolean>(false)   
    
    const [filterStatus, setFilterStatus] = useState('all')
    const [filterSCResponse, setFilterSCResponse] = useState('all')
    const [filterCategory, setFilterCategory] = useState('all')
    const [filterTicker, setFilterTicker] = useState('')
    
    const headersSec = ["Asset name", "Cat", "Ticker", "Total Quantity", "Available Quantity", "Price", "Value", "Portfolio weight", ""/*, ""*/]
    const alignmentSec = ["left", "left", "center", "right", "right", "right", "right", "right", "center"/*, ""*/]
    
    const headersTransactions = [
        {name: "Date", sort: () => {sortRows((a: any, b: any) => ((new Date(formatDatetimeOffset(a[0], offset)) < new Date(formatDatetimeOffset(b[0], offset))) ? 1 : -1), 0, rsTxn, setRsTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}])}},
        /*{name: "Date", sort: () => {sortRows((a: any, b: any) => ((new Date(a[0]) < new Date(b[0])) ? 1 : -1), 0, rsTxn, setRsTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}])}},  */
        {name: "Status", sort: () => {sortRows((a: any, b: any) => ((a[1] > b[1]) ? 1 : -1), 1, rsTxn, setRsTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}] )}},
        {name: "Response", sort: () => {sortRows((a: any, b: any) => ((b[2]) ? ((a[2] > b[2]) ? 1 : ((b[2] > a[2]) ? -1 : 0)) : -1), 2, rsTxn, setRsTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}] )}},
        {name: "Cat", sort: () => {sortRows((a: any, b: any) => ((a[3] - b[3]) || (a[3] > b[3]) ? 1 : -1), 3, rsTxn, setRsTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}] )}},
        {name: "Type of Security Token", sort: () => {sortRows((a: any, b: any) => ((a[4] > b[4]) ? 1 : -1), 4, rsTxn, setRsTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}] )}},
        {name: "Token name", sort: () => {sortRows((a: any, b: any) => ((a[5] > b[5]) ? 1 : -1), 5, rsTxn, setRsTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}] )}},
        {name: "Ticker", sort: () => {sortRows((a: any, b: any) => ((a[6] > b[6]) ? 1 : -1), 6, rsTxn, setRsTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}] )}}, 
        "Number of Tokens", "Price", "Fees", "Fees %", "Value", "Total",
        {name: "Trans Type", sort: () => {sortRows((a: any, b: any) => ((a[13] > b[13]) ? 1 : -1), 13, rsTxn, setRsTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}] )}},
        {name: "Counterpart", sort: () => {sortRows((a: any, b: any) => ((a[14] > b[14]) ? 1 : -1), 14, rsTxn, setRsTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}] )}}, 
        "Hash on Blockchain"]
    const formattedHeaderTransactions = ["Date","Status","Response","Cat","Type of Security Token","Token name","Ticker",
        "Number of Tokens", "Price", "Fees", "Fees %", "Value", "Total","Trans Type","Counterpart","Hash on Blockchain",]
    const alignmentTransactions = ["center", "left", "left", "left", "left", "left", "center",
        "right", "right", "right", "right", "right", "right","left", "left", "left"]
    
    const headersLiquidations = ["Date", "Status", "Cat",  "Type of Security Token", "Token name", "Ticker",
         "Number of Tokens", "Price per unit", "Fees", "Value", "Total","Trans Type", "Counterpart", "Hash on Blockchain", ""]
    const alignmentLiquidations = ["center", "left", "left", "left", "left", "center",
        "right", "right", "right", "right", "right", "left", "left", "left", "center"]
    
    const headersBankTxns = [
        {name: "Trans Type", sort: () => {sortRows((a: any, b: any) => ((a[0] > b[0]) ? 1 : -1), 0, rsBank, setRsBank, sortBankBy, setSortBanktBy, sortBankDESC, setSortBankDESC, [{index:2, format:"date"}, {index:4, format:"date"}])}},
        {name: "Status", sort: () => {sortRows((a: any, b: any) => ((a[1] > b[1]) ? 1 : -1), 1, rsBank, setRsBank, sortBankBy, setSortBanktBy, sortBankDESC, setSortBankDESC,  [{index:2, format:"date"}, {index:4, format:"date"}])}},
        {name: "Date Request", sort: () => {sortRows((a: any, b: any) => new Date(a[2]) < new Date(b[2]) ? 1 : -1, 2, rsBank, setRsBank, sortBankBy, setSortBanktBy, sortBankDESC, setSortBankDESC, [{index:2, format:"date"}, {index:4, format:"date"}])}}, 
        "Requested Amount", 
        {name: "Date Executed", sort: () => {sortRows((a: any, b: any) => ((b[4] && b[4]!=="-") ? ((new Date(a[4]) < new Date(b[4])) ? 1 : ((new Date(b[4]) < new Date(a[4])) ? -1 : 0)) : -1), 4, rsBank, setRsBank, sortBankBy, setSortBanktBy, sortBankDESC, setSortBankDESC, [{index:2, format:"date"}, {index:4, format:"date"}])}},  
        "Minted Amount", "Hash on Blockchain"]
    const formattedHeaderBankTransactions = ["Trans Type","Status","Date Request","Requested Amount","Date Executed","Minted Amount", "Hash on Blockchain"]
    const alignmentBankTxns = ["left", "left", "center", "right",  "center", "right", "left"]
    
    const headersShares = ["LP name", "Shares", "% Shares", ""]
    const alignmentShares = ["left", "right", "right", "center"]

    const rowsSec:any[] = [], rowsTransactions:any[] = [], rowsBankTxns:any[] = [], rowLiquidated:any[] = [], rowShares:any[] = []
    const formattedSec:any[] = [], formattedTxn:any[] = [], formattedBankTxn:any[] = [], formattedLiquidations:any[] = [], formattedShares:any[] = []

    useEffect(() => {
        if(!callsControl){
            actions.getTransactions()
            actions.getBankTransactions(setBankTxnList)
            actions.getActualBalance(setBalance)
            actions.getInvestorShares("", true, setShares)
            setCallsControl(true)
        }
    }, [])

    useEffect(() => {
        if (transactionsListState.payload ) {
            setPrimarySaleList(transactionsListState.payload.primary_sales ? transactionsListState.payload.primary_sales : [])
            setLiquidationsList(transactionsListState.payload.buybacks ? transactionsListState.payload.buybacks : [])
            setLiquidityPoolsList(transactionsListState.payload.liquidity_pools ? transactionsListState.payload.liquidity_pools : [])
            setP2pList(transactionsListState.payload.peer_to_peer ? transactionsListState.payload.peer_to_peer : [])
        }
    }, [transactionsListState.payload])

    useEffect(() => {
        if (balance ) {
            if(balance.balance){
                for (let r of balance.balance.security_entries) {
                    for (let e of r.class_balance) {
                        rowsSec.push([e.asset_name, r.class, e.symbol, euroQtyFormat(e.quantity), 
                            euroQtyFormat(e.available_quantity), euroFormat(e.current_price) + " " + e.currency, 
                            euroFormat(parseFloat(e.value)), euroFeesFormat(e.pct)+"%", 
                            <Link className="grid justify-center" to ={(!e.lp_flag && !e.p2p_flag) ? 
                                    "primary_sale/"+e.active_primary_sale : "/token/"+e.token_uuid}>
                                <DexxButton label={"Go to the Market"} className="px-2 rounded-lg "
                                    fontClasses="text-xs" color2="primary" color1="white"/>
                            </Link>])
                        formattedSec.push([e.asset_name, r.class, e.symbol, euroQtyFormat(e.quantity), 
                            euroQtyFormat(e.available_quantity), euroFormat(e.current_price) + " " + e.currency, 
                            euroFormat(parseFloat(e.value)), euroFeesFormat(e.pct)+"%"])
                        if(rowsSec.length) setRsSecurities(rowsSec)
                        if(formattedSec.length) setRsSecuritiesFormatted(formattedSec)
                    }
                }
            } 
        }
    }, [balance])

    useEffect(() => {
        if(primarySaleList && liquidationsList && liquidityPoolsList && p2pList){
            for (let t of primarySaleList) {
        
                if(t.txn_operation === "buy"){
                    rowsTransactions.push([/*formatDatetimeOffset(t.timestamp, offset)*/t.timestamp, t.status, 
                        t.response.OK === true ? "Success" : t.response.ERROR, t.asset_category, 
                        t.asset_type, t.asset_name, t.token_ticker, 
                        euroQtyFormat(t.quantity), 
                        "€"+euroFormat(t.price_per_unit), 
                        "€"+euroFormat(t.response.FEES?.buyer?.amount),
                        t.response.FEES?.buyer?.pct + "%", 
                        "€"+euroFormat(t.net_amount), 
                        "€"+euroFormat(t.gross_amount), 
                        t.txn_operation, t.txn_category, 
                        <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>
                    ])
                    formattedTxn.push([formatDatetimeOffset(t.timestamp, offset), t.status, 
                        t.response.OK === true ? "Success" : t.response.ERROR, t.asset_category, 
                        t.asset_type, t.asset_name, t.token_ticker, 
                        euroQtyFormat(t.quantity), 
                        "€"+euroFormat(t.price_per_unit), 
                        "€"+euroFormat(t.response.FEES?.buyer?.amount),
                        t.response.FEES?.buyer?.pct + "%", 
                        "€"+euroFormat(t.net_amount), 
                        "€"+euroFormat(t.gross_amount), 
                        t.txn_operation, t.txn_category,t.trx_identifier])
                }
            }
        
            for (let t of liquidationsList) {
                rowsTransactions.push([/*formatDatetimeOffset(t.timestamp, offset)*/t.timestamp, t.status, t.status === "Executed" ? "Success" : "Failed", t.asset_category, t.asset_type, t.asset_name, t.token_ticker, 
                    euroQtyFormat(t.quantity), 
                    "€"+euroFormat(t.price_per_unit), 
                    "€"+euroFormat(t.fees), 
                    "",
                    "€"+euroFormat(t.net_amount), 
                    "€"+euroFormat(t.gross_amount), 
                    t.txn_operation, t.txn_category, <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>  ])
                formattedTxn.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.status === "Executed" ? "Success" : "Failed", t.asset_category, t.asset_type, t.asset_name, t.token_ticker, 
                euroQtyFormat(t.quantity), 
                "€"+euroFormat(t.price_per_unit), 
                "€"+euroFormat(t.fees), 
                "€"+euroFormat(t.net_amount), 
                "€"+euroFormat(t.gross_amount), 
                t.txn_operation, t.txn_category,t.trx_identifier])
            }
        
            for (let t of liquidityPoolsList) { 
                if((t.txn_operation === "swap buy" || t.txn_operation === "swap sell") && t.status === "Executed"){ //success 
                    rowsTransactions.push([/*formatDatetimeOffset(t.timestamp, offset)*/t.timestamp, t.status, t.response.OK === true ? "Success" : t.response.ERROR, t.asset_a_category, t.asset_a_type, t.asset_a_name, t.token_a_ticker, 
                        t.response.INFO.quote_result.amount_a? euroQtyFormat(t.response.INFO.quote_result.amount_a) : "", 
                        t.response.INFO.quote_result.amount_b && t.response.INFO.quote_result.amount_a ? "€"+euroFormat(parseFloat(t.response.INFO.quote_result.amount_b) / parseFloat(t.response.INFO.quote_result.amount_a) ):"", 
                        t.response.INFO.quote_result.dexx_fees || t.response.INFO.quote_result.lp_fees ? "€"+euroFormat(parseFloat(t.response.INFO.quote_result.dexx_fees) + parseFloat(t.response.INFO.quote_result.lp_fees)) : "",
                        t.response.OK === true ?
                            (parseFloat(t.response.FEES?.dexx_fee?.pct) + parseFloat(t.response.FEES?.lp_fee?.pct))  + "%"
                        : "",
                        t.response.INFO.quote_result.amount_b ? "€"+euroFormat(parseFloat(t.response.INFO.quote_result.amount_b)) :"", 
                        t.response.INFO.quote_result.gross_amount ? "€"+euroFormat(t.response.INFO.quote_result.gross_amount) :"", 
                            t.txn_operation.replace("swap ", ""), "Liquidity Pool", <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>  ])
                    formattedTxn.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.response.OK === true ? "Success" : t.response.ERROR, t.asset_a_category, t.asset_a_type, t.asset_a_name, t.token_a_ticker, 
                        t.response.INFO.quote_result.amount_a? euroQtyFormat(t.response.INFO.quote_result.amount_a) : "", 
                        t.response.INFO.quote_result.amount_b && t.response.INFO.quote_result.amount_a ? "€"+euroFormat(parseFloat(t.response.INFO.quote_result.amount_b) / parseFloat(t.response.INFO.quote_result.amount_a) ):"", 
                        t.response.INFO.quote_result.dexx_fees || t.response.INFO.quote_result.lp_fees ? "€"+euroFormat(parseFloat(t.response.INFO.quote_result.dexx_fees) + parseFloat(t.response.INFO.quote_result.lp_fees)) : "",
                        t.response.OK === true ?
                            t.response.FEES?.buyer?.pct ? 
                                (parseFloat(t.response.FEES?.dexx_fee?.pct) + parseFloat(t.response.FEES?.lp_fee?.pct))  + "%"
                                : 
                                ""
                        : "",
                        t.response.INFO.quote_result.amount_b ? "€"+euroFormat(parseFloat(t.response.INFO.quote_result.amount_b)) :"", 
                        t.response.INFO.quote_result.gross_amount ? "€"+euroFormat(t.response.INFO.quote_result.gross_amount) :"", 
                        t.txn_operation.replace("swap ", ""), "Liquidity Pool",t.trx_identifier])
                }
                else
                if((t.txn_operation === "swap buy" || t.txn_operation === "swap sell") && t.status === "Failed"){ // failed
                    rowsTransactions.push([/*formatDatetimeOffset(t.timestamp, offset)*/t.timestamp, t.status, t.response.ERROR, t.asset_a_category, t.asset_a_type, t.asset_a_name, t.token_a_ticker, 
                        "", 
                        "",
                        "",
                        "", 
                        "",
                        "", 
                            t.txn_operation.replace("swap ", ""), "Liquidity Pool", <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>  ])
                    formattedTxn.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.response.ERROR, t.asset_a_category, t.asset_a_type, t.asset_a_name, t.token_a_ticker, 
                        "", 
                        "",
                        "", 
                        "",
                        "",
                        "", 
                            t.txn_operation.replace("swap ", ""), "Liquidity Pool",t.trx_identifier])
                }
                else       
                if(t.txn_operation === "add" && t.status === 'Executed'){
                    let amount_a = t.response?.INFO?.add_liquidity?.amount_a? t.response.INFO.add_liquidity.amount_a : ""
                    let amount_b = t.response?.INFO?.add_liquidity?.amount_b? t.response.INFO.add_liquidity.amount_b : ""
                    rowsTransactions.push([/*formatDatetimeOffset(t.timestamp, offset)*/t.timestamp, t.status, t.response.OK === true ? "Success" : t.response.ERROR, t.asset_a_category, t.asset_a_type, t.asset_a_name, t.token_a_ticker, 
                        amount_a !== ""? euroQtyFormat(amount_a) : "", 
                        amount_b !== ""? "€"+euroQtyFormat(amount_b) : "", 
                        /*t.response.FEES.dexx_fee.amount || t.response.FEES.lp_fee.amount ? "€"+euroFormat(parseFloat(t.response.FEES.dexx_fee.amount) + parseFloat(t.response.FEES.lp_fee.amount)) :*/ "€"+euroFormat("0"),
                        "",
                        amount_b !== "" ?"€"+euroFormat(parseFloat(amount_b)*2):"", 
                        /*amount_b !== "" && (t.response.FEES.dexx_fee.amount || t.response.FEES.lp_fee.amount) ? "€"+euroFormat(parseFloat(amount_b)*2 + parseFloat(t.response.FEES.dexx_fee.amount) + parseFloat(t.response.FEES.lp_fee.amount)):*/ amount_b !== "" ? "€"+euroFormat(parseFloat(amount_b)*2):"", 
                        t.txn_operation, t.txn_category, <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>  ])
                    formattedTxn.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.response.OK === true ? "Success" : t.response.ERROR, t.asset_a_category, t.asset_a_type, t.asset_a_name, t.token_a_ticker, 
                        amount_a !== ""? euroQtyFormat(amount_a) : "", 
                        amount_b !== ""? "€"+euroQtyFormat(amount_b) : "", "€"+euroFormat("0"), "",
                        amount_b !== "" ?"€"+euroFormat(parseFloat(amount_b)*2):"", amount_b !== "" ? "€"+euroFormat(parseFloat(amount_b)*2):"", 
                        t.txn_operation, t.txn_category,t.trx_identifier])
                }
                else
                if(t.txn_operation === "add" && t.status !== 'Executed'){
                    rowsTransactions.push([/*formatDatetimeOffset(t.timestamp, offset)*/t.timestamp, t.status, t.response.ERROR, t.asset_a_category, t.asset_a_type, t.asset_a_name, t.token_a_ticker, 
                        "", 
                        "",
                        "", 
                        "",
                        "",
                        "", 
                        t.txn_operation, t.txn_category, <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>  ])
                    formattedTxn.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.response.ERROR, t.asset_a_category, t.asset_a_type, t.asset_a_name, t.token_a_ticker, 
                        "","","","","","", 
                        t.txn_operation, t.txn_category,t.trx_identifier])
                }
                else
                if(t.txn_operation === "remove" && t.status === 'Executed'){
                    let amount_a = t.response?.INFO?.remove_liquidity?.amount_a? t.response.INFO.remove_liquidity.amount_a : ""
                    let amount_b = t.response?.INFO?.remove_liquidity?.amount_b? t.response.INFO.remove_liquidity.amount_b : ""
                    rowsTransactions.push([/*formatDatetimeOffset(t.timestamp, offset)*/t.timestamp, t.status, t.response.OK === true ? "Success" : t.response.ERROR, t.asset_a_category, t.asset_a_type, t.asset_a_name, t.token_a_ticker, 
                        amount_a !== ""? euroQtyFormat(amount_a) : "", 
                        amount_b !== ""? "€"+euroFormat(amount_b) : "", 
                        /*t.response.FEES.dexx_fee.amount || t.response.FEES.lp_fee.amount ? "€"+euroFormat(parseFloat(t.response.FEES.dexx_fee.amount) + parseFloat(t.response.FEES.lp_fee.amount)) :*/ "",
                        "",
                        amount_b !== "" ?"€"+euroFormat(parseFloat(amount_b)*2):"", 
                        /*amount_b !== "" && (t.response.FEES.dexx_fee.amount || t.response.FEES.lp_fee.amount) ? "€"+euroFormat(parseFloat(amount_b)*2 + parseFloat(t.response.FEES.dexx_fee.amount) + parseFloat(t.response.FEES.lp_fee.amount)):*/ amount_b !== "" ? "€"+euroFormat(parseFloat(amount_b)*2):"", 
                        t.txn_operation, t.txn_category, <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>  ])
                    formattedTxn.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.response.OK === true ? "Success" : t.response.ERROR, t.asset_a_category, t.asset_a_type, t.asset_a_name, t.token_a_ticker, 
                        amount_a !== ""? euroQtyFormat(amount_a) : "", 
                        amount_b !== ""? "€"+euroFormat(amount_b) : "", "", "",
                        amount_b !== "" ?"€"+euroFormat(parseFloat(amount_b)*2):"", amount_b !== "" ? "€"+euroFormat(parseFloat(amount_b)*2):"", 
                        t.txn_operation, t.txn_category,t.trx_identifier])
                }
                else
                if(t.txn_operation === "remove" && t.status === 'Failed'){
                    rowsTransactions.push([/*formatDatetimeOffset(t.timestamp, offset)*/t.timestamp, t.status, t.response.ERROR, t.asset_a_category, t.asset_a_type, t.asset_a_name, t.token_a_ticker, 
                        "", 
                        "",
                        "", 
                        "",
                        "",
                        "", 
                        t.transaction_operation, t.txn_category, <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>  ])
                    formattedTxn.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.response.ERROR, t.asset_a_category, t.asset_a_type, t.asset_a_name, t.token_a_ticker, 
                        "","","","","", "",
                        t.transaction_operation, t.txn_category,t.trx_identifier])
                }
            }    
             
            for (let t of p2pList) {
                
                if(t.txn_operation === "create"){
        
                    rowsTransactions.push([/*formatDatetimeOffset(t.timestamp, offset)*/t.timestamp, t.status, 
                        t.response.OK === true ? "Success" : t.response.ERROR, t.asset_category, 
                        t.asset_type, t.asset_name, t.token_ticker, 
                        euroQtyFormat(t.token_total_amount), "€"+euroFormat(t.price_per_unit), 
                        "", "",
                        /*t.response.OK === true ? "€"+euroFormat(t.net_amount) :*/ "", 
                        /*t.response.OK === true ? "€"+euroFormat(t.gross_amount) :*/ "",
                        t.txn_operation + "(" + t.p2p_type + ")", t.txn_category, 
                        <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>  
                    ])
                    formattedTxn.push([formatDatetimeOffset(t.timestamp, offset), t.status, 
                        t.response.OK === true ? "Success" : t.response.ERROR, t.asset_category, 
                        t.asset_type, t.asset_name, t.token_ticker, 
                        euroQtyFormat(t.token_total_amount), "€"+euroFormat(t.price_per_unit), 
                        "", "",
                        /*t.response.OK === true ? "€"+euroFormat(t.net_amount) : */"", 
                        /*t.response.OK === true ? "€"+euroFormat(t.gross_amount) : */"",
                        t.txn_operation + "(" + t.p2p_type + ")", t.txn_category,t.trx_identifier])
        
                } else if(t.txn_operation === "delete"){
        
                    rowsTransactions.push([/*formatDatetimeOffset(t.timestamp, offset)*/t.timestamp, t.status, 
                        t.response.OK === true ? "Success" : t.response.ERROR, t.asset_category, 
                        t.asset_type, t.asset_name, t.token_ticker, 
                        "", "", "", "", "", "",
                        t.txn_operation/* + "(" + t.p2p_type + ")"*/, t.txn_category, 
                        <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>  
                    ])
                    formattedTxn.push([formatDatetimeOffset(t.timestamp, offset), t.status, 
                        t.response.OK === true ? "Success" : t.response.ERROR, t.asset_category, 
                        t.asset_type, t.asset_name, t.token_ticker, 
                        "", "", "", "", "", "",
                        t.txn_operation + "(" + t.p2p_type + ")", t.txn_category,t.trx_identifier])
        
                } else if(t.txn_operation === "fill"){
        
                    rowsTransactions.push([/*formatDatetimeOffset(t.timestamp, offset)*/t.timestamp, t.status, 
                        t.response.OK === true ? "Success" : t.response.ERROR, t.asset_category, 
                        t.asset_type, t.asset_name, t.token_ticker, 
                        euroQtyFormat(t.amount_filled), "€"+euroFormat(t.price_per_unit), 
                        t.response.OK === true ?
                            t.response.FEES?.buyer?.amount ? 
                                "€"+euroFormat(t.response.FEES?.buyer?.amount)
                                : 
                                "€"+euroFormat(t.response.FEES?.seller?.amount)
                        : "", 
                        t.response.OK === true ?
                            t.response.FEES?.buyer?.pct ? 
                                t.response.FEES?.buyer?.pct + "%"
                                : 
                                t.response.FEES?.seller?.pct + "%"
                        : "",
                        t.response.OK === true ? "€"+euroFormat(t.net_amount) : "", 
                        t.response.OK === true ? "€"+euroFormat(t.gross_amount) : "",
                        (t.is_client && !t.is_owner ? 
                            "buyer - " : 
                        !t.is_client && t.is_owner ? 
                            "seller - " :
                        t.is_client && t.is_owner ? 
                            "by myself - " :
                        !t.is_client && !t.is_owner ? 
                            "nobody - " : "error") 
                        + 
                        t.txn_operation + "(" + t.p2p_type + ")", t.txn_category, 
                        <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>  
                    ])
                    formattedTxn.push([formatDatetimeOffset(t.timestamp, offset), t.status, 
                        t.response.OK === true ? "Success" : t.response.ERROR, t.asset_category, 
                        t.asset_type, t.asset_name, t.token_ticker, 
                        euroQtyFormat(t.amount_filled), "€"+euroFormat(t.price_per_unit), 
                        t.response.OK === true ?
                            t.response.FEES?.buyer?.amount ? 
                                ("€"+euroFormat(t.response.FEES?.buyer?.amount), t.response.FEES?.buyer?.pct + "%" )
                                : 
                                ("€"+euroFormat(t.response.FEES?.seller?.amount), t.response.FEES?.seller?.pct + "%" )
                        : "", "",
                        t.response.OK === true ? "€"+euroFormat(t.net_amount) : "", 
                        t.response.OK === true ? "€"+euroFormat(t.gross_amount) : "",
                        (t.is_client && !t.is_owner ? 
                            "buyer - " : 
                        !t.is_client && t.is_owner ? 
                            "seller - " :
                        t.is_client && t.is_owner ? 
                            "by myself - " :
                        !t.is_client && !t.is_owner ? 
                            "nobody - " : "error") 
                        + 
                        t.txn_operation + "(" + t.p2p_type + ")", t.txn_category,t.trx_identifier])
                
                } else {
                    
                    rowsTransactions.push([/*formatDatetimeOffset(t.timestamp, offset)*/t.timestamp, t.status, 
                        t.response.OK === true ? "Success" : t.response.ERROR, t.asset_category, 
                        t.asset_type, t.asset_name, t.token_ticker, 
                        "", "", "", "", "", "",
                        "UNKNOWN" + t.txn_operation + "(" + t.p2p_type + ")", t.txn_category, 
                        <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>  
                    ])
                    formattedTxn.push([formatDatetimeOffset(t.timestamp, offset), t.status, 
                        t.response.OK === true ? "Success" : t.response.ERROR, t.asset_category, 
                        t.asset_type, t.asset_name, t.token_ticker, 
                        "", "", "", "", "", "",
                        "UNKNOWN" + t.txn_operation + "(" + t.p2p_type + ")", t.txn_category,t.trx_identifier])
                }
            }
            //console.log(rowsTransactions)
            rowsTransactions.sort((a: any, b: any) => ((new Date(formatDatetimeOffset(a[0], offset)) < new Date(formatDatetimeOffset(b[0], offset))) ? 1 : -1)) 
            rowsTransactions.forEach((obj:any) => {
                obj[Object.keys(obj)[0]] = new Date(obj[Object.keys(obj)[0]]).toLocaleString();
            });
            formattedTxn.sort((a: any, b: any) => ((new Date(a[0]) < new Date(b[0])) ? 1 : -1)) 
            formattedTxn.forEach((obj:any) => {
                obj[Object.keys(obj)[0]] = new Date(obj[Object.keys(obj)[0]]).toLocaleString();
            });

            if(rowsTransactions.length) setRsTxn(rowsTransactions)
            if(formattedTxn.length) setRsTxnFormatted(formattedTxn)
        }
    }, [primarySaleList, liquidationsList, liquidityPoolsList, p2pList])

    useEffect(() => {
        if(bankTxnList){
            for (let t of bankTxnList) {
                rowsBankTxns.push([t.transaction_type, t.status, /*formatDate(t.requested_at, offset)*/t.requested_at, "€"+euroFormat(t.requested_amount), 
                /*t.executed_at ? formatDate(t.executed_at, offset) : "-"*/t.executed_at, t.minted_amount ? "€"+euroFormat(t.minted_amount) : "-", 
                <Link to={"/blockchain/transaction/"+t.trx_chain_identifier}>{t.trx_chain_identifier}</Link> ])
                
                formattedBankTxn.push([t.transaction_type, t.status, formatDate(t.requested_at, offset), "€"+euroFormat(t.requested_amount), 
                t.executed_at ? formatDate(t.executed_at, offset) : "-", t.minted_amount ? "€"+euroFormat(t.minted_amount) : "-", t.trx_chain_identifier])
            }
        
            rowsBankTxns.forEach((obj:any) => {
                obj[Object.keys(obj)[2]] = new Date(formatDate(obj[Object.keys(obj)[2]], offset)).toLocaleString().split(", ")[0];
                if(obj[Object.keys(obj)[4]] && obj[Object.keys(obj)[4]] !== "-") 
                    obj[Object.keys(obj)[4]] = new Date(formatDate(obj[Object.keys(obj)[4]], offset)).toLocaleString().split(", ")[0];
                else obj[Object.keys(obj)[4]] = "-"
            });
            formattedBankTxn.forEach((obj:any) => {
                obj[Object.keys(obj)[2]] = new Date(formatDate(obj[Object.keys(obj)[2]], offset)).toLocaleString().split(", ")[0];
                if(obj[Object.keys(obj)[4]] !== "-") obj[Object.keys(obj)[4]] = new Date(formatDate(obj[Object.keys(obj)[4]], offset)).toLocaleString().split(", ")[0];
            });
            if(rowsBankTxns.length) setRsBank(rowsBankTxns)
            if(formattedBankTxn.length) setRsBankFormatted(formattedBankTxn)
        }
    }, [bankTxnList])

    useEffect(() => {
        if(liquidationsList){
            for (let t of liquidationsList) {
                rowLiquidated.push([formatDate(t.timestamp, offset), t.status, t.asset_category, t.asset_type, t.asset_name, t.token_ticker, 
                    euroQtyFormat(t.quantity), "€"+euroFormat(t.price_per_unit), "€"+euroFormat(t.fees), 
                    "€"+euroFormat(t.net_amount), "€"+euroFormat(t.gross_amount), 
                    t.txn_operation, t.txn_category, <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>,
                    <Link to ={"/asset/"+t.asset_uuid} className="grid justify-center">
                        <DexxButton label={"details"} className="px-2 rounded-lg "
                                        fontClasses="text-xs" color2="primary" color1="white"/>
                    </Link>  ])
                formattedLiquidations.push([formatDate(t.timestamp, offset), t.status, t.asset_category, t.asset_type, t.asset_name, t.token_ticker, 
                    euroQtyFormat(t.quantity), "€"+euroFormat(t.price_per_unit), "€"+euroFormat(t.fees), 
                    "€"+euroFormat(t.net_amount), "€"+euroFormat(t.gross_amount), 
                    t.txn_operation, t.txn_category,t.trx_identifier])
            }
            
            rowLiquidated.forEach((obj:any) => {
                obj[Object.keys(obj)[0]] = new Date(obj[Object.keys(obj)[0]]).toLocaleString().split(", ")[0];
            });
            formattedLiquidations.forEach((obj:any) => {
                obj[Object.keys(obj)[0]] = new Date(obj[Object.keys(obj)[0]]).toLocaleString().split(", ")[0];
            });
            if(rowLiquidated.length) setRsLiquidated(rowLiquidated)
            if(formattedLiquidations.length) setRsLiquidatedFormatted(formattedLiquidations)
        }
    }, [liquidationsList])

    useEffect(() => {
        if(shares){
            for (let t of shares) {
                rowShares.push([t.LPWE.lp_name, t.LPWE.qty_lp_token, 
                                euroFormat(100 * t.LPWE.qty_lp_token / t.LP.lp_token_supply) + "%",
                                <Link to ={"/token/"+t.LP.token_a_uuid+"/lp"} className="grid justify-center">
                                    <DexxButton label={"details"} className="px-2 rounded-lg "
                                        fontClasses="text-xs" color2="primary" color1="white"/></Link>])
                formattedShares.push([t.LPWE.lp_name, t.LPWE.qty_lp_token, 
                    euroFormat(100 * t.LPWE.qty_lp_token / t.LP.lp_token_supply) + "%"])
                if(rowShares.length) setRsLiquidity(rowShares)
                if(formattedShares.length) setRsLiquidityFormatted(formattedShares)
            }
        }
    }, [shares])

    const handleChangeStatusFilter = (event: SelectChangeEvent) => {
        setFilterStatus(event.target.value as string);
    }

    const handleChangeCategoryFilter = (event: SelectChangeEvent) => {
        setFilterCategory(event.target.value as string);
    }

    const handleChangeSCResponseFilter = (event: SelectChangeEvent) => {
        setFilterSCResponse(event.target.value as string);
    }

    const handleChangeTickerFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterTicker(event.target.value as string);
    }
    
    useEffect(() => {
        filterTxnRows()
    }, [filterStatus, filterSCResponse, filterCategory, filterTicker])
    
    function filterTxnRows() {

        let tmp:any[] = [...rsTxn]
        if(filterStatus !== 'all'){
            if(filterStatus === 'e') tmp = tmp.filter((i) => {return i[1] === "Executed"})
            if(filterStatus === 'p') tmp = tmp.filter((i) => {return i[1] === "Pending"})
            if(filterStatus === 'f') tmp = tmp.filter((i) => {return i[1] === "Failed"})
        }
        if(filterSCResponse !== 'all'){
            if(filterSCResponse === 's') tmp = tmp.filter((i) => {return i[2] === "Success"})
            //if(filterSCResponse === 'f') tmp = tmp.filter((i) => {return i.response.ERROR !== ""})
            if(filterSCResponse === 'f') tmp = tmp.filter((i) => {return i[2] !== "Success"})
        }
        if(filterCategory !== 'all'){
            if(filterCategory === 'a') tmp = tmp.filter((i) => {return i[3] === "ART"})
            if(filterCategory === 'i') tmp = tmp.filter((i) => {return i[3] === "AIF"})
            if(filterCategory === 's') tmp = tmp.filter((i) => {return i[3] === "SPORTEX"})
            if(filterCategory === 'c') tmp = tmp.filter((i) => {return i[3] === "COLLECTABLES"})
        }
        if(filterTicker !== ''){
            tmp = tmp.filter((i) => {return i[6].toLowerCase().includes(filterTicker.toLowerCase())})
        }
        setRsFilteredTxn(tmp)
    }

    function sortRows(sortMethod: any, index: number, 
                    recordset: any[], setRecordset: any, 
                    sortBy: number, setSortBy: any, 
                    sortDESC: boolean, setSortDESC: any, 
                    arrayDates: any[]) {
        
        var tmp = [...recordset]
        
        var italianFormat = false
        const test = "02-13-2018"
        var check = new Date(test).toLocaleString();
        if(check.split("/")[0] === "13") italianFormat = true

        if(italianFormat){
            arrayDates.forEach((el:any) => {
                tmp.forEach((obj:any) => {
                    if(obj[Object.keys(obj)[el.index]] && obj[Object.keys(obj)[el.index]] !== "-"){
                        if(el.format === "datetime") {
                            var dateSplit = obj[Object.keys(obj)[el.index]].split(", ")
                            var dateSplit2 = dateSplit[0].split("/")
                            var dateSplit3 = dateSplit[1].split(":")
                            var dateObject = new Date(parseInt(dateSplit2[2]), parseInt(dateSplit2[1])-1, parseInt(dateSplit2[0]), parseInt(dateSplit3[0]), parseInt(dateSplit3[1]), parseInt(dateSplit3[2])); 
                        } else {
                            var dateSplit = obj[Object.keys(obj)[el.index]].split("/")
                            var dateObject = new Date(parseInt(dateSplit[2]), parseInt(dateSplit[1])-1, parseInt(dateSplit[0])); 
                        }
                        obj[Object.keys(obj)[el.index]] = dateObject
                        
                    } else obj[Object.keys(obj)[el.index]] = "-"
                });
            }); 
        }

        tmp.sort(sortMethod)

        if(index === sortBy){
            if(sortDESC) tmp.reverse()
            setSortDESC(!sortDESC)
        } else {
            setSortBy(index)
            setSortDESC(true)
        }

        arrayDates.forEach((el:any) => {
            tmp.forEach((obj:any) => {
                if(obj[Object.keys(obj)[el.index]] && obj[Object.keys(obj)[el.index]] !== "-"){
                    if(el.format === "datetime") 
                        obj[Object.keys(obj)[el.index]] = new Date(formatDatetimeOffset(obj[Object.keys(obj)[el.index]], 0/*offset*/)).toLocaleString();
                    else if(el.format === "date") 
                        obj[Object.keys(obj)[el.index]] = new Date(formatDate(obj[Object.keys(obj)[el.index]], 0/*offset*/)).toLocaleString().split(", ")[0];   
                } else obj[Object.keys(obj)[el.index]] = "-"
            });
        }); 
        setRecordset(tmp)

        filterTxnRows()
    }
  
    const headers = ['Asset / Security tokens', 'liquidated tokens', 'liquidity pool position', 
        'transaction history', 'bank transaction history']
    const [toBeShown, setToBeShown] = useState(headers.map((_,i) => false))
    function handleClick(id: number) {
        var tmp = toBeShown.map((b,i) => i === id ? !b : b)
        setToBeShown(tmp)
    }

    function showTable(fileName: string, TableHeaders: any[], AlignContent: string[], TableFormatted: any[], TableRows: any[], FormattedHeader?:any[]) {
        return (   
        <div className="grid w-full bg-platinum " id={fileName}>
            <CSVLink className="border-solid py-2 px-5 border-crayola rounded-full uppercase 
                text-primary text-sm mb-2 ml-8 md:ml-16 
                w-fit align-middle bg-platinum border-1" 
                filename={fileName} headers={FormattedHeader ? FormattedHeader : TableHeaders} 
                data={TableFormatted}>Download CSV </CSVLink>
            <DexxTable headers={TableHeaders} rows={TableRows} alignment={AlignContent} />
        </div>)
    }

    function showTableWithFilters(fileName: string, TableHeaders: any[], AlignContent: string[], TableFormatted: any[], TableRows: any[], FormattedHeader?:any[]) {
        return (   
        <div className="grid w-full bg-platinum " id={fileName}>
            <div className="flex mb-4 align-items: center">
                <CSVLink className="border-solid py-4 px-5 border-crayola rounded-full uppercase 
                    text-primary text-sm mb-2 ml-8 md:ml-16 
                    w-fit align-middle bg-platinum border-1" 
                    filename={fileName} headers={FormattedHeader ? FormattedHeader : TableHeaders} 
                    data={TableFormatted}>Download CSV </CSVLink>
                <SelectFilter label="Status" value={filterStatus} onChange={handleChangeStatusFilter}
                        values={[{name:"All", val:"all" },{name:"Executed", val:"e"},{name:"Pending", val:"p"},{name:"Failed", val:"f"}]} />
                
                <SelectFilter label="Response" value={filterSCResponse} onChange={handleChangeSCResponseFilter}
                        values={[{name:"All", val:"all" },{name:"Success", val:"s"},{name:"Failure", val:"f"}]} />
                
                <SelectFilter label="Category" value={filterCategory} onChange={handleChangeCategoryFilter}
                        values={[{name:"All", val:"all" },{name:"Art", val:"a"},{name:"AIF", val:"i"},{name:"Sportex", val:"s"},{name:"Collectables", val:"c"}]} />

                <InputFilter label="Ticker" value={filterTicker} onChange={handleChangeTickerFilter} className="ml-5 mr-5" />   
            </div>
            <DexxTable headers={TableHeaders} rows={TableRows} alignment={AlignContent} />
        </div>)
    }

    return <>
        <DexxPopup show={showPopUp} title={""} msg={""} btnOk={false}
            close={() =>{setShowPopUp(false); setResetAmountPopup(true)}} 
            body={<DepositWithdrawInterface reset={resetAmountPopup} isDefault={isDefaultPopup}
                closeFunction={() =>{setShowPopUp(false); setResetAmountPopup(true)}} />}/>

        <div className="flex place-content-center">
            <div className="min-w-2/3">
                <BlueLine className="hidden md:block"/>
                <div className="grid md:flex gap-8 justify-center">
                    <DexxPieChart data={[
                            { title: 'Asset/Security Tokens', value: parseFloat(balance?.balance.total), 
                                color: '#80C8C4' },
                            { title: 'Liquidity Pool Position', value: parseFloat(balance?.liquidity.total), 
                                color: '#6772CD' },
                            { title: 'FIMART Euro Voucher', value: parseFloat(balance?.payment_tokens.total), 
                                color: '#4494CE' },
                        ]} />
                    <div className="w-full text-white font-normal align-middle grid">
                        <div className="md:flex justify-evenly items-center mb-4 md:mb-0">
                            <div className="py-4">My portfolio
                                <DexxBalance className="text-3xl md:text-4xl lg:text-5xl">
                                    &euro;{balance ? euroFormat(balance.total) : euroFormat(0)}
                                </DexxBalance>
                            </div>
                            <div className="flex items-end justify-center md:h-14 lg:h-16">
                                <DexxButton label={"Deposit / Withdraw"} borderColor="dark_blue"
                                    className=" rounded-full px-6 bg-dark_blue font-semibold"
                                    onClick={() => {setShowPopUp(true); setResetAmountPopup(false)}} />
                            </div>
                        </div>
                        <BlueLine/>
                        <div className="md:flex justify-evenly md:gap-8 gap-4 grid mt-4 md:mt-0 md:flex-wrap">
                            <DexxSubBalance label={"Asset / Security tokens"} color={"succ1"} 
                                balance={euroFormat(balance?.balance.total)} />
                            <DexxSubBalance label={"Liquidity pool position"} color={"succ5"} 
                                balance={euroFormat(balance?.liquidity.total)} />
                            <DexxSubBalance label={"FIMART Euro Voucher"} color={"succ4"} 
                                balance={euroFormat(balance?.payment_tokens.total)} />
                        </div>
                    </div>
                </div>
                <BlueLine  className="hidden md:block"/>
            </div>
        </div>

        <div className=" mx-neg40 mt-5">
            <div className="">
                <DexxTableTitle label={"asset / security tokens ("+rsSecurities.length+")"} 
                    active={toBeShown[0]} className="pl-8 md:pl-16"
                    onClick={() => handleClick(0)}/>
                {toBeShown[0] ? showTable("securities.csv", headersSec, alignmentSec, rsSecuritiesFormatted, 
                    rsSecurities) : <></>}
                <DexxTableTitle label={"Liquidated tokens ("+rsLiquidated.length+")"} 
                    active={toBeShown[1]} dark className="pl-8 md:pl-16"
                    onClick={() => handleClick(1)}/>
                {toBeShown[1] ? showTable("liquidated-tokens.csv", headersLiquidations, alignmentLiquidations,
                    rsLiquidatedFormatted, rsLiquidated) : <></>}
                <DexxTableTitle label={"Liquidity pool position ("+rsLiquidity.length+")"} 
                    active={toBeShown[2]} className="pl-8 md:pl-16"
                    onClick={() => handleClick(2)}/>
                {toBeShown[2] ? showTable("pool-liquidity.csv", headersShares, alignmentShares, 
                    rsLiquidityFormatted, rsLiquidity) : <></>}
                <DexxTableTitle label={"transaction history ("+rsTxn.length+")"} 
                    active={toBeShown[3]} dark className="pl-8 md:pl-16"
                    onClick={() => handleClick(3)}/>
                {toBeShown[3] ? showTableWithFilters("history-transactions.csv", headersTransactions, alignmentTransactions, 
                    rsTxnFormatted, 
                            (Object.entries(rsFilteredTxn).length > 0 || 
                            (filterCategory !== 'all' || filterSCResponse !== 'all' || filterStatus !== 'all' || filterTicker !== '')) ? rsFilteredTxn : rsTxn, formattedHeaderTransactions) : <></>}
                <DexxTableTitle label={"bank transaction history ("+rsBank.length+")"} 
                    active={toBeShown[4]} className="pl-8 md:pl-16"
                    onClick={() => handleClick(4)}/>
                {toBeShown[4] ? showTable("history-bank-transactions.csv", headersBankTxns, alignmentBankTxns,
                    rsBankFormatted, rsBank, formattedHeaderBankTransactions) : <></>}
            </div>
        </div>
        <div className="flex justify-center mt-5">
            <BackButton />
        </div>
    </>
}