import { useDispatch } from 'react-redux'
import { bindActionCreators } from "redux";
import { actionCreators } from '../state'
import { useEffect, useState } from "react"
import { defaultUserState, UserAccount } from '../UserContext'
import Cookies from 'universal-cookie';
import { DexxButton } from "../components/light/LightButton"

export default function KYCRoute(): JSX.Element {

    const cookies = new Cookies();
    const [user/*, setUser*/] = useState<UserAccount>(defaultUserState)
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [showInfo, setShowInfo] = useState<boolean>(false)
    const [showTutorial, setShowTutorial] = useState<boolean>(false)

    useEffect(() => {
        if(user.email !== "") {
            //setUser(user)
            actions.getBasicAccountProfile()
        }
      }, [user])

    useEffect(() => {
        fetch('https://dexx.instance.kyc-chain.com/api/v2/invitations')
            .then(function(response){ return response.status; })
            .then(function(data) {
                const items = data;
            })
    })

    const VideoModal = (props: {src:string}) => {
        return <div style={{position: "fixed", top: "0", bottom: "0", left: "0", right: "0", width:"100%", height:"100%", backgroundColor: "rgba(0,0,0,0.8)"}}>
            <div style={{position: "fixed", top: "9%", right: "9%"}} className="font-bold text-xl cursor-pointer" onClick={() => setShowTutorial(false)}>X</div>
            <div style={{position: "relative", top: "10%", bottom: "10%", left: "10%", width: "80%", height: "80%", overflow: "hidden" }}>
            <iframe
                title={"Tutorial"}
                allowFullScreen
                //frameBorder="0"
                src={props.src}
                width="100%" 
                height="100%"
            />
            </div>
        </div>
      }
    
    return (<>
            <script src="https://www.google.com/recaptcha/api.js" async defer></script>
            <div className="grid pl-30 pr-30 mx-20">
                <div className="text-white text-center font-urbanist text-4xl mb-5 mt-5">
                    <div className="">
                        <div className="text-center uppercase">Complete the kyc
                        <div onClick={() => {setShowInfo(!showInfo)}} 
                            className={"cursor-pointer border-solid border-white w-8 h-8 lowercase "+
                                "rounded-full text-xl border-2 text-center float-right font-semibold"}>
                                i</div></div>
                    </div>
                    <div className={" text-xl mt-10 font-extralight "+(showInfo ? " hover:animate-bounce" : " hidden")}>
                    DEXX uses a third-party to process our Know Your Customer (KYC) requests. Their application will be embedded in the window below as you execute your own KYC. All your data will be kept on our own storage in Europe. If you need assistance with your KYC please send an email to <a className="text-succ2" href="mailto:support@dexx.finance">support@dexx.finance</a>.
                    </div>
                    <div className=" flex items-center justify-center mx-auto mt-10">
                        <DexxButton onClick={() => {setShowTutorial(true)}} className="rounded-full px-5 py-2"
                        label="Watch me before you begin" 
                        color1="navy_blue" color2="warning" />
                        {showTutorial ? <VideoModal src="https://dexx-public.s3.eu-south-1.amazonaws.com/tutorials/DEXX+-+04+-+Complete+KYC+Process.mp4"/> : null}
                    </div>
                    <div className="text-center text-xl font-extralight w-full mt-10 p-5">
                        Complete the KYC using {cookies.get("user_email")}
                    </div>

                </div>
                <div className="m-5 text-center w-full h-160 border-background border-4 border-solid">
                    <iframe src={"https://kyc.dexx.finance/"} className={"w-full border-solid border-blue h-full border-4"} 
                        data-sitekey="6LfSDHwiAAAAAPqPGGs5FyJe7MC5W_dUZd-iYMtV" allow="camera"/>
                </div>
                <div></div>
            </div>
    </>)
}
