// todo: add filename_mid: string
export interface OptimizedImage {
    name: string,
    url_big: string,
    url_small: string,
    filename_big: string
    filename_small: string
    uploaded?: boolean
}

export interface SortedImages {
    sorted_images: OptimizedImage[]
    others: string[]
}

export function getImagePosition(fullName: string): number {
    try {
        let n = fullName.split("/")[1].split("_")
        if (n.length > 1 && /^\d+$/.test(n[0])) {
            if (n[0][0] === '0' && n[0].length > 2)
                return -1
            let pos = parseInt(n[0])
            if (isNaN(pos)) return -1
            return pos
        }
        return -1
    } catch (_) {
        return -1
    }
}

export function getImageName(filename: string): string {
    try {
        let name = filename.split("/")[1].split("_")
        if(name.length > 2)
            return name.slice(2).reduce((x, y) => [x+"_"+y].toString())
        return ""
    } catch (e) {
        return ""
    }
}

// todo: change it to include mid images
export function imageIsBig(filename: string): boolean {
    try {
        let n = filename.split("/")[1].split("_")
        if (n.length > 1 && n[1] === "small")
            return false
        return true
    } catch (_) {
        return true
    }
}

export function nameIsFull(filename: string): boolean {
    try {
        if (filename.split("/").length === 2)
            return true
        return false
    } catch (_) {
        return false
    }
}

export function sortImages(images: { filename: string }[]): SortedImages {
    let dict: OptimizedImage[] = []
    let others: string[] = []
    images.forEach((i) => {
        if (getImagePosition(i.filename) >= 0) {
            let tmp = dict[getImagePosition(i.filename)]
            // might need a switch here
            if (imageIsBig(i.filename))
                tmp = {
                    name: getImageName(i.filename),
                    filename_small: tmp?.filename_small ? tmp.filename_small : "",
                    filename_big: i.filename,
                    url_big: tmp?.url_big, url_small: tmp?.url_small
                }
            else
                tmp = {
                    name: getImageName(i.filename),
                    filename_big: tmp?.filename_big ? tmp.filename_big : "",
                    filename_small: i.filename,
                    url_big: tmp?.url_big, url_small: tmp?.url_small
                }
            dict[getImagePosition(i.filename)] = tmp
        } else {
            if (i.filename.length > 0) others.push(i.filename)
        }
    })
    return { sorted_images: dict.filter((i) => i !== undefined), others: others }
}

export function getFilesPositions(images: any[]): number[] {
    let pos: number[] = images.map((i) => {
        return getImagePosition(i)
    })
    pos.sort((n,m) => n > m ? 1 : -1)
    return pos
}