import { useHistory  } from 'react-router-dom'
import { LightButton } from "../components/light/LightButton"
import { PageDescription, TitleColor1 } from '../components/typography/Typogtaphy'
import { useState } from "react"
import { DexxButton } from "../components/light/LightButton"

export default function AlmostDoneRoute(): JSX.Element {
    
    let history = useHistory()
    const [showTutorial, setShowTutorial] = useState<boolean>(false)

    const VideoModal = (props: {src:string}) => {
        return <div style={{position: "fixed", top: "0", bottom: "0", left: "0", right: "0", width:"100%", height:"100%", backgroundColor: "rgba(0,0,0,0.8)"}}>
            <div style={{position: "fixed", top: "9%", right: "9%"}} className="font-bold text-xl cursor-pointer" onClick={() => setShowTutorial(false)}>X</div>
        
            <div style={{position: "relative", top: "10%", bottom: "10%", left: "10%", width: "80%", height: "80%", overflow: "hidden" }}>
            <iframe
                title={"Tutorial"}
                allowFullScreen
                //frameBorder="0"
                src={props.src}
                width="100%" 
                height="100%"
            />
            </div>
        </div>
      }

    return (<>
            <div className="grid ">
                <div className="grid justify-items-center">
                    <div className="font-roboto py-2 tracking-wider right-text">
                        <PageDescription title={"Almost done!"} description={
                            "Regulations require that we collect this information to confirm your identity"} 
                            titleSize='5xl md:text-8xl'/>
                        <div className='md:h-8 h-4'></div>
                        <PageDescription title={'Know Your Customer'} description={
                            <div>
                            To complete the KYC you will need your passport <br></br>
                            This will take about 5 minutes and must be done in a single sitting.
                            </div>}
                            titleSize='2xl md:text-3xl font-extrabold' />
                        <div  className="flex gap-4 justify-center flex-wrap mt-10 text-tertiary">                        
                            <LightButton onClick={() => {history.push("/account/additional-info", { action: 'edit' })}} 
                                className="rounded-full px-5 py-2" label="Edit your information" alternate={true}/>
                            <LightButton onClick={() => {history.push("/kyc")}} className="rounded-full px-5 py-2"
                                label="Begin the KYC process" alternate={true}/>
                            <LightButton onClick={() => {history.push("/menu")}} className="rounded-full px-5 py-2"
                                label="Explore the platform" alternate={true}/>
                        </div>
                        <div  className="flex gap-4 justify-center flex-wrap mt-4 text-tertiary">                        
                            <div className="">
                            <DexxButton onClick={() => {setShowTutorial(true)}} className="rounded-full px-5 py-2"
                                label="See the video" 
                                color1="navy_blue" color2="warning" />
                                {showTutorial ? <VideoModal src="https://dexx-public.s3.eu-south-1.amazonaws.com/tutorials/DEXX+-+03+Begin+KYC+Process.mp4"/> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>)
}
