import { useHistory } from "react-router"

export function LightButton(props: {label: String, onClick?: any, alternate?: boolean, className?: string, fontClasses?: string, id?: string}): JSX.Element {
    var isAlternate = props.alternate? props.alternate : false

    return <>
        <div 
            id={props.id ? props.id : ""}
            className={"font-roboto border border-solid"+
            " not-italic text-center uppercase  cursor-pointer "+
            " tracking-wider py-2 w-fit "+
            (props.fontClasses ? props.fontClasses : "  text-sm ")+
            (isAlternate ? " text-tertiary border-tertiary hover:bg-background hover:text-primary hover:border-primary " : 
                " border-primary text-primary hover:bg-primary hover:text-tertiary ")+
            (props.className ? props.className : "")}
            onClick={props.onClick}>{props.label}</div>
    </>
}

export function DexxButton(props: {label: String, onClick?: any, color1?: string, color2?: string,
        color3?: string, color4?: string, borderColor?: string, className?: string, fontClasses?: string, noBorder?: boolean, 
        noHoverOnSelected?: boolean, noCursor?: boolean}): JSX.Element {
    const c1 = props.color1 ? props.color1 : "primary"
    const c2 = props.color2 ? props.color2 : "tertiary"
    const c3 = props.color3 ? props.color3 : c2
    const c4 = props.color4 ? props.color4 : c1
    const bc = props.borderColor ? props.borderColor : c2
    return <>
        <div className={" font-roboto "+
            (props.className ? props.className+" " : " ")+
            (props.noBorder ? " " : " border border-solid  border-"+bc+" ")+
            " not-italic text-center uppercase cursor-"+
            (props.noCursor ? "default " : "pointer ")+
            " tracking-wider py-2 "+
            (props.fontClasses ? props.fontClasses : "  text-xs lg:text-sm ")+
            " text-"+c2+
            (props.noHoverOnSelected ? " " : " hover:bg-"+c3+" hover:text-"+c4+
            " hover:border-"+c4+" ")}
            onClick={props.onClick}>{props.label}</div>
    </>
}

export function DexxMaxButton(props: {label: String, onClick?: any, color1?: string, color2?: string,
    className?: string, fontClasses?: string, noBorder?: boolean, 
    noHoverOnSelected?: boolean}): JSX.Element {
    
    let c1 = props.color1 ? props.color1 : "greenMax"
    let c2 = props.color2 ? props.color2 : "white"

return <>
    <div className={" font-roboto  border border-solid  border-"+c2+
        " not-italic text-center uppercase  cursor-pointer "+
        " tracking-wider py-1 w-fit text-xs "+
        " text-"+c2+ " hover:bg-"+c2+" hover:text-"+c1+
        " hover:border-"+c1+" "+
        (props.className ? props.className : "")}
        onClick={props.onClick}>{props.label}</div>
</>
}

export function BackButton(props: {previousPage?: string, className?: string}): JSX.Element {
    let history = useHistory()
    return <DexxButton label={"Back"} onClick={() => {
            props.previousPage ? 
                history.push(props.previousPage) : 
                history.goBack()}
        }
        className={props.className ? 
            props.className : 
            "rounded-full px-10 "}/>
}

export function DisabledButton(props: {label: String, className?: string, fontClasses?: string}): JSX.Element {

    return <>
        <div className={"font-roboto border border-solid"+
            " not-italic text-center uppercase cursor-none "+
            " tracking-wider py-1 w-fit "+
            (props.fontClasses ? props.fontClasses : "  text-xl ")+
            " border-blue text-blue opacity-25 " +
            (props.className ? props.className : "")}>{props.label}</div>
    </>
}