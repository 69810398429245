import { bindActionCreators } from "@reduxjs/toolkit"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { CarExtraInfo, defaultCarExtraInfo, Appraisals, defaultAppraisals, FileSrc } from "../../AssetContext"
import { actionCreators } from "../../state"
import { TitleColor1 } from "../typography/Typogtaphy"
import UploadFilesForm, {UploadTokenFilesForm} from "../UploadFilesForm"
import LabelAndInput from "../LabelAndInput"
import HTMLVisualizer from "../utils/HTMLVisualizer"
import UpdatableStructuredTable from "../UpdatableTable"
import { OptimizedImage, SortedImages, sortImages } from "../../functions/images"
import {sanitizeInputField} from "../../utils"
import UploadCardImage from "../UploadCardImage"


export default function ExtraInfoCarForm(props: {assetInfo: any, setUpdateFun: any, media: any,
        tbr: any[], setTbr: any, tbu: any[], setTbu: any,
        coownTBR: any[], setCoownTBR: any, coownTBU: any[], setCoownTBU: any,
        prospTBR: any[], setProspTBR: any, prospTBU: any[], setProspTBU: any}): JSX.Element {
    
    const dispatch = useDispatch() 
    const actions = bindActionCreators(actionCreators, dispatch)
    
    // Asset ExtraInfo fields
    const[assetName, setAssetName] = useState<string>("")
    const[assetTitle, setAssetTitle] = useState<string>("")
    const[descr, setDescr] = useState<string>("")
    const[year, setYear] = useState<string>("")
    const[make, setMake] = useState<string>("")
    const[model, setModel] = useState<string>("")
    const[VIN, setVIN] = useState<string>("")
    const[extColor, setExtColor] = useState<string>("")
    const[intColor, setIntColor] = useState<string>("")
    const[odometerValue, setOdometerValue] = useState<string>("")
    const[odometerUnit, setOdometerUnit] = useState<string>("")
    const[transmission, setTransmission] = useState<string>("")
    const[engineType, setEngineType] = useState<string>("")
    const[engineSize, setEngineSize] = useState<string>("")
    const[location, setLocation] = useState<string>("")
    const[marketValue, setMarketValue] = useState<string>("")
    const[condition, setCondition] = useState<string>("")
    const[appraisals, setAppraisals] = useState<Appraisals[]>([])

    const[videosSrc, setVideosSrc] = useState<{url:string, filename: string}[]>([])
    const[documentsSrc, setDocumentsSrc] = useState<{url:string, filename: string}[]>([])
    const [images2, setImages2] = useState<FileSrc[]>([])
    const[videos, setVideos] = useState<FileSrc[]>([])
    const [sortedImages, setSortedImages] = useState<OptimizedImage[]>([])
    const [unsortedImages, setUnsortedImages] = useState<OptimizedImage[]>([])
    const[documents, setDocuments] = useState<FileSrc[]>([])
    const[coownership, setCoownership] = useState<FileSrc[]>([])
    const[prospectus, setProspectus] = useState<FileSrc[]>([])
    const[download, setDownload] = useState<boolean>(true)

    const[isApprovedAndTokenized, setIsApprovedAndTokenized] = useState<boolean>(false)
    const[isSubmitted, setIsSubmitted] = useState<boolean>(false)



    var newCarInfo: CarExtraInfo = {
        //asset_title: assetTitle,
        name: sanitizeInputField(assetTitle),
        description: descr,
        year: parseInt(year),
        make: sanitizeInputField(make),
        model: sanitizeInputField(model),
        vin: sanitizeInputField(VIN),
        exterior_color: sanitizeInputField(extColor),
        interior_color: sanitizeInputField(intColor),
        odometer: {
            odometer_value: odometerValue,
            odometer_unit: odometerUnit
        },
        transmission: sanitizeInputField(transmission),
        engine_type: sanitizeInputField(engineType),
        engine_size: sanitizeInputField(engineSize),
        location: sanitizeInputField(location),
        market_value: sanitizeInputField(marketValue),
        condition: condition,
        appraisals: appraisals, 
    }

    useEffect(() => {
        if(props.assetInfo.payload && props.assetInfo.payload.extra_info) {
            let car = defaultCarExtraInfo
            if(props.assetInfo.payload.extra_info.cars) {
                car = props.assetInfo.payload.extra_info.cars.sub_assets ?
                    props.assetInfo.payload.extra_info.cars.sub_assets[0] :
                    defaultCarExtraInfo
            }
            if(props.assetInfo.payload.asset_status_name !== "In Process" && props.assetInfo.payload.asset_status_name !== "Rejected") 
                setIsApprovedAndTokenized(true)
            if(props.assetInfo.payload.asset_status_name === "Submitted")  
                setIsSubmitted(true)
            
            setAssetName(props.assetInfo.payload.name)
            if(car) {
                //setAssetTitle(car.asset_title)
                setAssetTitle(car.name)
                setDescr(car.description)
                setYear(car.year.toString())
                setMake(car.make)
                setModel(car.model)
                setVIN(car.vin)
                setExtColor(car.exterior_color)
                setIntColor(car.interior_color)
                setOdometerValue(car.odometer.odometer_value)
                setOdometerUnit(car.odometer.odometer_unit)
                setTransmission(car.transmission)
                setEngineType(car.engine_type)
                setEngineSize(car.engine_size)
                setLocation(car.location)
                setMarketValue(car.market_value)
                setCondition(car.condition)
                setAppraisals(car.appraisals)
            }
        }
    }, [props.assetInfo, props.assetInfo.loading])

    useEffect(() => {
        if(props.media && download) {
            actions.getDocuments(props.media, setDocumentsSrc)
            actions.getVideos(props.media, setVideosSrc)
            setDownload(false)
        }
    }, [props.media])

    useEffect(() => {
        if (props.media) {
            let imgs: SortedImages = sortImages(
                props.media.filter((m: any) => m.media_type_name === "Image"))
            actions.getAllImagesURL(imgs.sorted_images, setSortedImages)
            actions.getAllImagesURL(imgs.others.map((i) => {
                return { name: i, url_small: "", url_big: i, filename_big: "", filename_small: "" }
            }), setUnsortedImages)
        }
    }, [props.media])

    useEffect(() => {
        let i = [...sortedImages, ...unsortedImages].map((im) => {
            return { name: im.name, uploaded: true, src: im.url_big, small: im.url_small,
                filename_big: im.filename_big, filename_small: im.filename_small
             }
        })
        setImages2(i)
    }, [sortedImages, unsortedImages])

    useEffect(() => {
        setVideos(videosSrc.map((u) => {return {name: u.filename, uploaded: true, src: u.url}}))
    }, [videosSrc])

    useEffect(() => {
        let tmp:any = []
        documentsSrc.map((u) => {
            if(!u.filename.includes("coownership___") && !u.filename.includes("prospectus___"))
                tmp.push({name: u.filename, uploaded: true, src: u.url}) 
        })
        setDocuments(tmp)

        let tmp2:any = []
        documentsSrc.map((u) => {
            if(u.filename && u.filename.includes("coownership"))
                tmp2.push({name: u.filename, uploaded: true, src: u.url})
        })
        setCoownership(tmp2)

        let tmp3:any = [] 
        documentsSrc.map((u) => {
            if(u.filename && u.filename.includes("prospectus"))
                tmp3.push({name: u.filename, uploaded: true, src: u.url})
        })
        setProspectus(tmp3)
    }, [documentsSrc])

    function updateEI() {
        var subAsset :any[] = []

        let tmp:Appraisals[] = []
        tmp = newCarInfo.appraisals.filter((el:Appraisals) => el.date !== "" && el.date !== undefined && el.value !== "" && el.value !== undefined )
        newCarInfo.appraisals = tmp

        subAsset.push(newCarInfo)
        const assetExtraInfo = {
            cars: {
                sub_assets: subAsset
            }
        }
        return assetExtraInfo 
    }

    useEffect(() => {
        props.setUpdateFun(updateEI)
    }, [assetTitle,assetName,descr, year,make, model,VIN,extColor,intColor,
        odometerValue,odometerUnit,transmission, engineType,engineSize,location, marketValue,condition,appraisals])

    return <>
        <div className="grid md:px-20 px-5 grid-cols-12 mt-10" >
            <TitleColor1 className="col-span-12 text-lg">/ Car Information</TitleColor1>
                    
            <LabelAndInput id={"assetName"} label={"Asset Name"} value={assetName} fun={setAssetName} type={"disabled"} className={"col-span-6"} required/>
            <LabelAndInput id={"name"} label={"Asset Title *"} value={assetTitle} fun={setAssetTitle} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"description"} label={"Description * (text or HTML)"} value={descr} fun={setDescr} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className="col-span-12" required/>
            <HTMLVisualizer text={descr} className="w-full col-span-12 text-tertiary mb-5"/>
            <LabelAndInput id={"year"} label={"Year *"} value={year} fun={setYear} type={isApprovedAndTokenized ? "disabled" : "number"} className={"col-span-2"} required/>
            <LabelAndInput id={"make"} label={"Make *"} value={make} fun={setMake} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-5"} required/>
            <LabelAndInput id={"model"} label={"Model *"} value={model} fun={setModel} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-5"} required/>
            <LabelAndInput id={"vin"} label={"VIN *"} value={VIN} fun={setVIN} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-4"} required/>
            <LabelAndInput id={"exteriorColor"} label={"Exterior Color *"} value={extColor} fun={setExtColor} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-4"} required/>
            <LabelAndInput id={"interiorColor"} label={"Interior Color *"} value={intColor} fun={setIntColor} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-4"} required/>
            
            <LabelAndInput id={"odometerValue"} label={"Odeometer Value"} value={odometerValue} fun={setOdometerValue} className={"col-span-6"} 
                type={isApprovedAndTokenized ? "disabled" : "textButNumber"} 
                helpTip="You can leave it empty if N/A"/>
            
            <LabelAndInput id={"odometerUnit"} label={"Odeometer Value's Unit *"} value={odometerUnit} fun={setOdometerUnit} 
                type={isApprovedAndTokenized ? "disabled" : "select"} className={"col-span-6"} required options={[
                    {value: "", text: ""}, {value: "miles", text: "Miles"}, {value: "km", text: "Km"} 
                ]}/>

            <LabelAndInput id={"transmission"} label={"Transmission *"} value={transmission} fun={setTransmission} 
                type={isApprovedAndTokenized ? "disabled" : "select"} className={"col-span-4"} required options={[
                    {value: "", text: ""}, {value: "Automatic", text: "Automatic"}, {value: "Manual", text: "Manual"} 
                ]}/>
            
            <LabelAndInput id={"engineType"} label={"Engine Type *"} value={engineType} fun={setEngineType} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-4"} required/>
            <LabelAndInput id={"engineSize"} label={"Engine Size *"} value={engineSize} fun={setEngineSize} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-4"} required/>
            <LabelAndInput id={"location"} label={"Location *"} value={location} fun={setLocation} type={isApprovedAndTokenized ? "disabled" : "text"} className="col-span-4" required/>
            <LabelAndInput id={"marketValue"} label={"Market Value *"} value={marketValue} fun={setMarketValue} type={isApprovedAndTokenized ? "disabled" : "text"} className="col-span-4" required/>
            <LabelAndInput id={"condition"} label={"Condition *"} value={condition} fun={setCondition} 
                type={isApprovedAndTokenized ? "disabled" : "select"} className={"col-span-4"} required options={[
                    {value: "", text: ""}, {value: "Excellent", text: "Excellent"}, {value: "Show Quality", text: "Show Quality"}, {value: "Pristine", text: "Pristine"} 
                ]}/>

            <UpdatableStructuredTable title={"Appraisals"} state={appraisals} setState={setAppraisals} 
                emptyElement={{date: "", value: "", docs: defaultAppraisals}} className="mt-5"
                objectStructure={[{name: "date", type: "date"}, {name: "value", type: ""}]}/>

            <div className="col-span-12 grid mt-5">
                <UploadTokenFilesForm 
                    coownership={{names: coownership, setNames: setCoownership,
                        tbr: props.coownTBR, tbu: props.coownTBU, setTbr: props.setCoownTBR, setTbu: props.setCoownTBU}}
                    prospectus={{names: prospectus, setNames: setProspectus,
                        tbr: props.prospTBR, tbu: props.prospTBU, setTbr: props.setProspTBR, setTbu: props.setProspTBU}}
                    isSubmitted={isSubmitted}/>
            </div>

            <div className="col-span-12 mt-5">
                <UploadCardImage images={images2} assetName={assetName}/>
            </div>
            
            <div className="col-span-12 mt-5">
                <UploadFilesForm 
                    images={{names: images2, setNames: setImages2,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    videos={{names: videos, setNames: setVideos,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    documents={{names: documents, setNames: setDocuments,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    isSubmitted={isSubmitted}/>
            </div>
        </div>
    </>
}