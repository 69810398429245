import { PieChart } from "react-minimal-pie-chart"

export function BlueLine(props: {className?: string}): JSX.Element {
    return <>
        <div className={"w-full h-0.5 bg-dark_blue "+props.className}></div>
    </>
}

export function DexxPieChart(props: {data: any}): JSX.Element {
    return <>
        <div className="flex place-content-center">
            <div className="w-48 grid justify-items-center text-tertiary self-center md:pb-12">
                <div className={"relative text-4.5xl font-semibold inset-0 top-32 left-1 tracking-widest "}>
                    EUR
                </div>
                <PieChart className="bg-radial"
                    data={props.data}
                    lineWidth={3}
                    paddingAngle={5}
                    animate={true}
                    animationDuration={1000}
                />
            </div>
        </div>
    </>
}