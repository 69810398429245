import { FooterItem } from './NavbarItem'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function Footer() : JSX.Element {

    const isLogged = cookies.get("logged_in") 

    return <>
        <div className="flex place-content-center mb-2">
            <FooterItem id="about" to="/about" label="About" className=""/>
            <FooterItem id="support" to="/support"  label="Support" className=""/>
            <FooterItem id="privacy-policy" to="/privacy-policy" label="Privacy" className=""/>
            <FooterItem id="disclaimer" to="/disclaimer" label="Disclaimer" className=""/>
            { isLogged ? 
                <></> : 
                <FooterItem id="trade" to="/invest" label="Take a Tour" className=""/>
            }
        </div>
    </>
}