import { useEffect, useState } from "react";
import { DexxNumericInput, DexxIntegerInput } from "../utils/Input";
import GenericPopup, { PopupGrayLine, PopupLabelAndValue, PopupTitle } from "./GenericPopup";
import { euroFormat, quantityFormat } from "../../utils";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../state";
import { bindActionCreators } from "redux";
//import IconButton from "@mui/material/IconButton";
//import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popup from "../Popup";
//import { HtmlTooltip } from "../HtmlTooltip";
//import LabelAndInput from '../LabelAndInput'
import { Input } from "@mui/material";

export default function P2PCreateOfferPopup(props: { tokenInfo: any, isShown: boolean,
        closeFunction: Function, refreshList: Function }) : JSX.Element {

    const [quantity, setQuantity] = useState<number>(0)
    const [price, setPrice] = useState<number>(0.00)
    //const [type, setType] = useState<string>("")
    const [balance, setBalance] = useState<any>()
    const [maxTokenA, setMaxTokenA] = useState<number>(0)
    const [response, setResponse] = useState<any[]>([])
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    //RESPONSE POPUP
    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")

    function handleSubmit() {
        let r = {
            token_uuid: props.tokenInfo.uuid,
            quantity: quantity,
            per_token_price: parseFloat(price.toString().replace(",", ".")),
        }
        if(quantity <= 0) {
            setShowPopUp(true)
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please specify the quantity of "+props.tokenInfo.ticker + " to create the offer.")
        } else if( quantity - 0 > maxTokenA - 0 ) {
                setShowPopUp(true)
                setTitlePopUp("Sorry!")
                setMsgPopUp("You do not own enough "+props.tokenInfo.ticker + " tokens to create this offer.")
        } else if(price <= 0) {
            setShowPopUp(true)
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please specify a valid price.")
        } else {
            actions.createP2pAskOffer(r, setResponse)
        }
        /*if(quantity > 0 && price > 0 &&
            (type === "Ask" || type === "Bid")) {
            if(type === "Bid") {
                if(quantity <= parseFloat(balancePT))
                    actions.createP2pBidOffer(r, setResponse)
                else {
                    setShowPopUp(true)
                    setTitlePopUp("Error")
                    setMsgPopUp("Not enough XEUR")
                }
            }
            else
                if(quantity <= maxTokenA)
                    actions.createP2pAskOffer(r, setResponse)
                else {
                    setShowPopUp(true)
                    setTitlePopUp("Error")
                    setMsgPopUp("Not enough "+props.tokenInfo.ticker)
                }
        }*/
    }

    useEffect(() => {
        if(props.isShown === true)
            actions.getBalance(setBalance)
    }, [props.isShown])

    useEffect(() => {
        if(balance) {
            let securityEntries = balance?.balance?.security_entries
            let tokensByCat = securityEntries ? securityEntries.map((t: any) => t.class_balance) : []
            let Alltokens = [].concat.apply([], tokensByCat)
            let tokens: any[] = Alltokens.filter((t: any) => t.symbol === props.tokenInfo.ticker)
            let newMax = tokens.length > 0 ? tokens[0].available_quantity : 0
            setMaxTokenA(newMax)
        }
        
    }, [balance])

    useEffect(() => {
        if(response.length > 0) {
            switch(response[0]) {
                case 200:
                case 204:
                    setShowPopUp(true)
                    setTitlePopUp("Success")
                    setMsgPopUp("Your offer has been created.")
                    props.refreshList(true)
                    props.closeFunction()
                    break
                case 0:
                    break
                default:
                    setShowPopUp(true)
                    setTitlePopUp("Sorry!")
                    //setMsgPopUp(response[1] ? response[1].error : "An error occurred while serving the operation")
                    setMsgPopUp("We encountered an error creating your offer.")
                    break
            }
        }
    }, [response])

    let balancePT: string = balance?.payment_tokens?.available_total

    return <>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} 
            close={() => {setShowPopUp(false)}} />
        <GenericPopup 
            handleSubmit={handleSubmit}
            closeFunction={props.closeFunction}
        >
            
            <div className="mb-6">

                <div className="mb-2 px-14 grid grid-cols-2">
                    <PopupTitle color={"succ8"}>My balance</PopupTitle>
                    <PopupLabelAndValue label={props.tokenInfo.ticker+":"} 
                        value={quantityFormat(maxTokenA)} />
                    <PopupLabelAndValue label={"XEUR:"} 
                        value={euroFormat(balancePT ? balancePT : 0)} />
                </div>
                <PopupGrayLine />
                <div className="mb-2 px-14 grid grid-cols-2">
                    <PopupLabelAndValue label={"Token name:"} 
                        value={props.tokenInfo.asset_name} />
                    <PopupLabelAndValue label={"Ticker:"} 
                        value={props.tokenInfo.ticker} />
                </div>
                <div className="bg-gray4lines h-px w-full mt-2 " />
                <div className={"text-sm pl-10 pt-5 pb-4 bg-gradient-to-r from-succ8 to-transparent"}>
                    <div className={"text-primary font-semibold text-sm mb-3"}>
                        Create an offer to sell
                    </div>
                    <div className="grid grid-cols-2 mb-2">
                        <div className="font-semibold text-xl text-blue">Quantity *
                            <DexxIntegerInput value={quantity} fun={setQuantity}
                                className={"w-3/4"} max={maxTokenA}/>
                        </div>
                        
                        <div className="font-semibold text-xl text-blue">Price per Token *
                            <DexxNumericInput value={price} className="w-3/4" fun={setPrice} />
                        </div>
                    </div>
                </div>
                <PopupGrayLine />
            </div>
        </GenericPopup>
    </>
}
