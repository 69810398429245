import { useDispatch } from "react-redux";
import { actionCreators } from "../../state";
import { bindActionCreators } from "redux";
import LabelAndDescription, {AssetDescription, LabelAndInlineValue} from "../LabelAndDescription";
import { euroFormat, euroQtyFormat, convertToSimpleDate, convertLegalFrameworkForFE } from "../../utils";
import TabsMenu from "../TabsMenu";
import { useEffect, useState } from "react";
import { PieChart } from "react-minimal-pie-chart";
import AddLPPopup from "../popup/AddLPPopup";
import RemoveLPPopup from "../popup/RemoveLPPopup";
import Popup, { DexxPopup } from '../Popup'
import { DexxButton, DisabledButton } from "../light/LightButton"
import fileImg from '../../media/file.png'
import { Link } from "react-router-dom"
import Cookies from 'universal-cookie';
import P2PTokenTab from "./tabs/P2PTokenTab";
import { defaultPrimarySaleGet } from "../../PoolContext"
import SecondaryMarketStatistics from "./tabs/SecondaryMarketStatistics";
import { ListedLPTab } from "./tabs/LiquidityPoolTab";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { ImagesSlideShow } from "../utils/ImagesSlideShow";

const cookies = new Cookies();

export default function AifTokenBody (props: {
                assetInfo : any, tokenInfo: any, lpInfo?: any, media: any, 
                tokenSymbol: String, completeView?: boolean, openedTab?: string, reload?:Function,
                psUuid?: string, origin: string, lpCount: number,
                isLogged: boolean}): JSX.Element {
    
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [documents, setDocuments] = useState<{url:string, filename: string}[]>([])

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")

    const [showAddLPPopUp, setShowAddLPPopUp] = useState<boolean>(false)
    const [showRemoveLPPopUp, setShowRemoveLPPopUp] = useState<boolean>(false)
    const [showActualLP, setShowActualLP] = useState<string>("")
    const [lpCount, setLpCount] = useState<number>(0)

    const [shares, setShares] = useState<any[]>([])
    const [arrayShares, setArrayShares] = useState<any[]>([])
    const [p2p, setP2p] = useState<any[]>([])
    const [onlyActiveP2p, setOnlyActiveP2p] = useState<any[]>([])
    const [refreshP2pList, setRefreshP2pList] = useState<boolean>(false)
    const [primarySaleDetails, setPSDetails] = useState(defaultPrimarySaleGet)

    var asset = {overview: {}, characteristics: {}, investment_limits: {}, irr: {}, fund_terms: {}, other: {}}
    var overviewInfo = {}
    var dealCharInfo = {}
    var invLimitsInfo = {}
    var irrInfo = {}
    var fundTermsInfo = {}
    var otherInfo = {}

    //const isDev = cookies.get("env") !== "production"
    const isDev = cookies.get("env") === "develop"
    var filtered_shares:any = []

    function handleClosePopUp(){
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUp(false)
    }
    
    try {
        if(props.assetInfo.asset_type === "Private Debt") {
            asset = props.assetInfo.extra_info.private_debt.sub_assets[0]
        } else {
            asset = props.assetInfo.extra_info.private_equity.sub_assets[0]
        }
    } catch (e) {
        asset = {overview: {}, characteristics: {}, investment_limits: {}, irr: {}, fund_terms: {}, other: {}}
    }

    useEffect(() => {
        if(props.media){
            if(props.isLogged) actions.getDocuments(props.media, setDocuments)
        }
    }, [props.media])
    
    useEffect(() => {
        if(props.isLogged){
            if(props.lpInfo && props.lpInfo.length > 0){
                setLpCount(props.lpInfo.length)
                props.lpInfo.forEach((lp:any, i: number) => {
                    if(lp?.uuid !== undefined){
                        actions.getInvestorShares(lp.uuid, true, setShares)
                    }
                });
            }
        }
    }, [props.lpInfo])

    useEffect(() => {
        if(shares[0] !== undefined) setArrayShares([...arrayShares, shares[0].LPWE])
    }, [shares])

    useEffect(() => {
        if(props.tokenInfo && props.tokenInfo.uuid)
            actions.getPrimarySale("token_uuid="+props.tokenInfo.uuid, setPSDetails)
    }, [props.tokenInfo])
    
    useEffect(() => {
        if(props.isLogged){
            if(props.tokenInfo && props.tokenInfo.uuid)
                actions.getP2P({
                    token_a_uuid: props.tokenInfo.uuid
                }, setP2p)
            setRefreshP2pList(false)
        }
    }, [props.tokenInfo, refreshP2pList])

    useEffect(() => {
        if(p2p){
            let tmp:any = p2p.filter((o: any) => o.deleted === undefined || o.deleted === false)
            setOnlyActiveP2p(tmp)
        }
    }, [p2p])

    function getNameFromUrl(url:string){
        var noVars = url.split('?')[0]
        var name = noVars.substring(noVars.lastIndexOf('/')+1)
        name = name.replace("coownership___", "COOWNERSHIP AGREEMENT: ")
        name = name.replace("prospectus___", "ASSET TOKEN PROSPECTUS: ")
        name = name.replace("_", " ").replace("-", " ").split('.')[0]
        return name
    }

    function docDownload(documents: any) {
        var tmp = []
        try {
            tmp = documents.map((doc: any, i: number) =>
            <div className="" key={i}>
                <label className="art-doc-item">
                <a href={doc.url} download>
                    <div className="text-tertiary font-code-pro tracking-wider uppercase hover:bg-tertiary hover:text-primary
                            font-base text-lg border-solid border-1 border-tertiary rounded-xl px-4">
                                {getNameFromUrl(doc.filename)}
                    </div>
                </a>
                </label>
            </div>)
        } catch (e) {}
        let res = <></>
        if(props.isLogged) 
            res = tmp.length > 0 ? <div className="flex flex-wrap gap-2">{tmp}</div> : <></>
            //<div className="text-tertiary font-code-pro tracking-wider uppercase font-base text-xl">No documents provided</div>
        else
            res = <div className="text-tertiary font-code-pro tracking-wider uppercase font-base text-xl">Please Log in or register to see the token's documentation.</div>
        return res
    }

    function docListing(documents: any) {
        var tmp = []
        try {
            tmp = documents.map((doc: any, i: number) => 
            <div className="pb-5" key={(i+1).toString()}>
                
                    <a href={doc.url} target={"_blank"} >
                    <div className="flex items-center">    
                        <span className="inline"><img src={fileImg} alt={doc.filename} className="h-10"></img></span>
                        <span className="text-tertiary font-code-pro tracking-wider uppercase hover:text-succ2 
                            font-base text-lg px-4 inline">
                            {getNameFromUrl(doc.filename)}
                        </span>
                    </div></a>
            </div>)
        } catch (e) {}
        const res = tmp.length > 0 ? 
                <div className="mt-30 border-solid border-1 border-tertiary rounded-xl px-4 pt-5 pl-10">{tmp}</div> : 
                <div className="text-tertiary font-code-pro tracking-wider uppercase font-base text-xl">No documents provided</div>
        return res
    }
    
    overviewInfo = asset.overview 
    dealCharInfo = asset.characteristics
    invLimitsInfo = asset.investment_limits
    irrInfo = asset.irr
    fundTermsInfo = asset.fund_terms
    otherInfo = asset.other

    function showTable(title: string[], elements: any[], colSpec: string[]) {
        return title.map((e,i) => <LabelAndDescription alternate={true} label={e} description={elements[i]} className={colSpec[i]+" mb-5"} key={i} />)
    }
    
    function showAifDetails(info: any){
        if(info) {
            const ovwTitles = [/*"Investment strategy description",*/ "Target Allocation / Seniority", 
            "Main Risk And Return Characteristics", "Deal Type", "Target Fund Size", "Investment Period",
            "Fund Name"]
            const ovwElems = [/*info.investment_strategy_description,*/  info.target_allocation, 
                info.risk_return_characteristics, info.deal_type, info.target_fund_size, info.investment_period,
                info.fund_name]
            //const ovwCols = ["col-span-12", "col-span-12", "col-span-12", "col-span-6", "col-span-6", "col-span-6", "col-span-6"]
            //return <>{showTable(ovwTitles, ovwElems, ovwCols)}</>  
            return ovwTitles.map((l, i) => <LabelAndDescription alternate={true} label={l} description={ovwElems[i]} className="mb-10" key={i}/>)  
        }
        return <></>
    }

    function showOverview () {
        
        return <>
            <div className="grid lg:grid-cols-3 pt-5 mx-10 lg:mx-20">
            
                <div className="col-span-2 grid grid-cols-2 my-20 mx-10">
                    {showAifDetails(overviewInfo)}
                </div>

                <div className="">
                    <div className="grid justify-items-center mb-2 overflow-hidden mt-10">
                        <ImagesSlideShow media={props.media} />
                    </div>
                </div>
            </div>

            <div className="text-center lg:mx-20">
                {/*<div className="text-tertiary mb-5 mt-20">Download the document:</div>*/}
                <div className="flex justify-center">
                    {docDownload(documents)}
                </div>
            </div>

            { props.assetInfo.issuer_description || props.assetInfo.issuer_web_site ? (
                <div className="text-center lg:mx-20">
                    { props.assetInfo.issuer_description ? (<>
                        <div className="text-tertiary mb-5 mt-20">Issuer Description:</div>
                        <div className="text-tertiary text-left mb-5 mt-5 whitespace-pre-wrap">{props.assetInfo.issuer_description}</div>
                    </>) : <></>
                    }
                    { props.assetInfo.issuer_web_site ? (<>
                        <div className="text-tertiary mb-5 mt-20">Visit issuer's website: <a className="text-succ2" href={props.assetInfo.issuer_web_site} target="_blank" >{props.assetInfo.issuer_web_site}</a></div>
                    </>) : <></>
                    }
                </div>
            ) : <></>
            }
        </>
    }

    function showTokenOverview(){
        return <>
            <div className="grid grid-cols-6 mx-20">
                <div></div>
                <div className="col-span-4">

                    <LabelAndInlineValue label={"ASSET NAME: "} value={props.tokenInfo.asset_name} />
                    <LabelAndInlineValue label={"TOKEN TICKER: "} value={props.tokenInfo.ticker} />
                    { props.tokenInfo.legal_framework && props.tokenInfo.legal_framework !== "" ?
                        <LabelAndInlineValue label={"TOKEN LEGAL FRAMEWORK: "} value={convertLegalFrameworkForFE(props.tokenInfo.legal_framework)} /> :
                        <></>
                    }
                    <LabelAndInlineValue label={"TOKEN ISSUANCE DATE: "} value={convertToSimpleDate(primarySaleDetails.date_start)} />
                    <LabelAndInlineValue label={"PERCENTAGE OF ASSET TOKENIZED: "} value={props.tokenInfo.pct_tokenized + "%"} />
                    {/*<LabelAndInlineValue label={"TOKENIZED TOTAL VALUE: "} value={"Missing in AIF tokens (TARGET DEAL SIZE x NUMBER OF DEALS??)"} colorVal="warning"/>*/}
                    <LabelAndInlineValue label={"TOKEN SUPPLY: "} value={props.tokenInfo.total_supply} />
                    <LabelAndInlineValue label={"TOKEN FOR SALE: "} value={primarySaleDetails.total_supply} />
                    <LabelAndInlineValue label={"INITIAL TOKEN PRICE: "} value={primarySaleDetails.price+" EUR"} />

                    <AssetDescription alternate={true} label={"TOKEN DESCRIPTION:"} 
                        description={props.tokenInfo.token_term_cond} 
                        liquidation_price={props.tokenInfo.buyback_price}
                        liquidation_date={props.tokenInfo.date_buyback}
                        className="mt-5 text-xl font-roboto text text-tertiary"/>
                </div>
                <div></div>
            </div>
        </>
    }

    /*function showTermsCond() {
        return <LabelAndDescription alternate={true} label="Informations about Token's Terms and conditions" description={" "} className="col-span-12"/>
    }*/

    function showDealChar(info: any) {
        const dealTitles = ["Borrower Profile", "Target Company size", "Loans / Bonds", "Average Rating",
            "Bullet / Amortizing", "Minimum Rating", "Floating / Fixed", "Typical Loan To Value",
            "Primary / Secondary", "Type of Collateral", "Sponsor / Corporate","Max / Average net leverage",
            "Covenant", "Speed of deployment", "Deal FIMART Euro Voucher", "Target Deal Size", "Fund FIMART Euro Voucher",
            "Number Of Deals","Max / Typical Term Per Deal","Expected Term Per Deal"]
        const dealElems = [info.borrower_profile, info.target_company_size, info.loans_or_bonds,
            info.average_rating, info.bullet_or_amortizing, info.minimum_rating, info.floating_or_fixed,
            info.typical_loan_to_value, info.primary_or_secondary, info.type_of_collateral,
            info.sponsor_or_corporate, info.max_average_net_leverage, info.covenant, info.speed_of_deployment,
            info.deal_currency, info.target_deal_size, info.fund_currency, info.number_of_deals,
            info.max_typical_term_per_deal, info.expected_term_per_deal]
        const dealCols = ["col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6", 
            "col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6", 
            "col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6"]

            return <>
                <div className="grid grid-cols-3 my-20 mx-20">
                    {dealTitles.map((l, i) => <LabelAndDescription alternate={true} label={l} description={dealElems[i]} className="mb-10" key={i}/>)}
                </div>
            </>
    }
    function showInvLimits (info: any) {
        const invLimitsTitles = ["Geographic Area","Max Sector Limit","Leverage","Max Country Limit",
            "Max Issuer Limit", "Interest Coverage Ratio","Derivatives","ESG considerations","Excluded Profiles"]
        const invLimitsElems = [info.geographic_area,info.max_sector_limit,info.leverage,
            info.max_country_limit,info.max_issuer_limit, info.interest_coverage_ratio, info.derivatives,info.esg_considerations,
            info.excluded_profiles]
        const invLimitsCols = ["col-span-4", "col-span-4","col-span-4","col-span-4","col-span-4","col-span-4","col-span-6","col-span-6"]

        return <>
            <div className="grid grid-cols-3 my-20 mx-20">
                {invLimitsTitles.map((l, i) => <LabelAndDescription alternate={true} label={l} description={invLimitsElems[i]} className="mb-10" key={i}/>)}
            </div>
            {/*<div className="grid grid-cols-6 mx-20">
                <div></div>
                <div className="col-span-4">
                    <LabelAndInlineValue label={"MINIMUM INVESTMENT: "} value={"EUR "+ primarySaleDetails.price + " (1 Token)"} />
                    <AssetDescription alternate={true} label={"INVESTMENT RESTRICTIONS"} 
                        description={props.tokenInfo.investment_limits} className="mt-5 text-xl font-roboto text-tertiary"/>
                </div>
                <div></div>
            </div>*/}
        </>
    }
    function showIrr (info: any) {
        const irrTitles = ["Target Gross Irr","Target Cash Yield","Distribution Policy"]
        const irrElems = [info.target_gross_irr,info.target_cash_yield,info.distribution_policy]
        const irrCols = ["col-span-4","col-span-4","col-span-4"]

        return <>
            <div className="grid grid-cols-3 my-20 mx-20">
                {irrTitles.map((l, i) => <LabelAndDescription alternate={true} label={l} description={irrElems[i]} className="mb-10" key={i}/>)}
            </div>
        </>
    }
    function showFundTerms (info: any) {
        const fundTermsTitles = ["First Close","Final Close","Fund Term","Ramp-Up",
            "Investment Period",/*"Capital Calls",*/"Capital Restitutions"]
        const fundTermsElems = [info.first_close,info.final_close,info.fund_term,
            info.ramp_up,info.investment_period,/*info.capital_calls,*/info.capital_restitutions]
        const fundTermsCols = ["col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6", "col-span-6"]

        return <>
            <div className="grid grid-cols-3 my-20 mx-20">
                {fundTermsTitles.map((l, i) => <LabelAndDescription alternate={true} label={l} description={fundTermsElems[i]} className="mb-10" key={i}/>)}
            </div>
        </>
    }
    function showOther (info: any) {
        const otherTitles = ["AIFM","Auditor","Reporting","Distributor","Custiodian","Administrator",
            "Nav. Frequency","Share Classes","Incentive Fees","General Partner","Legal Structure",
            "Management Fees","Sub Distributor","Portfolio Manager"/*,"Subsequent Subscriptions"*/]
        const otherElems = [info.AIFM,info.auditor,info.reporting,info.distributor,info.custodian,
            info.administrator,info.nav_frequency,info.share_classes,info.incentive_fees,info.general_partner,
            info.legal_structure,info.management_fees,info.sub_distributor,info.portfolio_manager/*,
            info.subsequent_subscriptions*/]
        const otherCols = ["col-span-6","col-span-6","col-span-12","col-span-6","col-span-6","col-span-6","col-span-6",
            "col-span-6","col-span-6","col-span-6","col-span-6","col-span-6","col-span-6","col-span-6","col-span-6"]

        return <>
            <div className="grid grid-cols-3 my-20 mx-20">
                {otherTitles.map((l, i) => <LabelAndDescription alternate={true} label={l} description={otherElems[i]} className="mb-10" key={i}/>)}
            </div>
        </>
    }

    function showLiquidityPool() {
        return <>
            { //props.lpInfo === undefined || props.lpInfo === "" || props.lpInfo.length === 0 ?
            props.origin === "1m" && props.lpCount > 0 ?
                
            <div className="grid grid-cols-6 mx-20">
                <div></div>
                <ListedLPTab tokenInfo={props.tokenInfo} />
                <div></div>
            </div>

            :

            lpCount === 0 ?
               
               <div className="grid grid-cols-6 mx-20">
                    <div></div>
                    <div className="col-span-4">
                        <AssetDescription alternate={true} label={""} 
                            description={"No Liquidity Pools created for the token"} className="mt-5 text-xl font-roboto text-tertiary"/>
                    </div>
                    <div></div>
                </div>

            :

            <div className="">
                <DexxPopup show={showAddLPPopUp} title={"Add Liquidity"} 
                    msg={<AddLPPopup closeFunction={() => { setShowAddLPPopUp(false); if(props.reload) props.reload()}} uuidLP={showActualLP} />}
                    type="success"
                    helpMsg={
                        <div className="p-5 font-roboto font-semibold text-primary text-base">
                            <div className=" font-bold text-primary text-lg mb-5">
                            Add funds to a liquidity pool by entering the XEUR you will allocate. The number of asset tokens required will be calculated for you.
                            </div>
                            <div className=" font-bold text-primary text-lg mb-5">
                            The transaction has a tolerance of 1% meaning that the price of the asset token can increase up to 1% by the time you tap Submit.
                            </div>
                            <div className=" font-bold text-primary text-lg">
                            For more detailed information <Link to={"/support"} className="text-succ2">click here</Link>.
                            </div>
                        </div>
                    }
                    btnConfirm={false} close={() => { setShowAddLPPopUp(false); if(props.reload) props.reload() } } btnOk={false} />
                
                <DexxPopup show={showRemoveLPPopUp} title={"Remove Liquidity"} 
                    msg={<RemoveLPPopup closeFunction={() => { setShowRemoveLPPopUp(false); if(props.reload) props.reload()}} uuidLP={showActualLP} />}
                    type="alternate"
                    btnConfirm={false} close={() => { setShowRemoveLPPopUp(false); if(props.reload) props.reload() } } btnOk={false} />

                <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp}></Popup>
                
                {props.lpInfo.map((lp:any, i: number) => { 
                    filtered_shares = arrayShares.filter((s: any) => s.lp_uuid === lp.uuid)
                    return(
                
                <div className="grid lg:grid-cols-5 cover mt-10 mb-5 xl:px-30" key={i}>
                    <div></div>
                    <div className="font-urbanist uppercase text-tertiary text-3xl mt-16 ml-10">
                        <div className="">
                            <div className="text-succ2">Liquidity pool name</div>
                            <div className="text-2xl">{lp.lp_name}</div>
                            <div className=" my-5 text-succ2">Add / Remove Funds</div>
                            

                            <div className="flex justify-center  mb-4">
                            {
                                filtered_shares[0]?.qty_lp_token && parseFloat(filtered_shares[0].qty_lp_token) > 0 ? (
                                    <DexxButton label={"Remove"} className="rounded-full px-16 py-2 ml-10 font-bold text-xs md:text-sm bg-succ2 z-50" 
                                        color1="succ2" color2="primary"
                                        onClick={() => {setShowRemoveLPPopUp(true); setShowActualLP(lp.uuid)}}/>
                                ) : (
                                    <DisabledButton label={"Remove"} className="rounded-full px-16 py-2 mr-10 font-bold bg-succ2 z-50"
                                        fontClasses=" text-xs md:text-sm " />
                                )
                            }

                            <DexxButton label={"Add"} className="rounded-full px-16 py-2 ml-10 font-bold text-xs md:text-sm bg-succ2 z-50" 
                                color1="succ2" color2="primary"
                                onClick={() => {setShowAddLPPopUp(true); setShowActualLP(lp.uuid)}}/>
                        </div>
                        </div>
                    </div>
                    <div className="flex place-content-center xl:ml-20">
                        <div className="w-48 grid justify-items-center text-tertiary self-center">
                            <div className={"relative text-5xl font-base inset-0 top-30"}>
                                LP
                            </div>
                            <PieChart className=""
                                data={[
                                    { title: lp.token_a_ticker, value: parseFloat(lp.amount_a), color: '#80C8C4' },
                                    { title: lp.token_b_ticker, value: parseFloat(lp.amount_b), color: '#4494CE' },
                                ]}
                                lineWidth={4}
                                paddingAngle={10}
                                animate={true}
                                animationDuration={1000}
                            />
                        </div>
                    </div>
                    
                    <div className="font-urbanist uppercase text-tertiary my-10 text-4xl 
                            flex flex-col text-left place-content-center items-center">
                        <div>
                            <div className=" my-5">{euroQtyFormat(lp.amount_a)}
                                <div className="text-seafoam text-xl">{lp.token_a_ticker}</div>
                            </div>
                            <div className="my-5">{euroFormat(lp.amount_b)}
                                <div className="text-succ4 text-xl">{lp.token_b_ticker}</div>
                            </div>
                            <div className="my-5">{euroQtyFormat(lp.lp_token_supply)}
                                <div className="text-succ5 text-xl">Total LP Shares</div>
                            </div>
                        </div>
                    </div>

                    <div></div>
                </div>

                )})}
            </div>
        }
        </>
    }

    function showDataRoom() {
        return <>
            <div className="grid grid-cols-6 mx-20">
                <div></div>
                <div className="col-span-4">
                    {docListing(documents)}
                </div>
                <div></div>
            </div>
        </>
    }

    function showNoContentForNotLogged(){
        return <>
            <div className="text-center text-tertiary font-code-pro tracking-wider uppercase font-base text-xl">
                Please login or register to see this section.
            </div>
        </>
    }

    var headers:any = []
    var labels:any = []
    var contents:any = []

    var tabManager:any = [
        {h: "Asset Overview", l:"", c: showOverview(), show: true },
        {h: "Token Overview", l:"token", c: showTokenOverview(), show: true },
        {h: "Deal", l:"deal", c: showDealChar(dealCharInfo), show: true },
        {h: "Investment Restrictions", l:"restr", c: showInvLimits(invLimitsInfo), show: true },
        {h: "IRR / Fund terms", l:"irr", c: <>{showIrr(irrInfo)} {showFundTerms(fundTermsInfo)}</>, show: true },
        {h: "Other", l:"other", c: showOther(otherInfo), show: true },
        {h: "Market Statistics", l:"stats", show: isDev,
            c: <SecondaryMarketStatistics tokenUuid={props.tokenInfo.uuid} /> },
        {h: "Liquidity Pool", l:"lp", show: props.completeView, 
            c: props.isLogged ? showLiquidityPool() : showNoContentForNotLogged() },
        {h: "P2P", l:"p2p", show: props.completeView /*&& isDev && props.tokenInfo.p2p_flag*/, 
            c: props.isLogged ? <P2PTokenTab p2ps={onlyActiveP2p} tokenInfo={props.tokenInfo} refreshList={setRefreshP2pList}/> : showNoContentForNotLogged()},
        {h: "Data room", l:"docs", show: true, 
            c: props.isLogged ? showDataRoom(): showNoContentForNotLogged() },
    ]

    tabManager.forEach( (tab: any) => {
        if(tab.show){
            headers.push(tab.h)
            labels.push(tab.l)
            contents.push(tab.c)
        }
    });
    
    return <>
        <div className="">
            <TabsMenu headers={headers} labels={labels} contents={contents} openedTab={props.openedTab}/>
        </div>
    </>
}


