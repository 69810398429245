import { useEffect, useState } from "react";
import { getImagePosition } from "../functions/images";
import { TitleColor1 } from "./typography/Typogtaphy";
import deleteimg from '../media/delete.png'
import arrowUp from "../media/arrow2.png";
import { DexxButton } from "./light/LightButton";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { actionCreators } from "../state";
import sha1 from "sha1";
import Compressor from "compressorjs";

export default function UploadCardImage(props: { images: any, assetName: string }): JSX.Element {
    const [mainImage, setMainImage] = useState<{ src: string, name: string, filename_big: string, filename_small: string, uploaded: boolean }>()
    const [imageTBR, setImageTBR] = useState<{ filename_big: string, filename_small: string }>()
    const [files, setFiles] = useState<File[]>([])
    const acceptedFormats = ["image/png", "image/jpeg", "image/svg+xml"]
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    useEffect(() => {
        let pos0 = props.images.filter((i: any) => { return getImagePosition(i.filename_big) === 0 })
        if (pos0.length > 0)
            setMainImage(pos0[0])
    }, [props.images])

    return <>
        <div className="grid">
            <TitleColor1 className="text-lg">/ Main Image</TitleColor1>
            <div className="py-2 flex text-white uppercase align-middle items-center gap-8">
                <div className="flex align-middle items-center">
                    <label>
                        <img alt="" src={arrowUp} className="transform -rotate-90 cursor-pointer" />
                        <input className="hidden" type="file" onChange={(e) => {
                            if (e.target.files && e.target.files.length > 0) {
                                let file = e.target.files[0]
                                if (acceptedFormats.indexOf(file.type) > -1) {
                                    let hashname = sha1(props.assetName) + "/"
                                    let nameBig = hashname + "0_big_" + file.name
                                    let nameSmall = hashname + "0_small_" + file.name
                                    setMainImage({
                                        name: file.name, filename_big: nameBig,
                                        filename_small: nameSmall, src: URL.createObjectURL(file),
                                        uploaded: false
                                    })

                                    new Compressor(file, {
                                        quality: 0.1,
                                        maxWidth: 300,
                                        maxHeight: 300,
                                        success(result) {
                                            let s = new File([result], file.name)
                                            setFiles([file, s])
                                            files[1] = s
                                        }
                                    })
                                }
                            }
                        }} />
                    </label>
                    <div>Upload main image</div>
                </div>
                <div>
                    <DexxButton label={"Confirm"} className="rounded-xl px-8 font-semibold bg-succ2 " color1="white" color2="white" color4="blue"
                        onClick={() => {
                            if (mainImage) {
                                actions.uploadMedia(mainImage.filename_big, files[0], "Image")
                                actions.uploadMedia(mainImage.filename_small, files[1], "Image")
                            }
                            if (imageTBR) {
                                actions.removeMedia(imageTBR.filename_big)
                                actions.removeMedia(imageTBR.filename_small)
                            }
                        }}
                    />
                </div>
            </div>
            {mainImage ?
                <div className=" flex flex-col items-center bg-white p-2 rounded-md w-fit mt-2">
                    <div className="flex">
                        <div className="w-5"></div>
                        <div className=" bg-black_light md:w-72 w-56 flex place-content-center">
                            <img src={mainImage ? mainImage.src : ""}
                                className="md:h-40 h-32" />
                        </div>
                        <div className="h-4 w-5 text-right">
                            <img src={deleteimg} className="h-3 w-3 cursor-pointer"
                                onClick={() => {
                                    setMainImage(undefined)
                                    setImageTBR({
                                        filename_big: mainImage.filename_big,
                                        filename_small: mainImage.filename_small
                                    })
                                }} />
                        </div>
                    </div>
                    <div className={"md:w-72 w-56 text-sm text-"
                        + (mainImage?.uploaded ? "blue " : "purple-600 ") + " pt-2"}>
                        {mainImage?.name}
                    </div>
                </div> : <></>}
        </div>


    </>
}