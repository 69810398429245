import React, { useState } from 'react'
import { AssetInfo, PrimarySaleGet, SecurityToken, defaultBalance } from '../PoolContext'
import { FileSrc } from "../AssetContext"
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../state'
import './TradeInterface.css'
import {euroFormat, quantityFormat, euroFeesFormat} from "../utils"

import Popup from './Popup'
import LabelAndInput from './LabelAndInput'
import { DexxButton } from "./light/LightButton";

export interface PSInterfaceProps {
    asset: AssetInfo
    token: SecurityToken
    primary_sale: PrimarySaleGet
    closeFunction: Function
}

export default function BuyPSInterface(props: PSInterfaceProps) {
    
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const [buyAmountPS, setBuyAmountPS] = useState<number>(0)
    const [buyCalculatedPricePS, setBuyCalculatedPricePS] = useState<number>(0)
    const [balance, setBalance] = useState(defaultBalance)
    const [response, setResponse] = useState<any[]>([0, undefined])
    const [submitted, setSubmitted] = useState(false)
    const [fees, setFees] = useState<any>(null)
    const [closeAfterSubmit, setCloseAfterSubmit] = useState<boolean>(false)
    const [ownedTokenA, setOwnedTokenA] = useState<number>(0)
    
    //const [maxTokenA, setMaxTokenA] = useState<number>(0)
    //const [maxTokenB, setMaxTokenB] = useState<number>(0)
    
    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    
    const [coOwnership, setCoOwnership] = useState<boolean>(false)
    const [prospectus, setProspectus] = useState<boolean>(false)
    const [owningToken, setOwningToken] = useState<boolean>(false)
    const[media, setMedia] = useState<any>([])
    const[docSrc, setDocSrc] = useState<{url:string, filename: string}[]>([])
    const [coOwnDoc, setCoOwnDoc] = useState<FileSrc>()
    const [prospectusDoc, setProspectusDoc] = useState<FileSrc>()

    useEffect(() => {
        if(props.token.asset_uuid !== "" && props.token.asset_uuid !== "0")
            actions.getAssetMedia(props.token.asset_uuid, setMedia)
    }, [props.token.asset_uuid])

    function handleClosePopUp(){
        setBuyAmountPS(0)
        setBuyCalculatedPricePS(0)
        setShowPopUp(false)
        setTitlePopUp("")
        setMsgPopUp("")
        if(closeAfterSubmit) props.closeFunction()
    }
    
    useEffect(() => {
        actions.getActualBalance(setBalance)
        actions.getFeesFromCategory(props.primary_sale.asset_category, setFees)
    }, [])

    useEffect(() => {
        if(media)
            actions.getDocuments(media, setDocSrc)
    }, [media])

    useEffect(() => {
        if(docSrc){
            let tmp:any = []
            docSrc.map((u) => {
                if(u.filename && u.filename.includes("coownership"))
                    tmp.push({name: u.filename, uploaded: true, src: u.url})
            })
            setCoOwnDoc(tmp[0])

            let tmp2:any = [] 
            docSrc.map((u) => {
                if(u.filename && u.filename.includes("prospectus"))
                    tmp2.push({name: u.filename, uploaded: true, src: u.url})
            })
            setProspectusDoc(tmp2[0])
        }

    }, [docSrc])


    useEffect(() => {
        let securityEntries = balance?.balance?.security_entries
        let tokensByCat = securityEntries ? securityEntries.map((t: any) => t.class_balance) : []
        let Alltokens = [].concat.apply([], tokensByCat)
        let tokens: any[] = Alltokens.filter((t: any) => t.symbol === props.primary_sale.token_ticker)
        let owned = tokens.length > 0 ? tokens[0].available_quantity : 0
        setOwnedTokenA(owned)
        if(owned > 0){
            setCoOwnership(true)  
            setOwningToken(true)
            setProspectus(true)
        }

    }, [props, balance])

    useEffect(() => {
        actions.getFeesFromCategory(props.primary_sale.asset_category, setFees)
    }, [props.primary_sale])
       
    function handleBuyPS(qty: string){ 
        try {
            /*let qtyFloat = qty.length <= 0 || parseFloat(qty) < 0 ? 0 : parseFloat(qty)
            let maxXEUR
            //setBuyAmountPS(qtyFloat);

            let myXEUR = parseFloat(balance.payment_tokens.total)
            let remainingInPS = props.primary_sale.total_supply - props.primary_sale.shares_issued

            let maxFeesForBuyingRemaining = remainingInPS * props.primary_sale.price * (fees.Buyer/100)
            let euroToBuyRemaining =  (remainingInPS * props.primary_sale.price) - maxFeesForBuyingRemaining

            if(euroToBuyRemaining < myXEUR) maxXEUR = euroToBuyRemaining
            else maxXEUR = myXEUR
            let maxQtyForBuyingRemaining = maxXEUR / props.primary_sale.price
             
            
            let maxFeesMyXEUR = myXEUR * (fees.Buyer/100)
            let maxBuyableWithMyXEUR = (parseFloat(balance.payment_tokens.total) - maxFeesMyXEUR) / props.primary_sale.price

            let maxQty = maxQtyForBuyingRemaining < maxBuyableWithMyXEUR ? maxQtyForBuyingRemaining : maxBuyableWithMyXEUR
            
            
            let finalQty = qtyFloat > maxQty ? maxQty : qtyFloat */
            let qtyFloat = (parseFloat(qty) > 0 /*&& parseFloat(qty) <= Number.MAX_SAFE_INTEGER*/) ? parseFloat(qty) : 0
            let myXEUR = parseFloat(balance.payment_tokens.total) 
            let remainingInPS = props.primary_sale.total_supply - props.primary_sale.shares_issued
            let maxQty = Math.min( myXEUR/(props.primary_sale.price * (1+(fees.Buyer/100))) , remainingInPS )
            maxQty = Math.floor(maxQty)
            let finalQty = Math.min(maxQty, qtyFloat)

            setBuyAmountPS(finalQty)
            setBuyCalculatedPricePS(finalQty * (props.primary_sale.price))

        } catch(e) {
            setBuyAmountPS(0)
            setBuyCalculatedPricePS(0)
        }
    }

    // CONFIRM BUY PS
    function handleBuyPSCommit(/*e: React.MouseEvent*/) {
        if (buyAmountPS <= 0) {
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please enter a quantity greater than zero.")
            setShowPopUp(true)
        } 
        let currentPrice = /*parseFloat*/(props.primary_sale.price)

        if(coOwnership === false){
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please read and agree to the co-ownership agreement before proceeding.")
            setShowPopUp(true)
        } else if(prospectus === false){
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please read and agree the asset token prospectus before proceeding.")
            setShowPopUp(true)
        } else if (buyAmountPS > props.primary_sale.total_supply) {
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please add funds to your wallet to complete the token purchase.")
            setShowPopUp(true)
        } else {
            if (balance.payment_tokens ) {
                if (parseFloat(balance.payment_tokens.available_total) < buyAmountPS * currentPrice) {
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("Please add funds to your wallet to complete the token purchase.")
                    setShowPopUp(true)
                } else {
                    if(buyAmountPS > 0) {
                        
                        //let remainingInPS = props.primary_sale.total_supply - props.primary_sale.shares_issued
                        //let maxFees = parseFloat(balance.payment_tokens.total) * (fees.Buyer/100)
                        //let maxBuyable = parseFloat(balance.payment_tokens.total) * props.primary_sale.price

                        //let maxQty = remainingInPS < maxBuyable ? remainingInPS : maxBuyable
                        
                        //let finalQty = buyAmountPS > maxQty ? maxQty : buyAmountPS
                        //setBuyAmountPS(finalQty)
                        
                        let buyFromPS = {
                            uuid: props.primary_sale.uuid,
                            amount: /*finalQty*/buyAmountPS
                        }
                        actions.submitBuyPS(buyFromPS, setResponse)
                        actions.getActualBalance(setBalance)
                        setSubmitted(true)
                    }
                }
            }
        }
    }
    
    useEffect(() => {
        if(response[0] !== 0){
            if(response[0] === 204 && submitted){
                setSubmitted(false)
                setTitlePopUp("Success")
                setMsgPopUp("The purchase was successfully submitted. Check your Investor Dashboard to verify the completed transaction.")
                setShowPopUp(true)
                setCloseAfterSubmit(true)
            } else if(submitted){
                setSubmitted(false)
                setTitlePopUp("Sorry!")
                setMsgPopUp("The transaction was not successful. Please, try again." )
                setShowPopUp(true)
            }
        }

    }, [response, submitted])

    function labelAndValue(label: string, value: any, colorVal?: string, colorLabel?:string) {
        
        let cV = "gray-5"
        let cL = "primary"
        if(colorVal) cV = colorVal
        if(colorLabel) cL = colorLabel
        return <>
            <div className={"text-" + cL + " font-semibold text-sm"}>
                {label}
                <div className={"font-normal text-sm text-" + cV}>{value}</div>
            </div>
        </>
    }

    return (<>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp}  ></Popup>
        
        <div className="w-128 h-full font-roboto mt-2">
            <div className="pl-10 grid grid-cols-3 mb-2">
                <div className="col-span-2">
                    <div className={"text-succ1 text-sm font-medium"}>
                        My Balance</div>
                </div>
            </div>
            <div className="pl-10">
                <div className="grid grid-cols-2">
                    {labelAndValue(props.primary_sale.token_ticker+":", quantityFormat(ownedTokenA), "blue", "blue")}
                    {labelAndValue("XEUR:", euroFormat(balance.payment_tokens.total), "blue", "blue")}
                </div>
            </div>


            <div className="bg-gray4lines h-px w-full my-2"/>

            <div className="pl-10">
                <div className="grid grid-cols-2">
                    {labelAndValue("Asset Name:", props.primary_sale.asset_name, "gray4lines", "gray4lines")}
                    {labelAndValue("Ticker:", props.primary_sale.token_ticker, "gray4lines", "gray4lines")}
                </div>
            </div>

            <div className="bg-gray4lines h-px w-full my-2"/>

            <div className="pl-10 grid grid-cols-3 mb-2">
                <div className="col-span-2">
                    <div className={"text-succ1 text-sm font-medium"}>
                        Remaining Tokens for sale</div>
                </div>
            </div>
            <div className="pl-10">
                <div className="grid grid-cols-2">
                    {labelAndValue(props.primary_sale.token_ticker+":", quantityFormat(props.primary_sale.total_supply - props.primary_sale.shares_issued), "blue", "blue")}
                </div>
            </div>

            <div className="bg-gray4lines h-px w-full mt-2 " />
            
            <div className={"text-sm pl-10 pt-5 pb-4 bg-gradient-to-r from-succ1 to-transparent"}>

                
                <div className="grid grid-cols-2 mb-2">
                    
                    <div className="font-semibold text-xl text-blue">Quantity *
                        <LabelAndInput label={""} value={buyAmountPS} fun={handleBuyPS} 
                            color="blue" type="number" className="mr-8 text-xl" size='md'/>
                    </div>
                    <div>
                        {labelAndValue("Price:", euroFormat(props.primary_sale.price)+"€", "navy_blue")}
                    </div>
                    

                </div>
                <div className="grid grid-cols-2">
                    {labelAndValue("Max Amount:", quantityFormat(props.primary_sale.total_supply-props.primary_sale.shares_issued), "navy_blue")}
                    {labelAndValue("Calculated Cost:", euroFormat(buyCalculatedPricePS)+"€", "navy_blue")}
                </div>    
            </div>


            <div className="bg-gray4lines h-px w-full mb-3"/>
            <div className="pl-10 mb-2 font-roboto">
                <div className="text-succ1 text-sm font-medium">
                    Review</div>
                <div className="text-primary text-sm font-urbanist font-normal">
                    Estimated Purchase Amount:
                    <strong>{fees && fees.Buyer ? " €"+euroFormat(buyCalculatedPricePS * (1+fees.Buyer/100)) : ""}</strong>
                </div>
                <div className="text-primary text-sm font-urbanist font-normal">
                    Estimated Fees:
                    <strong>{fees && fees.Buyer ? " €"+euroFeesFormat(buyCalculatedPricePS * fees.Buyer / 100) : ""}</strong>
                </div>
            </div>

            <div className="grid grid-cols-8 pl-5">

                { owningToken ? (
                    <input type="checkbox" checked={true} className="w-10 py-4 mt-5 h-5 
                        text-black text-xl font-roboto leading-none col-span-1" disabled={true} />
                ) :   (
                    <input type="checkbox" checked={coOwnership} className="w-10 py-4 mt-5 h-5 
                        text-black text-xl font-roboto leading-none col-span-1"
                        onChange={() => { setCoOwnership(!coOwnership) }} />
                ) }

                <label className="w-full text-black text-sm py-4 pr-10 col-span-7 font-semibold">
                    I have read and I agree to the terms and conditions of the <a href={coOwnDoc ? coOwnDoc.src : ""} target="_blank" className='text-succ1'>co-ownership agreement</a>.
                </label>
            </div>

            <div className="grid grid-cols-8 pl-5 mb-5">

                { owningToken ? (
                    <input type="checkbox" checked={true} className="w-10 py-4 mt-5 h-5 
                        text-black text-xl font-roboto leading-none col-span-1" disabled={true} />
                ) :   (
                    <input type="checkbox" checked={prospectus} className="w-10 py-4 mt-5 h-5 
                        text-black text-xl font-roboto leading-none col-span-1"
                        onChange={() => { setProspectus(!prospectus) }} />
                ) }

                <label className="w-full text-black text-sm py-4 pr-10 col-span-7 font-semibold">
                I have read the <a href={prospectusDoc ? prospectusDoc.src : ""} target="_blank" className={'text-succ1' }>prospectus</a> on this asset token.
                </label>
            </div>

            <div className="flex justify-between px-6 pb-2">
                <DexxButton onClick={() => {props.closeFunction()}} label={"close"} fontClasses="text-sm font-medium"
                    className="rounded-full px-8 py-3 bg-white drop-shadow-n" color1='white' color2='gray5'/>
                <DexxButton onClick={() => {handleBuyPSCommit()}} label={"confirm"} 
                    fontClasses="text-sm  font-medium" className="rounded-full px-16 bg-white py-3" 
                    color1='white' color2='primary'/>
            </div>
        </div>
    </>)
}

