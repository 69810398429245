import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { BackButton, DexxButton } from "../components/light/LightButton";
import { DexxTable } from "../components/light/LightTable";
import { euroFormat, quantityFormat, formatDatetimeOffset, euroQtyFormat, euroFeesFormat, formatDate } from "../utils";
import { actionCreators, State } from "../state";
import { defaultBalance, defaultIssuerTransactionsList } from "../PoolContext";
import { DexxTableTitle } from "../components/HeaderButton";
import { CSVLink } from 'react-csv';
import { BlueLine, DexxPieChart } from "../components/utils/DexxElements";
import { DexxBalance, DexxSubBalance } from "../components/typography/Typogtaphy";
import { SelectChangeEvent } from "@mui/material";
import { InputFilter, SelectFilter } from "../components/light/TableFilters";

export default function IssuerPortfolio(): JSX.Element {

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const [balance, setBalance] = useState(defaultBalance)
    const issuerTransactionsListState:any = useSelector((state: State) => state.issuerTransactions)
    const [primarySaleList, setPrimarySaleList] = useState<any>(defaultIssuerTransactionsList.primary_sales)
    const [liquidityPoolsList, setLiquidityPoolsList] = useState<any>(defaultIssuerTransactionsList.liquidity_pools)
    const [liquidationsList, setLiquidationsList] = useState<any>(defaultIssuerTransactionsList.buybacks)
    const [p2pList, setP2pList] = useState<any>(defaultIssuerTransactionsList.peer_to_peer)
    const [tokenizationsList, setTokenizationsList] = useState<any>(defaultIssuerTransactionsList.tokenizations)
    const [arraySecurities, setArraySecurities] = useState<any[]>([])

    const [rowTxn, setRowTxn] = useState<any[]>([])
    const [rsFilteredTxn, setRsFilteredTxn] = useState<any[]>([])
    const [rsTxnFormatted, setRsTxnFormatted] = useState<any[]>([])
    const [rsSecurities, setRsSecurities] = useState<any[]>([])
    const [rsSecuritiesFormatted, setRsSecuritiesFormatted] = useState<any[]>([])
    const [sortTrxBy, setSortTrxtBy] = useState<number>(0)
    const [sortTrxDESC, setSortTrxDESC] = useState<boolean>(true)

    const [filterStatus, setFilterStatus] = useState('all')
    const [filterSCResponse, setFilterSCResponse] = useState('all')
    const [filterCategory, setFilterCategory] = useState('all')
    const [filterTicker, setFilterTicker] = useState('')
    
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload

    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0

    useEffect(() => {
        actions.getIssuerTransactions()
        actions.getIssuerBalance(setBalance)
    }, [])

    useEffect(() => {
        if (issuerTransactionsListState.payload ) {
            setPrimarySaleList(issuerTransactionsListState.payload.primary_sales ? issuerTransactionsListState.payload.primary_sales : [])
            setLiquidityPoolsList(issuerTransactionsListState.payload.liquidity_pools ? issuerTransactionsListState.payload.liquidity_pools : [])
            setLiquidationsList(issuerTransactionsListState.payload.buybacks ? issuerTransactionsListState.payload.buybacks : [])
            setP2pList(issuerTransactionsListState.payload.peer_to_peer ? issuerTransactionsListState.payload.peer_to_peer : [])
            setTokenizationsList(issuerTransactionsListState.payload.tokenizations ? issuerTransactionsListState.payload.tokenizations : [])
        }
    }, [issuerTransactionsListState.payload])

    const handleChangeStatusFilter = (event: SelectChangeEvent) => {
        setFilterStatus(event.target.value as string);
    }

    const handleChangeCategoryFilter = (event: SelectChangeEvent) => {
        setFilterCategory(event.target.value as string);
    }

    const handleChangeSCResponseFilter = (event: SelectChangeEvent) => {
        setFilterSCResponse(event.target.value as string);
    }

    const handleChangeTickerFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterTicker(event.target.value as string);
    }
    
    useEffect(() => {
        filterTxnRows()
    }, [filterStatus, filterSCResponse, filterCategory, filterTicker])
    
    function filterTxnRows() {

        let tmp:any[] = [...rowTxn]
        if(filterStatus !== 'all'){
            if(filterStatus === 'e') tmp = tmp.filter((i) => {return i[1] === "Executed"})
            if(filterStatus === 'p') tmp = tmp.filter((i) => {return i[1] === "Pending"})
            if(filterStatus === 'f') tmp = tmp.filter((i) => {return i[1] === "Failed"})
        }
        if(filterSCResponse !== 'all'){
            if(filterSCResponse === 's') tmp = tmp.filter((i) => {return i[2] === "Success"})
            //if(filterSCResponse === 'f') tmp = tmp.filter((i) => {return i.response.ERROR !== ""})
            if(filterSCResponse === 'f') tmp = tmp.filter((i) => {return i[2] !== "Success"})
        }
        if(filterCategory !== 'all'){
            if(filterCategory === 'a') tmp = tmp.filter((i) => {return i[3] === "ART"})
            if(filterCategory === 'i') tmp = tmp.filter((i) => {return i[3] === "AIF"})
            if(filterCategory === 's') tmp = tmp.filter((i) => {return i[3] === "SPORTEX"})
            if(filterCategory === 'c') tmp = tmp.filter((i) => {return i[3] === "COLLECTABLES"})
        }
        if(filterTicker !== ''){
            tmp = tmp.filter((i) => {return i[6].toLowerCase().includes(filterTicker.toLowerCase())})
        }
        setRsFilteredTxn(tmp)
    }

    function sortRows(sortMethod: any, index: number, 
        recordset: any[], setRecordset: any, 
        sortBy: number, setSortBy: any, 
        sortDESC: boolean, setSortDESC: any, 
        arrayDates: any[]) {

        var tmp = [...recordset]

        var italianFormat = false
        const test = "02-13-2018"
        var check = new Date(test).toLocaleString();
        if(check.split("/")[0] === "13") italianFormat = true

        if(italianFormat){
            arrayDates.forEach((el:any) => {
                tmp.forEach((obj:any) => {
                    if(obj[Object.keys(obj)[el.index]] && obj[Object.keys(obj)[el.index]] !== "-"){
                        if(el.format === "datetime") {
                            var dateSplit = obj[Object.keys(obj)[el.index]].split(", ")
                            var dateSplit2 = dateSplit[0].split("/")
                            var dateSplit3 = dateSplit[1].split(":")
                            var dateObject = new Date(parseInt(dateSplit2[2]), parseInt(dateSplit2[1])-1, parseInt(dateSplit2[0]), parseInt(dateSplit3[0]), parseInt(dateSplit3[1]), parseInt(dateSplit3[2])); 
                        } else {
                            var dateSplit = obj[Object.keys(obj)[el.index]].split("/")
                            var dateObject = new Date(parseInt(dateSplit[2]), parseInt(dateSplit[1])-1, parseInt(dateSplit[0])); 
                        }
                        obj[Object.keys(obj)[el.index]] = dateObject
                        
                    } else obj[Object.keys(obj)[el.index]] = "-"
                });
            }); 
        }

        tmp.sort(sortMethod)

        if(index === sortBy){
            if(sortDESC) tmp.reverse()
            setSortDESC(!sortDESC)
        } else {
            setSortBy(index)
            setSortDESC(true)
        }

        arrayDates.forEach((el:any) => {
            tmp.forEach((obj:any) => {
                if(obj[Object.keys(obj)[el.index]] && obj[Object.keys(obj)[el.index]] !== "-"){
                    if(el.format === "datetime") 
                        obj[Object.keys(obj)[el.index]] = new Date(formatDatetimeOffset(obj[Object.keys(obj)[el.index]], 0)).toLocaleString();
                    else if(el.format === "date") 
                        obj[Object.keys(obj)[el.index]] = new Date(formatDate(obj[Object.keys(obj)[el.index]], 0)).toLocaleString().split(", ")[0];   
                } else obj[Object.keys(obj)[el.index]] = "-"
            });
        }); 
        setRecordset(tmp)

        filterTxnRows()
    }

    const arrayColor = ["#8CD9CF","#FFCF74","#AF6CFC","#E95C7B","#C887DF","#A1AEBC"]
    const arrayColorText = ["succ1","warning","violet","error1","succ8","gray5"]
    
    const headersTransactions = [
        {name: "Date", sort: () => {sortRows((a: any, b: any) => ((new Date(formatDatetimeOffset(a[0], offset)) < new Date(formatDatetimeOffset(b[0], offset))) ? 1 : -1), 0, rowTxn, setRowTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}])}},
        {name: "Status", sort: () => {sortRows((a: any, b: any) => ((a[1] > b[1]) ? 1 : -1), 1, rowTxn, setRowTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}] )}},
        {name: "Response", sort: () => {sortRows((a: any, b: any) => ((b[2]) ? ((a[2] > b[2]) ? 1 : ((b[2] > a[2]) ? -1 : 0)) : -1), 2, rowTxn, setRowTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}] )}},
        {name: "Cat", sort: () => {sortRows((a: any, b: any) => ((a[3] - b[3]) || (a[3] > b[3]) ? 1 : -1), 3, rowTxn, setRowTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}] )}},
        {name: "Type of Security Token", sort: () => {sortRows((a: any, b: any) => ((a[4] > b[4]) ? 1 : -1), 4, rowTxn, setRowTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}] )}},
        {name: "Token name", sort: () => {sortRows((a: any, b: any) => ((a[5] > b[5]) ? 1 : -1), 5, rowTxn, setRowTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}] )}},
        {name: "Ticker", sort: () => {sortRows((a: any, b: any) => ((a[6] > b[6]) ? 1 : -1), 6, rowTxn, setRowTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}] )}}, 
        "Number of Tokens", "Price", "Buyer Fees", "Seller Fees", "Value", "Total",
        {name: "Trans Type", sort: () => {sortRows((a: any, b: any) => ((a[13] > b[13]) ? 1 : -1), 13, rowTxn, setRowTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}] )}},
        {name: "Counterpart", sort: () => {sortRows((a: any, b: any) => ((a[14] > b[14]) ? 1 : -1), 14, rowTxn, setRowTxn, sortTrxBy, setSortTrxtBy, sortTrxDESC, setSortTrxDESC, [{index:0, format:"datetime"}] )}}, 
        "Hash on Blockchain"]
    const formattedHeaderTransactions = ["Date", "Status", "Response", "Cat", "Type of Security Token", "Token name", "Ticker",
         "Number of Tokens", "Price", "Buyer Fees", "Seller Fees", "Value", "Total","Trans Type", "Counterpart", "Hash on Blockchain"]
    const alignmentTransactions = ["center", "left", "left", "left", "left", "left", "center",
         "right", "right", "right", "right", "right", "right","left", "left", "left"]
    
    const headersSec = ["Asset name", "Cat", "Ticker", "Quantity Owned", "Available Quantity", "Price", "Value", "Portfolio weight", ""]
    const alignmentSec = ["left", "left", "center", "right", "right", "right", "right", "right", "center"]
    
    const rowsSec:any[] = [], rowsTransactions:any[] = []
    const formattedSec:any[] = [], formattedTxn:any[] = []

    useEffect(() => {
        let count = 0
        let others = {
            title: "Others",
            value: 0,
            color: ""
        }
        let arrayS = []
        if(balance.balance){
            for (let r of balance.balance.security_entries) {
                for (let e of r.class_balance) {
                    let security = {
                        title: e.symbol,
                        value: parseFloat(e.available_value),
                        color: "",
                        colorName: ""
                    }
                    arrayS.push(security)
                }
            }
            arrayS.sort((a: any, b: any) => (b.value - a.value))
            let final = []
            for (let e of arrayS) {
                if(count < 5){
                    e.color = arrayColor[count]
                    e.colorName = arrayColorText[count]
                    final.push(e)
                } else {
                    others.value += e.value
                }
                count ++
            }
            if(others.value > 0) {
                others.color = arrayColor[5]
                final.push(others)
            }
            setArraySecurities(final)
        }
    }, [balance])

    useEffect(() => {
        if(balance) {
            if(balance.balance){
                for (let r of balance.balance.security_entries) {
                    for (let e of r.class_balance) {
                        rowsSec.push([e.asset_name,r.class, e.symbol, euroQtyFormat(e.quantity),  euroQtyFormat(e.available_quantity), euroFormat(e.current_price) + " " + e.currency, 
                            euroFormat(parseFloat(e.value)), euroFeesFormat(e.pct)+"%", 
                            <Link to ={"/asset/"+e.asset_uuid} className="grid justify-center">
                                <DexxButton label={"Details"} color1="white" color2="primary"
                                    className="px-2 rounded-lg"
                                    fontClasses="text-xs"/>
                            </Link>])
                            
                        formattedSec.push([e.asset_name,r.class, e.symbol, parseInt(e.quantity), parseInt(e.available_quantity), euroFormat(e.current_price) + " " + e.currency,  
                            euroFormat(parseFloat(e.value)), euroFeesFormat(e.pct)+"%"])
                        
                        if(rowsSec.length) setRsSecurities(rowsSec)
                        if(formattedSec.length) setRsSecuritiesFormatted(formattedSec)
                    }
                }
            }
        }
    }, [balance])
    
    useEffect(() => {
        if(primarySaleList && liquidationsList && liquidityPoolsList && p2pList && tokenizationsList){
            for (let t of primarySaleList) {
                if(t.txn_operation === "create" || t.txn_operation === "update"){

                rowsTransactions.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.response.OK === true ? "Success" : t.response.ERROR, t.asset_category, t.asset_type, t.asset_name, t.token_ticker, 
                    quantityFormat(t.total_supply), 
                    "€"+euroFormat(t.price_per_unit), 
                    t.response.FEES && t.response.FEES.buyer ? "€"+euroFormat(t.response.FEES.buyer.amount) : "", 
                    t.response.FEES && t.response.FEES.seller ? "€"+euroFormat(t.response.FEES.seller.amount) : "",
                    "€"+euroFormat(t.price_per_unit * t.total_supply), 
                    t.response.FEES && t.response.FEES.buyer ? "€"+euroFormat(t.price_per_unit * t.total_supply + t.response.FEES.buyer.amount) : "€"+euroFormat(t.price_per_unit * t.total_supply),
                    t.txn_operation, t.txn_category, <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>  ])
                formattedTxn.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.response.OK === true ? "Success" : t.response.ERROR, t.asset_category, t.asset_type, t.asset_name, t.token_ticker, 
                    quantityFormat(t.total_supply), 
                    "€"+euroFormat(t.price_per_unit), 
                    t.response.FEES && t.response.FEES.buyer ? "€"+euroFormat(t.response.FEES.buyer.amount) : "", 
                    t.response.FEES && t.response.FEES.seller ? "€"+euroFormat(t.response.FEES.seller.amount) : "",
                    "€"+euroFormat(t.price_per_unit * t.total_supply), 
                    t.response.FEES && t.response.FEES.buyer ? "€"+euroFormat(t.price_per_unit * t.total_supply + t.response.FEES.buyer.amount) : "€"+euroFormat(t.price_per_unit * t.total_supply),
                    t.txn_operation, t.txn_category, t.trx_identifier ])

                } else { // start, stop, resume, pause

                rowsTransactions.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.response.OK === true ? "Success" : t.response.ERROR, t.asset_category, t.asset_type, t.asset_name, t.token_ticker, 
                    "", "","", "",
                    "", "", 
                    t.txn_operation, t.txn_category, <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>  ])
                formattedTxn.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.response.OK === true ? "Success" : t.response.ERROR, t.asset_category, t.asset_type, t.asset_name, t.token_ticker, 
                    "", "","", "", "", "",             
                    t.txn_operation, t.txn_category, t.trx_identifier])
                }
            }
            for (let t of liquidityPoolsList) {
                rowsTransactions.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.response.OK === true ? "Success" : t.response.ERROR, t.asset_category, t.asset_type, t.asset_a_name, t.token_a_ticker, 
                    t.amount_a? quantityFormat(t.amount_a) : "", 
                    t.amount_b?"€"+euroFormat(t.amount_b):"", 
                    //t.response.FEES.buyer.amount?"€0,00":"", 
                    "",
                    //t.response.FEES.seller.amount?"€0,00":"", 
                    "",
                    t.amount_b?"€"+euroFormat(parseInt(t.amount_b)*2):"", 
                    t.amount_b?"€"+euroFormat(parseInt(t.amount_b)*2):"", 
                    t.txn_operation, t.txn_category, <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>  ])
                formattedTxn.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.response.OK === true ? "Success" : t.response.ERROR, t.asset_category, t.asset_type, t.asset_a_name, t.token_a_ticker, 
                    t.amount_a? quantityFormat(t.amount_a) : "", 
                    t.amount_b?"€"+euroFormat(t.amount_b):"", 
                    "", "",
                    t.amount_b?"€"+euroFormat(parseInt(t.amount_b)*2):"", 
                    t.amount_b?"€"+euroFormat(parseInt(t.amount_b)*2):"", 
                        t.txn_operation, t.txn_category, t.trx_identifier ])
            }

            for (let t of liquidationsList) {
                rowsTransactions.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.status === "Executed" ? "Success" : "Failed", t.asset_category, t.asset_type, t.asset_name, t.token_ticker, 
                    t.quantity ? quantityFormat(t.quantity) : "", 
                    t.price_per_unit ? "€"+euroFormat(t.price_per_unit) : "", 
                    t.fees ? "€"+euroFormat(t.fees) : "", 
                    "",
                    t.quantity && t.price_per_unit ? "€"+euroFormat(parseFloat(t.t.quantity) * parseFloat(t.price_per_unit)) : "", 
                    t.quantity && t.price_per_unit ? "€"+euroFormat(parseFloat(t.t.quantity) * parseFloat(t.price_per_unit) + parseFloat(t.fees)) : "",  
                    t.txn_operation, t.txn_category, <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>  ])
                formattedTxn.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.status === "Executed" ? "Success" : "Failed", t.asset_category, t.asset_type, t.asset_name, t.token_ticker, 
                    t.quantity ? quantityFormat(t.quantity) : "", 
                    t.price_per_unit ? "€"+euroFormat(t.price_per_unit) : "", 
                    t.fees ? "€"+euroFormat(t.fees) : "", 
                    "",
                    t.quantity && t.price_per_unit ? "€"+euroFormat(parseFloat(t.t.quantity) * parseFloat(t.price_per_unit)) : "", 
                    t.quantity && t.price_per_unit ? "€"+euroFormat(parseFloat(t.t.quantity) * parseFloat(t.price_per_unit) + parseFloat(t.fees)) : "",  
                    t.txn_operation, t.txn_category, t.trx_identifier ])
            }

            for (let t of p2pList) {
                //if(t.txn_operation === "create" || t.txn_operation === "delete")
                    rowsTransactions.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.response.OK === true ? "Success" : t.response.ERROR, t.asset_category, t.asset_type, t.asset_name, t.token_ticker, 
                        t.total_token_amount ? quantityFormat(t.total_token_amount) : "", 
                        t.price_per_unit ? "€"+euroFormat(t.price_per_unit) : "", 
                        /*t.response.FEES.buyer?.amount ? "€"+euroFormat(t.response.FEES.buyer.amount) + "(" + t.response.FEES.buyer.pct + "%)" :*/ "",
                        /*t.response.FEES.seller?.amount ? "€"+euroFormat(t.response.FEES.seller.amount) + "(" + t.response.FEES.seller.pct + "%)" :*/ "",
                        "€"+euroFormat(parseFloat(t.price_per_unit) * parseFloat(t.total_token_amount)), 
                        "€"+euroFormat(parseFloat(t.price_per_unit) * parseFloat(t.total_token_amount)), //+ parseFloat(t.response.FEES.buyer.amount) + parseFloat(t.response.FEES.seller.amount)), 
                        t.txn_operation + "(" + t.p2p_type + ")", t.txn_category, <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>  ])
                    formattedTxn.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.response.OK === true ? "Success" : t.response.ERROR, t.asset_category, t.asset_type, t.asset_name, t.token_ticker, 
                        t.total_token_amount ? quantityFormat(t.total_token_amount) : "", 
                        t.price_per_unit ? "€"+euroFormat(t.price_per_unit) : "", 
                        "", "",
                        "€"+euroFormat(parseFloat(t.price_per_unit) * parseFloat(t.total_token_amount)), 
                        "€"+euroFormat(parseFloat(t.price_per_unit) * parseFloat(t.total_token_amount)), //+ parseFloat(t.response.FEES.buyer.amount) + parseFloat(t.response.FEES.seller.amount)), 
                        t.txn_operation + "(" + t.p2p_type + ")", t.txn_category, t.trx_identifier ])
            }

            for (let t of tokenizationsList) {
                //if(t.txn_operation === "create" || t.txn_operation === "delete")
                rowsTransactions.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.response.OK === true ? "Success" : t.response.ERROR ? t.response.ERROR : "Failed", t.asset_category, t.asset_type, t.asset_name, t.ticker, 
                    t.total_supply ? quantityFormat(t.total_supply) : "", 
                    t.proposed_price ? "€"+euroFormat(t.proposed_price) : "", 
                    /*t.response.FEES.listing.amount ? "€"+euroFormat(t.response.FEES.buyer.amount) + "(" + t.response.FEES.buyer.pct + "%)" :*/ "",
                    /*t.response.FEES.tokenization.amount ? "€"+euroFormat(t.response.FEES.seller.amount) + "(" + t.response.FEES.seller.pct + "%)" :*/ "",
                    "€"+euroFormat(parseFloat(t.price_per_unit) * parseFloat(t.total_token_amount)), 
                    "€"+euroFormat(parseFloat(t.price_per_unit) * parseFloat(t.total_token_amount)), //+ parseFloat(t.response.FEES.buyer.amount) + parseFloat(t.response.FEES.seller.amount)), 
                    t.txn_operation, t.txn_category, <Link to={"/blockchain/transaction/"+t.trx_identifier}>{t.trx_identifier}</Link>  ])
                formattedTxn.push([formatDatetimeOffset(t.timestamp, offset), t.status, t.response.OK === true ? "Success" : t.response.ERROR ? t.response.ERROR : "Failed", t.asset_category, t.asset_type, t.asset_name, t.ticker, 
                    t.total_supply ? quantityFormat(t.total_supply) : "", 
                    t.proposed_price ? "€"+euroFormat(t.proposed_price) : "", 
                    "",  "",
                    "€"+euroFormat(parseFloat(t.price_per_unit) * parseFloat(t.total_token_amount)), 
                    "€"+euroFormat(parseFloat(t.price_per_unit) * parseFloat(t.total_token_amount)), //+ parseFloat(t.response.FEES.buyer.amount) + parseFloat(t.response.FEES.seller.amount)), 
                    t.txn_operation, t.txn_category, t.trx_identifier ])
            }

            rowsTransactions.sort((a: any, b: any) => ((new Date(a[0]) < new Date(b[0])) ? 1 : -1))
            rowsTransactions.forEach((obj:any) => {
                obj[Object.keys(obj)[0]] = new Date(obj[Object.keys(obj)[0]]).toLocaleString();
            });
            formattedTxn.sort((a: any, b: any) => ((new Date(a[0]) < new Date(b[0])) ? 1 : -1))
            formattedTxn.forEach((obj:any) => {
                obj[Object.keys(obj)[0]] = new Date(obj[Object.keys(obj)[0]]).toLocaleString();
            });
            /*rowsTransactions.forEach(function (txn:any) {
                txn[0] = txn[0].split(" ")[0]
                
            });*/     // to remove minutes

            if(rowsTransactions.length) setRowTxn(rowsTransactions)
            if(formattedTxn.length) setRsTxnFormatted(formattedTxn)
        }
    }, [primarySaleList, liquidationsList, liquidityPoolsList, p2pList])

    const headers = ['Asset / Security tokens', /*'liquidity pool', */'transaction history'/*, 'bank transaction history'*/]
    const [toBeShown, setToBeShown] = useState(headers.map((_,i) => false))
    function handleClick(id: number) {
        var tmp = toBeShown.map((b,i) => i === id ? !b : b)
        setToBeShown(tmp)
    }

    function showTable(fileName: string, TableHeaders: any[], AlignContent: string[], TableFormatted: any[], TableRows: any[], FormattedHeader?:any[]) {
        return (   
        <div className="grid w-full bg-platinum " id={fileName}>
            <CSVLink className="border-solid py-2 px-5 border-crayola rounded-full uppercase 
                text-primary text-sm mb-2 ml-8 md:ml-16 
                w-fit align-middle bg-platinum border-1" 
                filename={fileName} headers={FormattedHeader ? FormattedHeader : TableHeaders}
                data={TableFormatted}>Download CSV </CSVLink>
            <DexxTable headers={TableHeaders} rows={TableRows} alignment={AlignContent} />
        </div>)
    }

    function showTableWithFilters(fileName: string, TableHeaders: any[], AlignContent: string[], TableFormatted: any[], TableRows: any[], FormattedHeader?:any[]) {
        return (   
        <div className="grid w-full bg-platinum " id={fileName}>
            <div className="flex mb-4 align-items: center">
                <CSVLink className="border-solid py-4 px-5 border-crayola rounded-full uppercase 
                    text-primary text-sm mb-2 ml-8 md:ml-16 
                    w-fit align-middle bg-platinum border-1" 
                    filename={fileName} headers={FormattedHeader ? FormattedHeader : TableHeaders} 
                    data={TableFormatted}>Download CSV </CSVLink>
                <SelectFilter label="Status" value={filterStatus} onChange={handleChangeStatusFilter}
                        values={[{name:"All", val:"all" },{name:"Executed", val:"e"},{name:"Pending", val:"p"},{name:"Failed", val:"f"}]} />
                
                <SelectFilter label="Response" value={filterSCResponse} onChange={handleChangeSCResponseFilter}
                        values={[{name:"All", val:"all" },{name:"Success", val:"s"},{name:"Failure", val:"f"}]} />
                
                <SelectFilter label="Category" value={filterCategory} onChange={handleChangeCategoryFilter}
                        values={[{name:"All", val:"all" },{name:"Art", val:"a"},{name:"AIF", val:"i"},{name:"Sportex", val:"s"},{name:"Collectables", val:"c"}]} />

                <InputFilter label="Ticker" value={filterTicker} onChange={handleChangeTickerFilter} className="ml-5 mr-5" />   
            </div>
            <DexxTable headers={TableHeaders} rows={TableRows} alignment={AlignContent} />
        </div>)
    }

    return <>
        <div className="h-full">
            <div className="flex place-content-center">
                <div className="min-w-2/3">
                    <BlueLine className="hidden md:block"/>
                    <div className="grid md:flex gap-8 justify-center">
                        <DexxPieChart data={arraySecurities} />
                        <div className="w-full text-white font-normal align-middle grid">
                            <div className="md:flex justify-evenly items-center mb-4 md:mb-0">
                                <div className="py-4">Issued tokens value
                                    <DexxBalance className="text-3xl md:text-4xl lg:text-5xl">
                                        &euro;{balance ? euroFormat(balance.balance.available_total) : euroFormat(0)}
                                    </DexxBalance>
                                </div>
                                <div className="md:h-14 lg:h-16">
                                </div>
                            </div>
                            <BlueLine/>
                            <div className="md:flex justify-evenly md:gap-8 gap-4 grid mt-4 md:mt-0 md:flex-wrap">
                                { arraySecurities.map((sec:any, i:number) =>
                                    <DexxSubBalance label={sec.title} color={sec.colorName} 
                                        balance={euroFormat(sec.value)} key={i.toString()}/>)
                                }
                            </div>
                        </div>    
                    </div>
                    <BlueLine  className="hidden md:block"/>
                    <div className="flex justify-evenly mt-5 gap-2 flex-wrap">
                        <Link to="/issuer/dashboard"><DexxButton label={"My dashboard"} className="rounded-full px-4 md:px-8 font-semibold
                            bg-gray6" color1="gray8" borderColor="gray6"/>
                        </Link>
                        <Link to="/issuer/asset/new">
                            <DexxButton label={"New Asset"} className="rounded-full px-4 md:px-8 font-semibold 
                            bg-succ2_light" color1="dark_blue" borderColor="succ2" />
                        </Link>
                    </div>
                </div>
            </div>
            
            <div className=" mx-neg40 mt-5">
                <div className="">
                    <DexxTableTitle label={"Asset / Security tokens ("+rsSecurities.length+")"} 
                        active={toBeShown[0]} dark={false} className="pl-8 md:pl-16"
                        onClick={() => handleClick(0)}/>
                    {toBeShown[0] ? showTable("securities.csv", headersSec, alignmentSec,   
                        rsSecuritiesFormatted, rsSecurities) : <></>}
                    <DexxTableTitle label={"transaction history ("+rowTxn.length+")"} 
                        active={toBeShown[1]} className="pl-8 md:pl-16" dark={true}
                        onClick={() => handleClick(1)}/>
                    {toBeShown[1] ? showTableWithFilters("history-transactions.csv", headersTransactions, alignmentTransactions, 
                        rsTxnFormatted, 
                        (Object.entries(rsFilteredTxn).length > 0 || 
                        (filterCategory !== 'all' || filterSCResponse !== 'all' || filterStatus !== 'all' || filterTicker !== '')) ? rsFilteredTxn :  rowTxn, formattedHeaderTransactions) : <></>}
                </div>
            </div>
            <div className="flex justify-center mt-5">
                <BackButton />
            </div>
        </div>
    </>
}