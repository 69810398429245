import { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux"
import { actionCreators, State } from "../../state"
import { euroFormat } from "../../utils"
import { DexxButton } from "../light/LightButton"
import { Countdown } from "./Countdown"
import Cookies from 'universal-cookie';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { OptimizedImage, SortedImages, sortImages } from "../../functions/images"

const cookies = new Cookies();

export default function Card(props: { assetInfo: any, id: string | number, className: string}): JSX.Element {

    interface AssetInfo {
        issuer_name: string
        asset_type: string
        extra_info: {
            art_investing?: any
            art_lending?: any
        }
    }
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const asset = props.assetInfo
    const [assetInfo, setAssetInfo] = useState<AssetInfo>({issuer_name: "", extra_info: {}, asset_type: ""})
    const [media, setMedia] = useState<any>()
    const [cardImage, setCardImage] = useState<OptimizedImage>()
    const [cardImageURLs, setCardImageURLs] = useState<OptimizedImage>()
    const basicAccountState = useSelector((state: State) => state.basicAccount)
    const basicInfo = basicAccountState.payload
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload
    const isLogged = cookies.get("logged_in")
    
    const [onHoverClasses, setOnHoverClasses] = useState<string>(" from-cards1 to-cards2")
    const [onHoverImg, setOnHoverImg] = useState<string>("")
    //const [isLogged, setIsLogged] = useState<boolean>(false)

    useEffect(() => {
        /*if(basicInfo && basicInfo.email !== ""){
            setIsLogged(true)
        }*/
        actions.getAssetInfo(asset.asset_uuid, setAssetInfo, isLogged)
    }, [])

    useEffect(() => {
        actions.getAssetMedia(asset.asset_uuid, setMedia, isLogged)
    }, [asset.id])

    useEffect(() => {
        if(media){
            let sortedImages: SortedImages = sortImages(media.filter((m: any) => m.media_type_name === "Image"))
            if(sortedImages.sorted_images.length > 0)
                setCardImage(sortedImages.sorted_images[0])
            else if(sortedImages.others.length > 0)
                setCardImage({url_big: "",
                    url_small: "", name: sortedImages.others[0], 
                    filename_big: sortedImages.others[0], filename_small: ""})
            else
                setCardImage(undefined)
            //actions.getImagesURL(sortedImages.others, setImages)
            actions.getImagesURL(cardImage, setCardImageURLs)
        }
    }, [media])

    useEffect(() => {
        if(cardImage)
            actions.getImagesURL(cardImage, setCardImageURLs)
    }, [cardImage])

    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0

    let categoryShort = asset.asset_category === "SPORTEX" ? 
        <div className="text-succ3">SPORT</div> :
        (asset.asset_category === "COLLECTABLES" ? 
        <div className="text-primary">CLLTC</div> :  
        <div className="text-primary">{asset.asset_category}</div>)
    
    let cdStart = new Countdown(new Date(asset.date_start), "Started")
    let cdEnds = new Countdown(new Date(asset.date_end), "Ended")

    var days180 = new Date(asset.date_end)
    days180.setDate(days180.getDate() + 180)

    var days30 = new Date(asset.date_end)
    days30.setDate(days30.getDate() + 30)

    const [cdS, setCdS] = useState(cdStart.getCountdownNoSec())
    const [cdE, setCdE] = useState(cdEnds.getCountdownNoSec())

    useEffect(() => {
        const interval = setInterval(() => {
            setCdS(cdStart.getCountdownNoSec())
            setCdE(cdEnds.getCountdownNoSec())
            }, 1000);
        
            return () => clearInterval(interval);
        
    }, [cdEnds, cdStart])

    let colorStatus = "primary"
    let labelCountdown: any = ""
    let countDown: any = ""
    switch(asset.status) {
        case "Initialized":
            colorStatus = "succ4"
            labelCountdown = "Coming Soon"
            countDown = cdS
            break
        case "Stopped":
            colorStatus = "error2"
            labelCountdown = <br/>
            countDown = <br/>
            break
        case "Started":
            colorStatus = "green-500"
            labelCountdown = "Ends"
            countDown = cdE
            break
        case "Ended":
            colorStatus = "error1"
            labelCountdown = "Completed"
            countDown = <br/>
            break
    }

    return <>
        <Link to={"/primary_sale/"+asset.uuid}>
            <div className={" m-1 bg-white1 font-roboto py-4 pr-6 w-96"+props.className}
                onMouseEnter={() => {
                    setOnHoverClasses(" from-cards3 to-cards4")
                    setOnHoverImg(" invisible")}}
                onMouseLeave={() => {
                    setOnHoverClasses(" from-cards1 to-cards2")
                    setOnHoverImg(" ")}}>
                <div className="flex justify-between">
                    <div className="text-black font-medium text-sm ml-6 mb-2">{asset.asset_category}</div>
                    <div className={"font-medium uppercase text-"+colorStatus}>{asset.pct_sold < 100 ? asset.status : "Sold Out"}</div>
                </div>

                <div className="text-navy_blue flex justify-between font-semibold text-xl ml-6 mb-6">
                    <div>{asset.pct_sold < 100 ? labelCountdown : <br/>}</div>
                    <div>{asset.pct_sold < 100 ? countDown : <br/>}</div>
                </div>
                    
                {cardImageURLs ? <> 
                    <div className="w-80 h-40 rounded-tr-full rounded-br-full
                            align-middle z-10 relative object-cover overflow-hidden">
                        <LazyLoadImage src={cardImageURLs.url_big} className="w-80 h-40 rounded-tr-full 
                            rounded-br-full align-middle z-10 relative object-cover"
                            effect="blur" placeholderSrc={cardImageURLs.url_small} treshold={1000}/>
                    </div>
                    <div className={"w-80 h-40 rounded-tr-full rounded-br-full mt-neg160 z-20 relative "+
                            " bg-cards3_light flex "+onHoverImg}>
                                <div className="text-7xl font-medium tracking-wider_plus self-center ml-6 
                                    leading-11 ">
                                    {categoryShort}</div>
                            </div>
                    </>
                    : 
                    <div className={"w-80 h-40 bg-gradient-to-r "+
                            "rounded-tr-full rounded-br-full flex realtive z-10 "+onHoverClasses}>
                        <div className="text-7xl font-medium tracking-wider_plus self-center ml-6 
                            leading-11 ">
                            {categoryShort}</div>
                    </div>    
                }
                <div className="grid content-between w-full h-72">
                    <div>
                        <div className="text-black font-medium text-medium ml-6 mt-6 mb-4">
                            {assetInfo ? (asset.asset_category === "ART" ? (
                                assetInfo.asset_type === "ART Investing" ?
                                    assetInfo.extra_info.art_investing?.sub_assets[0]?.artist :
                                    assetInfo.extra_info.art_lending?.sub_assets[0]?.artist
                            ) :  
                                (assetInfo.issuer_name ? assetInfo.issuer_name : <br/>) ) : <br/>}
                        </div>
                        <div className="pl-6 text-4xl font-light leading-10 my-5 tracking-wide">
                            {assetInfo ? (asset.asset_category === "ART" ? (
                                assetInfo.asset_type === "ART Investing" ?
                                    assetInfo.extra_info.art_investing?.sub_assets[0]?.artwork_title :
                                    assetInfo.extra_info.art_lending?.sub_assets[0]?.artwork_title
                            ) :  
                                (asset.asset_name ? asset.asset_name : <br/>) ) : <br/>}
                        </div>
                        <div className="pl-6 text-black font-medium">
                            {asset.token_ticker ? asset.token_ticker : <br/>}
                        </div>
                    </div>  
                    <div className=" flex justify-between pl-6">
                        <div>
                            <div className="font-light tracking-wide">TOKEN PRICE</div>
                            <div className="font-medium tracking-wide">{euroFormat(asset.price)+" EUR"}</div>
                        </div>
                        <div>
                            <Link to={"/primary_sale/"+asset.uuid}>
                                {asset.status === "Started" ?
                                    <DexxButton label={"BUY"} color1="succ1" color2="white"
                                        className=" px-10 rounded-full bg-succ1 font-medium tracking-wide"
                                        fontClasses="md:text-xl"/> :
                                    <DexxButton label={"DETAILS"} color1="gray4" color2="white"
                                        className=" px-10 rounded-full bg-gray4 font-medium tracking-wide"
                                        fontClasses="md:text-xl"/>
                                }
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    </>
}

export function StaticCard(props: {title?: string, category?: string, textSize?: string}): JSX.Element {
    
    const [onHoverClasses, setOnHoverClasses] = useState<string>(" from-cards1 to-cards2")
    const [onHoverImg, setOnHoverImg] = useState<string>("")

    let shortName = props.category
    if(props.category && props.category === "SPORTEX") {
        shortName = "SPORT"        
    } else if(props.category && props.category === "COLLECTABLES") {
        shortName = "CLLCT"        
    }
    
    return <>
        <div className={" m-1 bg-white1 font-roboto py-4 pr-6 w-96"}
            onMouseEnter={() => {
                setOnHoverClasses(" from-cards3 to-cards4")
                setOnHoverImg(" invisible")}}
            onMouseLeave={() => {
                setOnHoverClasses(" from-cards1 to-cards2")
                setOnHoverImg(" ")}}>
            <div className="flex justify-between">
                <div className="text-black font-medium text-sm ml-6 mb-2 ">
                    {props.category ? props.category : <br/>}</div>
                <div className={"font-medium uppercase"}><br/></div>
            </div>

            <div className="flex justify-between font-semibold text-xl ml-6 mb-6 h-7"></div>

            <div className={"w-80 h-40 bg-gradient-to-r "+
                        "rounded-tr-full rounded-br-full flex realtive z-10 from-cards1 to-cards2 "+onHoverClasses}>
                <div className={"text-7xl font-medium tracking-wider_plus self-center ml-6 leading-11 "}>
                    {shortName ? shortName : ""}</div>
            </div>    
            <div className="h-72 content-between grid">
                <div>
                    <div className="text-black font-medium text-medium ml-6 mt-6 mb-4">
                        New token
                    </div>
                    <div className={"pl-6 font-light leading-10 my-5 tracking-wide "+
                            (props.textSize ? props.textSize : " text-4xl ")}>
                        Coming soon
                    </div>
                </div>
                <div className="pl-6 font-medium">
                    <br/>
                </div>
                <div className="mt-20 flex justify-between pl-6 h-12"><br/></div>
            </div>
        </div>      
    </>
}