import { useContext, useEffect, useState } from "react"
import { UserContext } from '../UserContext'
import { useHistory } from 'react-router-dom'
import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux";
import { actionCreators } from "../state";
import LabelAndInput from "../components/LabelAndInput"
import { BackButton, DexxButton } from "../components/light/LightButton"
import Popup from '../components/Popup'
import { PageDescription, TitleColor1 } from "../components/typography/Typogtaphy";
import { formValidationClearErrors, formValidationCheckObject, removeTimezoneOffset, isValidHttpUrl } from '../utils'
import check from "../media/check.png"
import dlt from "../media/delete.png"
import { ITimezoneOption } from "react-timezone-select";
import Cookies from 'universal-cookie';

export function DexxCheckbox(props: { value: boolean, setValue: Function, id?: string }): JSX.Element {
    const color: string = props.value ? "succ2" : "gray1"
    const dimension: string = props.value ? "3" : "4"
    return <>
        <div onClick={() => props.setValue(!props.value)} id={props.id}>
            <div className={"h-6 w-6 bg-" + color + " bg-opacity-30 rounded-full " +
                " flex place-content-center items-center cursor-pointer"}>
                <div className={"h-" + dimension + " w-" + dimension + " bg-" + color + " opacity-100 " +
                    " shadow-2xl rounded-full"}></div>
            </div>
        </div>
    </>
}

export default function ProfileRoute(): JSX.Element {

    const cookies = new Cookies()

    const userCtx = useContext(UserContext)
    let history = useHistory()

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    //const [user, setUser] = useState<UserAccount>(defaultUserState)
    const [initialized, setInitialized] = useState<boolean>(false)

    // Fields for registration:
    const [firstName, setFirstName] = useState<string>("")
    const [middleName, setMiddleName] = useState<string>("")
    const [lastName, setLastName] = useState<string>("")
    const [streetNumber, setStreetNumber] = useState<string>("")
    const [streetName, setStreetName] = useState<string>("")
    const [birthDate, setBirthDate] = useState<string>("")
    const [telephone, setTelephone] = useState<string>("")
    const [city, setCity] = useState<string>("")
    const [postalCode, setPostalCode] = useState<string>("")
    const [countryN, setCountry] = useState<string>("")
    const [taxResidence, setTaxResidence] = useState<string>("")
    const [timezone, setTimezone] = useState<ITimezoneOption>(
        { value: Intl.DateTimeFormat().resolvedOptions().timeZone, label: "", offset: 0 })
    const [issuerName, setIssuerName] = useState<string>("")
    const [issuerDescr, setIssuerDescr] = useState<string>("")
    const [webSite, setWebSite] = useState<string>("")
    const [proInvestor, setProInvestor] = useState<boolean>(false)

    const [responseAccountDetail, setResponseAccountDetail] = useState<any[]>([0, undefined])
    const [responseIssuerDetail, setResponseIssuerDetail] = useState<any[]>([0, undefined])
    const [submitted, setSubmitted] = useState(false)
    const [showSuccessPopup, setShowSuccessPopup] = useState(false)

    const allCountries = ["", "Albania", "Algeria", "Andorra", "Antigua and Barbuda", "Argentina", "Armenia",
        "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
        "Belarus", "Belgium", "Belize", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana",
        "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia",
        "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia",
        "Comoros", "Congo", "Costa Rica", "Côte d'Ivoire", "Croatia", "Cuba", "Cyprus",
        "Czech Republic (Czechia)", "Denmark", "Djibouti", "Dominica", "Dominican Republic",
        "DR Congo", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia",
        "Eswatini", "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany",
        "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti",
        "Holy See", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iraq", "Ireland",
        "Israel", "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kuwait",
        "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein",
        "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta",
        "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco",
        "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal",
        "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway",
        "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines",
        "Poland", "Portugal", "Qatar", "Romania", "Rwanda", "Saint Kitts & Nevis", "Saint Lucia",
        "Samoa", "San Marino", "Sao Tome & Principe", "Saudi Arabia", "Senegal", "Serbia",
        "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands",
        "Somalia", "South Africa", "South Korea", "South Sudan", "Spain", "Sri Lanka",
        "St. Vincent & Grenadines", "State of Palestine", "Sudan", "Suriname", "Sweden",
        "Switzerland", "Syria", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo",
        "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda",
        "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "Uruguay", "Uzbekistan",
        "Vanuatu", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"]

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    function handleClosePopUp() {
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUp(false)
        setShowSuccessPopup(false)
        actions.getBasicAccountProfile()
        actions.getAccountDetails()
        if (userCtx && userCtx.is_issuer) actions.getIssuerInfo()
    }

    function changeIfExists(field: string | undefined): string {
        return field ? field : ""
    }

    useEffect(() => {
        if (userCtx.email !== "" && !initialized) {
            setInitialized(true)
            setFirstName(changeIfExists(userCtx.first_name))
            setMiddleName(changeIfExists(userCtx.middle_name))
            setLastName(changeIfExists(userCtx.last_name))
            setBirthDate(changeIfExists(userCtx.date_of_birth))
            setTelephone(changeIfExists(userCtx.telephone))
            setStreetName(changeIfExists(userCtx.street_name))
            setStreetNumber(changeIfExists(userCtx.street_number))
            setCountry(changeIfExists(userCtx.country))
            setCity(changeIfExists(userCtx.city))
            setPostalCode(changeIfExists(userCtx.postal_code))
            setTaxResidence(changeIfExists(userCtx.tax_residence))
            setIssuerName(changeIfExists(userCtx.name))
            setIssuerDescr(changeIfExists(userCtx.description))
            setWebSite(changeIfExists(userCtx.web_site))
            setTimezone({
                value: userCtx.timezone_value ? userCtx.timezone_value :
                    Intl.DateTimeFormat().resolvedOptions().timeZone, offset:
                    (userCtx.timezone_offset ? userCtx.timezone_offset : 0), label: ""
            })
            setProInvestor((userCtx.is_pro_investor ? userCtx.is_pro_investor : false))

            //setUser(userCtx)
        }
    }, [])


    function handleEditProfile(e: React.MouseEvent) {
        formValidationClearErrors()
        const newUser = {
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            street_name: streetName,
            street_number: streetNumber,
            date_of_birth: removeTimezoneOffset(birthDate, timezone.offset ? timezone.offset : 0),
            telephone: telephone !== '' && telephone.substring(0, 1) !== '+' ? '+' + telephone : telephone,
            city: city,
            postal_code: postalCode,
            country: countryN,
            tax_residence: taxResidence,
            username: userCtx.email,
            email: userCtx.email,
            is_issuer: false,
            is_investor: false,
            is_employee: false,
            timezone_value: timezone.value,
            timezone_offset: timezone.offset
            //is_pro_investor: professionalInvestor
        }

        let count = formValidationCheckObject(newUser)
        if (count > 0) {
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please complete the highlighted fields.")
            setShowPopUp(true)
        } else {

            if (webSite !== "" && !isValidHttpUrl(webSite)) {
                setTitlePopUp("Sorry!")
                setMsgPopUp("Please provide a valid website URL.")
                setShowPopUp(true)
            } else {
                setSubmitted(true)

                actions.updateAccountDetails({
                    account_details: newUser,
                    pro_investor: proInvestor
                }, setResponseAccountDetail)

            }
        }
    }

    useEffect(() => {
        if (responseAccountDetail[0] !== 0) {
            if (responseAccountDetail[0] === 200 && submitted) {
                let info = {
                    issuerName: issuerName,
                    webSite: webSite,
                    issuerDescr: issuerDescr
                }
                actions.setIssuerInfo(info, setResponseIssuerDetail)

            } else if (submitted) {
                setSubmitted(false)
                setTitlePopUp("Sorry!")
                /*if(responseAccountDetail[1] && responseAccountDetail[1].error !== "" ) setMsgPopUp(responseAccountDetail[1].error)
                else*/ setMsgPopUp('There was an error in your responses, please review and submit again.')
                setShowPopUp(true)
            }
        }

    }, [responseAccountDetail, submitted])

    useEffect(() => {
        if (responseIssuerDetail[0] !== 0) {
            if (responseIssuerDetail[0] === 200 && submitted) {
                setShowSuccessPopup(true)

            } else if (submitted) {
                setSubmitted(false)
                setTitlePopUp("Sorry!")
                setMsgPopUp('There was an error in your responses, please review and submit again.')
                setShowPopUp(true)
            }
        }

    }, [responseIssuerDetail, submitted])

    useEffect(() => {
        if (showSuccessPopup && submitted) {
            setTitlePopUp("Success")
            setMsgPopUp('Your data has been saved.')
            setShowPopUp(true)
            setSubmitted(false)
        }
    }, [showSuccessPopup, submitted])

    function AccountType(props: { condition: boolean | undefined, label: string }): JSX.Element {
        return <>
            <div className={"col-span-3 font-roboto py-2 tracking-wider flex items-center"}>
                {props.condition ? <img src={check} className="w-3" /> : <img src={dlt} className="w-3" />}
                <div className="w-full text-tertiary text-base ml-2">
                    {props.label}</div>
            </div>
        </>
    }

    let isIssuerOrInvestor = userCtx.is_investor || userCtx.is_issuer

    function ProfileLabel(props: { text: string }): JSX.Element {
        return <>
            <div className="text-tertiary text-base py-2">{props.text}</div>
        </>
    }

    function ProfileBigInfo(props: { text: string }): JSX.Element {
        return <>
            <div className="md:text-2xl font-light"><TitleColor1>{props.text}</TitleColor1></div>
        </>
    }

    return (<>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp} ></Popup>
        <div className="grid xl:grid-cols-4 gap-5 max-w-full">
            <div className="grid justify-items-center xl:mt-40 md:mt-20 mt-10 text-center
                 mb-5">
                <div>
                    <PageDescription title={"Profile"} description={"Your profile information"} />
                    {/*<img src={imgRefresh} className="h-4 mt-5 cursor-pointer" onClick={() => {window.location.reload()}}/>*/}
                    <div className="flex w-full mt-6 gap-5">
                        <BackButton className="rounded-full px-5" />
                        <DexxButton label={"save"} className="rounded-full px-5"
                            color2="succ2" onClick={handleEditProfile} />
                    </div>
                    {userCtx.kyc_submitted ? <></> :
                        <DexxButton label={"Complete the KYC"} className="rounded-full px-5 mt-3"
                            color2="succ1" onClick={() => { history.push("/kyc") }} />}
                </div>
            </div>
            <div className="xl:col-span-2 max-w-full">
                <ProfileLabel text={"Account E-mail"} />
                <ProfileBigInfo text={userCtx.email} />
                <div className="h-4"></div>
                <ProfileLabel text={"Account number"} />
                <ProfileBigInfo text={cookies.get("username")} />
                <div className="h-4"></div>
                <ProfileLabel text={"Account type"} />
                <div className="flex gap-5 flex-wrap">
                    {<AccountType condition={true} label={"Basic"} />}
                    {<AccountType condition={userCtx.kyc_submitted} label={"KYC Submitted"} />}
                    <AccountType condition={userCtx.is_investor} label={"Investor"} />
                    <AccountType condition={userCtx.is_issuer} label={"Issuer"} />
                </div>
                <div className="h-4"></div>
                <div className="flex items-center gap-5">
                    <DexxCheckbox value={proInvestor} setValue={setProInvestor} id={"selfCertification"} />
                    <label className="w-full text-tertiary text-sm py-2">
                        Self-certification of Professional Investor qualification
                    </label>
                </div>
                <div className="h-8"></div>
                <ProfileBigInfo text={"Profile Information"} />
                <div className="h-8"></div>
                <div className="md:grid md:grid-cols-3 max-w-full grid-cols-1">
                    <LabelAndInput id={"firstName"} label={"First"} value={firstName} fun={setFirstName}
                        type={"disabled"} className={"md:pr-10"} required />
                    <LabelAndInput label={"Middle"} value={middleName} fun={setMiddleName}
                        type={"disabled"} className={"md:pr-10"} />
                    <LabelAndInput id={"lastName"} label={"Last"} value={lastName} fun={setLastName}
                        type={"disabled"} className={"md:pr-10"} required />
                    <LabelAndInput id={"taxResidence"} label={"Nationality"} value={taxResidence} fun={setTaxResidence}
                        type={userCtx.kyc_submitted ? "disabled" : "select"} className="md:pr-10"
                        options={allCountries.map((v) => { return { value: v, text: v } })} required />
                    <LabelAndInput id={"dateOfBirth"} label={"Date of birth"} value={birthDate} fun={setBirthDate}
                        type={"date-disabled"} className={"md:pr-10"} required />
                    <LabelAndInput id={"country"} label={"Country of Residence"} value={countryN}
                        fun={setCountry} type={userCtx.kyc_submitted ? "disabled" : "select"} className="md:pr-10"
                        options={allCountries.map((v) => { return { value: v, text: v } })} required />
                    <LabelAndInput id={"streetName"} label={"Street"} value={streetName}
                        fun={setStreetName} type={userCtx.kyc_submitted ? "disabled" : "text"}
                        className={"col-span-2 md:pr-10"} required />
                    <LabelAndInput id={"streetNumber"} label={"Street number"} value={streetNumber}
                        fun={setStreetNumber} type={userCtx.kyc_submitted ? "disabled" : "text"}
                        className={"md:pr-10"} required />
                    <LabelAndInput id={"city"} label="City" value={city}
                        fun={setCity} type={userCtx.kyc_submitted ? "disabled" : "text"}
                        className="md:pr-10" required />
                    <LabelAndInput id={"postalCode"} label="Postal code" value={postalCode}
                        fun={setPostalCode} type={userCtx.kyc_submitted ? "disabled" : "text"}
                        className="md:pr-10" required />
                    <LabelAndInput id={"telephone"} label={"Mobile"} value={telephone}
                        fun={setTelephone} type={userCtx.kyc_submitted ? "disabled" : "phone"} className={"md:pr-10 py-2"} required />
                    <LabelAndInput label={"Timezone"} value={timezone.value}
                        fun={setTimezone} type={"timezone"} className="col-span-3 md:pr-10" />
                </div>
                <div className="h-8"></div>
                {userCtx.is_issuer ? (<>
                    <ProfileBigInfo text={"Issuer Information"} />
                    <div className="h-8"></div>
                    <div className="md:grid md:grid-cols-3">
                        <LabelAndInput label={"Issuer Name"} value={issuerName} id={"issuerName"}
                            fun={setIssuerName} type={"text"} className={"col-span-2 md:pr-10"}
                            /*helpTip="Define your name as issuer. You will have the possibility to change it while issuing an asset."*/ />
                        <LabelAndInput label={"Website"} value={webSite} fun={setWebSite}
                            type={"text"} className={"col-span-2 md:pr-10"} id="website"
                            helpTip='Specify the URL including the "HTTP://" or "HTTPS://, e.g. https://www.example.com"' />
                        <LabelAndInput label={"Issuer Description"} value={issuerDescr} required={true}
                            fun={setIssuerDescr} type="textarea" rows={3} className="col-span-3 md:pr-10"
                            helpTip="This description will be available for investors who want to buy your issued tokens"
                            id={"issuerDescription"} />
                    </div>
                </>) : ""}
                <div className="flex w-full my-6 gap-5 place-content-center md:mt-8">
                    <BackButton className="rounded-full px-5" />
                    <DexxButton label={"save"} className="rounded-full px-5"
                        color2="succ2" onClick={handleEditProfile} />
                </div>
            </div>
        </div>
    </>)
}
