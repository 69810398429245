import * as sanitizeHtml from 'sanitize-html';

export default function HTMLVisualizer(props: {text: any, className?: string}) {
    let html = props.text === "" ? "Preview" : props.text

    function sanitize(html: string) {
        let options = {
            allowedTags: ['b', 'i', 'strong', 'a', 'br'],
            allowedAttributes: {
                'a': ['href']
            }
        }
        return sanitizeHtml(html, options)
    }
    return <>
        <div className={props.className+" border-solid border-tertiary "+
                " border-1 p-4 font-extralight tracking-wider whitespace-pre-wrap"} 
            dangerouslySetInnerHTML={{__html: sanitize(html)}}>
        </div>
    </>
}