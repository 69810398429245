import { bindActionCreators } from "@reduxjs/toolkit"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DexxButton, DisabledButton } from "../components/light/LightButton"
import { defaultLiquidityPool, defaultSecurityToken, defaultBalance } from "../PoolContext"
import { actionCreators, State } from "../state"
import { euroFormat, euroQtyFormat, formatDate } from "../utils"
import { BigTitle} from "../components/typography/Typogtaphy"
import { TokenDescriptionTable4PS } from "../components/TokenDescriptionTable"
import { PieChart } from "react-minimal-pie-chart";
import LightTable from "../components/light/LightTable";
import Popup, { DexxPopup } from '../components/Popup'
import AddLPPopup from "../components/popup/AddLPPopup";
import RemoveLPPopup from "../components/popup/RemoveLPPopup";
import { Link } from "react-router-dom"
import { useHistory, useLocation } from "react-router-dom"

export default function IssuerLiquidityPoolRoute(props: any): JSX.Element {
    const uuid = props.match.params.uuid
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const [token_a, setToken_a] = useState(defaultSecurityToken)
    const [token_b, setToken_b] = useState(defaultSecurityToken)
    const [liquidityPoolDetails, setLPDetails] = useState(defaultLiquidityPool)
    //const [liquidityPoolUuid, setLiquidityPoolUuid] = useState("")
    const [balance, setBalance] = useState(defaultBalance)
    //const [response, setResponse] = useState<any[]>([0, undefined])
    //const [submitted, setSubmitted] = useState(false)
    //const detailAccountState = useSelector((state: State) => state.detailAccount)
    //const userCtx = detailAccountState.payload
    //const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    //const [confirmPopUp, setConfirmPopUp] = useState<boolean>(false)
    //const [actionPopUp, setActionPopUp] = useState<string>("")
    const [investorsShares, setInvestorsShares] = useState<any[]>([])
    const [showAddLPPopUp, setShowAddLPPopUp] = useState<boolean>(false)
    const [showRemoveLPPopUp, setShowRemoveLPPopUp] = useState<boolean>(false)
    const [showConfirmDestroyPopUp, setShowConfirmDestroyPopUp] = useState<boolean>(false)
    const [responseDestroy, setResponseDestroy] = useState<any[]>([0, undefined])

    //const hdsSingleLiquidityPool = ["Pool Name", "Status", "Date created", "Token A", "Amount",  "Fimart Euro Voucher Amount", "Amount","LP Token Supply"]
    //const hdsSingleLiquidityPool = ["investor", "Token Amount", "Fimart Euro Voucher Amount", "LP shares %"]
    const hdsSingleLiquidityPool = ["investor", "LP shares %"]

    let history = useHistory()

    function handleClosePopUp(){
        setTitlePopUp("")
        setMsgPopUp("")
        //setConfirmPopUp(false)
        //setActionPopUp("")
        setShowPopUp(false)
        history.push("/issuer/dashboard/#Tokens" )
    }

    function ColoredTab(props: {label: string, color: string, index: number, headers: any, rows: any}): JSX.Element {
        return <div>
            <>
            {props.rows.length > 0 ?
                <LightTable headers={props.headers} rows={props.rows}
                    colorHd={"gradient-to-r from-"+props.color+" to-gray3"}
                    color1={"gradient-to-r from-"+props.color+"_lighter  to-gray1"}
                    color2={"gradient-to-r from-"+props.color+"_light to-gray2"} /> 
                :
                <div className="bg-white">
                    <div className={"font-roboto uppercase text-gray pl-10 py-2 bg-"+props.color+"_light"}>None</div>
                </div>}
            </>
        </div>
    }

    function investorSharesRows(l: any[], btnUrls: string[], btnLabels: string[]): any[] {
        return l ?
            /*l.map(t => addBtns([t.lp_name, t.status_name, formatDate(t.date_created, offset), 
                t.token_a_ticker, euroQtyFormat(t.amount_a), 
                t.token_b_ticker, euroFormat(t.amount_b), 
                euroQtyFormat(t.lp_token_supply)],btnLabels, btnUrls, t.uuid)
            ) : []*/
            l.map(t => [ 
                t.Investor.email, liquidityPoolDetails.lp_token_supply && liquidityPoolDetails.lp_token_supply !== "0" ? (100 * parseInt(t.LPWE.qty_lp_token) / parseInt(liquidityPoolDetails.lp_token_supply))+"%" : "NONE"
            ]
            ) : []
    }
    
    useEffect(() => {
        actions.getLiquidityPool(uuid, setLPDetails)
        actions.getActualBalance(setBalance)
    }, [])

    useEffect(() => {

        if(liquidityPoolDetails.uuid !== ''){
            actions.getTokenInfoByTokenUUID(liquidityPoolDetails.token_a_uuid, setToken_a)
            actions.getTokenInfoByTokenUUID(liquidityPoolDetails.token_b_uuid, setToken_b)
            actions.getInvestorShares(uuid, false, setInvestorsShares)
            //setLiquidityPoolUuid(uuid)
        } 
    }, [liquidityPoolDetails])

    useEffect(() => {
        if(responseDestroy[0] !== 0){
            if(responseDestroy[0] === 204){
                setTitlePopUp("Success")
                setMsgPopUp("The liquidity pool was destroyed successfully")
                setShowPopUp(true)
            } else {
                setTitlePopUp("Sorry!")
                /*if(responseDestroy[1] && responseDestroy[1].error) setMsgPopUp(responseDestroy[1].error)
                else */setMsgPopUp("Liquidity Pool has not been destroyed" )
                setShowPopUp(true)
            }
        }
    }, [responseDestroy])

    return (<>
        <div>
            <BigTitle title={liquidityPoolDetails.lp_name? liquidityPoolDetails.lp_name : ""}/>
            
            <TokenDescriptionTable4PS tokensList={{
                tokens: [token_a]
            }} />
        </div>

        <div className="">
            <div className="grid lg:grid-cols-4 cover my-10 xl:px-30">
                <div></div>
                <div className="flex place-content-center xl:ml-20">
                    <div className="w-48 grid justify-items-center text-tertiary self-center">
                        <div className={"relative text-5xl font-base inset-0 top-30"}>
                            LP
                        </div>
                        <PieChart className=""
                            data={[
                                { title: liquidityPoolDetails.token_a_ticker, value: parseFloat(liquidityPoolDetails.amount_a), color: '#80C8C4' },
                                { title: liquidityPoolDetails.token_b_ticker, value: parseFloat(liquidityPoolDetails.amount_b), color: '#4494CE' },
                            ]}
                            lineWidth={4}
                            paddingAngle={10}
                            animate={true}
                            animationDuration={1000}
                        />
                    </div>
                </div>
                
                <div className="font-urbanist uppercase text-tertiary my-10 text-4xl 
                        flex flex-col text-left place-content-center items-center">
                    <div>
                        <div className=" my-5">{euroQtyFormat(liquidityPoolDetails.amount_a)}
                            <div className="text-seafoam text-xl">{liquidityPoolDetails.token_a_ticker}</div>
                        </div>
                        <div className="my-5">{euroFormat(liquidityPoolDetails.amount_b)}
                            <div className="text-succ4 text-xl">{liquidityPoolDetails.token_b_ticker}</div>
                        </div>
                        <div className="my-5">{euroFormat(liquidityPoolDetails.lp_token_supply)}
                            <div className="text-succ5 text-xl">Total LP Shares</div>
                        </div>
                    </div>
                </div>

                <div></div>
            </div>

            <div className="grid mt-10">
                <div className="">
                    <div className="font-urbanist uppercase text-tertiary text-4xl ml-10 mb-5">
                        Liquidity Pool Providers
                    </div>
                    <ColoredTab label={""} color={"succ4"} index={0} headers={[...hdsSingleLiquidityPool/*,...[""]*/]} 
                            rows={investorSharesRows(investorsShares ? investorsShares : [], [], [])} />
                </div>
            </div>

            

            <div className="font-urbanist uppercase text-tertiary text-4xl mt-10 ml-10  grid grid-cols-3">
                <div className="">Add / Remove Funds</div>
                <div className="">

                <DexxPopup show={showAddLPPopUp} title={"Add Liquidity"} 
                    msg={<AddLPPopup closeFunction={() => { setShowAddLPPopUp(false); actions.getLiquidityPool(uuid, setLPDetails)}} uuidLP={uuid} />}
                    helpMsg={
                        <div className="p-5 font-roboto font-semibold text-primary text-base">
                            <div className=" font-bold text-primary text-lg mb-5">
                            Add funds to a liquidity pool by entering the XEUR you will allocate. The number of asset tokens required will be calculated for you.
                            </div>
                            <div className=" font-bold text-primary text-lg mb-5">
                            The transaction has a tolerance of 1% meaning that the price of the asset token can increase up to 1% by the time you tap Submit.
                            </div>
                            <div className=" font-bold text-primary text-lg">
                            For more detailed information <Link to={"/support"} className="text-succ2">click here</Link>.
                            </div>
                        </div>
                    }
                    btnConfirm={false} close={() => { setShowAddLPPopUp(false); actions.getLiquidityPool(uuid, setLPDetails)} } btnOk={false} />
                
                <DexxPopup show={showRemoveLPPopUp} title={"Remove Liquidity"} 
                    type="alternate"
                    msg={<RemoveLPPopup closeFunction={() => { 
                        setShowRemoveLPPopUp(false); actions.getLiquidityPool(uuid, setLPDetails)}} uuidLP={uuid} />}
                    btnConfirm={false} close={() => { 
                        setShowRemoveLPPopUp(false); actions.getLiquidityPool(uuid, setLPDetails)} } btnOk={false} />

                    <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp}></Popup>

                    <div className="flex justify-center mb-4">
                        <DexxButton label={"Remove"} className="rounded-full px-16 py-2 ml-10 font-bold text-xl bg-succ2 " 
                                color1="succ2" color2="primary"
                                onClick={() => {setShowRemoveLPPopUp(true); }}/>
                        <DexxButton label={"Add"} className="rounded-full px-16 py-2 ml-10 font-bold text-xl bg-succ2 " 
                                color1="succ2" color2="primary"
                                onClick={() => {setShowAddLPPopUp(true)}}/>
                    </div>
                </div>
                <div></div>
            </div>

            <div className="font-urbanist uppercase text-tertiary text-4xl mt-10 ml-10  grid grid-cols-3">
                <div className="">Destroy Liquidity Pool</div>
                <div className="">

                    <Popup show={showConfirmDestroyPopUp} title={"Confirm"} 
                        msg={<div>Do you want to destroy the liquidity pool?</div>} btnOk={true} btnConfirm={true} 
                        close={() => {setShowConfirmDestroyPopUp(false)}} 
                        handleConfirm={() => {actions.destroyLiquidityPool(uuid, setResponseDestroy); setShowConfirmDestroyPopUp(false)}}></Popup>

                    <div className="flex justify-center mb-4">
                        { liquidityPoolDetails.lp_token_supply === "0" ? 
                            <DexxButton label={"Destroy"} className="rounded-full px-16 py-2 ml-10 font-bold text-xl bg-succ2" 
                                color1="succ2" color2="primary"
                                onClick={() => {setShowConfirmDestroyPopUp(true)}}/>
                        :
                            <DisabledButton label={"Destroy"} className="rounded-full px-16 py-2 ml-10 font-bold text-xl bg-succ2" />
                        }
                    </div>
                </div>
                <div></div>
            </div>
        </div>
        
    </>)
}
