export enum ActionType {
    GET_POOLS_REQUEST = "GET_POOLS_REQUEST",
    GET_POOLS_SUCCESS = "GET_POOLS_SUCCESS",
    GET_POOLS_FAILURE = "GET_POOLS_FAILURE",

    GET_POOL_REQUEST = "GET_POOL_REQUEST",
    GET_POOL_SUCCESS = "GET_POOL_SUCCESS",
    GET_POOL_FAILURE = "GET_POOL_FAILURE",

    SET_POOLS_REQUEST = "SET_POOLS_REQUEST",
    SET_POOLS_SUCCESS = "SET_POOLS_SUCCESS",
    SET_POOLS_FAILURE = "SET_POOLS_FAILURE",

    GET_TOKENS_REQUEST = "GET_TOKENS_REQUEST",
    GET_TOKENS_SUCCESS = "GET_TOKENS_SUCCESS",
    GET_TOKENS_FAILURE = "GET_TOKENS_FAILURE",

    GET_POOL_TOKENS_REQUEST = "GET_POOL_TOKENS_REQUEST",
    GET_POOL_TOKENS_SUCCESS = "GET_POOL_TOKENS_SUCCESS",
    GET_POOL_TOKENS_FAILURE = "GET_POOL_TOKENS_FAILURE",

    UPDATE_POOL_REQUEST = "UPDATE_POOL_REQUEST",
    UPDATE_POOL_SUCCESS = "UPDATE_POOL_SUCCESS",
    UPDATE_POOL_FAILURE = "UPDATE_POOL_FAILURE",
    
    CREATE_USER_ACCOUNT_REQUEST = "CREATE_USER_ACCOUNT_REQUEST",
    CREATE_USER_ACCOUNT_SUCCESS = "CREATE_USER_ACCOUNT_SUCCESS",
    CREATE_USER_ACCOUNT_FAILURE = "CREATE_USER_ACCOUNT_FAILURE",

    EMAIL_VERIFICATION_REQUEST = "EMAIL_VERIFICATION_REQUEST",
    EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS",
    EMAIL_VERIFICATION_FAILURE = "EMAIL_VERIFICATION_FAILURE",

    SET_ACCOUNT_REQUEST = "SET_ACCOUNT_REQUEST",
    SET_ACCOUNT_SUCCESS = "SET_ACCOUNT_SUCCESS",
    SET_ACCOUNT_FAILURE = "SET_ACCOUNT_FAILURE",

    UPDATE_ACCOUNT_REQUEST = "UPDATE_ACCOUNT_REQUEST",
    UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS",
    UPDATE_ACCOUNT_FAILURE = "UPDATE_ACCOUNT_FAILURE",

    SET_INVESTOR_PERMISSIONS_REQUEST = "SET_INVESTOR_PERMISSIONS_REQUEST",
    SET_INVESTOR_PERMISSIONS_SUCCESS = "SET_INVESTOR_PERMISSIONS_SUCCESS",
    SET_INVESTOR_PERMISSIONS_FAILURE = "SET_INVESTOR_PERMISSIONS_FAILURE",

    SET_ISSUER_PERMISSIONS_REQUEST = "SET_ISSUER_PERMISSIONS_REQUEST",
    SET_ISSUER_PERMISSIONS_SUCCESS = "SET_ISSUER_PERMISSIONS_SUCCESS",
    SET_ISSUER_PERMISSIONS_FAILURE = "SET_ISSUER_PERMISSIONS_FAILURE",

    SET_ISSUER_KYC_DETAILS_REQUEST = "SET_ISSUER_KYC_DETAILS_REQUEST",
    SET_ISSUER_KYC_DETAILS_SUCCESS = "SET_ISSUER_KYC_DETAILS_SUCCESS",
    SET_ISSUER_KYC_DETAILS_FAILURE = "SET_ISSUER_KYC_DETAILS_FAILURE",

    SIGNIN_USER_ACCOUNT_REQUEST = "SIGNIN_USER_ACCOUNT_REQUEST",
    SIGNIN_USER_ACCOUNT_SUCCESS = "SIGNIN_USER_ACCOUNT_SUCCESS",
    SIGNIN_USER_ACCOUNT_FAILURE = "SIGNIN_USER_ACCOUNT_FAILURE",

    GET_ACCOUNT_PROFILE_REQUEST = "GET_ACCOUNT_PROFILE_REQUEST",
    GET_ACCOUNT_PROFILE_SUCCESS = "GET_ACCOUNT_PROFILE_SUCCESS",
    GET_ACCOUNT_PROFILE_FAILURE = "GET_ACCOUNT_PROFILE_FAILURE",
    
    GET_BASIC_ACCOUNT_PROFILE_REQUEST = "GET_BASIC_ACCOUNT_PROFILE_REQUEST",
    GET_BASIC_ACCOUNT_PROFILE_SUCCESS = "GET_BASIC_ACCOUNT_PROFILE_SUCCESS",
    GET_BASIC_ACCOUNT_PROFILE_FAILURE = "GET_BASIC_ACCOUNT_PROFILE_FAILURE",

    GET_ISSUER_KYC_DETAILS_REQUEST = "GET_ISSUER_KYC_DETAILS_REQUEST",
    GET_ISSUER_KYC_DETAILS_SUCCESS = "GET_ISSUER_KYC_DETAILS_SUCCESS",
    GET_ISSUER_KYC_DETAILS_FAILURE = "GET_ISSUER_KYC_DETAILS_FAILURE", 
        
    GET_TOKEN_INFO_REQUEST = "GET_TOKEN_INFO_REQUEST",
    GET_TOKEN_INFO_SUCCESS = "GET_TOKEN_INFO_SUCCESS",
    GET_TOKEN_INFO_FAILURE = "GET_TOKEN_INFO_FAILURE",
    
    GET_ALL_ASSET_CATEGORIES_REQUEST = "GET_ALL_ASSET_CATEGORIES_REQUEST",
    GET_ALL_ASSET_CATEGORIES_SUCCESS = "GET_ALL_ASSET_CATEGORIES_SUCCESS",
    GET_ALL_ASSET_CATEGORIES_FAILURE = "GET_ALL_ASSET_CATEGORIES_FAILURE",
    
    GET_ASSET_TYPE_BY_CATEGORY_REQUEST = "GET_ASSET_TYPE_BY_CATEGORY_REQUEST",
    GET_ASSET_TYPE_BY_CATEGORY_SUCCESS = "GET_ASSET_TYPE_BY_CATEGORY_SUCCESS",
    GET_ASSET_TYPE_BY_CATEGORY_FAILURE = "GET_ASSET_TYPE_BY_CATEGORY_FAILURE",
    
    GET_ASSET_CATEGORY_BY_NAME_REQUEST = "GET_ASSET_CATEGORY_BY_NAME_REQUEST",
    GET_ASSET_CATEGORY_BY_NAME_SUCCESS = "GET_ASSET_CATEGORY_BY_NAME_SUCCESS",
    GET_ASSET_CATEGORY_BY_NAME_FAILURE = "GET_ASSET_CATEGORY_BY_NAME_FAILURE",

    GET_ASSET_TYPE_BY_NAME_REQUEST = "GET_ASSET_TYPE_BY_NAME_REQUEST",
    GET_ASSET_TYPE_BY_NAME_SUCCESS = "GET_ASSET_TYPE_BY_NAME_SUCCESS",
    GET_ASSET_TYPE_BY_NAME_FAILURE = "GET_ASSET_TYPE_BY_NAME_FAILURE",

    GET_ASSET_CURRENCIES_REQUEST = "GET_ASSET_CURRENCIES_REQUEST",
    GET_ASSET_CURRENCIES_SUCCESS = "GET_ASSET_CURRENCIES_SUCCESS",
    GET_ASSET_CURRENCIES_FAILURE = "GET_ASSET_CURRENCIES_FAILURE",

    GET_ASSET_INFO_REQUEST = "GET_ASSET_INFO_REQUEST",
    GET_ASSET_INFO_SUCCESS = "GET_ASSET_INFO_SUCCESS",
    GET_ASSET_INFO_FAILURE = "GET_ASSET_INFO_FAILURE",

    RESET_ASSET_INFO = "RESET_ASSET_INFO",

    SEND_TRADE_REQUEST = "SEND_TRADE_REQUEST",
    SEND_TRADE_SUCCESS = "SEND_TRADE_SUCCESS",
    SEND_TRADE_FAILURE = "SEND_TRADE_FAILURE",

    GET_TRANSACTIONS_REQUEST = "GET_TRANSACTIONS_REQUEST",
    GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS",
    GET_TRANSACTIONS_FAILURE = "GET_TRANSACTIONS_FAILURE",

    GET_ISSUER_TRANSACTIONS_REQUEST = "GET_ISSUER_TRANSACTIONS_REQUEST",
    GET_ISSUER_TRANSACTIONS_SUCCESS = "GET_ISSUER_TRANSACTIONS_SUCCESS",
    GET_ISSUER_TRANSACTIONS_FAILURE = "GET_ISSUER_TRANSACTIONS_FAILURE",

    GET_BALANCE_REQUEST = "GET_BALANCE_REQUEST",
    GET_BALANCE_SUCCESS = "GET_BALANCE_SUCCESS",
    GET_BALANCE_FAILURE = "GET_BALANCE_FAILURE",

    EDIT_USER_INVESTOR_REQUEST = "EDIT_USER_INVESTOR_REQUEST",
    EDIT_USER_INVESTOR_SUCCESS = "EDIT_USER_INVESTOR_SUCCESS",
    EDIT_USER_INVESTOR_FAILURE = "EDIT_USER_INVESTOR_FAILURE",

    GET_ALL_ASSETS_REQUEST = "GET_ALL_ASSETS_REQUEST",
    GET_ALL_ASSETS_SUCCESS = "GET_ALL_ASSETS_SUCCESS",
    GET_ALL_ASSETS_FAILURE = "GET_ALL_ASSETS_FAILURE",

    GET_FILE_REQUEST = "GET_FILE_REQUEST",
    GET_FILE_SUCCESS = "GET_FILE_SUCCESS",
    GET_FILE_FAILURE = "GET_FILE_FAILURE",

    GET_FILE_UPLOAD_LINK_REQUEST = "GET_FILE_UPLOAD_LINK_REQUEST",
    GET_FILE_UPLOAD_LINK_SUCCESS = "GET_FILE_UPLOAD_LINK_SUCCESS",
    GET_FILE_UPLOAD_LINK_FAILURE = "GET_FILE_UPLOAD_LINK_FAILURE",
    GET_FILE_UPLOAD_LINK_DONE = "GET_FILE_UPLOAD_LINK_DONE",

    GET_ASSET_MEDIA_REQUEST = "GET_ASSET_MEDIA_REQUEST",
    GET_ASSET_MEDIA_SUCCESS = "GET_ASSET_MEDIA_SUCCESS",
    GET_ASSET_MEDIA_FAILURE = "GET_ASSET_MEDIA_FAILURE",

    GET_DOCUMENTS_REQUEST = "GET_DOCUMENTS_REQUEST",
    GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS",
    GET_DOCUMENTS_FAILURE = "GET_DOCUMENTS_FAILURE",

    GET_IMAGES_REQUEST = "GET_IMAGES_REQUEST",
    GET_IMAGES_SUCCESS = "GET_IMAGES_SUCCESS",
    GET_IMAGES_FAILURE = "GET_IMAGES_FAILURE",

    GET_VIDEOS_REQUEST = "GET_VIDEOS_REQUEST",
    GET_VIDEOS_SUCCESS = "GET_VIDEOS_SUCCESS",
    GET_VIDEOS_FAILURE = "GET_VIDEOS_FAILURE",

    GET_ISSUED_TOKENS_REQUEST = "GET_ISSUED_TOKENS_REQUEST",
    GET_ISSUED_TOKENS_SUCCESS = "GET_ISSUED_TOKENS_SUCCESS",
    GET_ISSUED_TOKENS_FAILURE = "GET_ISSUED_TOKENS_FAILURE",

    GET_ISSUED_ASSETS_REQUEST = "GET_ISSUED_ASSETS_REQUEST",
    GET_ISSUED_ASSETS_SUCCESS = "GET_ISSUED_ASSETS_SUCCESS",
    GET_ISSUED_ASSETS_FAILURE = "GET_ISSUED_ASSETS_FAILURE",

    CREATE_ASSET_REQUEST = "CREATE_ASSET_REQUEST",
    CREATE_ASSET_SUCCESS = "CREATE_ASSET_SUCCESS",
    CREATE_ASSET_FAILURE = "CREATE_ASSET_FAILURE",

    SUBMIT_ASSET_REQUEST = "SUBMIT_ASSET_REQUEST",
    SUBMIT_ASSET_SUCCESS = "SUBMIT_ASSET_SUCCESS",
    SUBMIT_ASSET_FAILURE = "SUBMIT_ASSET_FAILURE",

    CREATE_TOKEN_REQUEST = "CREATE_TOKEN_REQUEST",
    CREATE_TOKEN_SUCCESS = "CREATE_TOKEN_SUCCESS",
    CREATE_TOKEN_FAILURE = "CREATE_TOKEN_FAILURE",

    SUBMIT_TOKEN_REQUEST = "SUBMIT_TOKEN_REQUEST",
    SUBMIT_TOKEN_SUCCESS = "SUBMIT_TOKEN_SUCCESS",
    SUBMIT_TOKEN_FAILURE = "SUBMIT_TOKEN_FAILURE",

    ADD_MEDIA_REQUEST = "ADD_MEDIA_REQUEST",
    ADD_MEDIA_SUCCESS = "ADD_MEDIA_SUCCESS",
    ADD_MEDIA_FAILURE = "ADD_MEDIA_FAILURE",

    REMOVE_MEDIA_REQUEST = "REMOVE_MEDIA_REQUEST",
    REMOVE_MEDIA_SUCCESS = "REMOVE_MEDIA_SUCCESS",
    REMOVE_MEDIA_FAILURE = "REMOVE_MEDIA_FAILURE",
    REMOVE_CARD_MEDIA_SUCCESS = "REMOVE_CARD_MEDIA_SUCCESS",
    REMOVE_MEDIA_RESULT_SHOWN = "REMOVE_MEDIA_RESULT_SHOWN",

    GET_ALL_MEDIA_TYPES_REQUEST = "GET_ALL_MEDIA_TYPES_REQUEST",
    GET_ALL_MEDIA_TYPES_SUCCESS = "GET_ALL_MEDIA_TYPES_SUCCESS",
    GET_ALL_MEDIA_TYPES_FAILURE = "GET_ALL_MEDIA_TYPES_FAILURE",

    GET_BLOCKCHAIN_TRX_DETAILS_REQUEST = "GET_BLOCKCHAIN_TRX_DETAILS_REQUEST",
    GET_BLOCKCHAIN_TRX_DETAILS_SUCCESS = "GET_BLOCKCHAIN_TRX_DETAILS_SUCCESS",
    GET_BLOCKCHAIN_TRX_DETAILS_FAILURE = "GET_BLOCKCHAIN_TRX_DETAILS_FAILURE",

    GET_ALL_PRIMARY_SALES_REQUEST = "GET_ALL_PRIMARY_SALES_REQUEST",
    GET_ALL_PRIMARY_SALES_SUCCESS = "GET_ALL_PRIMARY_SALES_SUCCESS",
    GET_ALL_PRIMARY_SALES_FAILURE = "GET_ALL_PRIMARY_SALES_FAILURE",

    GET_ISSUER_PRIMARY_SALES_REQUEST = "GET_ISSUER_PRIMARY_SALES_REQUEST",
    GET_ISSUER_PRIMARY_SALES_SUCCESS = "GET_ISSUER_PRIMARY_SALES_SUCCESS",
    GET_ISSUER_PRIMARY_SALES_FAILURE = "GET_ISSUER_PRIMARY_SALES_FAILURE",
    
    GET_PRIMARY_SALE_REQUEST = "GET_PRIMARY_SALE_REQUEST",
    GET_PRIMARY_SALE_SUCCESS = "GET_PRIMARY_SALE_SUCCESS",
    GET_PRIMARY_SALE_FAILURE = "GET_PRIMARY_SALE_FAILURE",

    SUBMIT_PRIMARY_SALE_REQUEST = "SUBMIT_PRIMARY_SALE_REQUEST",
    SUBMIT_PRIMARY_SALE_SUCCESS = "SUBMIT_PRIMARY_SALE_SUCCESS",
    SUBMIT_PRIMARY_SALE_FAILURE = "SUBMIT_PRIMARY_SALE_FAILURE",    

    START_PRIMARY_SALE_REQUEST = "START_PRIMARY_SALE_REQUEST",
    START_PRIMARY_SALE_SUCCESS = "START_PRIMARY_SALE_SUCCESS",
    START_PRIMARY_SALE_FAILURE = "START_PRIMARY_SALE_FAILURE",

    PAUSE_PRIMARY_SALE_REQUEST = "PAUSE_PRIMARY_SALE_REQUEST",
    PAUSE_PRIMARY_SALE_SUCCESS = "PAUSE_PRIMARY_SALE_SUCCESS",
    PAUSE_PRIMARY_SALE_FAILURE = "PAUSE_PRIMARY_SALE_FAILURE",

    RESUME_PRIMARY_SALE_REQUEST = "RESUME_PRIMARY_SALE_REQUEST",
    RESUME_PRIMARY_SALE_SUCCESS = "RESUME_PRIMARY_SALE_SUCCESS",
    RESUME_PRIMARY_SALE_FAILURE = "RESUME_PRIMARY_SALE_FAILURE",

    STOP_PRIMARY_SALE_REQUEST = "STOP_PRIMARY_SALE_REQUEST",
    STOP_PRIMARY_SALE_SUCCESS = "STOP_PRIMARY_SALE_SUCCESS",
    STOP_PRIMARY_SALE_FAILURE = "STOP_PRIMARY_SALE_FAILURE",

    SUBMIT_BUY_PRIMARY_SALE_REQUEST = "SUBMIT_BUY_PRIMARY_SALE_REQUEST",
    SUBMIT_BUY_PRIMARY_SALE__SUCCESS = "SUBMIT_BUY_PRIMARY_SALE__SUCCESS",
    SUBMIT_BUY_PRIMARY_SALE_ERROR = "SUBMIT_BUY_PRIMARY_SALE_ERROR",

    GET_BANK_TRANSACTIONS_REQUEST = "GET_BANK_TRANSACTIONS_REQUEST",
    GET_BANK_TRANSACTIONS_SUCCESS = "GET_BANK_TRANSACTIONS_SUCCESS",
    GET_BANK_TRANSACTIONS_ERROR = "GET_BANK_TRANSACTIONS_ERROR",

    GET_FEES_CATEGORY_REQUEST = "GET_FEES_CATEGORY_REQUEST",
    GET_FEES_CATEGORY_SUCCESS = "GET_FEES_CATEGORY_SUCCESS",
    GET_FEES_CATEGORY_ERROR = "GET_FEES_CATEGORY_ERROR",    

    GET_ISSUER_BALANCE_REQUEST = "GET_ISSUER_BALANCE_REQUEST",
    GET_ISSUER_BALANCE_SUCCESS = "GET_ISSUER_BALANCE_SUCCESS",
    GET_ISSUER_BALANCE_FAILURE = "GET_ISSUER_BALANCE_FAILURE",     
    
    GET_TOKEN_STATS_REQUEST = "GET_TOKEN_STATS_REQUEST",
    GET_TOKEN_STATS_SUCCESS = "GET_TOKEN_STATS_SUCCESS",
    GET_TOKEN_STATS_ERROR = "GET_TOKEN_STATS_ERROR", 
        
    GET_ISSUER_INFO_REQUEST = "GET_ISSUER_INFO_REQUEST",
    GET_ISSUER_INFO_SUCCESS = "GET_ISSUER_INFO_SUCCESS",
    GET_ISSUER_INFO_ERROR = "GET_ISSUER_INFO_ERROR",

    SET_ISSUER_INFO_REQUEST = "SET_ISSUER_INFO_REQUEST",
    SET_ISSUER_INFO_SUCCESS = "SET_ISSUER_INFO_SUCCESS",
    SET_ISSUER_INFO_ERROR = "SET_ISSUER_INFO_ERROR",

    GET_ALL_LIQUIDITY_POOLS_REQUEST = "GET_ALL_LIQUIDITY_POOLS_REQUEST",
    GET_ALL_LIQUIDITY_POOLS_SUCCESS = "GET_ALL_LIQUIDITY_POOLS_SUCCESS",
    GET_ALL_LIQUIDITY_POOLS_FAILURE = "GET_ALL_LIQUIDITY_POOLS_FAILURE",

    GET_ISSUER_LIQUIDITY_POOLS_REQUEST = "GET_ISSUER_LIQUIDITY_POOLS_REQUEST",
    GET_ISSUER_LIQUIDITY_POOLS_SUCCESS = "GET_ISSUER_LIQUIDITY_POOLS_SUCCESS",
    GET_ISSUER_LIQUIDITY_POOLS_FAILURE = "GET_ISSUER_LIQUIDITY_POOLS_FAILURE",
    
    GET_LIQUIDITY_POOL_REQUEST = "GET_LIQUIDITY_POOL_REQUEST",
    GET_LIQUIDITY_POOL_SUCCESS = "GET_LIQUIDITY_POOL_SUCCESS",
    GET_LIQUIDITY_POOL_FAILURE = "GET_LIQUIDITY_POOL_FAILURE",

    GET_INVESTOR_SHARES_REQUEST = "GET_INVESTOR_SHARES_REQUEST",
    GET_INVESTOR_SHARES_SUCCESS = "GET_INVESTOR_SHARES_SUCCESS",
    GET_INVESTOR_SHARES_ERROR = "GET_INVESTOR_SHARES_ERROR",

    CLEAR_ALL = "CLEAR_ALL"
}