import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { ArtLendingExtraInfo, defaultArtLendingExtraInfo, FileSrc } from "../../AssetContext";
import { actionCreators } from "../../state";
import { TitleColor1 } from "../typography/Typogtaphy";
import UploadFilesForm, {UploadTokenFilesForm} from "../UploadFilesForm";
import LabelAndInput from "./../LabelAndInput";
import HTMLVisualizer from "../utils/HTMLVisualizer";
import { UpdatableStringTable } from "../UpdatableTable";
import { OptimizedImage, SortedImages, sortImages } from "../../functions/images"
import {sanitizeInputField} from "../../utils"
import UploadCardImage from "../UploadCardImage";

export default function ExtraInfoArtLendingForm(props: {assetInfo: any, setUpdateFun: any, media: any,
        tbr: any[], setTbr: any, tbu: any[], setTbu: any,
        coownTBR: any[], setCoownTBR: any, coownTBU: any[], setCoownTBU: any,
        prospTBR: any[], setProspTBR: any, prospTBU: any[], setProspTBU: any}): JSX.Element {

    const dispatch = useDispatch() 
    const actions = bindActionCreators(actionCreators, dispatch)

    // Asset ExtraInfo fields
    const[assetName, setAssetName] = useState<string>("")
    const[artworkName, setArtworkName] = useState<string>("")
    const[artistName, setArtistName] = useState<string>("")
    const[artLocation, setArtLocation] = useState<string[]>([])
    const[artSigned, setArtSigned] = useState<string>("")
    const[artTechnique, setArtTechnique] = useState<string>("")
    const[artSize, setArtSize] = useState<string>("")
    const[artYear, setArtYear] = useState<string>("")
    const[artEstimate, setArtEstimate] = useState<string>("")
    const[artInsurance, setArtInsurance] = useState<string>("")
    const[artOwners, setArtOwners] = useState<string[]>([])
    const[artDescr, setArtDescr] = useState<string>("")
    const[subtype, setSubtype] = useState<string>("")
    const[timeSpan, setTimeSpan] = useState<string>("")
    const[loanAmount, setLoanAmount] = useState<string>("")
    const[rightOfFirstRefusal, setRightOfFirstRefusal] = useState<string>("")
    const[termInMonths, setTermInMonths] = useState<string>("")
    const[interestStructure, setInterestStructure] = useState<string>("")

    const[videosSrc, setVideosSrc] = useState<{url:string, filename: string}[]>([])
    const[documentsSrc, setDocumentsSrc] = useState<{url:string, filename: string}[]>([])
    const [images2, setImages2] = useState<FileSrc[]>([])
    const[videos, setVideos] = useState<FileSrc[]>([])
    const [sortedImages, setSortedImages] = useState<OptimizedImage[]>([])
    const [unsortedImages, setUnsortedImages] = useState<OptimizedImage[]>([])
    const[documents, setDocuments] = useState<FileSrc[]>([])
    const[coownership, setCoownership] = useState<FileSrc[]>([])
    const[prospectus, setProspectus] = useState<FileSrc[]>([])   
    const[download, setDownload] = useState<boolean>(true)

    const[isApprovedAndTokenized, setIsApprovedAndTokenized] = useState<boolean>(false)
    const[isSubmitted, setIsSubmitted] = useState<boolean>(false)
    
    var newArtLendingInfo: ArtLendingExtraInfo = {
        artist: sanitizeInputField(artistName),
        artwork_title: sanitizeInputField(artworkName),
        art_location: artLocation,
        signed: artSigned,
        technique: sanitizeInputField(artTechnique),
        size: sanitizeInputField(artSize),
        year: artYear,
        estimate: sanitizeInputField(artEstimate),
        insurance: sanitizeInputField(artInsurance),
        subtype: subtype,
        description: artDescr,
        owners: artOwners,
        time_span: sanitizeInputField(timeSpan),
        loan_amount: sanitizeInputField(loanAmount),
        term_in_months: sanitizeInputField(termInMonths),
        interest_structure: sanitizeInputField(interestStructure),
        right_of_first_refusal: sanitizeInputField(rightOfFirstRefusal),
    }

    useEffect(() => {
        if(props.assetInfo.payload && props.assetInfo.payload.extra_info) {
            let artLending = defaultArtLendingExtraInfo
            if(props.assetInfo.payload.extra_info.art_lending) {
                artLending = props.assetInfo.payload.extra_info.art_lending.sub_assets ?
                    props.assetInfo.payload.extra_info.art_lending.sub_assets[0] :
                    defaultArtLendingExtraInfo
            }
            if(props.assetInfo.payload.asset_status_name !== "In Process" && props.assetInfo.payload.asset_status_name !== "Rejected")  
                setIsApprovedAndTokenized(true)
            if(props.assetInfo.payload.asset_status_name === "Submitted")  
                setIsSubmitted(true)
                
            setAssetName(props.assetInfo.payload.name)
            if(artLending) {
                setArtworkName(artLending.artwork_title)
                setArtistName(artLending.artist)
                setArtLocation(artLending.art_location)
                setArtSigned(artLending.signed)
                setArtTechnique(artLending.technique)
                setArtSize(artLending.size)
                setArtYear(artLending.year)
                setArtEstimate(artLending.estimate)
                setArtInsurance(artLending.insurance)
                setArtOwners(artLending.owners)
                setArtDescr(artLending.description)
                setSubtype(artLending.subtype)
                setTimeSpan(artLending.time_span)
                setLoanAmount(artLending.loan_amount)
                setTermInMonths(artLending.term_in_months)
                setInterestStructure(artLending.interest_structure)
                setRightOfFirstRefusal(artLending.right_of_first_refusal)
            }
        }    
    }, [props.assetInfo, props.assetInfo.loading])
    
    useEffect(() => {
        if(props.media && download) {
            actions.getDocuments(props.media, setDocumentsSrc)
            actions.getVideos(props.media, setVideosSrc)
            setDownload(false)
        }
    }, [props.media])

    useEffect(() => {
        if (props.media) {
            let imgs: SortedImages = sortImages(
                props.media.filter((m: any) => m.media_type_name === "Image"))
            actions.getAllImagesURL(imgs.sorted_images, setSortedImages)
            actions.getAllImagesURL(imgs.others.map((i) => {
                return { name: i, url_small: "", url_big: i, filename_big: "", filename_small: "" }
            }), setUnsortedImages)
        }
    }, [props.media])

    useEffect(() => {
        let i = [...sortedImages, ...unsortedImages].map((im) => {
            return { name: im.name, uploaded: true, src: im.url_big, small: im.url_small,
                filename_big: im.filename_big, filename_small: im.filename_small
             }
        })
        setImages2(i)
    }, [sortedImages, unsortedImages])

    useEffect(() => {
        setVideos(videosSrc.map((u) => {return {name: u.filename, uploaded: true, src: u.url}}))
    }, [videosSrc])

    useEffect(() => {
        let tmp:any = []
        documentsSrc.map((u) => {
            if(!u.filename.includes("coownership___") && !u.filename.includes("prospectus___"))
                tmp.push({name: u.filename, uploaded: true, src: u.url}) 
        })
        setDocuments(tmp)

        let tmp2:any = []
        documentsSrc.map((u) => {
            if(u.filename && u.filename.includes("coownership"))
                tmp2.push({name: u.filename, uploaded: true, src: u.url})
        })
        setCoownership(tmp2)

        let tmp3:any = [] 
        documentsSrc.map((u) => {
            if(u.filename && u.filename.includes("prospectus"))
                tmp3.push({name: u.filename, uploaded: true, src: u.url})
        })
        setProspectus(tmp3)
    }, [documentsSrc])

    function updateEI() {
        var subAsset :any[] = []

        let tmp:string[] = []
        tmp = newArtLendingInfo.owners.filter((el:string) => el !== "" && el !== undefined )
        newArtLendingInfo.owners = tmp
        tmp = newArtLendingInfo.art_location.filter((el:string) => el !== "" && el !== undefined )
        newArtLendingInfo.art_location = tmp

        subAsset.push(newArtLendingInfo)
        const assetExtraInfo = {
            art_lending: {
                sub_assets: subAsset
            }
        }
        return assetExtraInfo 
    }
    
    useEffect(() => {
        props.setUpdateFun(updateEI)
    }, [assetName,artworkName, artistName,artLocation, artSigned,artTechnique,artSize,
        artYear,artEstimate, artInsurance,artOwners,artDescr, subtype,timeSpan, loanAmount,
        rightOfFirstRefusal,termInMonths,interestStructure])


    return <>
        <div className="grid md:px-20 px-5 grid-cols-12 mt-10" >
        <TitleColor1 className=" col-span-12 text-lg">/ Artwork Information</TitleColor1>
            <LabelAndInput id={"assetName"} label={"Asset Name"} value={assetName} fun={setAssetName} type={"disabled"} className={"col-span-6"} required/>
            <LabelAndInput id={"artworkTitle"} label={"Artwork Title *"} value={artworkName} fun={setArtworkName} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"description"} label={"Artwork Description *  (text or HTML)"} value={artDescr} fun={setArtDescr} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className="col-span-12" required/>
            <HTMLVisualizer text={artDescr} className="w-full col-span-12 text-tertiary mb-5"/>
            <LabelAndInput id={"artist"} label={"Artist *"} value={artistName} fun={setArtistName} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"signed"} label={"Signed *"} value={artSigned} fun={setArtSigned} 
                type={isApprovedAndTokenized ? "disabled" : "select"} className={"col-span-6"} required options={[
                    {value: "", text: "Select"}, {value: "signed and archived", text: "Signed and Archived"},
                    {value: "yes", text: "Yes"}, {value: "no", text: "No"}
                ]}/>
            <UpdatableStringTable title={"Locations"} state={artLocation} setState={setArtLocation} 
                submitted={isSubmitted}/>
            <LabelAndInput id={"technique"} label={"Technique *"} value={artTechnique} fun={setArtTechnique} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"size"} label={"Size *"} value={artSize} fun={setArtSize} type={isApprovedAndTokenized ? "disabled" : "text"} className="col-span-6" required/>
            <LabelAndInput id={"year"} label={"Year *"} value={artYear} fun={setArtYear} type={isApprovedAndTokenized ? "disabled" : "number"} className={"col-span-6"} required/>
            <LabelAndInput id={"estimate"} label={"Estimate Value *"} value={artEstimate} fun={setArtEstimate} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"insurance"} label={"Insurance *"} value={artInsurance} fun={setArtInsurance} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            {/*<LabelAndInput id={"subtype"} label={"Subtype"} value={subtype} fun={setSubtype} type={"select"} className="col-span-3"
                options={[["", ""], ["basic", "Basic"], ["monthly_payments", "Monthly payments"],
                    ["other", "Other options"]].map((l) => {return {value: l[0], text: l[1]}})} required/>*/}
            
            <UpdatableStringTable title={"Owners"} state={artOwners} setState={setArtOwners} 
                submitted={isSubmitted} />
            <LabelAndInput id={"timeSpan"} label={"Time Span *"} value={timeSpan} fun={setTimeSpan} type={isApprovedAndTokenized ? "disabled" : "text"} className="col-span-4" required/>
            <LabelAndInput id={"loanAmount"} label={"Loan Amount *"} value={loanAmount} fun={setLoanAmount} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-4"} required/>
            <LabelAndInput id={"termInMonths"} label={"Term in Month *"} value={termInMonths} fun={setTermInMonths} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-4"} required/>
            <LabelAndInput id={"interestStructure"} label={"Interest Structure *"} value={interestStructure} fun={setInterestStructure} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-8"} required/>
            <LabelAndInput id={"rightOfFirstRefusal"} label={"Right of First Refusal *"} value={rightOfFirstRefusal} fun={setRightOfFirstRefusal} type={isApprovedAndTokenized ? "disabled" : "text"} className="col-span-4" required/>
           
            <div className="col-span-12 grid mt-5">
                <UploadTokenFilesForm 
                    coownership={{names: coownership, setNames: setCoownership,
                        tbr: props.coownTBR, tbu: props.coownTBU, setTbr: props.setCoownTBR, setTbu: props.setCoownTBU}}
                    prospectus={{names: prospectus, setNames: setProspectus,
                        tbr: props.prospTBR, tbu: props.prospTBU, setTbr: props.setProspTBR, setTbu: props.setProspTBU}}
                    isSubmitted={isSubmitted}/>
            </div>

            <div className="col-span-12 mt-5">
                <UploadCardImage images={images2} assetName={assetName}/>
            </div>
            
            <div className="col-span-12 mt-5">
                <UploadFilesForm 
                    images={{names: images2, setNames: setImages2,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    videos={{names: videos, setNames: setVideos,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    documents={{names: documents, setNames: setDocuments,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    isSubmitted={isSubmitted}/>
            </div>
            
        </div>
    </>
}