import { bindActionCreators } from "@reduxjs/toolkit"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import LabelAndInput from "../components/LabelAndInput"
import { LightButton } from "../components/light/LightButton"
import LightTable from "../components/light/LightTable"
import { defaultSecurityToken, Token, defaultBalance } from "../PoolContext"
import { actionCreators } from "../state"
import Popup from '../components/Popup'
import {euroFormat} from "../utils"
import { defaultAsset} from "../AssetContext"
import { BigTitle } from "../components/typography/Typogtaphy";
import { formValidationClearErrors, formValidationCheckObjectTokenization } from '../utils'
import HTMLVisualizer from "../components/utils/HTMLVisualizer";


export default function TokenizationRoute(props: any): JSX.Element {
    let history = useHistory();

    const uuid = props.match.params.uuid
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    
    const [asset, setAsset] = useState(defaultAsset)
    const [token, setToken] = useState(defaultSecurityToken)
    const [ticker, setTicker] = useState("")
    const [totalSupply, setTotalSupply] = useState(0)
    const [pctTokenized, setPctTokenized] = useState<number>(100)
    const [proposedPrice, setProposedPrice] = useState<number|string>(0)
    const [tokenTermCond, setTokenTermCond] = useState("")
    const [investmentLimit, setInvestmentLimit] = useState("")
    const [legalFramework, setLegalFramework] = useState("")
    const p2pFlag = false
    const lpFlag = false
    const toBeListed = true
    const [saved, setSaved] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    
    const [tokenUuid, setTokenUuid] = useState("")
    const [response, setResponse] = useState<any[]>([0, undefined])
    const [balance, setBalance] = useState(defaultBalance)

    var assetName = asset.name
    var assetCategory = asset.asset_category
    var assetType = asset.asset_type
    var newToken: Token = {
        asset_uuid: uuid,
        ticker: ticker.toUpperCase(),
        total_supply: totalSupply,
        pct_tokenized: pctTokenized /*asset.asset_category === "ART" ? 100 : pctTokenized*/,
        proposed_price: proposedPrice ? proposedPrice.toString().replace(",", ".") : 0,
        p2p_flag: p2pFlag,
        lp_flag: lpFlag,
        to_be_listed: toBeListed,
        token_term_cond: tokenTermCond,
        investment_limits: investmentLimit,
        legal_framework: legalFramework
    }

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [showPopUpSuccess, setShowPopUpSuccess] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    function handleClosePopUp(){
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUp(false)
    }

    function handleCloseSuccessPopUp(){
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUpSuccess(false)
        history.push("/issuer/dashboard")
    }

    useEffect(() => {
        actions.getAssetInfo(uuid, setAsset)
        actions.getTokenInfo(uuid, setToken)
        actions.getActualBalance(setBalance)
    }, [])

    useEffect(() => {
        setPctTokenized(parseFloat(token.pct_tokenized !== undefined ? token.pct_tokenized : "100"))
        setProposedPrice(parseFloat(token.proposed_price !== undefined ? token.proposed_price : "0"))
        setTicker(token.ticker)
        setTotalSupply(parseFloat(token.total_supply !== undefined ? token.total_supply : "0"))
        setTokenTermCond(token.token_term_cond ? token.token_term_cond : "")
        setInvestmentLimit(token.investment_limits ? token.investment_limits : "")
        setLegalFramework(token.legal_framework ? token.legal_framework : "")
        if(token.uuid !== "0") setTokenUuid(token.uuid)
    }, [token])

    async function handleSave() {
        let t = newToken
        if(tokenUuid !== "")
            t.uuid = tokenUuid
        formValidationClearErrors()
        let count = formValidationCheckObjectTokenization(t)
        if(count > 0){
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please complete the highlighted fields.")
            setShowPopUp(true)
        } else if(!/^[a-zA-Z]+$/.test(ticker)){
            setTitlePopUp("Sorry!")
            setMsgPopUp("Ticker must contains only letters.")
            setShowPopUp(true)
        } else {

            setSaved(true)
            actions.createToken({
                token : t,
                submit: false
            }, setResponse)
        }
    }

    function handleSubmit() {
        let t = newToken
        t.uuid = tokenUuid
        formValidationClearErrors()
        let count = formValidationCheckObjectTokenization(t)
        if(count > 0){
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please complete the highlighted fields.")
            setShowPopUp(true)
        } else if(!/^[a-zA-Z]+$/.test(ticker)){
            setTitlePopUp("Sorry!")
            setMsgPopUp("Ticker must contains only letters.")
            setShowPopUp(true)
        } else {
            setSubmitted(true)
            actions.createToken({
                token: t,
                submit: true
            }, setResponse)
        }
    }

    function handlePercentage(v: string) {
        let perc = parseFloat(v)
        if(!perc) perc = 0
        else if(perc > 100) perc = 100
        setPctTokenized(perc)
    }

    useEffect(() => {
        if(response && (saved || submitted)){
            switch(response[0]) {
                case 200:
                    setTokenUuid(response[1].token_uuid)
                    setTitlePopUp("Success")
                    if(saved) {
                        setMsgPopUp("Your data has been saved.")
                        setShowPopUp(true)
                        setSaved(false)
                    } else if (submitted){
                        setMsgPopUp("Your data has been submitted.")
                        setShowPopUpSuccess(true)
                        setSaved(false)
                    }
                    break
                case 0:
                    break
                default:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("We encountered an error saving your data.")
                    setShowPopUp(true)
                    setSaved(false)
            }
        }
    }, response)

    function setUppercaseTicker(tckr: string) {
        setTicker(tckr.toUpperCase())
    }

    let checkIfSbmitted = (token.status === "In Process" ||
                            token.status === "Submitted" ||
                            token.status === "Denied" ||
                            token.status === "Approved" ||
                            token.status === "Listed"||
                            asset.asset_status_name === "Tokenization In Process" ||
                            asset.asset_status_name === "Tokenized") && token.status !== "Rejected"

    return <>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp} />
        <Popup show={showPopUpSuccess} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleCloseSuccessPopUp}></Popup>
        <div>
            <BigTitle title={asset.name} />
            <div className="flex place-content-center mb-5 justify-between flex-wrap gap-4 md:mx-20">
                <div className=" text-succ2 text-2xl uppercase tracking-wider font-normal flex place-content-center">
                    Tokenize
                </div>
                <div className=" text-succ2 text-xl tracking-wider font-normal flex place-content-center">
                    {"€"+euroFormat(balance.payment_tokens.available_total)}
                </div>
            </div>
            <LightTable headers={["Asset name", "Asset category", "Asset type"]} 
                rows={[[assetName, assetCategory, assetType]]} noBorders={true} colorHd="primary text-tertiary"/>
        </div>
        <div className="grid justify-center xl:mx-36 p-10">
            
            <div className="grid md:grid-cols-2 p-5">
                <div className="mx-2">
                    <LabelAndInput id={"ticker"} label={"Ticker *"} value={ticker} fun={setUppercaseTicker} type={checkIfSbmitted ? "disabled" : "text"} className="mr-1.5" color="white" required />
                    {
                        /*asset.asset_category === "ART" ? (<>
                            <LabelAndInput label={"Pct Tokenized"} value={100.0} helpTip={"Just for ART Assets, you must tokenize 100% of the asset, "+
                                "and then you will be able to sell at maximum 49% of it."}
                                fun={() => {}} type={checkIfSbmitted ? "disabled" : "number"} className="mx-1.5" color="white"/>
                        </>) : (*/
                            <LabelAndInput id={"pctTokenized"} label={"Pct Tokenized *"} value={parseFloat(pctTokenized.toString())} 
                                helpTip="Specify the percentage of the asset you want to tokenize"
                                fun={(v: string) => handlePercentage(v)} type={checkIfSbmitted ? "disabled" : "number"} 
                                className="mx-1.5" color="white" required />
                        //)
                    }
                </div>
                <div className="mx-2">
                    <LabelAndInput id={"totalSupply"} label={"Total Supply *"} value={totalSupply} 
                        fun={(v: string) => setTotalSupply(parseFloat(v ? v : "0"))} type={checkIfSbmitted ? "disabled" : "number"} 
                        className="mx-1.5" color="white" required />
                    <LabelAndInput id={"proposedPrice"} label={"Proposed Price € *"} value={proposedPrice} 
                        helpTip="Specify the price for each token you are minting"
                        fun={(v: string) => setProposedPrice(v)} type={checkIfSbmitted ? "disabled" : "float"} 
                        className="ml-1.5" color="white" required />
                </div>
                <div className="mx-2 ">
                    
                    <LabelAndInput required id={"legalFramework"} label="Token Legal Framework *" value={legalFramework} fun={(e: string) => setLegalFramework(e)} 
                        type={checkIfSbmitted ? "disabled" : "select"} className=""
                        options={[
                            {value: "", text: ""},
                            {value: "coownership", text: "Asset Co-Ownership Token"},
                            {value: "security", text: "Security Token"}
                        ]}/>
                    
                </div>
                <div className="mx-2 col-span-2">
                <LabelAndInput id={"tokenTermCond"} label={"Token Terms and Conditions *"} value={tokenTermCond} 
                    helpTip="Specify the token's Terms & Conditions based on the type of token: Asset Co-Ownership Token or a Security Token."
                    fun={setTokenTermCond} type="textarea" rows={3} className="col-span-12 ml-1.5" color="white" required  />
                <HTMLVisualizer text={tokenTermCond} className="mx-1 text-tertiary"/>
                <LabelAndInput id={"investmentLimits"} label={"Investment Restrictions *"} value={investmentLimit} 
                    helpTip="Specify any investment restrictions for this token. Examples might be a list or restricted countries or availability only to qualified investors."
                    fun={setInvestmentLimit} type="textarea" rows={3} className="col-span-12 ml-1.5" color="white" required  />
                <HTMLVisualizer text={investmentLimit} className="mx-1 text-tertiary"/>
                </div>
            </div>
            <div className="grid grid-cols-4 mb-10">

            </div>
            <div className="text-primary p-5 bg-tertiary text-center font-urbanist uppercase text-1xl mb-10 tracking-wider">
                TOKENIZATION FEES ARE TEMPORARILY FREE</div>
            
            <div className="flex justify-center flex-wrap gap-4 grid-cols-3 mt-10">
                <Link to={checkIfSbmitted ? "/issuer/dashboard/#Tokens" : "/issuer/dashboard"} className="">
                    <LightButton label={"back"} alternate={true} className="float-left rounded-full py-2 px-5"/></Link>
                {
                    token.status === "Submitted" ? "" : (<>
                        <LightButton label={"save"} alternate={true} className="rounded-full py-2 px-5" onClick={() => handleSave()}/>
                        { tokenUuid === "" ? "" : <LightButton label={"submit"} alternate={true} className="rounded-full py-2 px-5" onClick={() => handleSubmit()}/>}
                </>)}
            </div>
        </div>
    </>
}
