import { useEffect, useState } from "react";
import Popup from "../Popup";
import GenericPopup, { PopupGrayLine, PopupLabelAndValue, PopupTitle } from "./GenericPopup";
import { euroFormat, quantityFormat } from "../../utils";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { P2P } from "../../P2PContext";
import LabelAndInput from '../LabelAndInput'
import { FileSrc } from "../../AssetContext"


export default function P2PBuyPopup(props: {tokenInfo: any, p2p: P2P | undefined
        isShown: boolean, closeFunction: Function, refreshList: Function}) : JSX.Element {

    const [quantity, setQuantity] = useState<number>(0)
    const [balance, setBalance] = useState<any>()
    const [maxTokenA, setMaxTokenA] = useState<number>(0)
    const [response, setResponse] = useState<any[]>([])
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    //RESPONSE POPUP
    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")

    const [coOwnership, setCoOwnership] = useState<boolean>(false)
    const [prospectus, setProspectus] = useState<boolean>(false)
    const [owningToken, setOwningToken] = useState<boolean>(false)
    const[media, setMedia] = useState<any>([])
    const[docSrc, setDocSrc] = useState<{url:string, filename: string}[]>([])
    const [coOwnDoc, setCoOwnDoc] = useState<FileSrc>()
    const [prospectusDoc, setProspectusDoc] = useState<FileSrc>()

    useEffect(() => {
        if(props.tokenInfo.asset_uuid !== "" && props.tokenInfo.asset_uuid !== "0")
            actions.getAssetMedia(props.tokenInfo.asset_uuid, setMedia)
    }, [props.tokenInfo])

    useEffect(() => {
        if(media)
            actions.getDocuments(media, setDocSrc)
    }, [media])

    useEffect(() => {
        if(docSrc){
            let tmp:any = []
            docSrc.map((u) => {
                if(u.filename && u.filename.includes("coownership"))
                    tmp.push({name: u.filename, uploaded: true, src: u.url})
            })
            setCoOwnDoc(tmp[0])

            let tmp2:any = [] 
            docSrc.map((u) => {
                if(u.filename && u.filename.includes("prospectus"))
                    tmp2.push({name: u.filename, uploaded: true, src: u.url})
            })
            setProspectusDoc(tmp2[0])
        }

    }, [docSrc])

    
    useEffect(() => {
        if(props.isShown === true)
            actions.getBalance(setBalance)
    }, [props.isShown])

    useEffect(() => {
        if(balance) {
            let securityEntries = balance?.balance?.security_entries
            let tokensByCat = securityEntries ? securityEntries.map((t: any) => t.class_balance) : []
            let Alltokens = [].concat.apply([], tokensByCat)
            let tokens: any[] = Alltokens.filter((t: any) => t.symbol === props.tokenInfo.ticker)
            let newMax = tokens.length > 0 ? tokens[0].quantity : 0
            setMaxTokenA(newMax)
        }
        
    }, [balance])

    useEffect(() => {
        if(balance){
            let sec_token_same_class = balance?.balance?.security_entries?.filter((c: any) => c.class === props.tokenInfo.asset_class)
            sec_token_same_class[0]?.class_balance?.forEach((class_token: any) => {
                if(class_token.token_uuid === props.tokenInfo.uuid){
                    if(class_token.quantity > 0){
                        setOwningToken(true)  
                        setCoOwnership(true)
                        setProspectus(true)
                    }
                }
            });
        }
    }, [props, balance])

    useEffect(() => {
        if(response.length > 0) {
            switch(response[0]) {
                case 200:
                case 204:
                    setShowPopUp(true)
                    setTitlePopUp("Success")
                    setMsgPopUp("Your purchase was successful.")
                    props.refreshList(true)
                    props.closeFunction()
                    break
                case 0:
                    break
                default:
                    setShowPopUp(true)
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("We encountered an error executing your purchase.")
                    break
            }
        }
    }, [response])

    function handleSubmit() {
        let params = {
            uuid: props.p2p ? props.p2p.uuid : "",
            amount: quantity
        }
        if(coOwnership === false){
            setTitlePopUp("Warning")
            setMsgPopUp("You must check the co-ownership agreement in order to buy the token.")
            setShowPopUp(true)
        } else if(prospectus === false){
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please read and agree the asset token prospectus before proceeding.")
            setShowPopUp(true)
        } else if(quantity <= 0){
            setTitlePopUp("Sorry!")
            setMsgPopUp("Quantity must be greater than 0.")
            setShowPopUp(true)
        } else
            actions.buyP2p(params, setResponse)
    }

    let balancePT = balance?.payment_tokens?.available_total
    let price = props.p2p?.per_token_price ? parseFloat(props.p2p.per_token_price) : 0
    let maxQuantity: number = props.p2p ? parseInt(props.p2p.token_left_amount) : 0
    
    return <>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} 
            close={() => {setShowPopUp(false)}} />
        <GenericPopup handleSubmit={handleSubmit} closeFunction={props.closeFunction}>
            <div className="mb-6">
                
                <div className="mb-2 px-14 grid grid-cols-2">
                    <PopupTitle color={"succ1"}>My balance</PopupTitle>
                    <PopupLabelAndValue label={props.tokenInfo.ticker+":"} 
                        value={quantityFormat(maxTokenA)} />
                    <PopupLabelAndValue label={"XEUR:"} 
                        value={euroFormat(balancePT ? balancePT : 0)} />
                </div>
                <PopupGrayLine />
                <div className="mb-2 px-14 grid grid-cols-2">
                    <PopupLabelAndValue label={"Token name:"} 
                        value={props.tokenInfo.asset_name} />
                    <PopupLabelAndValue label={"Ticker:"} 
                        value={props.tokenInfo.ticker} />
                </div>
                <PopupGrayLine />
                <div className="mb-2 px-14">
                        <div className={"text-succ1 text-sm font-medium"}>
                            Remaining Tokens for sale</div>
                    <div className="grid grid-cols-2">
                        <PopupLabelAndValue label={props.tokenInfo.ticker} 
                            value={maxQuantity} />
                    </div>
                </div>
                <div className="bg-gray4lines h-px w-full mt-2 " />
                <div className={"text-sm pl-10 pt-5 pb-4 bg-gradient-to-r from-succ1 to-transparent"}>
                    <div className="grid grid-cols-2 mb-2">
                        <div className="font-semibold text-xl text-blue">Quantity *
                            <LabelAndInput label={""} value={quantity} fun={(v:string) => setQuantity(parseInt(v ? v: "0"))} 
                                color="blue" type="number" max={maxQuantity} className="mr-8 text-xl" size='md'/>
                        </div>
                        <PopupLabelAndValue label={"Price:"} 
                            value={" €"+quantityFormat(price)} />
                    </div>
                    <div className="grid grid-cols-2">
                        <PopupLabelAndValue label={"Max Amount:"} 
                            value={quantityFormat(maxQuantity)} />
                        <PopupLabelAndValue label={"Calculated Cost:"} 
                            value={" €"+euroFormat(quantity*price)} />
                    </div>
                </div>
                <PopupGrayLine />

                <div className="pl-10 mb-2 font-roboto">
                    <div className="text-succ1 text-sm font-medium">
                        Review</div>
                    <div className="text-primary text-sm font-urbanist font-normal">
                        You will be charged with a fee for each transaction.
                    </div>
                </div>

                <div className="grid grid-cols-8 pl-5">

                    { owningToken ? (
                        <input type="checkbox" checked={true} className="w-10 py-4 mt-5 h-5 
                            text-black text-xl font-roboto leading-none col-span-1" disabled={true} />
                    ) :   (
                        <input type="checkbox" checked={coOwnership} className="w-10 py-4 mt-5 h-5 
                            text-black text-xl font-roboto leading-none col-span-1"
                            onChange={() => { setCoOwnership(!coOwnership) }} />
                    ) }

                <label className="w-full text-black text-sm py-2 pr-10 col-span-7 font-semibold">
                    I have read and I agree to the terms and conditions of the <a href={coOwnDoc ? coOwnDoc.src : ""} target="_blank" className='text-succ1'>co-ownership agreement</a>.
                </label>
                </div>

                <div className="grid grid-cols-8 pl-5 mb-5">

                { owningToken ? (
                    <input type="checkbox" checked={true} className="w-10 py-4 mt-5 h-5 
                        text-black text-xl font-roboto leading-none col-span-1" disabled={true} />
                ) :   (
                    <input type="checkbox" checked={prospectus} className="w-10 py-4 mt-5 h-5 
                        text-black text-xl font-roboto leading-none col-span-1"
                        onChange={() => { setProspectus(!prospectus) }} />
                ) }

                <label className="w-full text-black text-sm py-4 pr-10 col-span-7 font-semibold">
                I have read the <a href={prospectusDoc ? prospectusDoc.src : ""} target="_blank" className={'text-succ1' }>prospectus</a> on this asset token.
                </label>
            </div>

            </div>
        </GenericPopup>
    </>
}