//import LightTable from "./light/LightTable";
import { PieChart } from "react-minimal-pie-chart";
import { TitleColor1 } from "./typography/Typogtaphy";
import { InvestorBreakdownDetailedTable } from "./TokenTables"


export default function PieAndList(props: {tokenStats: any}): JSX.Element {

    const countries = props.tokenStats ? Object.keys(props.tokenStats.rows) : []
    const countryvalues = props.tokenStats ? Object.values(props.tokenStats.rows) : []
    const colors = [
        {name: "violet", code: "#AF6CFC"}, {name: "succ1", code: "#8CD9CF"}, {name: "error2", code: "#FFB37C"}, 
        {name: "error1", code: "#E95C7B"}, {name: "succ2", code: "#7ADFAE"}, {name: "succ4", code: "#4494CE"}, 
        {name: "succ6", code: "#4B58CE"}, {name: "succ8", code: "#C887DF"},  {name: "succ5", code: "#6772CD"},
        {name: "seafoam", code: "#80C8C4"}, {name: "succ7", code: "#AC4BCE"}, {name: "peach", code: "#FFB37C"},  
        {name: "background", code: "#E5E5E5"}, {name: "light_blue", code: "#1890E3"}, {name: "wild_blue_yonder", code: "#7A8EC0"}]
    let arrayCountry:any[] = []
    let arrayPie:any[] = []
    if(countries) {
        countries.forEach((country: string, i: number) => {
            let obj:any = countryvalues[i]
            let details = obj.entries && obj.entries.length ? obj.entries.length : 0
            let perc = (100*obj.total_quantity/props.tokenStats.total_quantity)
            let countryInfo = {
                "label": country+" ("+details+")",
                "qty": obj.total_quantity,
                "val": obj.total_value,
                "perc": perc,
                "col": colors[i].name
            }
            arrayCountry.push(countryInfo)
            arrayPie.push({title: country, value: perc, color: colors[i].code})
        })
    }
    
    return <>
        {countries.length > 0 ? <>
        <div className="">
            <div className="grid md:grid-cols-2 cover my-10">
                <div className="font-urbanist uppercase text-tertiary my-10 text-4xl 
                        flex flex-col text-left place-content-center items-center
                        md:border-solid border-tertiary border-r-1 border-0">
                    <div>
                    { arrayCountry.map((country, i) => {
                        return (<>
                            <div className=" my-5" key={i}>&euro;{country.val}
                                <div className={"text-"+country.col+" text-xl"}>{country.label}</div>
                            </div>
                        </>)
                    })}
                    </div>
                </div>
                <div className="md:border-solid border-tertiary border-0 border-l-1 my-10
                        grid xl:grid-cols-2">
                    <div className="flex flex-col justify-items-center self-center mt-16 justify-center text-center">
                        <div className="font-urbanist text-tertiary text-4xl">
                            &euro;{props.tokenStats ? props.tokenStats.total_value : ""}
                            <div className="font-roboto pt-2 text-tertiary text-2xl">
                                Total quantity: {props.tokenStats ? props.tokenStats.total_quantity : ""}
                            </div>
                        </div>
                    </div>
                    <div className="flex place-content-center">
                        <div className="w-48 grid justify-items-center text-tertiary self-center">
                            <div className={"relative text-5xl font-base inset-0 top-30"}>
                            &euro;
                            </div>
                            <PieChart className=""
                                data={arrayPie}
                                lineWidth={4}
                                paddingAngle={10}
                                animate={true}
                                animationDuration={1000}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="grid grid-cols-6 mt-20">
            <div></div>
            <div className="col-span-4">
                { countries.map((country, i) => 
                {
                    let obj:any = countryvalues[i]
                    return <> 
                        <div className="font-roboto font-bold text-2xl uppercase text-succ2 mb-5"><TitleColor1>{country}</TitleColor1></div>
                        <InvestorBreakdownDetailedTable users={obj.entries} />
                    </>
                })}
            </div>
            <div></div>
        </div> </> : 
        <div className="mt-10 ml-20 text-left text-gray4 text-xl">
            No data
        </div>}
    </> 
}