import { useEffect, useState } from "react"
import {LightButton} from "./light/LightButton"
import { Link } from "react-router-dom"
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default function CookieBanner() : JSX.Element {

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const cookieConsent = cookies.get('cookieConsent');
        if (!cookieConsent) {
          setIsVisible(true);
        }
      }, []);
    
    const handleAccept = () => {
        cookies.set('cookieConsent', 'true', {path: "/", sameSite: "strict"});
        setIsVisible(false);
    };
    
    if (!isVisible) return <></>;
    
    return <>
        <div className={"fixed bottom-0 w-full bg-primary text-white text-center p-4 mr-20 z-50 grid justify-items-center"}>
            <p className={"m-0 px-0 pt-4 inline-block"}>
                We only use strictly necessary cookies. For more details visit our 
                <Link to={"/privacy-policy"} className="text-succ1"> Privacy Policy</Link>
            </p>
            <LightButton label="OK" onClick={handleAccept} id={"cookieBanner"} 
                className="rounded-full px-10 py-2 mt-5" alternate={true} />
        </div>
    </>
}