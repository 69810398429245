import { createContext, useContext } from 'react'
//import { v4 as uuidv4 } from 'uuid';

export interface Asset {
  uuid?: string,
  issuer_uuid: string,
  asset_category: string,
  name: string,
  asset_type: string,
  asset_status_name?: string
  date_created?: string,
  currency_cd: string,
  issuer_name: string,
  extra_info: any ,
  comment?: string
}

export interface AssetCreation {
  uuid?: string,
  issuer_uuid?: string,
  asset_category: string,
  name: string,
  asset_type: string,
  currency_cd: string,
  issuer_name: string,
  extra_info: any 
}

export const defaultAsset: Asset = {
  //uuid: 0,
  issuer_uuid: "",
  name: "",
  date_created: "",
  currency_cd: "",
  issuer_name: "",
  extra_info: {},
  asset_category: '',
  asset_type: '',
  asset_status_name: '',
  comment: ''
}


/* HORSE EXTRA INFO */

export interface SportResult {
  rank : string,
  year : number,
  rider : string,
  height : string,
  competition : string
}

export const defaultSportResult: SportResult = {
  rank : "",
  year : 0,
  rider : "",
  height : "",
  competition : "",
}

export interface PrizeEarnings {
  year : number,
  prize : string
}

export const defaultPrizeEarnings: PrizeEarnings = {
  year : 0,
  prize : "",
}

export interface HorseExtraInfo {
  age: number,
  name: string,
  sire: string,
  color: string,
  rider: string,
  gender: string,
  madame: string,
  breeder: string,
  est_value: string,
  discipline: string,
  fei_number: string,
  best_result: string,
  description: string,
  date_of_birth: number,
  sport_results: SportResult[],
  total_winning: string, 
  prize_earnings: PrizeEarnings[],
  rating_scuderia: string
}

export const defaultHorseExtraInfo: HorseExtraInfo = {
  age: 0,
  name: '',
  sire: '',
  color: '',
  rider: '',
  gender: '',
  madame: '',
  breeder: '',
  est_value: '',
  discipline: '',
  fei_number: '',
  best_result: '',
  description: '',
  date_of_birth: 0,
  sport_results: [],
  total_winning: '',
  prize_earnings: [],
  rating_scuderia: ''
}

export interface ArtInvestingExtraInfo {
  art_location: string[],
  artist: string,
  artwork_title: string,
  description: string,
  estimate: string,
  insurance: string,
  owners: string[],
  signed: string,
  size: string,
  subtype: string,
  technique: string,
  year: string
}

export const defaultArtInvestingExtraInfo: ArtInvestingExtraInfo = {
  art_location: [],
  artist: '',
  artwork_title: '',
  description: '',
  estimate: '',
  insurance: '',
  owners: [],
  signed: '',
  size: '',
  subtype: '',
  technique: '',
  year: ''
}

/* ART LENDING EXTRA INFO */
export interface  ArtLendingExtraInfo{
  art_location: string[],
  artist: string,
  artwork_title: string,
  description: string,
  estimate: string,
  insurance: string,
  interest_structure: string,
  loan_amount: string,
  owners: string[],
  right_of_first_refusal: string,
  signed: string,
  size: string,
  subtype: string,
  technique: string,
  term_in_months: string,
  time_span: string,
  year: string
}

export const defaultArtLendingExtraInfo: ArtLendingExtraInfo = {
  art_location: [],
  artist: '',
  artwork_title: '',
  description: '',
  estimate: '',
  insurance: '',
  interest_structure: '',
  loan_amount: '',
  owners: [],
  right_of_first_refusal: '',
  signed: '',
  size: '',
  subtype: '',
  technique: '',
  term_in_months: '',
  time_span: '',
  year: ''
}



/* AIF EXTRA INFO */

export interface AifIrr {
  target_moic? : string,
  target_gross_irr : string,
  target_cash_yield? : string,
  distribution_policy : string
}

export const defaultAifIrr: AifIrr = {
  target_moic : "",
  target_gross_irr : "",
  target_cash_yield : "",
  distribution_policy : ""
}

export interface AifOther {
  AIFM : string,
  auditor : string,
  custodian : string,
  reporting : string,
  distributor : string,
  gp_clawback? : string,
  hurdle_rate? : string,
  administrator : string,
  nav_frequency : string,
  share_classes : string,
  incentive_fees : string,
  general_partner? : string,
  legal_counselor? : string,
  legal_structure? : string,
  management_fees : string,
  sub_distributor : string,
  portfolio_manager : string,
  type_of_waterfall? : string,
  subsequent_subscriptions : string
}

export const defaultAifOther: AifOther = {
  AIFM : "",
  auditor : "",
  custodian : "",
  reporting : "",
  distributor : "",
  gp_clawback : "",
  hurdle_rate : "",
  administrator : "",
  nav_frequency : "",
  share_classes : "",
  incentive_fees : "",
  general_partner : "",
  legal_counselor : "",
  legal_structure : "",
  management_fees : "",
  sub_distributor : "",
  portfolio_manager : "",
  type_of_waterfall : "",
  subsequent_subscriptions : ""
}

export interface AifOverview {
  deal_type : string,
  fund_name : string,
  target_fund_size : string,
  investment_period? : string,
  risk_return_characteristics : string,
  target_allocation : string,
  investment_strategy_description? : string
}

export const defaultAifOverview: AifOverview = {
  deal_type : "",
  fund_name : "",
  target_fund_size : "",
  investment_period : "",
  risk_return_characteristics : "",
  target_allocation : "",
  investment_strategy_description : ""
}

export interface AifFundTerms {
  ramp_up : string,
  fund_term : string,
  final_close : string,
  first_close : string,
  capital_calls : string,
  investment_period : string,
  capital_restitutions : string
}

export const defaultAifFundTerms: AifFundTerms = {
  ramp_up : "",
  fund_term : "",
  final_close : "",
  first_close : "",
  capital_calls : "",
  investment_period : "",
  capital_restitutions : ""
}

export interface AifPrivateDebtCharacteristics {
  covenant : string,
  deal_currency : string,
  fund_currency : string,
  average_rating : string,
  loans_or_bonds : string,
  minimum_rating : string,
  number_of_deals : string,
  borrower_profile : string,
  target_deal_size : string,
  floating_or_fixed : string,
  type_of_collateral : string,
  speed_of_deployment : string,
  target_company_size : string,
  bullet_or_amortizing : string,
  primary_or_secondary : string,
  sponsor_or_corporate : string,
  typical_loan_to_value : string,
  expected_term_per_deal : string,
  max_average_net_leverage : string,
  max_typical_term_per_deal : string
}

export const defaultAifPrivateDebtCharacteristics: AifPrivateDebtCharacteristics = {
  covenant : "",
  deal_currency : "",
  fund_currency : "",
  average_rating : "",
  loans_or_bonds : "",
  minimum_rating : "",
  number_of_deals : "",
  borrower_profile : "",
  target_deal_size : "",
  floating_or_fixed : "",
  type_of_collateral : "",
  speed_of_deployment : "",
  target_company_size : "",
  bullet_or_amortizing : "",
  primary_or_secondary : "",
  sponsor_or_corporate : "",
  typical_loan_to_value : "",
  expected_term_per_deal : "",
  max_average_net_leverage : "",
  max_typical_term_per_deal : ""
}

export interface AifPrivateEquityCharacteristics {
  warranty : string,
  co_investment : string,
  deal_currency : string,
  fund_currency : string,
  target_sectors : string,
  number_of_deals : string,
  target_deal_size : string,
  primary_secondary : string,
  speed_of_deployment : string,
  target_company_size : string,
  portfolio_company_profile : string,
  typical_stake_in_portfolio_companies : string
}

export const defaultAifPrivateEquityCharacteristics: AifPrivateEquityCharacteristics = {
  warranty : "",
  co_investment : "",
  deal_currency : "",
  fund_currency : "",
  target_sectors : "",
  number_of_deals : "",
  target_deal_size : "",
  primary_secondary : "",
  speed_of_deployment : "",
  target_company_size : "",
  portfolio_company_profile : "",
  typical_stake_in_portfolio_companies : ""
}

export interface AifInvestmentLimits {
  leverage : string,
  derivatives : string,
  geographic_area : string,
  max_issuer_limit : string,
  max_sector_limit : string,
  esg_considerations : string,
  excluded_profiles : string,
  max_country_limit : string
  interest_coverage_ratio: string
}

export const defaultAifInvestmentLimits: AifInvestmentLimits = {
  leverage : "",
  derivatives : "",
  geographic_area : "",
  max_issuer_limit : "",
  max_sector_limit : "",
  esg_considerations : "",
  excluded_profiles : "",
  max_country_limit : "",
  interest_coverage_ratio: ""
}

export interface AifPrivateDebtExtraInfo {
  irr: AifIrr,
  other: AifOther,
  overview: AifOverview,
  fund_terms: AifFundTerms,
  characteristics: AifPrivateDebtCharacteristics,
  investment_limits: AifInvestmentLimits
}

export const defaultAifPrivateDebtExtraInfo: AifPrivateDebtExtraInfo = {
  irr: defaultAifIrr,
  other: defaultAifOther,
  overview: defaultAifOverview,
  fund_terms: defaultAifFundTerms,
  characteristics: defaultAifPrivateDebtCharacteristics,
  investment_limits: defaultAifInvestmentLimits
}

export interface AifPrivateEquityExtraInfo {
  irr: AifIrr,
  other: AifOther,
  overview: AifOverview,
  fund_terms: AifFundTerms,
  characteristics: AifPrivateEquityCharacteristics,
  investment_limits: AifInvestmentLimits
}

export const defaultAifPrivateEquityExtraInfo: AifPrivateEquityExtraInfo = {
  irr: defaultAifIrr,
  other: defaultAifOther,
  overview: defaultAifOverview,
  fund_terms: defaultAifFundTerms,
  characteristics: defaultAifPrivateEquityCharacteristics,
  investment_limits: defaultAifInvestmentLimits
}


// COLLECTABLE CARS
export interface Appraisals {
  date : string,
  value : string
  //docs : FileSrc[]
}

export const defaultAppraisals: Appraisals = {
  date: "",
  value: "",
  //docs : []
}

export interface Odometer {
  odometer_value : string,
  odometer_unit : string
}

export const defaultOdometer: Odometer = {
  odometer_value : "",
  odometer_unit : ""
}

export interface CarExtraInfo {
  //asset_title: string,
  name: string,
  description: string,
  year: number,
  make: string,
  model: string,
  vin: string,
  exterior_color: string,
  interior_color: string,
  odometer: Odometer,
  transmission: string,
  engine_type: string,
  engine_size: string,
  location: string,
  market_value: string,
  condition: string,
  appraisals: Appraisals[], 

}

export const defaultCarExtraInfo: CarExtraInfo = {
  //asset_title: "",
  name: "",
  description: "",
  year: 0,
  make: "",
  model: "",
  vin: "",
  exterior_color: "",
  interior_color: "",
  odometer: defaultOdometer,
  transmission: "",
  engine_type: "",
  engine_size: "",
  location: "",
  market_value: "",
  condition: "",
  appraisals: [], 

}

// MEDIA

export interface FileSrc {
  name: string, 
  uploaded: boolean, 
  src: string,
  small?: string,
  index?: number,
  filename_big?: string,
  filename_small?: string
}

export const defaultFileSrc: FileSrc = {
  name: "", 
  uploaded: false, 
  src: ""
}

export const AssetContext = createContext<Asset>(defaultAsset)
export const useAssetContext = () => useContext(AssetContext)

