export class Timezone extends Date {
    offset: number;

    constructor(date: string, offset: number, set?: boolean) {
        super(date ? date : Date.now())
        this.offset = offset
        if(set && date){
            this.setDateNoOffset(date)
        }
    }

    addLeadingZero(s: string|number): string {
        return (s+"").length > 1 ? s+"" : "0"+s
    }

    setDateNoOffset(date: string) {
        let t = new Date(date)
        let off = (- t.getTimezoneOffset() - this.offset * 60)
        let u = t.getTime() + off * 60 * 1000
        this.setTime(u)
    }

    toString(): string {
        let t = new Date(this.getTime() + ((this.offset * 60 + this.getTimezoneOffset()) * 60 * 1000))
        let tmp = t.getFullYear()+"-"+
            this.addLeadingZero(t.getMonth()+1)+"-"+
            this.addLeadingZero(t.getDate())+"T"+
            this.addLeadingZero(t.getHours())+":"+
            this.addLeadingZero(t.getMinutes())
        return tmp
    }
}