import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { actionCreators } from "../../state"
import { euroQtyFormat, euroFormat, quantityFormat } from "../../utils"
import { DexxButton, DexxMaxButton } from "../light/LightButton"
import Popup from "../Popup"
import { DexxNumericInput, DexxSelectInput } from "../utils/Input"

export default function AddLPPopup(props: {closeFunction: Function, uuidLP: string}) : JSX.Element {
    
    //let uuid = props.uuidLP
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const [detailsLPRequest, setDetailsLPRequest] = useState()
    const [issuedTokens, setIssuedTokens] = useState<any>()
    const [balance, setBalance] = useState<any>()

    const [uuid, setUuid] = useState<string>("")

    const [lpName, setLpName] = useState<string>("LP_")
    const [tokenA, setTokenA] = useState<string>("")
    const [tokenB, setTokenB] = useState<string>('XEUR')
    const [assetName, setAssetName] = useState<string>("")
    const [assetClass, setAssetClass] = useState<string>("")
    const [tokenAName, setTokenAName] = useState<string>("")
    const [tokenBName, setTokenBName] = useState<string>("")
    const [amountTokenA, setAmountTokenA] = useState<number>(0)
    const [amountTokenB, setAmountTokenB] = useState<number>(0)
    const [maxTokenA, setMaxTokenA] = useState<number>(0)
    const [maxTokenB, setMaxTokenB] = useState<number>(0)
    const [lpAmountA, setLpAmountA] = useState<number>(0)
    const [lpAmountB, setLpAmountB] = useState<number>(0)
    const [getQuote, setGetQuote] = useState<boolean>(true)
    const [quote, setQuote] = useState<any>()
    const [spotPrice, setSpotPrice] = useState<number>(0)
    const [calcAmountTokenA, setCalcAmountTokenA] = useState<number>(0)
    const [calcAmountTokenB, setCalcAmountTokenB] = useState<number>(0.00)
    const [lpShares, setLpShares] = useState<number>(0)
    const [lpSharesPerc, setLpSharesPerc] = useState<number>(0)

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    //const [showConfirmPopUp, setConfirmShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    const [request, setRequest] = useState<any>()

    //let issuedTokensList = issuedTokens?.listed_tokens ? issuedTokens.listed_tokens : []

    useEffect(() => {
        setUuid(props.uuidLP)
    }, [props.uuidLP])

    
    useEffect(() => {
        if(uuid) actions.getDetailsLiquidityPool(uuid, setDetailsLPRequest)
        actions.getIssuedTokens(setIssuedTokens)
        actions.getActualBalance(setBalance)
    }, [uuid])

    useEffect(() => {
        if(detailsLPRequest && detailsLPRequest[0] === 200) {
           let l : any = detailsLPRequest[1]
           setTokenAName(l.token_a_ticker)
           setTokenBName(l.token_b_ticker)
           setTokenA(l.token_a_uuid)
           setTokenB(l.token_b_uuid)
           setLpName(l.lp_name)
           setAssetName(l.asset_a_name)
           setLpAmountA(l.amount_a)
           setLpAmountB(l.amount_b)
           setAssetClass(l.asset_a_category)
        }
    }, [detailsLPRequest])

    useEffect(() => {
        if (getQuote && uuid !== "") {
            let r = {
                lp_uuid: uuid,
                token_a_uuid: tokenA,
                amount_a: 1,
                is_buy: true
            }
            actions.getLPQuote(r, setQuote)
            setGetQuote(false)
        }
    }, [getQuote, uuid, tokenA, props])

    useEffect(() => {
        if(quote) {
            if(quote[0] === 200) {
                let q = quote[1]
                /*setEffectivePrice(q ? q.effective_price : effectivePrice)
                setEstValue(effectivePrice * (q ? parseInt(q.amount_a) : 1))
                setDexxFees(q ? q.dexx_fees : 0)
                setAmountB(q ? quantity * q.effective_price : amountB)
                setGrossAmount(q ? q.gross_amount : grossAmount)*/
                let SP = Math.ceil(100 *q.lp_b / q.lp_a) / 100
                setSpotPrice(SP)

            } else {
                if(quote[0] === 500 && quote[1]) {
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("We encountered an error providing a price quote.  Please try again.")
                    setShowPopUp(true)
                }
            }
        }
    }, [quote])

    useEffect(() => {
        if(balance && balance.payment_tokens) {
            setMaxTokenB(balance.payment_tokens.available_total)
        }
        
        if(balance && balance.balance.security_entries) {
            let tokensFromClass = balance.balance.security_entries.filter((c:any) => { return c.class === assetClass })
            if(tokensFromClass[0] && tokensFromClass[0].class_balance){
                let actualToken = tokensFromClass[0].class_balance.filter((t:any) => { return t.token_uuid === tokenA })
                if(actualToken[0] && actualToken[0]?.available_quantity)
                    setMaxTokenA(actualToken[0]?.available_quantity)
            }
        }
    }, [balance, tokenA])

    useEffect(() => {
        if(request && request.length > 0) {
            if(request[0] === 200 || request[0] === 204) {
                setTitlePopUp("Success")
                setMsgPopUp("Your data has been saved.")

                if(uuid) actions.getDetailsLiquidityPool(uuid, setDetailsLPRequest)
                actions.getIssuedTokens(setIssuedTokens)
                actions.getActualBalance(setBalance)

            } else {
                setTitlePopUp("Sorry!")
                //setMsgPopUp(request && request[1] && request[1].error ? request[1].error : "There was an error while executing the request")
                setMsgPopUp("We encountered an error saving your data.")
            }
            setShowPopUp(true)
        }
    }, [request])

    useEffect(() => {
        if(amountTokenB !== 0 && spotPrice !== 0) {

            /*
            Old calculation
            setAmountTokenA(Math.floor((amountTokenB / spotPrice) * 100) / 100)
            //let amount = Math.floor((amountTokenB / spotPrice))
            let amount = Math.floor((amountTokenB / spotPrice)) < (+maxTokenA+maxIssuerTokenA) ? Math.floor((amountTokenB / spotPrice)) : (+maxTokenA+maxIssuerTokenA)
            if(Math.floor((amountTokenB / spotPrice)) > ((maxTokenA) + (maxIssuerTokenA))) setAmountTokenB(amount * spotPrice)
            setCalcAmountTokenA((amount))
            setCalcAmountTokenB(Math.round((amount * spotPrice * 100))/100)*/

            let sp = parseFloat(spotPrice.toString().replace(",", "."))
            let amountB = parseFloat(amountTokenB.toString().replace(",", "."))

            // if desired_euro_amount > euro_available ==> desired_euro_amount == euro_available (update field in popup)
            if(amountB > maxTokenB) setAmountTokenB(maxTokenB)

            // desired_tokens_amount == ROUNDDOWN( desired_euro_amount / desired_price ; 0 decimals ) //internal, not shown
            let fixedAmountA = sp > 0 ? parseInt(Math.floor(amountB / sp).toFixed(0)) : 0
            
            //console.log("amountTokenB / spotPrice: " + Math.floor(amountTokenB / spotPrice) + "    toFixed: " +  Math.floor(amountTokenB / spotPrice).toFixed(0) + "     parseInt: " + parseInt((amountTokenB / spotPrice).toFixed(0)))
            // if desired_tokens_amount > tokens_available ==> tokens_amount == tokens_available
            let amount = fixedAmountA < maxTokenA ? fixedAmountA : maxTokenA
            setAmountTokenA(amount)
            setCalcAmountTokenA((amount))

            // euro_amount == ROUND( tokens_amount * desired_price ; 2 decimals) //it's the same for both cases
            setCalcAmountTokenB(Math.floor((amount * sp * 100))/100)
            
            //setAmountTokenB(Math.round((amount * spotPrice * 100))/100)
        }
    }, [maxTokenA, amountTokenB, spotPrice, maxTokenB])

    function handleMax(){
        if(spotPrice && spotPrice !== 0){
            let sp = parseFloat(spotPrice.toString().replace(",", "."))
            //When MAX is pressed, the calculation needs to take the min of both ways:

            // desired_euro_amount = ROUNDUP( tokens_available * desired_price ; 2 decimals )
            let fixedAmountB = Math.round(maxTokenA * sp * 100) / 100

            // if desired_euro_amount > euro_available ==> desired_euro_amount == euro_available
            if(fixedAmountB > maxTokenB) fixedAmountB = maxTokenB

            // update field desired_euro_amount
            setAmountTokenB(fixedAmountB)

            // tokens_amount == ROUNDDOWN( desired_euro_amount / desired_price ; 0 decimals )
            let fixedAmountA = Math.floor(parseInt((fixedAmountB / sp).toFixed(0)) * 100) / 100
            
            // update fields in box EFFECTIVE...
            setCalcAmountTokenA((fixedAmountA))
            setCalcAmountTokenA((Math.round((fixedAmountA * sp * 100))/100))
        } else {
            setTitlePopUp("Sorry!")
            setMsgPopUp("Spot price not defined")
            setShowPopUp(true)
        }
    }

    useEffect(() => {
        if(calcAmountTokenA !== 0 && calcAmountTokenB !== 0) {
            setLpShares(Math.floor((calcAmountTokenA) * (calcAmountTokenB)))
        }
    }, [calcAmountTokenA, calcAmountTokenB])

    useEffect(() => {
        if(detailsLPRequest && detailsLPRequest[0] === 200) {
            let l : any = detailsLPRequest[1]
            setLpSharesPerc(lpShares * 100 / (+l.lp_token_supply + +lpShares))
        }
    }, [lpShares])

    function handleSubmit() {
        let requestInfo = {
            lp_uuid: uuid,
            token_a: tokenA,
            amount_a: calcAmountTokenA,
            token_b: tokenB,
            max_amount_b: calcAmountTokenB.toString().replace(",", "."),
        }
        actions.addLiquidityPool(requestInfo, setRequest)
    }

    function labelAndValue(label: string, value: any, colorVal?: string, colorLabel?:string) {
        
        let cV = "gray-5"
        let cL = "primary"
        if(colorVal) cV = colorVal
        if(colorLabel) cL = colorLabel
        return <>
            <div className={"text-" + cL + " font-semibold text-sm"}>
                {label}
                <div className={"font-normal text-sm text-" + cV}>{value}</div>
            </div>
        </>
    }
    
    return <>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} 
            close={() => {setShowPopUp(false); props.closeFunction()}} />
        
        <div className="w-128 h-full font-roboto mt-2">
            
            <div className="pl-10 grid grid-cols-3 mb-2">
                <div className="col-span-2">
                    <div className={"text-greenMax text-sm font-medium"}>
                        My Balance</div>
                </div>
            </div>
            <div className="pl-10">
                <div className="grid grid-cols-2">
                    {labelAndValue(tokenAName+":", quantityFormat(maxTokenA), "blue", "blue")}
                    {labelAndValue(tokenBName+":", euroFormat(maxTokenB), "blue", "blue")}
                </div>
            </div>

            <div className="bg-gray4lines h-px w-full my-2"/>

            <div className="pl-10">
                <div className="grid grid-cols-2">
                    {labelAndValue("Asset Name:", assetName, "gray4lines", "gray4lines")}
                    {labelAndValue("Ticker:", tokenAName, "gray4lines", "gray4lines")}
                </div>
            </div>

            <div className="bg-gray4lines h-px w-full mt-2"/>
            
            <div className="pl-10 mt-2">
                <div className="grid grid-cols-2">
                <div className={"text-greenMax font-normal text-sm"}>
                    Reserves of<br /><span className="font-semibold">{lpName}</span>
                </div>
                    {labelAndValue("Spot Price:", euroFormat(spotPrice), "navy_blue", "gray4lines")}
                </div>
            </div>
            <div className="pl-10 mt-2">
                <div className="grid grid-cols-2">
                    {labelAndValue(tokenAName+":", euroQtyFormat(lpAmountA), "gray4lines", "gray4lines")}
                    {labelAndValue(tokenBName+":", euroFormat(lpAmountB), "gray4lines", "gray4lines")}
                </div>
            </div>
            
            <div className="bg-gray4lines h-px w-full mt-2"/>
            
            <div className={"text-sm pl-10 pt-2 pb-2 bg-gradient-to-r from-succ1 to-transparent"}>
                <div className="grid grid-cols-2 mb-1">

                    <div className=""></div>

                    <div className="font-semibold text-gray4lines">Desired {tokenBName} Amount *
                        <DexxNumericInput value={amountTokenB/* <= calcAmountTokenB ? amountTokenB : calcAmountTokenB*/ } className="text-sm font-bold" max={maxTokenB}
                            fun={(v:any) => setAmountTokenB((v ? v : "0"))}/>
                        <DexxMaxButton label={"MAX"} className="px-3 rounded-xl bg-greenMax mt-1" 
                            onClick={() => {
                            handleMax()
                        }}/>
                    </div>
                </div>
            </div>

            <div className="bg-gray4lines h-px w-full"/>

            <div className="pl-10 mt-2">
                <div className="grid grid-cols-2">
                    <div className={"text-primary font-semibold text-sm"}>
                        Reserves to add to<br /> <span className="font-normal">{lpName}</span>
                    </div>
                </div>
            </div>
            <div className="pl-10 mt-2 mb-2">
                <div className="grid grid-cols-2">
                    {labelAndValue(tokenAName+":", euroQtyFormat(calcAmountTokenA), "blue", "blue")}
                    {labelAndValue(tokenBName+":", euroFormat(calcAmountTokenB), "blue", "blue")}
                </div>
            </div>

            <div className="bg-gray4lines h-px w-full mb-2"/>

            <div className="pl-10 mb-5 font-roboto">
                <div className="text-greenMax text-sm font-medium">
                    Review</div>
                <div className="text-primary text-sm font-urbanist font-normal">
                    Effective {tokenAName}:
                    <strong>{euroQtyFormat(calcAmountTokenA)}</strong>
                </div>
                <div className="text-primary text-sm font-urbanist font-normal">
                    Effective {tokenBName}:
                    <strong>{euroFormat(calcAmountTokenB)}</strong>
                </div>
                <div className="text-primary text-sm font-urbanist font-normal">
                    LP shares to receive:
                    <strong>{euroQtyFormat(lpShares) + " (" + euroFormat(lpSharesPerc) + "%)"}</strong>
                </div>
            </div>
        </div>

        <div className="flex justify-between px-6 pb-2">
            <DexxButton onClick={() => {props.closeFunction()}} label={"close"} fontClasses="text-sm font-medium"
                className="rounded-full px-8 py-3 bg-white drop-shadow-n" color1='white' color2='gray5'/>
            <DexxButton onClick={() => {handleSubmit()}} label={"confirm"} 
                fontClasses="text-sm  font-medium" className="rounded-full px-16 bg-white py-3" 
                color1='white' color2='primary'/>
        </div>
    </>
}