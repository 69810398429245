import { Link } from "react-router-dom";
import { DexxTableTitle, HeaderButton } from "../HeaderButton";
import { LightButton } from "../light/LightButton";
import LightTable, { DexxTable } from "../light/LightTable";

export function addBtns(row: any[], btnLabels: string[], btnUrls: string[], uuid: any) {
    let tmp = btnLabels.map((b, i) => {
        return <div><TableButton label={b} link={btnUrls[i] + uuid} key={i}/></div>})
    return [...row, ...tmp]
}

export function ColoredTab(props: {label: string, color: string, index: number, headers: any, 
    rows: any, handleClick: Function, tabs: any}): JSX.Element {
    return <div>
        <HeaderButton name={props.label}  click={() => { if(props.rows.length) props.handleClick(props.index) }}
                className={" port-link cursor-pointer pl-10 bg-"+(props.index % 2 === 0 ? "primary" : "transparent")}
                color1={props.color} color2="white" shown={props.tabs[props.index] && props.rows.length > 0}/>
        {props.tabs[props.index] && props.rows.length > 0 ? <>
            {props.rows.length > 0 ?
            <LightTable headers={props.headers} rows={props.rows}
                colorHd={"gradient-to-r from-"+props.color+" to-gray3"}
                color1={"gradient-to-r from-"+props.color+"_lighter  to-gray1"}
                color2={"gradient-to-r from-"+props.color+"_light to-gray2"} /> :
            <div className="bg-white">
                <div className={"font-roboto uppercase text-gray pl-10 py-2 bg-"+props.color+"_light"}>None</div>
            </div>}
            
        </> : ""}
    </div>
}

export function DashboardTab(props: {active: boolean, label: string, 
    headers: any[], rows: any[], onClick?: Function, dark?: boolean}): JSX.Element {
    const active = props.active && props.rows.length > 0
    return <>
        <DexxTableTitle label={props.label + " (" + props.rows.length + ")"} 
            active={active} onClick={props.onClick}
            className={"pl-8 md:pl-16 "+(props.dark ? " bg-dark_blue_lighter " : "")}/>
        { active ?
            <DexxTable headers={props.headers} rows={props.rows} alignment={[]} /> : <></>
        }
    </>
}

export function Filter(props: {label: string, checked: boolean, setFunction: Function}): JSX.Element {
    return <>
        <div className={"col-span-4 font-urbanist py-2 tracking-wider"}>   
                <label className="w-full text-white uppercase text-sm py-4">
                    <input type="checkbox" checked={props.checked} className={"mr-4"}
                    onChange={() => props.setFunction(!props.checked)} />
                    {props.label}
                </label>
        </div>
    </>
}

export function TableButton(props: { label: string, link: string }): JSX.Element {
    return <>
        <Link className="grid justify-center" to={props.link}>
            <LightButton label={props.label} className="px-2 rounded m-1 font-normal" />
        </Link>
    </>
}