import { BackButton } from "../components/light/LightButton";
import { FaqQuestion, FaqAnswer } from "../components/typography/Typogtaphy";

export default function SupportRoute(): JSX.Element {

    return <>
        <div className="md:pl-20 pl-5 md:pr-20 pr-5">
            <div className="font-roboto font-thin text-left">
                <div className="text-succ2 text-6xl text-center mt-10 mb-10">Support</div>
                
                <div className="text-tertiary text-xl">
                        If you are having an issue using the platform please review the FAQ and Usage sections below to see if your issue can be resolved. 
                        If not, send us an email at <a className="text-succ2" href="mailto:support@dexx.finance">support@dexx.finance</a> describing your situation and we will help you resolve the issue.
                </div>

                <div className="text-succ2 text-4xl mt-10 mb-10">FAQ</div>

                <FaqQuestion content="1. Do I need an account? How do I create an account?" />
                <FaqAnswer content="Yes, all Users will need to create an account and pass our KYC process prior to transacting on the DEXX platform. To create an account open a browser to www.dexx.finance and tap ‘Login’ at the top right of the screen. On the pop-up window that appears, tap ‘Create Account’ and follow the onscreen instructions.  You may enter your own email and password or sign up using your Google email.  Be sure to check the Terms & Conditions box.  Then look for the Unique Code we sent you in your inbox." />

                <FaqQuestion content="2. I did not receive a Unique Code when creating my account." />
                <FaqAnswer content="Ensure the email account that you used to create your account is the one you checked for the Unique Code. Then check your spam folder for the email. If neither works, try signing in again using the same email and password you originally provided and tap ‘Resend Code’ at the bottom of the dialogue box on the screen. " />

                <FaqQuestion content="3. I am receiving error messages when I try to complete my profile telephone number." />
                <FaqAnswer content="Ensure you select the proper country associated with your telephone number. If you live in Switzerland but have an Italian cell phone number you will need to select ‘Italy’ for telephone country and then enter the number." />

                {
                    /* 
                    <FaqQuestion content="4. How long does the KYC take and what information do I need?" />
                    <FaqAnswer content="The KYC should take 10 to 15 minutes.  Have your passport available and be prepared to do a live video recording which the KYC will use to match your live appearance to the passport or other ID photo you provide. Residents of Switzerland are required to print out, sign and return (upload) a Form A document as part of the KYC process as well." />
                    */
                }
                <FaqQuestion content="4. Why is KYC important?" />
                <ul className="text-tertiary text-xl mt-3">
                    <li>Security and compliance: DEXX strives to provide a secure and compliant trading environment in accordance with regulatory requirements</li>
                    <li>Prevent illegal activities: Prevent illegal activities and detect suspicious behavior as early as possible.</li>
                    <li>Provide enhanced services: Users who complete individual KYC can enjoy all DEXX platform services.</li>
                    <li>Provide convenience and security: In the case of lost credentials to access the platform, a KYC-verified account provides a simpler process to help you to recover your account</li>
                </ul>

                <FaqQuestion content="5. How long does the KYC verification process take?" />
                <FaqAnswer content="Generally, the KYC verification process takes approximately 15 minutes. However, due to the complexity of information verification, KYC verification may at times take up to 48 hours." />

                <FaqQuestion content="6. What should I do if the KYC verification process exceeds 48 hours?" />
                <FaqAnswer content="If you encounter any issues with KYC verification, kindly contact us by submitting a case to our Customer Support: support@dexx.finance. The relevant team will reach out to you within one working day." />

                <FaqQuestion content="7. How long will I have to wait for approval after submitting their KYC documentation?" />
                <FaqAnswer content="The processing time can vary depending on a few factors. Once validators can verify applications in the KYC, the progress of the application can take from a few days to a few  weeks. KYC results will also depend on your name matching, screening against government AML and anti-terrorism sanctions list." />

                <FaqQuestion content="8. How will I be notified if I have passed KYC or not?" />
                <FaqAnswer content="You can check your KYC status inside the DEXX platform in “My Profile”. You can see the “Account Type” section and if you have a green tick near “Investor” it means that your KYC is approved. Furthermore the system will send you an email to confirm you have passed KYC." />

                <FaqQuestion content="9. What happens if I don’t pass KYC? Will my account be burned immediately?" />

                <FaqAnswer content="Failure in KYC can result from multiple reasons. Different reasons may lead to different outcomes, e.g. another chance to resubmit KYC application, appeal or permanent rejection. DEXX accounts will not be burned immediately. A grace period will likely be given for any resubmission and appeal cases, which will be announced later." />

                <FaqQuestion content="10. My KYC registration has been rejected – Why?" />
                <FaqAnswer content="When users apply for KYC, in some cases they are rejected, which often leaves applicants feeling confused as to why. In reality, there are a number of reasons why your Know Your Customer application may be rejected. One of the most common reasons for the rejection of a KYC application has to do with customer due diligence issues, including the identification elements required for a successful KYC procedure. For example, an out of date or blurry ID photo, passport or driver’s license photo will automatically be rejected during the screening process. When applying for KYC, all photo identification needs to be as recent as possible. Another common issue found on rejected KYC applications is aN invalid proof of address documents will certainly cause your KYC application to be rejected." />

                <FaqQuestion content="11. What are common reasons for verification failure?" />
                <FaqAnswer content="Here are some of the common reasons why verification may be unsuccessful (not a complete list):" />
                <ul className="text-tertiary text-xl mt-3">
                    <li>The system detects other verified accounts with the same ID information. Please be informed that DEXX only allows one account to process verification for each person.</li>
                    <li>Facial recognition fails. Please ensure to center your face in the frame.</li>
                    <li>ID detection fails. Please ensure your original ID is clear and visible; overexposure, reflection or an incomplete image may lead to failure.</li>
                    <li>Invalid document. Please resubmit using the supporting documentation guidance displayed on the verification page.</li>
                    <li>Your profile violates the DEXX Terms of Service, such as being under the age of 18, or you may be from one of our service restricted countries.</li>
                </ul>

                <FaqQuestion content="12. Can I have multiple KYC verified accounts in DEXX?" />
                <FaqAnswer content="DEXX only allows one account per user to process KYC verification. For security reasons, we are not allowed to transfer or remove personal information from the account." />

                <FaqQuestion content="13. Can I amend my KYC’s information (Proof of Identity & Proof of Address) after verification?" />
                <FaqAnswer content="No, you can’t amend your KYC information after verification unless your legal information has changed, i.e. Legal Name, Nationality." />

                <FaqQuestion content="14. What kind of Proof of Identity documents are accepted?" />
                <FaqAnswer content="DEXX only accepts documents issued by your country of origin which contain the following information: photo, name, date of birth, document number, date of issue. These include passport, identity card, residence permit and driving license. Student Visa, Working Visa or Travel Visa are not accepted." />

                <FaqQuestion content="15. What kind of Proof of Address documents are accepted?" />
                <FaqAnswer content="Proof of Address must be dated within the last three months. Documents older than three months will be rejected. The Proof of address documents accepted by DEXX include:" />
                <ul className="text-tertiary text-xl mt-3">
                    <li>Utility bills</li>
                    <li>Official bank statements</li>
                    <li>Residential proof issued by government</li>
                    <li>Internet/cable TV/house phone line bills</li>
                    <li>Tax returns</li>
                    <li>Council tax bills</li>
                    <li>Government-issued certificates of residence, etc.</li>
                </ul>

                <FaqQuestion content="16. How can I deposit funds in my account?" />
                <FaqAnswer content="In the My Portfolio menu you will see a Deposit / Withdraw button. Tap the Deposit tab to show the two options: 1) Wire Transfer and 2) Credit Card.  Credit Card deposits are quick and require a small fee charged to DEXX by our credit card processor.  Wire Transfers can take a few days to a week to process. Be sure to include your Unique Code in your wire transfer note area when you deposit funds. " />

                <FaqQuestion content="17. What if I said I was going to deposit one amount but deposited a different amount - is that OK?" />
                <FaqAnswer content="Yes, we credit your platform wallet with the actual net funds deposited to our bank. If you tell us 1000 but send 1500 and there is a 30 wire fee DEXX will receive 1470 and will credit your account that amount." />

                <FaqQuestion content="18. What happens with my cash deposit when I wire it to the DEXX Bank - does DEXX spend my money?" />
                <FaqAnswer content="No, DEXX does not spend your deposited funds. Fully 100% of deposited funds sit in the deposit account waiting for when Investors or Issuers request a withdrawal. The purpose of having a bank account is to “translate” a Euro into a digital Fimart Euro Token, which can be used on the DEXX platform. There is always 1 Euro in the bank for every 1 Euro Dexx coin on the DEXX platform. No interest is accrued to deposited funds and they are not insured by any government entity." />

                <FaqQuestion content="19. How long after the Primary Sale until the Secondary Market begins and I can sell my tokens to a liquidity pool or to another investor?" />
                <FaqAnswer content="The Secondary Market timing is up to the Issuer but it should take place within days of the Primary Sale. For the first few Primary Sales it will take a few more days than usual as we ensure everything is operating as planned." />

                <FaqQuestion content="20. How can I look at my data in Excel or another tool?" />
                <FaqAnswer content="Each of the transaction detail screens has a Download CSV button. Tap the button and open the file once it has downloaded to your computer." />

                <FaqQuestion content="21. Where do I see the assets I can invest in?" />
                <FaqAnswer content="Tap on Invest on the main menu to see the asset tokens available on DEXX.  Tap on a tile that interests you to see the status of the offering and detailed information about the asset and token sale. Assets might be awaiting their initial primary sale or already be active - either in the Primary Sale or Secondary Sale phases of their offering." />

                <FaqQuestion content="22. How do the assets get into DEXX?" />
                <FaqAnswer content="DEXX works with Issuers on supplying assets to the DEXX platform.  DEXX vets these Issuers carefully so that the assets you see are legitimate and fairly represented - though as with any asset potentially risky.  DEXX assists the Issuer in inserting their asset onto the DEXX platform but the Issuer is responsible for all information provided." />
                
                <div className="text-succ2 text-4xl mt-10 mb-10">Usage Section</div>
            
                <FaqQuestion content="Creating an account" />
                <FaqAnswer content="To access the platform for the first time you will create an account by providing your email address, a password and approval of our terms and conditions.  Tap on the “Create Account” tab on the Sign In dialogue box.  You will receive an email with a verification code within 5 minutes.  Enter this code into the “We Sent A Code” box on the screen or when you attempt to log in the next time by using the email and password you provided." />

                <FaqQuestion content="My Profile" />
                <FaqAnswer content="Sign in and tap on the My Profile menu option to enter the information requested. At the bottom select your preferred time - all transactions are stored in UTC time but you can convert those times to your preferred time zone using this selection.  When ready tap “Submit” to save your information.  The next screen asks you to either Edit your information, Begin the KYC process or Explore the platform and return to account setup later." />
                <FaqAnswer content="You must return and complete the KYC process before you may view or transact with the assets on the platform. The KYC process will take about 10 to 15 minutes. You may be asked to download forms to complete offline and sign and then upload to the KYC process once completed.  The exact format of the KYC will depend on your country of residence." />
                <FaqAnswer content="Once you have created an account and submitted a KYC the information will be reviewed and you will be notified when the KYC has been accepted. The information gathered here is based on government regulations.  Once the KYC is reviewed and accepted you will be granted Investor status on the platform. This allows you to view assets, fund a wallet and invest in primary and secondary token sales, liquidity pools and make withdraws." />

                <FaqQuestion content="My Investor Portfolio" />
                <FaqAnswer content="Once you are an approved Investor the My Portfolio menu option will become available to you. Tap on My Portfolio to show your holdings overview. Your portfolio total value is on the left side. Below your portfolio balance is the Deposit / Withdraw button.  Tap it to add or withdraw funds from the platform." />
                <FaqAnswer content="Your portfolio value is made up of the assets on the right side of the My Portfolio screen;  tokens, liquidity pool holdings and FIMART Euro Voucher.  Currency here is the DEXX stored value coin which is pegged 1:1 to the Euro. When you deposit 100 Euro, you will see 100 Euro in Currency once the deposit posts to our platform." />
                <FaqAnswer content="Below the summary data on the right are four tappable rows each containing details within which are downloadable as a CSV file. " />
                <ul className="text-tertiary text-xl mt-3">
                    <li><i>Asset / Security Tokens</i> link contains your purchased security token assets.  When you tap on the link a single row for each token will be displayed.  At the right side of the screen is a Details button which when tapped displays information on the token or asset as provided by the Issuer.</li>
                    {/*<li><i>Liquidity Pool Positions</i> are viewable by tapping the link. (Hide this until LPs are available)</li>*/}
                    <li>The <i>Transaction History</i> link will reveal a list of your transactions including the hash associated with the blockchain transaction.</li>
                    <li><i>Bank Transaction History</i> link will show your wallet funding and withdrawal activity.</li>
                </ul>


                <FaqQuestion content="My Issuer Dashboard" />
                <FaqAnswer content="A similar process is followed if you are an issuer of securities." />

                <FaqQuestion content="Invest" />
                <FaqAnswer content="The Invest menu option presents all the token offerings available for purchase. You can filter the tiles by selecting Art (fina art), Sportex (sport & equestrian) or AIF (alternative investment funds) to filter a single asset class. Tapping on a tile brings you to the detailed asset page which contains the information needed to make an investment decision. Tapping BUY opens a popup box allowing you to enter the quantity desired and see the extended price with fees. Your available FIMART Euro Voucher balance is in the top right hand corner. Tapping Confirm submits the purchase transaction." />

                <FaqQuestion content="Funding your account" />
                <FaqAnswer content="Tapping the Deposit / Withdraw button on the My Portfolio page opens a popup with both a Deposit and a Withdraw tab." />

                <FaqQuestion content="Depositing Funds" />
                <FaqAnswer content="Tapping the Deposit tab displays the DEXX banking information and a Unique Code you will need to wire funds to our bank; BANCO BPM Bank located in Mantova, Italy. It is important to use your name exactly as it appears in your profile due to regulatory requirements.  Also include the Unique Code in the wire message which enables us to match the funds to your DEXX account. Once the wire arrives we will match the net funds received and credit the proper number of DEXX coins to your platform wallet." />

                <FaqQuestion content="Withdrawing Funds" />
                <FaqAnswer content="Tap on the Withdraw tab and enter the information requested. Tap the confirm button 
                    to execute the withdrawal. Funds will be wired to your bank account less all wire transfer fees. 
                    You will receive 1 Euro for each 1 Fimart Euro Voucher (XEUR) transferred less wire fees." />

                <div className="mb-20"></div>
            </div>
        </div>
        <div className="flex justify-center mb-2    ">
            <BackButton />
        </div>
    </>
}