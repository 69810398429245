import ReactApexChart from 'react-apexcharts'

export interface ChartData {
  close: string,
  high: string,
  low: string,
  open: string,
  volume: string,
  reference_date: string
}
type Xaxis = 'datetime' | 'numeric' | 'category' | undefined

function addLeadingZeros(num: string | number): string {
  let n = "" + num
  return n.length > 1 ? n : "0" + num
}

function getDayMonthYear(date: Date) {
  return date.getFullYear() + "-" +
    addLeadingZeros(date.getMonth() + 1) + "-" +
    addLeadingZeros(date.getDate())
}

function lastDayIsToday(serie: any[]): boolean {
  let today = getDayMonthYear(new Date())
  let lastDay = serie[serie.length - 1].x.substring(0, 10)
  return today === lastDay

}

function addToday(serie: any[]) {
  let today = getDayMonthYear(new Date())
  if (lastDayIsToday(serie))
    return serie
  serie.push({
    x: today,
    y: serie[serie.length - 1].y
  })
  return serie
}

function fillGaps(serie: any[], propagate: boolean): any[] {
  if (serie.length < 1)
    return serie
  let isToday = lastDayIsToday(serie)
  let newSerie = []
  let updatedSerie = addToday(serie)
  const oneDay = 24 * 60 * 60 * 1000
  for (let i = 0; i < updatedSerie.length - 1; i++) {
    let firstDate = new Date(updatedSerie[i].x)
    let secondDate = new Date(updatedSerie[i + 1].x)
    const diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay));
    newSerie.push({ x: updatedSerie[i].x.substring(0, 10), y: updatedSerie[i].y })
    for (let j = 1; j < diffDays; j++) {
      let nextDay = new Date(firstDate)

      nextDay.setDate(firstDate.getDate() + j)
      newSerie.push({
        x: getDayMonthYear(nextDay),
        y: propagate ? updatedSerie[i].y : 0
      })
    }
  }
  newSerie.push({
    x: updatedSerie[updatedSerie.length - 1].x.substring(0, 10),
    y: (isToday || propagate) ? updatedSerie[updatedSerie.length - 1].y : 0
  })

  return newSerie
}

function fillGapsCandle(serie: any[]): any[] {
  if (serie.length < 1)
    return serie
  let newSerie = []
  let updatedSerie = addToday(serie)
  const oneDay = 24 * 60 * 60 * 1000
  for (let i = 0; i < updatedSerie.length - 1; i++) {
    let firstDate = new Date(updatedSerie[i].x)
    let secondDate = new Date(updatedSerie[i + 1].x)
    const diffDays = Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay));
    newSerie.push({ x: updatedSerie[i].x.substring(0, 10), y: updatedSerie[i].y })
    for (let j = 1; j < diffDays; j++) {
      let nextDay = new Date(firstDate)

      nextDay.setDate(firstDate.getDate() + j)
      newSerie.push({
        x: getDayMonthYear(nextDay),
        y: [updatedSerie[i].y[3], updatedSerie[i].y[3], updatedSerie[i].y[3], updatedSerie[i].y[3]]
      })
    }
  }
  newSerie.push({
    x: updatedSerie[updatedSerie.length - 1].x.substring(0, 10),
    y: updatedSerie[updatedSerie.length - 1].y
  })
  return newSerie
}

export default function DexxChart(props: { serie: ChartData[] }): JSX.Element {
  const serie = [{
    name: "series",
    type: "line",
    data: fillGaps(props.serie.sort((a, b) =>
      Date.parse(a.reference_date) > Date.parse(b.reference_date) ? 1 : -1)
      .map((i) => {
        return { x: i.reference_date.substring(0, 10), y: i.close }
      }), true)
  }]
  const volumeSerie = {
    name: "seriesv",
    type: "bar",
    data: fillGaps(props.serie.sort((a, b) =>
      Date.parse(a.reference_date) > Date.parse(b.reference_date) ? 1 : -1)
      .map((i) => {
        return { x: i.reference_date.substring(0, 10), y: i.volume }
      }), false)
  }
  const candleSerie = fillGapsCandle(props.serie.sort((a, b) =>
    Date.parse(a.reference_date) > Date.parse(b.reference_date) ? 1 : -1)
    .map((i) => {
      return {
        x: i.reference_date.substring(0, 10),
        y: [i.open, i.high, i.low, i.close]
      }
    }))

  let maxDate = new Date().getTime()
  let minDate = maxDate - (86400000 * 7) //7 days before

  const optionsArea = {
    chart: {
      id: "chart1",
      brush: {
        target: 'chart3',
        enabled: true
      },
      selection: {
        enabled: true,
        xaxis: {
          max: maxDate,
          min: minDate,
        },
        fill: {
          color: "#ccc",
          opacity: 0.4
        }
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '100%',
        colors: {
          ranges: [{
            from: -1000,
            to: 0,
            color: '#FFCF74'
          }, {
            from: 1,
            to: 10000,
            color: '#4494CE'
          }],

        }
      }
    },
    colors: ["#4494CE", '#AC4BCE'],
    stroke: {
      width: [1, 3]
    },
    labels: volumeSerie.data.map((i: any) => i.x),
    fill: {
      type: 'solid',
      opacity: [0.35, 1],
    },
    yaxis: [{
      title: {
        text: 'Volume',
      },

    }, {
      opposite: true,
      title: {
        text: 'Close'
      }
    }],
    xaxis: {
      type: 'datetime' as Xaxis,
      axisBorder: {
        offsetX: 13
      }
    },
  }
  const optionsCandle = {
    chart: {
      id: "chart3",
      toolbar: {
        autoSelected: 'pan' as "pan",
        show: false
      },
      zoom: {
        enabled: false
      },
    },
    plotOptions: {
      candlestick: {
        colors: {
          upward: '#7ADFAE',
          downward: '#E95C7B'
        }
      }
    },
    stroke: {
      width: 2
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1,
    },
    markers: {
      colors: ['#6772CD']
    },
    yaxis: {
    },
    xaxis: {
      type: 'datetime' as Xaxis,
    },

  }

  return <>
    {props.serie.length > 0 ? <>
      <div className="bg-white p-5  border-solid  justify-center flex-col
                    border-3 border-succ1">
        <div className=' w-full justify-center'>
          <ReactApexChart options={optionsCandle}
            series={[{
              name: "OHCL",
              data: candleSerie ? candleSerie : []
            }]}
            type='candlestick'
            height="300" />
        </div>
        <div className=' w-full justify-center'>
          <ReactApexChart options={optionsArea}
            series={[{
              name: 'Volume',
              type: 'bar',
              data: volumeSerie.data.map((i: any) => i.y)
            }, {
              name: 'Close',
              type: 'line',
              data: serie[0].data.map((i: any) => i.y)
            }]}
            type='line'
            height="300" />
        </div>
      </div>
    </> :
      <div className='text-white h-64 flex place-content-center pt-10'>
        No data available
      </div>
    }
  </>
}