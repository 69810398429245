import { bindActionCreators } from "@reduxjs/toolkit"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { BackButton, DexxButton } from "../components/light/LightButton"
import { defaultAssetInfo, defaultPrimarySaleGet, defaultSecurityToken, LiquidityPool } from "../PoolContext"
import { actionCreators, State } from "../state"
import ArtTokenBody from "../components/tokenBody/ArtTokenBody";
import AifTokenBody from "../components/tokenBody/AifTokenBody";
import HorseTokenBody from "../components/tokenBody/HorseTokenBody";
import CarsTokenBody from "../components/tokenBody/CarsTokenBody";
import PrimarySaleDescriptionTable from "../components/PrimarySaleDescriptionTable";
import BuyPSInterface from "../components/BuyPSInterface";
import { BigTitle, TabTitle } from "../components/typography/Typogtaphy"
import Popup, { DexxPopup } from "../components/Popup"
import { Countdown } from "../components/utils/Countdown"
import { Helmet } from "react-helmet"
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default function PrimarySaleRoute(props: any): JSX.Element {

    const uuid = props.match.params.uuid
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [asset, setAsset] = useState(defaultAssetInfo)
    const [token, setToken] = useState(defaultSecurityToken)
    const [primarySaleDetails, setPSDetails] = useState(defaultPrimarySaleGet)
    const [media, setMedia] = useState("")
    const [showPopUp, setShowPopUp] = useState<boolean>(false)

    const basicAccountState = useSelector((state: State) => state.basicAccount)
    const basicInfo = basicAccountState.payload
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload
    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0
    const [isAmericanAndSportex, setIsAmericanAndSportex] = useState<boolean>(false)
    const [allTokenLiquidityPools, setAllTokenLiquidityPools] = useState<LiquidityPool[]>([])
    const [lpCount, setLpCount] = useState<number>(0)
    //const [isLogged, setIsLogged] = useState<boolean>(false)
    const isLogged = cookies.get("logged_in")

    const REFRESH_PERIOD = 20000; //20 SEC

    useEffect(() => {
        /*if(basicInfo && basicInfo.email !== ""){
            setIsLogged(true)
        }*/
        if(uuid !== 0 && uuid !== "0")
            actions.getPrimarySale("uuid="+uuid, setPSDetails)
    }, [basicInfo, uuid, isLogged])

    useEffect(() => {
        const interval = setInterval(() => {
          actions.getPrimarySale("uuid="+uuid, setPSDetails)
        }, REFRESH_PERIOD);
        return () => clearInterval(interval)
      }, [])

    useEffect(() => {
        if (primarySaleDetails.uuid !== '') {
            actions.getAssetInfo(primarySaleDetails.asset_uuid, setAsset, isLogged)
            actions.getTokenInfo(primarySaleDetails.asset_uuid, setToken, isLogged)
            actions.getAssetMedia(primarySaleDetails.asset_uuid, setMedia, isLogged)
            actions.getAllLiquidityPoolRequests(setAllTokenLiquidityPools, {"token_a_uuid":primarySaleDetails.token_uuid})
        }
    }, [primarySaleDetails])

    useEffect(() => {
        if(isLogged){
            if(allTokenLiquidityPools){
                var filteredLP = allTokenLiquidityPools.filter((lp: any) => lp.token_a_uuid === primarySaleDetails.token_uuid && lp.status_name === "Approved")
                setLpCount(filteredLP.length)
            }
        }
    }, [allTokenLiquidityPools])

    

    useEffect(() => {
        if(userCtx && primarySaleDetails) 
            setIsAmericanAndSportex(userCtx?.tax_residence === "United States" && primarySaleDetails.asset_category === "SPORTEX" ? true : false)
    }, [primarySaleDetails, userCtx])

    if (!primarySaleDetails.uuid || primarySaleDetails.uuid === "0") {
        <div className="grid justify-center text-white text-4xl font-urbanist">Asset not found</div>
    }

    const psDetailsRows = { primary_sales: [primarySaleDetails] }
    var tokenBody: JSX.Element = <></>
    switch (primarySaleDetails.asset_category) {
        case "ART":
            tokenBody = <ArtTokenBody
                                origin={"1m"} lpCount={lpCount}
                                assetInfo={asset} tokenInfo={token} 
                                media={media} tokenSymbol={token.ticker} completeView={true}
                                psUuid={uuid} isLogged={isLogged} />
            break
        case "AIF":
            tokenBody = <AifTokenBody 
                                origin={"1m"} lpCount={lpCount}
                                assetInfo={asset} tokenInfo={token} 
                                media={media} tokenSymbol={token.ticker} completeView={true}
                                psUuid={uuid} isLogged={isLogged} />
            break
        case "SPORTEX":
            tokenBody = <HorseTokenBody 
                                origin={"1m"} lpCount={lpCount}
                                assetInfo={asset} tokenInfo={token} 
                                media={media} completeView={true} 
                                psUuid={uuid} isLogged={isLogged}/>
            break
        case "COLLECTABLES":
            tokenBody = <CarsTokenBody 
                                origin={"1m"} lpCount={lpCount}
                                assetInfo={asset} tokenInfo={token} 
                                media={media} tokenSymbol={token.ticker} completeView={true}
                                psUuid={uuid} isLogged={isLogged}/>
            break
        default:
            tokenBody = <></>

    }
    const body = useMemo(() => (tokenBody), [primarySaleDetails, token])

    let cdStart = new Countdown(new Date(primarySaleDetails.date_start), "Started")
    let cdEnds = new Countdown(new Date(primarySaleDetails.date_end), "Ended")

    const [cdS, setCdS] = useState(cdStart.getCountdown())
    const [cdE, setCdE] = useState(cdEnds.getCountdown())

    useEffect(() => {
        const interval = setInterval(() => {
            setCdS(cdStart.getCountdown())
            setCdE(cdEnds.getCountdown())
          }, 1000);
        
          return () => clearInterval(interval);
        
    }, [cdEnds, cdStart])
    
    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content={"Primary Sale - Asset name: "+(primarySaleDetails.asset_name )
                +" - Ticker: "+(primarySaleDetails.token_ticker)}/>
            <title>Primary Sale</title>
        </Helmet>
        {
            (!basicInfo?.is_investor && !basicInfo?.kyc_submitted) ? (
                <Popup show={showPopUp} title={"Sorry!"} msg={"Please complete the KYC in order to proceed with the purchase"} btnOk={true} close={() => {setShowPopUp(false)}} />
            ) : isAmericanAndSportex ? (
                <Popup show={showPopUp} title={"Sorry!"} msg={"US citizens are not allowed to buy this token. Take a look at Investment Restrictions for more details."} btnOk={true} close={() => {setShowPopUp(false)}} />
            ) : (
                <DexxPopup show={showPopUp} title={"Buy From Primary Sale"} btnOk={false} close={() => {setShowPopUp(false)}}
                    msg={<BuyPSInterface  asset={asset} token={token} primary_sale={primarySaleDetails} 
                            closeFunction={() => setShowPopUp(false)}/>} />
            )
        }

        <div className="font-roboto">
            <TabTitle className="flex place-content-center">{primarySaleDetails.asset_category}</TabTitle>
            <BigTitle title={primarySaleDetails.asset_name} />
            <div className="flex flex-wrap w-full justify-between px-10 mb-5">
                <div>
                    <DexxButton label={"Sell"} className="rounded-full px-16 py-2 font-bold text-xl bg-succ2 opacity-25 " 
                        color1="succ2" color2="primary"/>
                </div>
                
                <div>
                    <div className="flex text-lg text-tertiary gap-4 justify-between">
                        <div className="uppercase font-bold">Starts:</div>
                        <div>{cdS}</div>
                    </div>
                    <div className="flex text-lg text-tertiary gap-4 justify-between">
                        <div className="uppercase font-bold">Ends:</div>
                        <div>{cdE}</div>
                    </div>
                </div>
                
                <div>
                    {!isLogged || primarySaleDetails.status !== "Started" ?
                        <DexxButton label={"Buy"} className="rounded-full px-16 py-2 font-bold text-xl bg-succ2 opacity-25" 
                            color1="succ2" color2="primary"/> :
                        <DexxButton label={"Buy"} className="rounded-full px-16 py-2 font-bold text-xl bg-succ2 " 
                            color1="succ2" color2="primary" onClick={() => setShowPopUp(!showPopUp)}/>}
                </div>
            </div>
            <div className="">
                <PrimarySaleDescriptionTable primarySalesList={psDetailsRows} timezone={offset}/>
            </div>
            <div className="grid pb-10">
                {body}
            </div>
            <div className="flex justify-center mb-5">
                <BackButton />
            </div>
        </div>
    </>)
}
