import { Link } from 'react-router-dom'
import image from '../media/Logo-dexx.png'
import { useAuthenticator } from '@aws-amplify/ui-react'
import { useHistory } from 'react-router'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function Navbar(props: {handleSignOutInvestor: any, showAssets: boolean, isBasicOrLogOut?: boolean}): JSX.Element {

    const { user, signOut } = useAuthenticator((context) => [context.user])
    const history = useHistory()
    const isMenuOpen = window.document.location.pathname === "/menu"
    
    function logOut() {
        props.handleSignOutInvestor()
        cookies.remove("logged_in", {path: "/", sameSite: "strict"})
        cookies.remove("username", {path: "/", sameSite: "strict"})
        cookies.remove("user_email", {path: "/", sameSite: "strict"})
        cookies.remove("token_id", {path: "/", sameSite: "strict"})
        cookies.remove("new_user", {path: "/", sameSite: "strict"})
        cookies.remove("session_expired", {path: "/", sameSite: "strict"})
        sessionStorage.clear()
        signOut()
        history.push("/account/signin")
    }

    return (
        <div className='flex justify-between flex-wrap lg:grid lg:grid-cols-3'>
            <div className=''>
                <Link className="self-center" to="/">
                    <div>
                        <img className="h-16 md:h-24 xl:h-24 p-1" src={image} alt="dexx"/>
                    </div>
                </Link>
            </div>
            {cookies.get("logged_in") ? <>
                    <Link to="/menu" className="items-center flex flex-col 
                        place-content-center">
                    <div className='text-center items-center flex flex-col text-tertiary uppercase 
                        place-content-center font-roboto font-medium md:text-lg'>
                        <span className={"h-px w-13 bg-current cursor-pointer animate-pulse "+ 
                            (isMenuOpen ? "" : " invisible ")+ ""}/>
                        <div className=' hover:h-2 cursor-pointer '>menu</div>
                        <span className={"h-px w-13 bg-current cursor-pointer animate-pulse "+ 
                            (isMenuOpen ? "" : " invisible ")+ ""}/>
                    </div>
                    </Link>
                    <div className='flex pr-4 justify-end'>
                        <Link to="/account/signin" className="flex self-center">
                            <button className='bg-transparent border-solid border-1 border-tertiary
                            rounded text-tertiary uppercase font-roboto font-bold
                            tracking-widest md:w-36 px-2 h-8 hover:bg-tertiary hover:text-secondary
                            cursor-pointer md:text-sm text-xs' onClick={() => {logOut()}}>
                            log out</button>
                        </Link>
                    </div>
                    {/*<div className='flex align-middle items-center justify-end cursor-pointer'>
                        <div className='flex align-middle items-center' onClick={() => {logOut()}}>
                            <div className='bg-succ1 w-8 h-8 rounded-full mx-1'></div>
                            <img src={icon} className="h-6 mr-5"/>
                        </div>
                    </div>*/}
                </> : <>
                    <div className='flex content-center pr-4 lg:justify-end lg:col-span-2'>
                        <Link to="/account/signin" className="self-center">
                            <button className='bg-transparent border-solid border-1 border-tertiary
                            rounded text-tertiary uppercase font-roboto font-bold
                            tracking-widest md:w-36 px-2 h-8 hover:bg-tertiary hover:text-secondary
                            cursor-pointer md:text-sm text-xs'>
                            log in</button>
                        </Link>
                    </div>
                </>}
        </div>
    )
}