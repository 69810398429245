import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { BigTitle } from "../components/typography/Typogtaphy";
import { CardsCollectionEarnLiquidityPool } from "../components/utils/CardsCollection";
import { actionCreators } from "../state";
import { BackButton } from "../components/light/LightButton";
import { TabTitle } from "../components/typography/Typogtaphy";

import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default function EarnRoute(props: any): JSX.Element {

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [lpList, setLpList] = useState([])
    const [shownList, setShownList] = useState<any[]>([])
    const [hideCards, setHideCards] = useState<number[]>([])
    const [activeTab, setActiveTab] = useState<boolean[]>([true, false, false, false, false])
    const [category, setCategory] = useState<string>("All")
    const [categoryID, setCategoryID] = useState<number>(0)
    
    //const [isLogged, setIsLogged] = useState<boolean>(false)
    const isLogged = cookies.get("logged_in")
    
    useEffect(() => {
        actions.getAllLiquidityPools(setLpList)
    }, [])

    function changeView(category: string, id: number) {
        setCategory(category)
        setCategoryID(id)
        let tmp = category === "All" ? [] : (    
            lpList.map((lp: any, i) => {
                return lp.asset_a_category !== category ? i : -1})
            )
        tmp.filter((n) => n !== -1)
        setHideCards(tmp)
        var tmp2 = activeTab
        tmp2 = tmp2.map((_, i) => {return (i===id ? true : false)})
        setActiveTab(tmp2)
    }

    function Tab(props: {category: string, id: number}): JSX.Element {
        return <>
            <div onClick={() => {changeView(props.category, props.id)}} 
                className={"cursor-pointer mx-1 md:text-3xl " + 
                (activeTab[props.id] ? "text-succ1" : "")}>
                {props.category}</div>
        </>
    }

    return <>
        <div className="h-full">
            <div className="w-full px-10 pb-2">
                <BigTitle title={"Earn"} />
            </div>
            <TabTitle className="flex place-content-center mt-8 gap-2 z-10 flex-wrap
                            mx-neg80 md:mb-6 mb-neg40">
                        <Tab category="All" id={0}/>
                        <Tab category="ART" id={1}/>
                        <Tab category="SPORTEX" id={2}/>
                        {isLogged ? (<Tab category="AIF" id={3}/>) : <></>}
                        <Tab category="COLLECTABLES" id={4}/>
                    </TabTitle>
            <div>
                <CardsCollectionEarnLiquidityPool lpList={lpList} hide={hideCards} categoryName={category} />
            </div>
            <div className="flex justify-center mt-5">
                <BackButton />
            </div>
        </div>
        
    </>
}