import { useEffect, useState } from "react"
import DexxChart, { ChartData } from "../../utils/DexxChart"
import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { actionCreators } from "../../../state"

function SetRangeButton(props: { onChange: Function, label: string }): JSX.Element {
    return <div
        onClick={() => props.onChange()}
        className={"border-solid border-succ1 border-3 " +
            " bg-white rounded-md px-2 py-1 cursor-pointer"}>
        {props.label}
    </div>
}

export default function SecondaryMarketStatistics(props: { tokenUuid: string }): JSX.Element {
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [daysRange, setDaysRange] = useState<string>("30")
    const [tradeChartData, setTradeChartData] = useState<ChartData[]>([])

    useEffect(() => {
        if (props.tokenUuid) {
            actions.getTradeMetricsToken(props.tokenUuid, daysRange, setTradeChartData)
        }
    }, [daysRange])

    return <>
        <div className="grid grid-cols-6 mx-20">
            <div></div>
            <div className="col-span-4 flex-col flex justify-center center">
                <DexxChart serie={tradeChartData ? tradeChartData.slice(0) : []} />
                {(tradeChartData && tradeChartData.length > 0) ?
                    <div className="uppercase text-primary flex justify-center
                            text-sm mt-2 gap-2">
                        <SetRangeButton onChange={() => { setDaysRange("7") }}
                            label={"1 week"} />
                        <SetRangeButton onChange={() => { setDaysRange("30") }}
                            label={"1 month"} />
                        <SetRangeButton onChange={() => { setDaysRange("365") }}
                            label={"1 year"} />
                    </div> : <></>}
            </div>
            <div></div>
        </div>
    </>
}