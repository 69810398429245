import { useContext, useEffect, useState} from "react"
import { UserContext, defaultUserState, UserAccount } from '../UserContext'
import { useHistory  } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from "redux";
import { actionCreators, State } from "../state";
import LabelAndInput from "../components/LabelAndInput"
import { DexxButton, LightButton } from "../components/light/LightButton"
import Popup from '../components/Popup'
import {formValidationClearErrors, formValidationCheckObject, removeTimezoneOffset} from '../utils'
import { PageDescription } from "../components/typography/Typogtaphy";
import { ITimezoneOption } from "react-timezone-select";
import Cookies from 'universal-cookie';
import imgRefresh from '../media/refresh.png'
import { DexxCheckbox } from "./Profile.route";

export default function AdditionalInfoRoute(): JSX.Element {

    const cookies = new Cookies();
    let history = useHistory()

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    //const actionEdit : boolean = history.location.state !== undefined && history.location.state.action !== undefined && history.location.state.action === "edit"
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const [user, setUser] = useState<UserAccount>(defaultUserState)

    // Fields for registration:
    const[firstName, setFirstName] = useState<string>("")
    const[middleName, setMiddleName] = useState<string>("")
    const[lastName, setLastName] = useState<string>("")
    const[streetNumber, setStreetNumber] = useState<string>("")
    const[streetName, setStreetName] = useState<string>("")
    const[birthDate, setBirthDate] = useState<string>("")
    const[telephone, setTelephone] = useState<string>("")
    const[city, setCity] = useState<string>("")
    const[postalCode, setPostalCode] = useState<string>("")
    const[countryN, setCountry] = useState<string>("")
    const[taxResidence, setTaxResidence] = useState<string>("")
    const [timezone, setTimezone] = useState<ITimezoneOption>(
        {value: Intl.DateTimeFormat().resolvedOptions().timeZone, label: "", offset: 0})

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    const [success, setSuccess] = useState<boolean>(false)
    const userCtx = useContext(UserContext)

    //const userCtx = detailAccountState.payload

    const [responseAccountDetail, setResponseAccountDetail] = useState<any[]>([0, undefined])
    const [submitted, setSubmitted] = useState(false)
    const [proInvestor, setProInvestor] = useState<boolean>(false)

    const allCountries = ["", "Albania", "Algeria", "Andorra", "Antigua and Barbuda", "Argentina", "Armenia", 
                        "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", 
                        "Belarus", "Belgium", "Belize", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", 
                        "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", 
                        "Cameroon", "Canada", "Central African Republic", "Chad", "Chile", "China", "Colombia", 
                        "Comoros", "Congo", "Costa Rica", "Côte d'Ivoire", "Croatia", "Cuba", "Cyprus", 
                        "Czech Republic (Czechia)", "Denmark", "Djibouti", "Dominica", "Dominican Republic", 
                        "DR Congo", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", 
                        "Eswatini", "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", 
                        "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", 
                        "Holy See", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iraq", "Ireland", 
                        "Israel", "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kuwait", 
                        "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", 
                        "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", 
                        "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", 
                        "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", 
                        "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", 
                        "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", 
                        "Poland", "Portugal", "Qatar", "Romania", "Rwanda", "Saint Kitts & Nevis", "Saint Lucia", 
                        "Samoa", "San Marino", "Sao Tome & Principe", "Saudi Arabia", "Senegal", "Serbia", 
                        "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", 
                        "Somalia", "South Africa", "South Korea", "South Sudan", "Spain", "Sri Lanka", 
                        "St. Vincent & Grenadines", "State of Palestine", "Sudan", "Suriname", "Sweden", 
                        "Switzerland", "Syria", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo", 
                        "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", 
                        "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "Uruguay", "Uzbekistan", 
                        "Vanuatu", "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"]    
    //const [submitted, setSubmitted] = useState<boolean>(false)
    
    function handleClosePopUp(){
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUp(false)
        if(success) {
            setSuccess(false)
            actions.getBasicAccountProfile()
            actions.getAccountDetails()
            history.push('/account/almost-done')
        }      
    }
    
    function changeIfExists(field: string | undefined | null, cookieField: string | null): string {
        return field ? field : (cookieField ? cookieField : "")
    }

    useEffect(() => {
        let cookieUser = cookies.get("new_user")
        if(detailAccountState.payload || cookieUser) {
            setFirstName(changeIfExists(userCtx?.first_name, cookieUser?.first_name))
            setMiddleName(changeIfExists(userCtx?.middle_name, cookieUser?.middle_name))
            setLastName(changeIfExists(userCtx?.last_name, cookieUser?.last_name))
            setBirthDate(changeIfExists(userCtx?.date_of_birth, cookieUser?.date_of_birth))
            setTelephone(changeIfExists(userCtx?.telephone, cookieUser?.telephone))
            setStreetName(changeIfExists(userCtx?.street_name, cookieUser?.street_name))
            setStreetNumber(changeIfExists(userCtx?.street_number, cookieUser?.street_number))
            setCountry(changeIfExists(userCtx?.country, cookieUser?.country))
            setCity(changeIfExists(userCtx?.city, cookieUser?.city))
            setPostalCode(changeIfExists(userCtx?.postal_code, cookieUser?.postal_code))
            setTaxResidence(changeIfExists(userCtx?.tax_residence, cookieUser?.tax_residence))
            setTimezone((userCtx?.timezone_value ? 
                {value: userCtx.timezone_value, offset: userCtx.timezone_offset, label: ""} : 
                (cookieUser?.timezone_value ? 
                    {value: cookieUser.timezone_value, offset: cookieUser.timezone_offset, label: ""}:
                    {value: Intl.DateTimeFormat().resolvedOptions().timeZone, offset: 0, label: ""})
                ))
            setProInvestor((userCtx.is_pro_investor ? userCtx.is_pro_investor : false))    
            
        }

    }, [detailAccountState.payload])

    /*useEffect(() => {
        if (detailAccountState.payload) {
                if (detailAccountState.type === 'set' && JSON.stringify(detailAccountState.payload) === '[200,204]') {
                    actions.getAccountDetails()
                    history.push('/account/almost-done')
                } else if(detailAccountState.type === 'set') {
                    setTitlePopUp("Error")
                    setMsgPopUp(detailAccountState.payload.length > 0 ? detailAccountState.payload[1].error : "An error occurred")
                    setShowPopUp(true)
                }
            if(detailAccountState.type === 'get'){
                var entireUser = {...user, ...detailAccountState.payload};
                setUser(entireUser)
            }
        }
    }, [detailAccountState.payload])*/

    function handleEditProfile(e: React.MouseEvent) {
        formValidationClearErrors()  
        const newUser = {
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
            street_name: streetName,
            street_number: streetNumber,
            date_of_birth: removeTimezoneOffset(birthDate, timezone.offset ? timezone.offset : 0),
            telephone: telephone !== '' && telephone.substring(0, 1) !== '+' ? '+'+telephone : telephone,
            city: city,
            postal_code: postalCode,
            country: countryN,
            tax_residence: taxResidence,
            is_issuer: false,
            is_investor: false,
            is_employee: false,
            timezone_value: timezone.value,
            timezone_offset: timezone.offset
        }
        cookies.set("new_user", newUser, {path: "/", sameSite: "strict"})
        let count = formValidationCheckObject(newUser)
        if(count === 0){
            setSubmitted(true)
            actions.updateAccountDetails({
                account_details: newUser,
                pro_investor: proInvestor
            }, setResponseAccountDetail)
            
        } else {
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please complete the highlighted fields.")
            setShowPopUp(true)
        }
    }

    useEffect(() => {
        if(responseAccountDetail[0] !== 0){
            if(responseAccountDetail[0] === 200 && submitted){
                setSubmitted(false)
                setSuccess(true)
                setTitlePopUp("Success")
                setMsgPopUp('Your data has been saved.')
                setShowPopUp(true)
            } else if(submitted){
                setSubmitted(false)
                setTitlePopUp("Sorry!")
                /*if(responseAccountDetail[1] && responseAccountDetail[1].error) {
                    setMsgPopUp(responseAccountDetail[1].error)
                }*/
                setMsgPopUp("We encountered an error saving your data.")
                
                setShowPopUp(true)
            }
        }

    }, [responseAccountDetail, submitted])

    return (<>
            <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp} ></Popup>
            <div className="grid xl:grid-cols-4 gap-5 max-w-full">
                <div className="grid xl:justify-items-center xl:mt-40 md:mt-20 mt-10 text-center mb-5">
                    <div>
                        <PageDescription title={"Welcome!"} 
                            description={"Continue with your account creation or explore the platform"} />
                        {/*<img src={imgRefresh} className="h-4 mt-5 cursor-pointer" onClick={() => {window.location.reload()}}/>*/}
                        <div className="flex w-full mt-6 gap-5">
                            <DexxButton label={"Explore"} className="rounded-full px-5"
                                color2="warning" onClick={() => { history.push("/menu") }}/>
                            <DexxButton label={"save"} className="rounded-full px-5"
                                color2="succ2" onClick={handleEditProfile}/>
                        </div>
                    </div>
                    
                </div>
                <div className="xl:col-span-2 max-w-full">
                    <div className="md:grid md:grid-cols-3 max-w-full grid-cols-1">
                        <LabelAndInput id={"firstName"} label={"First"} value={firstName} 
                            fun={setFirstName} type={"text"} className={"md:pr-10"} required/> 
                        <LabelAndInput label={"Middle"} value={middleName} fun={setMiddleName} 
                            type={"text"} className={"md:pr-10"} />
                        <LabelAndInput id={"lastName"} label={"Last"} value={lastName} 
                            fun={setLastName} type={"text"} className={"md:pr-10"} required/>   
                        <div className="h-8 col-span-3"></div>
                        <div className="flex items-center gap-5 col-span-3">
                            <DexxCheckbox value={proInvestor} setValue={setProInvestor} id={"selfCertification"}/>
                            <label className="w-full text-tertiary text-sm py-2">
                                Self-certification of Professional Investor qualification
                            </label>
                        </div>
                        <div className="h-8 col-span-3"></div>
                        <LabelAndInput id={"taxResidence"} label={"Nationality"} value={taxResidence} fun={setTaxResidence} 
                            type={"select"} className="md:pr-10"
                            options={allCountries.map((v) => {return {value: v, text: v}})}  required/>
                        <LabelAndInput id={"dateOfBirth"} label={"Date of birth"} value={birthDate} fun={setBirthDate} 
                            type={"date"} className={"md:pr-10"} required/>
                        <LabelAndInput id={"country"} label={"Country of Residence"} value={countryN} 
                            fun={setCountry} type={"select"} className="md:pr-10"
                            options={allCountries.map((v) => {return {value: v, text: v}})}  required/>
                        <LabelAndInput id={"streetName"} label={"Street"} value={streetName} 
                            fun={setStreetName} type={"text"} className={"col-span-2 md:pr-10"} required/>
                        <LabelAndInput id={"streetNumber"} label={"Street number"} value={streetNumber} 
                            fun={setStreetNumber} type={"text"} className={"md:pr-10"} required/>
                        <LabelAndInput id={"city"} label="City" value={city} 
                            fun={setCity} type="text" className="md:pr-10" required/>
                        <LabelAndInput id={"postalCode"} label="Postal code" value={postalCode} 
                            fun={setPostalCode} type="text" className="md:pr-10" required />
                        <LabelAndInput id={"telephone"} label={"Mobile"} value={telephone} 
                            fun={setTelephone} type={"phone"} className={"md:pr-10 py-2"} required />
                        <LabelAndInput label={"Timezone"} value={timezone.value} 
                            fun={setTimezone} type={"timezone"} className="col-span-3 md:pr-10" />
                    </div>
                    <div className="flex w-full my-6 gap-5 place-content-center md:invisible">
                        <DexxButton label={"Explore"} className="rounded-full px-5"
                            color2="warning" onClick={() => { history.push("/menu") }}/>
                        <DexxButton label={"save"} className="rounded-full px-5"
                            color2="succ2" onClick={handleEditProfile}/>
                    </div>
                </div>
            </div>
    </>)
}
