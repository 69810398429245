import { LiquidityPool, DistinctToken } from "../../PoolContext";
import { DexxButton } from "../light/LightButton";
import { Link } from 'react-router-dom'
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import { OptimizedImage, SortedImages, sortImages } from "../../functions/images";

export function CardEarnLiquidityPool(props: {info: LiquidityPool, className: string}) : JSX.Element {

    let lp = props.info
    let categoryShort = lp.asset_a_category === "SPORTEX" ? "SPORT" : 
                        (lp.asset_a_category === "COLLECTABLES" ? "CLLCT" :
                        lp.asset_a_category)
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [assetInfo, setAssetInfo] = useState<any>()

    useEffect(() => {
        actions.getAssetInfo(lp.asset_a_uuid ? lp.asset_a_uuid : "", setAssetInfo)
    }, [])

    return <>
        <Link to={{pathname: "/token/" + lp.token_a_uuid + "/lp",
                                state: { from: "/earn"}}} >
            <div className={" m-1 bg-white1 font-roboto py-4 pr-6 w-96 "+props.className}>
                <div className="flex justify-between">
                    <div className={"font-medium uppercase text-succ7 ml-6"}>{"Liquidity Pool Name"}</div>
                    <div className="text-black font-medium text-sm ml-6 mb-2"><br/></div>
                </div>
                <div className="flex justify-between font-base text-xl ml-6 mb-6 h-7">
                    {lp.lp_name}</div>

                <div className="w-80 h-40 bg-gradient-to-r 
                            rounded-tr-full rounded-br-full flex realtive z-10 from-cards1 to-cards2 ">
                    <div className="text-7xl font-medium tracking-wider_plus self-center ml-6 
                        leading-11 ">
                        {categoryShort}</div>
                </div>    
                <div className="grid content-between h-72">
                    <div>
                        <div className="text-black font-medium text-medium ml-6 mt-6 mb-4">
                            {lp.token_a_ticker}
                        </div>
                        <div className="pl-6 text-3xl font-light leading-10 my-5 tracking-wide">
                            {assetInfo ? assetInfo.name : <br/>}
                        </div>
                        <div className="pl-6 font-medium  flex justify-between">
                            <div>
                                <div className="text-navy_blue">{lp.token_a_ticker}</div>
                                <div>{lp.amount_a}</div>
                            </div>
                            <div className="text-right">
                                <div className="text-navy_blue">{lp.token_b_ticker}</div>
                                <div>{lp.amount_b}</div>
                            </div>
                        </div>
                    </div>
                    <div className=" flex justify-between pl-6">
                        <div>
                            <div className="font-light tracking-wide">Expected Year Return</div>
                            <div className="font-medium tracking-wide">
                                {"0-250%"}</div>
                        </div>
                        <div>
                            <Link to={{pathname: "/token/" + lp.token_a_uuid + "/lp",
                                    state: { from: "/earn"}}} >
                                <DexxButton label={"FUND"} color1="succ1" color2="white"
                                    className=" px-10 rounded-full bg-succ1 font-medium tracking-wide"
                                    fontClasses="md:text-xl"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    </>
}

export function CardInvestLiquidityPool(props: {info: LiquidityPool}) : JSX.Element {  // DEPRECATED : was passing lp_uuid, now token_uuid is needed 
    
    let lp = props.info
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [tokenInfo, setTokenInfo] = useState<any>()
    const [media, setMedia] = useState<any>()
    const [cardImage, setCardImage] = useState<OptimizedImage>()
    const [cardImageURLs, setCardImageURLs] = useState<OptimizedImage>()
    const [onHoverClasses, setOnHoverClasses] = useState<string>(" from-cards1 to-cards2")
    const [onHoverImg, setOnHoverImg] = useState<string>("")

    useEffect(() => {
        actions.getTokenInfoByTokenUUID(lp.token_a_uuid, setTokenInfo)
    }, [])

    useEffect(() => {
        if(tokenInfo)
            actions.getAssetMedia(tokenInfo.asset_uuid, setMedia)
    }, [tokenInfo])

    useEffect(() => {
        if(media){
            let sortedImages: SortedImages = sortImages(media.filter((m: any) => m.media_type_name === "Image"))
            if(sortedImages.sorted_images.length > 0)
                setCardImage(sortedImages.sorted_images[0])
            else if(sortedImages.others.length > 0)
                setCardImage({filename_big: sortedImages.others[0],
                    filename_small: "", name: sortedImages.others[0], 
                    url_big: "", url_small: ""})
            else
                setCardImage(undefined)
            actions.getImagesURL(cardImage, setCardImageURLs)
        }
    }, [media])

    useEffect(() => {
        if(cardImage)
            actions.getImagesURL(cardImage, setCardImageURLs)
    }, [cardImage])

    let categoryShort = tokenInfo?.asset_class === "SPORTEX" ? "SPORT" : 
                        (tokenInfo?.token_category === "COLLECTABLES" ? "CLLCT" :
                        tokenInfo?.asset_class)
    
    return <>
        <Link to={"/token/" + lp.uuid} >
            <div className={" m-1 bg-white1 font-roboto py-4 pr-6 w-96"}
                onMouseEnter={() => {
                    setOnHoverClasses(" from-cards3 to-cards4")
                    setOnHoverImg(" invisible")}}
                onMouseLeave={() => {
                    setOnHoverClasses(" from-cards1 to-cards2")
                    setOnHoverImg(" ")}}>
                <div className="flex justify-between ml-6 mb-2">
                    <div className="text-black font-medium text-sm mb-2">{tokenInfo?.asset_class}</div>
                    <div className={"font-medium uppercase text-succ7"}>{"Liquidity Pool"}</div>
                </div>
                <div className="flex justify-between font-semibold text-xl ml-6 mb-6 h-7"></div>
                {cardImageURLs ? <>
                    <div className="w-80 h-40 rounded-tr-full rounded-br-full
                            align-middle z-10 relative object-cover overflow-hidden">
                        <LazyLoadImage src={cardImageURLs.url_big} className="w-80 h-40 rounded-tr-full 
                            rounded-br-full align-middle z-10 relative object-cover" 
                            placeholderSrc={cardImageURLs.url_small} effect="blur" threshold={1000}/>
                    </div>
                    <div className={"w-80 h-40 rounded-tr-full rounded-br-full mt-neg160 z-20 relative "+
                            " bg-cards3_light flex "+onHoverImg}>
                                <div className="text-7xl font-medium tracking-wider_plus self-center ml-6 
                                    leading-11 ">
                                    {categoryShort}</div>
                    </div>
                    </>
                    : 
                    <div className={"w-80 h-40 bg-gradient-to-r "+
                            "rounded-tr-full rounded-br-full flex realtive z-10 "+onHoverClasses}>
                        <div className="text-7xl font-medium tracking-wider_plus self-center ml-6 
                            leading-11 ">
                            {categoryShort}</div>
                    </div>    
                }
                <div className="h-72 content-between grid">
                    <div>
                        <div className="text-black font-medium text-medium ml-6 mt-6 mb-4">
                            {lp.token_a_ticker}
                        </div>
                        <div className="pl-6 text-3xl font-light leading-10 my-5 tracking-wide">
                            {tokenInfo?.asset_name}
                        </div>
                    </div>
                    <div className=" flex justify-between pl-6">
                        <div></div>
                        <div>
                            <Link to={"/token/" + lp.uuid} >
                                <DexxButton label={"BUY/SELL"} color1="succ1" color2="white"
                                    className=" px-10 rounded-full bg-succ1 font-medium tracking-wide"
                                    fontClasses="md:text-xl"/>
                            </Link>
                        </div>
                    </div>
                </div> 
            </div>
        </Link>
    </>
}

export function CardInvestSecondaryMarket(props: {assetInfo: any, id: string | number, className: string}) : JSX.Element {
    
    let unique = props.assetInfo
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [tokenInfo, setTokenInfo] = useState<any>()
    const [media, setMedia] = useState<any>()
    const [cardImage, setCardImage] = useState<OptimizedImage>()
    const [cardImageURLs, setCardImageURLs] = useState<OptimizedImage>()
    const [onHoverClasses, setOnHoverClasses] = useState<string>(" from-cards1 to-cards2")
    const [onHoverImg, setOnHoverImg] = useState<string>("")

    useEffect(() => {
        actions.getTokenInfoByTokenUUID(unique.token_uuid, setTokenInfo)
    }, [])

    useEffect(() => {
        if(tokenInfo)
            actions.getAssetMedia(tokenInfo.asset_uuid, setMedia)
    }, [tokenInfo])

    useEffect(() => {
        if(media){
            let sortedImages: SortedImages = sortImages(media.filter((m: any) => m.media_type_name === "Image"))
            if(sortedImages.sorted_images.length > 0)
                setCardImage(sortedImages.sorted_images[0])
            else if(sortedImages.others.length > 0)
                setCardImage({filename_big: sortedImages.others[0],
                    filename_small: "", name: sortedImages.others[0], 
                    url_big: "", url_small: ""})
            else
                setCardImage(undefined)
            actions.getImagesURL(cardImage, setCardImageURLs)
        }
    }, [media])

    useEffect(() => {
        if(cardImage)
            actions.getImagesURL(cardImage, setCardImageURLs)
    }, [cardImage])

    let categoryShort = unique?.token_category === "SPORTEX" ? "SPORT" : 
                        (unique?.token_category === "COLLECTABLES" ? "CLLCT" :
                        unique?.token_category)
    
    return <>
        <Link to={"/token/" + unique.token_uuid} >
            <div className={" m-1 bg-white1 font-roboto py-4 pr-6 w-96"+props.className}
                onMouseEnter={() => {
                    setOnHoverClasses(" from-cards3 to-cards4")
                    setOnHoverImg(" invisible")}}
                onMouseLeave={() => {
                    setOnHoverClasses(" from-cards1 to-cards2")
                    setOnHoverImg(" ")}}>
                <div className="flex justify-between ml-6 mb-2">
                    <div className="text-black font-medium text-sm mb-2">{unique?.token_category}</div>
                    <div className={"font-medium uppercase text-succ7"}>{""}</div>
                </div>
                <div className="flex justify-between font-semibold text-xl ml-6 mb-4 h-7"></div>
                {cardImageURLs ? <> 
                    <div className="w-80 h-40 rounded-tr-full rounded-br-full
                        align-middle z-10 relative object-cover overflow-hidden">
                        <LazyLoadImage src={cardImageURLs.url_big} className="w-80 h-40 rounded-tr-full 
                            rounded-br-full align-middle z-10 relative object-cover" 
                            placeholderSrc={cardImageURLs.url_small} effect="blur" threshold={1000}/>
                    </div>
                    <div className={"w-80 h-40 rounded-tr-full rounded-br-full mt-neg160 z-20 relative "+
                            " bg-cards3_light flex "+onHoverImg}>
                                <div className="text-7xl font-medium tracking-wider_plus self-center ml-6 
                                    leading-11 ">
                                    {categoryShort}</div>
                    </div>
                    </>
                    : 
                    <div className={"w-80 h-40 bg-gradient-to-r "+
                            "rounded-tr-full rounded-br-full flex realtive z-10 "+onHoverClasses}>
                        <div className="text-7xl font-medium tracking-wider_plus self-center ml-6 
                            leading-11 ">
                            {categoryShort}</div>
                    </div>    
                }
                <div className="h-72 content-between grid">
                    <div>
                        <div className="text-black font-medium text-medium ml-6 mt-6 mb-4">
                            {unique.token_ticker}
                        </div>
                        <div className="pl-6 text-3xl font-light leading-10 my-5 tracking-wide">
                            {unique.token_name}
                        </div>
                    </div>
                    <div className=" flex justify-between pl-6">
                        <div></div>
                        <div>
                            <Link to={"/token/" + unique.token_uuid} >
                                <DexxButton label={"BUY/SELL"} color1="succ1" color2="white"
                                    className=" px-10 rounded-full bg-succ1 font-medium tracking-wide"
                                    fontClasses="md:text-xl"/>
                            </Link>
                        </div>
                    </div>
                </div> 
                
            </div>
        </Link>
    </>
}