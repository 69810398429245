import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import { DexxButton } from "../components/light/LightButton";
import img1 from "../media/landing/1.jpg"
import img2 from "../media/landing/2.jpg"
import img3 from "../media/landing/3.jpg"
import img4 from "../media/landing/car-seat.jpg"
import img5 from "../media/landing/2556_edited.jpg"
import img6 from "../media/landing/art.jpg"
import dexx from "../media/Logo-dexx.png"
import icon1 from "../media/landing/icons8-secure-100.png"
import icon2 from "../media/landing/icons8-connect-100.png"
import icon3 from "../media/landing/icons8-drops-96.png"
import icon4 from "../media/landing/icons8-refresh-90.png"
import load from "../media/landing/loadingblue.gif"
import placeholder from "../media/landing/card.png"
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state";
import { defaultPrimarySalesList, PrimarySaleGet, PrimarySalesList } from "../PoolContext";
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie';

function Block(props: { children?: any }): JSX.Element {
    return <>
        <div className="grid w-full">
            {props.children}
        </div>
    </>
}

function PresentationCard(props: { title: string, description: string, image: string }): JSX.Element {
    return <>
        <div className=" max-w-xs ">
            <div className="w-full h-80">
                <LazyLoadImage src={props.image}
                    className="h-80 w-full relative object-cover" />
            </div>
            <div className="bg-white p-4 text-center h-80">
                <div className=" font-semibold my-5 text-lg font-barlow ">
                    {props.title}</div>
                <div className="leading-7 text-sm font-inria font-thin ">
                    {props.description}</div>
            </div>
        </div>
    </>
}

function AssetCard(props: { ps: PrimarySaleGet, info: string }): JSX.Element {
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [show, setShow] = useState<boolean>(false)
    const [media, setMedia] = useState()
    const [images, setImages] = useState<any[]>([])
    const [token, setToken] = useState<any>()
    const [isSoldOut, setIsSoldOut] = useState<boolean>(false)

    useEffect(() => {
        actions.getAssetMedia(props.ps.asset_uuid, setMedia, false)
    }, [])

    useEffect(() => {
        actions.getTokenInfo(props.ps.asset_uuid, setToken)
    }, [props.ps])
    
    useEffect(() => {
        if(token)
            setIsSoldOut(token.date_buyback !== "0001-01-01T01:01:01Z")
    }, [token])

    useEffect(() => {
        actions.getImages(media, setImages)
    }, [media])

    return <>
        <div className="grid" onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}>
            <div className="md:h-144 md:w-112 h-96 w-80 z-10 flex">
                <LazyLoadImage src={(images?.length > 0 ? images[0].url : placeholder)}
                    className="w-full h-full relative object-cover"
                    effect="black-and-white" placeholderSrc={load} />
            </div>
            <div className="md:w-112 md:h-144 h-96 w-80 md:mt-neg576 mt-neg384 
                    bg-transparent hover:bg-black_light z-20 grid text-white items-end">
                <div className={" grid place-content-center  text-center mb-10" +
                    (show ? "" : " hidden ")}>
                    <div className=" font-barlow text-4xl font-semibold mb-2">
                        {isSoldOut ? "Sold out" : props.ps.asset_name}
                    </div>
                    <div className=" font-inria mb-4">
                        {isSoldOut ? props.ps.asset_name : props.ps.asset_type}
                    </div>
                    {isSoldOut ? <div className="h-12"></div> :
                    <Link to={"/invest"} className="flex place-content-center">
                        <DexxButton label={"Buy now"} className="px-10 py-3 rounded-lg" />
                    </Link> }
                </div>
            </div>
        </div>
    </>
}

function Title(props: { children?: any, className?: string }): JSX.Element {
    return <>
        <div className={props.className + " md:text-4.5xl text-center " +
            " place-content-center flex w-full py-10 font-playfair text-2xl "}>
            {props.children}
        </div>
    </>
}

function Box(props: { title: string, description: string, button: string, logged?: boolean }): JSX.Element {
    return <>
        <div className="bg-white px-10 pb-10 text-center max-w-lg">
            <Title className="text-landing_title ">
                {props.title}
            </Title>
            <Link className="flex place-content-center" to={props.logged ? "/profile" : "/account/signin"}>
                <DexxButton label={props.button} className="px-8 rounded-lg"
                    color2="black" color3="landing_title" color4="white" />
            </Link>
            <div className="mt-5">
                {props.description}
            </div>
        </div>
    </>
}

function SmallDescription(props: {
    title: string, body: string, image: string,
    className?: string
}): JSX.Element {
    return <>
        <div className={props.className + " text-white p-4 xl:p-10 max-w-xs"}>
            <img src={props.image} className="h-10 xl:my-5 my-1" />
            <div className="text-xl leading-10 mb-3">{props.title}</div>
            <div className="text-sm font-inria font-thin leading-6">{props.body}</div>
        </div>
    </>
}

export default function LandingRoute(): JSX.Element {

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [primarySalesList, setPrimarySalesList] = useState<PrimarySalesList>(defaultPrimarySalesList)
    const cookies = new Cookies();
    const logged = cookies.get("logged_in")

    useEffect(() => {
        actions.getAllPrimarySales(setPrimarySalesList)
    }, [])

    return <>
        <div className="mx-neg40">
            <Block>
                <div className="text-white font-playfair ml-10 lg:ml-80 px-10 py-40 animate-fadein">
                    <div className="font-thin font-barlow md:text-2xl mb-2 text-xl">
                        The hybrid blockchain platform<br />
                        for the tokenization and trading of alternative assets
                    </div>
                    <div className="md:text-7.5xl md:leading-22 text-6xl">
                        Welcome <br />to Dexx
                    </div>
                    <Link className="flex mt-6" to="/about">
                        <DexxButton label={"Learn more"} className=" rounded-lg px-6 py-3 md:text-4xl " />
                    </Link>
                </div>
            </Block>
            <Block>
                <div className="bg-dark_blue p-10 w-full flex gap-6 place-content-center flex-wrap ">
                    <PresentationCard title={"A token for every opportunity"}
                        description="We create new opportunities by tokenizing real and 
                            alternative assets, and soon, financial products, in a simple 
                            and secure manner, with particular attention to the legal 
                            compliance of our operations."
                        image={img1} />
                    <PresentationCard title={"Broaden your financial horizons"}
                        description="We enable investors
                            and traditional finance operators to access new markets
                            (e.g., art, real estate,
                            collectibles, etc.) with continuously growing trends."
                        image={img2} />
                    <PresentationCard title={"Primary and secondary market"}
                        description="Our tokens are easily purchasable and tradable 
                            thanks to the simplicity of access to the primary market and 
                            an innovative liquidity pool system that supports and fuels 
                            our secondary market. A unique plus in the global landscape!"
                        image={img3} />
                </div>
            </Block>
            <Block>
                <Title className="text-blue bg-white">
                    Assets available for purchase
                </Title>
            </Block>
            <Block>
                <div className="w-full flex bg-white gap-4 px-10 pb-10 overflow-x-auto no-scrollbar">
                    {primarySalesList.primary_sales.map((ps, idx) => {
                        return <AssetCard ps={ps} info={""}
                            key={idx} />
                    })}
                </div>
            </Block>
            <Block>
                <div className="grid h-160 lg:h-128">
                    <LazyLoadImage src={img4} className="w-full absolute object-cover lg:h-128 h-160 z-10" />
                    <div className=" z-20 min-h-128">
                        <Title className="text-white">
                            Start collecting!
                        </Title>
                        <div className="flex place-content-center w-full gap-1 mb-10 flex-wrap">
                            <Box title={"New to Dexx?"} description="It only takes a few simple steps!
                        Watch the tutorial and start collecting your first digital token."
                                button={"register"} logged={logged}/>
                            <Box title={"Already a member"} description="Browse through the assets 
                        already available on the platform, carefully selected by our experts.
                        Art, collectibles, real estate, finance: get your digital token to 
                        hold or trade!" button={"log in"} logged={logged}/>
                        </div>
                    </div>
                </div>
            </Block>
            <Block>
                <div className="grid" style={{
                    backgroundImage: `url(${img5})`,
                    backgroundRepeat: "no-repeat",
                    height: "100%",
                    width: "100%",
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                }}>
                    {/*<LazyLoadImage src={img5} className="w-full fixed top-0 object-cover z-0 pt-96" />*/}
                    <div className="z-10 pt-10 h-160 bg-black_light grid">
                        <Title className="text-white">
                            Easy, transparent and liquid
                        </Title>
                        <div className="flex w-full place-content-center justify-evenly flex-wrap">
                            <div className="flex justify-evenly">
                                <SmallDescription title={"Secure and compliant"}
                                    body={"DEXX strives to provide a secure and compliant trading " +
                                        "environment in accordance with regulatory requirements"}
                                    image={icon1} className="xl:mt-24" />
                                <SmallDescription title="Easy and interoperable"
                                    body="Easy to use with on / off chain systems via interchain patent, 
                                        supported by our partner Dragonchain."
                                    image={icon2} />
                            </div>
                            <div className="flex justify-evenly">
                                <SmallDescription title={"Liquidity Pool"}
                                    body="Our system includes proprietary algorythms that can 
                                        drastically increase secondary market's liquidity."
                                    image={icon3} className="xl:mt-32" />
                                <SmallDescription title={"All in one service"}
                                    body="Our integrated system is designed for banks and financial 
                                        institutions to increase platforms volumes."
                                    image={icon4} />
                            </div>
                        </div>
                        <Link className="flex place-content-center mb-80 md:mt-10 mt-4"
                            to="/support">
                            <DexxButton label={"F.A.Q."} className="rounded-lg px-10 py-3" />
                        </Link>
                    </div>
                </div>
            </Block>
            <Block>
                <div className="grid lg:grid-cols-2 z-10">
                    <div>
                        <LazyLoadImage src={img6} className="w-full h-full relative object-cover" />
                    </div>
                    <div className=" xl:pt-48 p-16 text-white font-inria md:text-lg
                            text-sm bg-gradient-to-b from-landing_gradient2 to-landing_gradient1">
                        <div>
                            Dexx finally makes it possible to turn what has been only a passion
                            for a few into a new and exciting investment opportunity for everyone.
                        </div>
                        <div className="text-3xl font-playfair md:text-4.5xl md:leading-11 mt-4">
                            Passion, your best<br />
                            asset over time.
                        </div>
                    </div>

                </div>
            </Block>
            <Block>
                <div className="bg-gray11 text-white font-barlow pb-6 w-full grid place-content-center">
                    <div className="flex place-content-center my-10">
                        <LazyLoadImage src={dexx} className="h-16" />
                    </div>
                    <div className="flex place-content-center text-center pt-10 px-10 md:px-20 flex-wrap md:gap-0 gap-2
                            md:text-base text-xxs font-thin border-t-1 border-0 border-white border-solid">
                        <div className="border-0 border-r-1 border-white md:border-solid md:px-12 lg:px-24 text-sm leading-5">
                            <div className="font-normal">FIMART ITALIA SRL</div>
                            Via Gaetano Tacchi 1, 38068<br />
                            Rovereto (TN) | Italy<br />
                            P.IVA 02589680228<br />
                        </div>
                        <div className="md:px-12 lg:px-24 text-sm leading-5">
                            <div className="font-normal">LL DEXX SA</div>
                            Piazza Indipendenza 3, 6900,<br />
                            Lugano, Switzerland<br />
                            ID CHE495668204
                        </div>
                    </div>
                    <div className="flex place-content-center mt-10 text-sm font-thin">
                        @ {new Date().getFullYear()} by Look Lateral
                    </div>
                </div>
            </Block>
        </div>
    </>
}