import { bindActionCreators } from "@reduxjs/toolkit"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import LabelAndInput from "../components/LabelAndInput"
import { LightButton } from "../components/light/LightButton"
import { defaultAsset } from "../AssetContext"
import { defaultPrimarySaleGet, PrimarySaleSet, defaultSecurityToken, defaultBalance } from "../PoolContext"
import { actionCreators, State } from "../state"
import Popup from '../components/Popup'
import { euroFormat, euroQtyFormat, formatDatetime, quantityFormat } from "../utils"
import Play from '@mui/icons-material/PlayArrow';
import Stop from '@mui/icons-material/Stop';
import Pause from '@mui/icons-material/Pause';
import Restart from '@mui/icons-material/RestartAlt';
import Button from '@mui/material/Button';
import { BigTitle} from "../components/typography/Typogtaphy"
import { TokenDescriptionTable4PS } from "../components/TokenDescriptionTable"
import { useHistory } from "react-router"
import LightTable from "../components/light/LightTable";
import { HeaderButton } from "../components/HeaderButton"
import { ITimezoneOption } from "react-timezone-select";
import { Timezone } from "../Timezone"

export default function IssuerPrimarySaleRoute(props: any): JSX.Element {

    const uuid = props.match.params.uuid
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    let history = useHistory()

    //const userCtx = useContext(UserContext)

    const [asset, setAsset] = useState(defaultAsset)
    const [token, setToken] = useState(defaultSecurityToken)
    const [primarySaleDetails, setPSDetails] = useState(defaultPrimarySaleGet)
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [primarySaleAmount, setPrimarySaleAmount] = useState(0)
    const [primarySalePrice, setPrimarySalePrice] = useState(0)
    const [primarySaleUuid, setPrimarySaleUuid] = useState("")
    const [balance, setBalance] = useState(defaultBalance)
    const [response, setResponse] = useState<any[]>([0, undefined])
    const [startPS, setStartPS] = useState(null)
    const [pausePS, setPausePS] = useState(null)
    const [resumePS, setResumePS] = useState(null)
    const [endPS, setEndPS] = useState(null)
    const [feesDescr, setFeesDescr] = useState<any>(null)
    const [disableIfStarted, setDisableIfStarted] = useState<boolean>(false)
    const [submitted, setSubmitted] = useState(false)
    const detailAccountState:any = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload
    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0
    const [startDateOffset, setStartDateOffset] = useState<Timezone>(new Timezone("", offset))
    const [endDateOffset, setEndDateOffset] = useState<Timezone>(new Timezone("", offset))
    const [buyersPrimarySale, setBuyersPrimarySale] = useState<any[]>([])
    const hdsBuyersPrimarySale = ["ticker", "Asset Name", "Total Supply", "Investor Email", "Token Quantity", "Token Buy Price"]
    const [showInvestors, setShowInvestors] = useState<boolean>(false)
    const [showTransactions, setShowTransactions] = useState<boolean>(false)
    const [transactions, setTransactions] = useState<any[]>([])
    const [timezone, setTimezone] = useState<ITimezoneOption>(
        {value: Intl.DateTimeFormat().resolvedOptions().timeZone, label: "", offset: 0})

    var newPrimarySale: PrimarySaleSet = {
        token_uuid: token.uuid,
        total_supply: primarySaleAmount.toString(),
        price: primarySalePrice.toString().replace(",", "."),
        start_date: startDateOffset ? startDateOffset.toISOString() : "",
        end_date: endDateOffset ? endDateOffset.toISOString() : "",
        open: false,
    }

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    const [confirmPopUp, setConfirmPopUp] = useState<boolean>(false)
    const [actionPopUp, setActionPopUp] = useState<string>("")
    
    function handleClosePopUp(){
        setTitlePopUp("")
        setMsgPopUp("")
        setConfirmPopUp(false)
        setActionPopUp("")
        setShowPopUp(false)
        
        if(primarySaleDetails.uuid && primarySaleDetails.uuid !== '')
            actions.getPrimarySale("uuid="+primarySaleDetails.uuid, setPSDetails)
        else if(primarySaleUuid && primarySaleUuid !== "")
            history.push("/issuer/primary-sale/" + primarySaleUuid )
        
    }

    useEffect(() => {
        if(userCtx){
            setTimezone({value: userCtx.timezone_value ? userCtx.timezone_value : 
                Intl.DateTimeFormat().resolvedOptions().timeZone, offset: 
                    (userCtx.timezone_offset ? userCtx.timezone_offset : 0), label: ""})
        }
        actions.getPrimarySale("uuid="+uuid, setPSDetails)
        actions.getActualBalance(setBalance)
        actions.getBuyersPrimarySale(uuid, setBuyersPrimarySale)
        actions.getTransactionsPrimarySale(uuid, setTransactions)
    }, [])
    
    useEffect(() => {
        if(primarySaleDetails.uuid !== ''){
            
            actions.getAssetInfo(primarySaleDetails.asset_uuid, setAsset)
            actions.getTokenInfo(primarySaleDetails.asset_uuid, setToken)

            setStartDate(primarySaleDetails.date_start)
            setEndDate(primarySaleDetails.date_end)
            setStartDateOffset(new Timezone(primarySaleDetails.date_start, offset))
            setEndDateOffset(new Timezone(primarySaleDetails.date_end, offset))
            setPrimarySaleAmount(primarySaleDetails.total_supply !== undefined ? primarySaleDetails.total_supply : 0)
            setPrimarySalePrice(primarySaleDetails.price !== undefined ? primarySaleDetails.price : 0)
            setPrimarySaleUuid(uuid)

            if(primarySaleDetails.status !== "Initialized") setDisableIfStarted(true)
        
        } else {
            if(primarySaleDetails.uuid === ''){
                actions.getAssetInfo(uuid, setAsset)
                actions.getTokenInfo(uuid, setToken)
            }
        }

    }, [primarySaleDetails])

    useEffect(() => {
        actions.getFeesFromCategory(asset.asset_category, setFeesDescr)
    }, [asset])

    
    useEffect(() => {
        if(primarySaleDetails.uuid === ''){
            setPrimarySalePrice(parseFloat(token.price))
        }
    }, [token])

    function handleSubmit() {
        let t = newPrimarySale
        if(primarySaleUuid !== "") t.uuid = primarySaleUuid
        if(parseInt(t.total_supply) <= 0) {
            setTitlePopUp("Sorry!")
            setMsgPopUp("Quantity field must be greater than zero.")
            setShowPopUp(true)
        } else if(t.total_supply === "") {
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please specify the quantity of tokens to put in the primary sale.")
            setShowPopUp(true)
        } else if(parseFloat(t.price) <= 0) {
            setTitlePopUp("Sorry!")
            setMsgPopUp("Price field must be greater than zero.")
            setShowPopUp(true)
        } else if(t.price === "") {
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please specify the price.")
            setShowPopUp(true)
        } else if(new Date() > new Date(t.start_date)) {
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please specify a valid start date.")
            setShowPopUp(true)
        } else if(new Date() > new Date(t.end_date) || new Date(t.start_date) >= new Date(t.end_date)) {
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please specify a valid end date.")
            setShowPopUp(true)
        } else {
            setSubmitted(true)
            actions.submitPrimarySale(t, setResponse)
        }
    }

    function handleAction(type: string) {
        setTitlePopUp("Confirm your action")
        setMsgPopUp("Are you sure you want to " + type + " the Primary Sale?")
        setConfirmPopUp(true)
        setActionPopUp(type)
        setShowPopUp(true)
        setSubmitted(false)
    }

    function handleConfirmation() {
        handleClosePopUp()

        switch(actionPopUp) {
            case "start":
                actions.startPrimarySale(primarySaleUuid, setStartPS)
                break
            case "pause":
                actions.pausePrimarySale(primarySaleUuid, setPausePS)
                break
            case "resume":
                actions.resumePrimarySale(primarySaleUuid, setResumePS)
                break
            case "stop":
                actions.stopPrimarySale(primarySaleUuid, setEndPS)
                break
            default:
        }

        setSubmitted(true)
    }
    
    function handleRefresh() {
        setShowPopUp(true)
        setSubmitted(false)
        if(actionPopUp === ""){
            //history.push("/issuer/dashboard/#Tokens" )
        } else { // play/pause/resume/stop
            actions.getPrimarySale("uuid="+primarySaleUuid, setPSDetails)
            actions.getActualBalance(setBalance)
        }
        
    }

    useEffect(() => {
        if(submitted && response) {
            switch(response[0]){
                case 200:
                    setTitlePopUp("Success")
                    setMsgPopUp("Your data has been saved.")
                    setPrimarySaleUuid(response[1].primary_sale_uuid)
                    break
                default:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("We encountered an error saving your data.")
                    //setMsgPopUp(response.length > 0 ? response[1] ? response[1].error : "Error" : "Error")
            }
            handleRefresh()
        }
    }, response)

    useEffect(() => {
        if(submitted && startPS) {
            switch(startPS[0]){
                case 200:
                    setTitlePopUp("Success")
                    setMsgPopUp("Primary Sale started!")
                    break
                default:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("There was an error starting the Primary Sale.")
            }
            handleRefresh()
        }
    }, [startPS])

    useEffect(() => {
        if(submitted && pausePS) {
            switch(pausePS[0]){
                case 200:
                    setTitlePopUp("Success")
                    setMsgPopUp("Primary Sale paused!")
                    break
                default:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("There was an error pausing the Primary Sale.")
            }
            handleRefresh()
        }
    }, [pausePS])

    useEffect(() => {
        if(submitted && resumePS) {
            switch(resumePS[0]){
                case 200:
                    setTitlePopUp("Success")
                    setMsgPopUp("Primary Sale resumed!")
                    break
                default:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("There was an error resuming the Primary Sale.")
            }
            handleRefresh()
        }
    }, [resumePS])

    useEffect(() => {
        if(submitted && endPS) {
            switch(endPS[0]){
                case 200:
                    setTitlePopUp("Success")
                    setMsgPopUp("Primary Sale stopped!")
                    break
                default:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("There was an error stopping the Primary Sale.")
            }
            handleRefresh()
        }
    }, [endPS])


    function buyersPrimarySaleRows(l: any[]): any[] {
        return l ?
            l.map(t => ([t.token_ticker, t.asset_name, quantityFormat(t.total_supply),
                        t.investor_email, quantityFormat(t.quantity), euroFormat(t.price_per_token)])
            ) : []
    }

    function ColoredTab(props: {label: string, color: string, index: number, headers: any, rows: any}): JSX.Element {
        return <div>
            <>
            {props.rows.length > 0 ?
                <LightTable headers={props.headers} rows={props.rows}
                    colorHd={"gradient-to-r from-"+props.color+" to-gray3"}
                    color1={"gradient-to-r from-"+props.color+"_lighter  to-gray1"}
                    color2={"gradient-to-r from-"+props.color+"_light to-gray2"} /> 
                :
                <div className="bg-white">
                    <div className={"font-roboto uppercase text-gray pl-10 py-2 bg-"+props.color+"_light"}>None</div>
                </div>}
                
            </>
        </div>
    }

    const issuerFees = feesDescr && feesDescr.Seller? "ISSUER: a fee equal to the "+feesDescr.Seller+"% of the amount will be charged for each txn" : ""
    const investorFees = feesDescr && feesDescr.Buyer?"INVESTOR: a fee equal to the "+feesDescr.Buyer+"% of the amount will be charged for each txn" : ""

    function ActionButton(props: {action: string, label: string, iconActive: JSX.Element, 
            iconDisabled: JSX.Element, conditions: string[]}) : JSX.Element {
        return <>
            <div className=" border-1">
                {props.conditions.includes(primarySaleDetails.status) ? 
                    <Button variant="contained" color="secondary" onClick={() => handleAction(props.action)} 
                        startIcon={props.iconActive}>
                        {props.label}
                    </Button>
                : 
                    <Button variant="contained" color="secondary" disabled={true}
                        startIcon={props.iconDisabled}>
                        {props.label}
                    </Button>
                }
            </div>
        </>
    }

    return (<>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp} 
            btnConfirm={confirmPopUp} handleConfirm={handleConfirmation}/>
        <div>
            <BigTitle title={asset.name}/>
            <div className="flex place-content-center mb-5 justify-between flex-wrap gap-4 md:mx-10">
                <div className=" flex align-middle">
                    <div className=" text-succ2 text-center text-xl font-normal uppercase">
                        { startDate === "" || endDate === "" ? "Primary Sale Initialization" : "Primary sale " + 
                            (primarySaleDetails.status ? primarySaleDetails.status : "")}
                    </div>
                </div>
                <div className=" text-blue text-center flex flex-wrap gap-4">
                    <ActionButton action={"start"} label={"Start"} conditions={["Initialized"]} 
                        iconActive={<Play className="cursor-pointer" />} 
                        iconDisabled={<Play color="disabled" />} />
                    <ActionButton action={"pause"} label={"Pause"} conditions={["Started"]} 
                        iconActive={<Pause className="cursor-pointer" />} 
                        iconDisabled={<Pause color="disabled" />} />
                    <ActionButton action={"resume"} label={"Resume"} conditions={["Stopped"]} 
                        iconActive={<Restart className="cursor-pointer" />} 
                        iconDisabled={<Restart color="disabled" />} />
                    <ActionButton action={"stop"} label={"Close"} conditions={["Started", "Stopped"]} 
                        iconActive={<Stop className="cursor-pointer" />} 
                        iconDisabled={<Stop color="disabled" />} />
                </div>
                <div className=" text-succ2 text-xl tracking-wider font-normal flex place-content-center">
                    BALANCE: 
                        {balance && balance.payment_tokens && balance.payment_tokens.total ? " €"+euroFormat(balance.payment_tokens.total) : ""}
                </div>
            </div>
            <TokenDescriptionTable4PS tokensList={{
                tokens: [token]
            }} />
        </div>
        <div className="grid justify-center xl:mx-36  font-roboto"> 
            
            {token && token.listing_limit ?
                
                <div className="text-primary p-5 bg-tertiary text-center font-urbanist uppercase text-1xl my-10 tracking-wider">
                    Please consider holding back at least 5% of your tokens from the Primary Sale in order to be able to create a Liquidity Pool with those tokens.<br />
                    Instead of selling the maximum {token.listing_limit}% of the asset’s tokens sell only {(parseInt(token.listing_limit)-5)}%.
                </div>
            : 
                <></>
            }

            <div className="py-5 px-12 font-roboto text-base font-normal tracking-wider">
                    <LabelAndInput label={"Timezone"} value={timezone.value} fun={setTimezone} 
                        type={"timezone"} className="col-span-12" disabled />
                </div>
            <div className="grid md:grid-cols-2 pb-10 px-10">

                <div className="mx-2">
                    <LabelAndInput label={"Start Date *"} value={startDateOffset?.toString()} 
                        fun={(d: string) => {setStartDateOffset(new Timezone(d, offset, true))}}
                        type={disableIfStarted ? "datetime-local-disabled" : "datetime-local"} className="mr-1.5 uppercase" color="white"/>
                    <div className="grid gap-3">
                        <LabelAndInput label={"Quantity *"} value={(primarySaleAmount)}
                            fun={(amount: string) => 
                                {
                                    let a: number = 0
                                    let b = false
                                    try {
                                        if(amount.length > 0){
                                            a = (Number(amount))
                                            b = true
                                        }
                                    } catch(e) {}
                                    if((a && b) || a === 0)
                                        if(a > Number(token.total_available)) 
                                            amount = token.total_available
                                        setPrimarySaleAmount(parseFloat(amount ? amount : "0"))
                            }} 
                            type={disableIfStarted ? "disabled" : "number"} className="mx-1.5 uppercase" color="white" 
                            /*maxValue={parseFloat(token.total_available)}*//>
                        {// This button is not working correctly: it is always showing 100% of the supply, while sometimes it should
                        //  be the 49%. We'll be hiding it while we fix this function.
                        /*<div className="flex place-content-center self-center">
                            <LightButton label={"MAX"} alternate={true} className="px-2 py-1" 
                                onClick={() => disableIfStarted ? null : setPrimarySaleAmount(parseFloat(token.total_available))}/>
                        </div>*/}
                    </div>
                </div>
                
                <div className="mx-2">
                    <LabelAndInput label={"End Date *"} value={endDateOffset?.toString()} 
                        fun={(d: string) => {setEndDateOffset(new Timezone(d, offset, true))}}
                        type={disableIfStarted ? "datetime-local-disabled" : "datetime-local"}
                        className="mr-1.5 uppercase" color="white"/>
                    <LabelAndInput label={"Price *"} value={primarySalePrice !== 0 ? 
                            (primarySalePrice.toString().replace(".", ",")) : (token.proposed_price)}
                        fun={setPrimarySalePrice} 
                        type={disableIfStarted ? "disabled" : "float"} step="0.01" className="mx-1.5 uppercase" color="white"/>
                </div>
            </div>
            <div className="text-primary p-5 bg-tertiary font-roboto text-base mb-5 font-normal tracking-wider">
                <div className="text-center p-2 uppercase">Fees recap</div>
                <div className="p-2">{issuerFees}</div>
                <div className="p-2">{investorFees}</div>
            </div>
            <div className="flex justify-center mb-5 flex-wrap gap-4">
                <Link to="/issuer/dashboard/#Tokens" className="">
                    <LightButton label={"back"} alternate={true} className="float-left rounded-full py-2 px-5 w-fit"/></Link>
                {
                    !disableIfStarted ? 
                    <LightButton label={"submit"} alternate={true} className="rounded-full py-2 px-5 w-fit" onClick={() => handleSubmit()}/>
                    : <></>
                }
            </div>

            
        </div>
        <div className="grid mt-10">
                <div className="">
                    <HeaderButton click={() => {
                            if(buyersPrimarySale && buyersPrimarySale?.length)
                                setShowInvestors(!showInvestors)}} 
                        className={"cursor-pointer"} 
                        name={"Investors of this Primary Sale ("+
                            (buyersPrimarySale ? buyersPrimarySale.length : 0)+")"} 
                        color1={"succ4"} color2={"white"} />
                    {showInvestors ? <ColoredTab label={""} color={"succ4"} index={0} 
                            headers={[...hdsBuyersPrimarySale,...[""]]} 
                            rows={buyersPrimarySaleRows(buyersPrimarySale ? buyersPrimarySale : [])} /> 
                        : <></>}
                </div>
                <div>
                    <HeaderButton click={()=> {
                            if(transactions && transactions?.length > 0)
                                setShowTransactions(!showTransactions)}} 
                        className={"cursor-pointer"} 
                        name={"Transaction History ("+transactions?.length+")"} 
                        color1={"seafoam"} color2={"white"} />
                    {showTransactions ? <ColoredTab label={""} color={"seafoam"} index={0} 
                            headers={["Date", "Status", "Number of tokens", "price", "fees", 
                                "value", "total", "trans type", "hash on blockchain"]} 
                            rows={transactions.map((t) => {
                                return [formatDatetime(t.timestamp), t.status, euroQtyFormat(t.quantity),
                                    "€"+euroFormat(t.price_per_unit), 
                                    t.txn_operation === "buy" ? 
                                        "€"+euroFormat(t.response?.FEES?.buyer?.amount) : 
                                        "€"+euroFormat(t.response?.FEES?.seller?.amount),
                                    "€"+euroFormat(t.net_amount), "€"+euroFormat(t.gross_amount),
                                    t.txn_operation, <Link to={"/blockchain/transaction/"+t.trx_identifier}>
                                        {t.trx_identifier}</Link>]
                            })} /> 
                        : <></>}
                </div>
            </div>
    </>)
}
