import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { defaultHorseExtraInfo, FileSrc, HorseExtraInfo, PrizeEarnings, SportResult } from "../../AssetContext";
import { actionCreators } from "../../state";
import LabelAndInput from "../LabelAndInput";
//import PrizeEarningsRow from "../PrizeEarningsRow";
//import SportResultRow from "../SportResultRow";
import { TitleColor1 } from "../typography/Typogtaphy";
//import UpdatableTable from "../UpdatableTable";
import UploadFilesForm, { UploadTokenFilesForm } from "../UploadFilesForm";
import HTMLVisualizer from "../utils/HTMLVisualizer";
import { getImageName, OptimizedImage, SortedImages, sortImages } from "../../functions/images";
import {sanitizeInputField} from "../../utils"
import UploadCardImage from "../UploadCardImage";

export default function ExtraInfoEquestrianForm(props: {
    assetInfo: any, setUpdateFun: any, media: any,
    tbr: any[], setTbr: any, tbu: any[], setTbu: any,
    coownTBR: any[], setCoownTBR: any, coownTBU: any[], setCoownTBU: any,
    prospTBR: any[], setProspTBR: any, prospTBU: any[], setProspTBU: any
}): JSX.Element {

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    // Asset ExtraInfo fields
    const [assetName, setAssetName] = useState<string>("")
    const [horseName, setHorseName] = useState<string>("")
    const [horseAge, setHorseAge] = useState<string>("")
    const [horseSire, setHorseSire] = useState<string>("")
    const [horseColor, setHorseColor] = useState<string>("")
    const [horseRider, setHorseRider] = useState<string>("")
    const [horseGender, setHorseGender] = useState<string>("")
    const [horseMadame, setHorseMadame] = useState<string>("")
    const [horseBreeder, setHorseBreeder] = useState<string>("")
    const [horseEstValue, setHorseEstValue] = useState<string>("")
    const [horseDiscipline, setHorseDiscipline] = useState<string>("")
    const [horseFeiNumber, setHorseFeiNumber] = useState<string>("")
    const [horseBestResult, setHorseBestResult] = useState<string>("")
    const [horseDescr, setHorseDescr] = useState<string>("")
    const [horseDateOfBirth, setHorseDateOfBirth] = useState<string>("")
    const [horseTotalWinning, setHorseTotalWinning] = useState<string>("")
    const [horseRatingScuderia, setHorseRatingScuderia] = useState<string>("")

    const [sportResults, setSportResults] = useState<SportResult[]>([])
    const [prizeEarnings, setPrizeEarnings] = useState<PrizeEarnings[]>([])
    const [videosSrc, setVideosSrc] = useState<{ url: string, filename: string }[]>([])
    const [documentsSrc, setDocumentsSrc] = useState<{ url: string, filename: string }[]>([])
    const [images2, setImages2] = useState<FileSrc[]>([])
    const [videos, setVideos] = useState<FileSrc[]>([])
    const [sortedImages, setSortedImages] = useState<OptimizedImage[]>([])
    const [unsortedImages, setUnsortedImages] = useState<OptimizedImage[]>([])
    const [documents, setDocuments] = useState<FileSrc[]>([])
    const [coownership, setCoownership] = useState<FileSrc[]>([])
    const [prospectus, setProspectus] = useState<FileSrc[]>([])
    const [download, setDownload] = useState<boolean>(true)

    const [isApprovedAndTokenized, setIsApprovedAndTokenized] = useState<boolean>(false)
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

    var newEquestrianInfo: HorseExtraInfo = {
        age: parseInt(horseAge),
        name: sanitizeInputField(horseName),
        sire: sanitizeInputField(horseSire),
        color: sanitizeInputField(horseColor),
        rider: sanitizeInputField(horseRider),
        gender: sanitizeInputField(horseGender),
        madame: sanitizeInputField(horseMadame),
        breeder: sanitizeInputField(horseBreeder),
        est_value: sanitizeInputField(horseEstValue),
        discipline: sanitizeInputField(horseDiscipline),
        fei_number: sanitizeInputField(horseFeiNumber),
        best_result: sanitizeInputField(horseBestResult),
        description: horseDescr,
        date_of_birth: parseInt(horseDateOfBirth),
        sport_results: sportResults,
        total_winning: sanitizeInputField(horseTotalWinning),
        prize_earnings: prizeEarnings,
        rating_scuderia: sanitizeInputField(horseRatingScuderia)
    }

    useEffect(() => {
        if (props.media && download) {
            actions.getDocuments(props.media, setDocumentsSrc)
            actions.getVideos(props.media, setVideosSrc)
            setDownload(false)
        }
    }, [props.media])

    useEffect(() => {
        if (props.media) {
            let imgs: SortedImages = sortImages(
                props.media.filter((m: any) => m.media_type_name === "Image"))
            actions.getAllImagesURL(imgs.sorted_images, setSortedImages)
            actions.getAllImagesURL(imgs.others.map((i) => {
                return { name: i, url_small: "", url_big: i, filename_big: "", filename_small: "" }
            }), setUnsortedImages)
        }
    }, [props.media])

    useEffect(() => {
        let i = [...sortedImages, ...unsortedImages].map((im) => {
            return { name: im.name, uploaded: true, src: im.url_big, small: im.url_small,
                filename_big: im.filename_big, filename_small: im.filename_small
            }
        })
        setImages2(i)
    }, [sortedImages, unsortedImages])

    useEffect(() => {
        setVideos(videosSrc.map((u) => { return { name: u.filename, uploaded: true, src: u.url } }))
    }, [videosSrc])

    useEffect(() => {
        let tmp: any = []
        documentsSrc.map((u) => {
            if (!u.filename.includes("coownership___") && !u.filename.includes("prospectus___"))
                tmp.push({ name: u.filename, uploaded: true, src: u.url })
        })
        setDocuments(tmp)

        let tmp2: any = []
        documentsSrc.map((u) => {
            if (u.filename && u.filename.includes("coownership"))
                tmp2.push({ name: u.filename, uploaded: true, src: u.url })
        })
        setCoownership(tmp2)

        let tmp3: any = []
        documentsSrc.map((u) => {
            if (u.filename && u.filename.includes("prospectus"))
                tmp3.push({ name: u.filename, uploaded: true, src: u.url })
        })
        setProspectus(tmp3)
    }, [documentsSrc])


    useEffect(() => {
        if (props.assetInfo.payload && props.assetInfo.payload.extra_info) {
            let horse1 = defaultHorseExtraInfo
            if (props.assetInfo.payload.extra_info.equestrian) {
                horse1 = props.assetInfo.payload.extra_info.equestrian.sub_assets ?
                    props.assetInfo.payload.extra_info.equestrian.sub_assets[0] :
                    defaultHorseExtraInfo
            }
            if (props.assetInfo.payload.asset_status_name !== "In Process" && props.assetInfo.payload.asset_status_name !== "Rejected")
                setIsApprovedAndTokenized(true)
            if (props.assetInfo.payload.asset_status_name === "Submitted")
                setIsSubmitted(true)

            setAssetName(props.assetInfo.payload.name)
            if (horse1) {
                setHorseName(horse1.name)
                setHorseAge(horse1.age.toString())
                setHorseSire(horse1.sire)
                setHorseColor(horse1.color)
                setHorseRider(horse1.rider)
                setHorseGender(horse1.gender)
                setHorseMadame(horse1.madame)
                setHorseBreeder(horse1.breeder)
                setHorseEstValue(horse1.est_value)
                setHorseDiscipline(horse1.discipline)
                setHorseFeiNumber(horse1.fei_number)
                setHorseBestResult(horse1.best_result)
                setHorseDescr(horse1.description)
                setHorseDateOfBirth(horse1.date_of_birth.toString())
                setHorseTotalWinning(horse1.total_winning)
                setHorseRatingScuderia(horse1.rating_scuderia)
                setSportResults(horse1.sport_results)
                setPrizeEarnings(horse1.prize_earnings)
            }
        }
    }, [props.assetInfo, props.assetInfo.loading])

    function updateEI() {
        var subAsset: any[] = []

        let tmp: SportResult[] = []
        tmp = newEquestrianInfo.sport_results.filter((el: SportResult) => el.rank !== "" && el.year !== 0 && el.rider !== "" && el.height !== "" && el.competition !== "")
        newEquestrianInfo.sport_results = tmp

        let tmp2: PrizeEarnings[] = []
        tmp2 = newEquestrianInfo.prize_earnings.filter((el: PrizeEarnings) => el.year !== 0 && el.prize !== "")
        newEquestrianInfo.prize_earnings = tmp2

        subAsset.push(newEquestrianInfo)
        const assetExtraInfo = {
            equestrian: {
                sub_assets: subAsset
            }
        }
        return assetExtraInfo
    }

    useEffect(() => {
        props.setUpdateFun(updateEI)
    }, [assetName, horseName, horseAge, horseSire, horseColor, horseRider, horseGender, horseMadame,
        horseBreeder, horseEstValue, horseDiscipline, horseFeiNumber, horseBestResult, horseDescr,
        horseDateOfBirth, horseTotalWinning, horseRatingScuderia, sportResults, prizeEarnings])

    return <>
        <div className="grid md:px-20 px-5 grid-cols-12 mt-10" >

            <div className="col-span-12 text-lg"><TitleColor1>/ Horse Information</TitleColor1></div>
            <LabelAndInput id={"assetName"} label={"Asset Name"} value={assetName} fun={setAssetName} type={"disabled"} className={"col-span-6"} required={true} />
            {/*<LabelAndInput id={"name"} label={"Horse Name *"} value={horseName} fun={setHorseName} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required={true}/>
            <LabelAndInput id={"sire"} label={"Sire *"} value={horseSire} fun={setHorseSire} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required={true}/>
            <LabelAndInput id={"madame"} label={"Madame *"} value={horseMadame} fun={setHorseMadame} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required={true}/>
            <LabelAndInput id={"age"} label={"Age *"} value={horseAge} fun={setHorseAge} type={isApprovedAndTokenized ? "disabled" : "number"} className={"col-span-2"} required={true}/>
            <LabelAndInput id={"dateOfBirth"} label={"Year of Birth *"} value={horseDateOfBirth} fun={setHorseDateOfBirth} type={isApprovedAndTokenized ? "disabled" : "number"} className="col-span-3" required={true}/>
            <LabelAndInput id={"color"} label={"Color *"} value={horseColor} fun={setHorseColor} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-4"} required={true}/>
            <LabelAndInput id={"gender"} label={"Gender *"} value={horseGender} fun={setHorseGender} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-3"} required={true}/>
            <LabelAndInput id={"rider"} label={"Rider *"} value={horseRider} fun={setHorseRider} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required={true}/>
            <LabelAndInput id={"breeder"} label={"Breeder *"} value={horseBreeder} fun={setHorseBreeder} type={isApprovedAndTokenized ? "disabled" : "text"} className="col-span-6" required={true}/>*/}
            <LabelAndInput id={"description"} label={"Description * (text or HTML)"} value={horseDescr} fun={setHorseDescr} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className="col-span-12" required={true} />
            <HTMLVisualizer text={horseDescr} className="w-full col-span-12 text-tertiary mb-5" />
            {/*<LabelAndInput id={"discipline"} label={"Discipline *"} value={horseDiscipline} fun={setHorseDiscipline} type={isApprovedAndTokenized ? "disabled" : "text"} className="col-span-4" required={true} />
            <LabelAndInput id={"feiNumber"} label={"Fei Number *"} value={horseFeiNumber} fun={setHorseFeiNumber} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-2"} required/>
            <LabelAndInput id={"bestResult"} label={"Best Result *"} value={horseBestResult} fun={setHorseBestResult} type={isApprovedAndTokenized ? "disabled" : "text"} className="col-span-6" required={true} />
            <LabelAndInput id={"estValue"} label={"Estimate Value *"} value={horseEstValue} fun={setHorseEstValue} type={isApprovedAndTokenized ? "disabled" : "text"} className="col-span-4" required={true}/>
            <LabelAndInput id={"totalWinning"} label={"Total Winning *"} value={horseTotalWinning} fun={setHorseTotalWinning} type={isApprovedAndTokenized ? "disabled" : "text"} className="col-span-4" required={true} />
            <LabelAndInput id={"ratingScuderia"} label={"Rating Scuderia *"} value={horseRatingScuderia} fun={setHorseRatingScuderia} type={isApprovedAndTokenized ? "disabled" : "select"} className="col-span-4"
                options={[{value: "", text: ""}, {value: "1 Star", text: "1 Star"}, {value: "2 Star", text: "2 Star"}, {value: "3 Star", text: "3 Star"},
                    {value: "4 Star", text: "4 Star"}, {value: "5 Star", text: "5 Star"}]} required={true}/>
            
            <div className="col-span-12 text-lg mt-5"><TitleColor1>/ Sport Results</TitleColor1></div>
            <div id="children-sportResult" className="col-span-12">
                <UpdatableTable rowsJSX={sportResults.map((r, i) => {
                        return <SportResultRow key={i} info={r} rows={sportResults} setRows={setSportResults} idx={i} isSubmitted={isSubmitted}/>
                    })}
                    rows={sportResults} setRows={setSportResults}
                    newRow={{"rank": "", "year": 0, "rider": "", "height": "", "competition": ""}}
                    isSubmitted={isSubmitted}/>
            </div>

            <div className="col-span-12 text-lg mt-5"><TitleColor1>/ Prize Earnings</TitleColor1></div>
            <div id="children-sportResult" className="col-span-12">
                <UpdatableTable rowsJSX={prizeEarnings.map((r, i) => {
                        return <PrizeEarningsRow key={i} info={r} rows={prizeEarnings} setRows={setPrizeEarnings} idx={i} isSubmitted={isSubmitted}/>
                    })}
                    rows={prizeEarnings} setRows={setPrizeEarnings}
                    newRow={{"year": 0, "prize": ""}}
                    isSubmitted={isSubmitted}/>
            </div>*/}

            <div className="col-span-12 grid mt-5">
                <UploadTokenFilesForm
                    coownership={{
                        names: coownership, setNames: setCoownership,
                        tbr: props.coownTBR, tbu: props.coownTBU, setTbr: props.setCoownTBR, setTbu: props.setCoownTBU
                    }}
                    prospectus={{
                        names: prospectus, setNames: setProspectus,
                        tbr: props.prospTBR, tbu: props.prospTBU, setTbr: props.setProspTBR, setTbu: props.setProspTBU
                    }}
                    isSubmitted={isSubmitted} />
            </div>

            <div className="col-span-12 mt-5">
                <UploadCardImage images={images2} assetName={assetName}/>
            </div>

            <div className="col-span-12 mt-5">
                <UploadFilesForm
                    images={{
                        names: images2, setNames: setImages2,
                        tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu
                    }}
                    videos={{
                        names: videos, setNames: setVideos,
                        tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu
                    }}
                    documents={{
                        names: documents, setNames: setDocuments,
                        tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu
                    }}
                    isSubmitted={isSubmitted} />
            </div>
        </div>
    </>
}