import { FaqQuestion, FaqAnswer } from "../components/typography/Typogtaphy";

export default function AboutRoute(): JSX.Element {
    
    return <>
        <div className="md:pl-20 pl-5 md:pr-20 pr-5">
            <div className="font-roboto font-thin text-left">
                <div className="text-succ2 text-6xl text-center mt-10 mb-10">ABOUT DEXX</div>

                <FaqQuestion content="What" />
                <FaqAnswer content="We are building the leading European platform specialized in the tokenization of alternative assets (both existing and digital-native), this way allowing their fractional ownership as well as secondary market trading, with the goal of letting alternative investing become liquid and democratized." />

                <FaqQuestion content="How" />
                <FaqAnswer content="This is made possible by combining (i) the endorsement from regulators and (ii) our deep understanding of financial market dynamics with (iii) the possibilities deriving from the use of three innovative technological enablers: blockchain, security tokens and smart contracts." />

                <FaqQuestion content="Who" />
                <FaqAnswer content="DEXX is a Swiss-based FinTech platform promoted by a team of leading international investment, legal and technology experts. Look Lateral Inc and Colombo & Partners are founding partners of this venture." />

                <FaqQuestion content="Why" />
                <FaqAnswer content="Coherently with the strong digitalization trends reshaping the entire financial sector, traditional financial instruments will be progressively upgraded/replaced by digital securities (security tokens), expanding investment opportunities and increasing security, automation and efficiency." />

                <div className="mb-20"></div>
            </div>
        </div>
    </>
}
