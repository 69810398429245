import { ActionType } from '../action-types'
import { Actions } from '../actions'
import { UserAccount, defaultUserState } from "../../UserContext"

interface issuerInfoState {
    loading: boolean
    payload?: UserAccount
    //type: string
}

const defaultState: issuerInfoState = {
    loading: false,
    payload: defaultUserState,
    //type: 'null',
}

const issuerInfoReducer = (state: issuerInfoState = defaultState, action: Actions): issuerInfoState => {
    //console.log(action)
    switch(action.type) {
        case ActionType.GET_ISSUER_INFO_REQUEST:
            return {
                loading: true,
                //type: 'get',
            }
        case ActionType.GET_ISSUER_INFO_ERROR:
            return {
                loading: false,
                payload: action.error,
                //type: 'get',
            }
        case ActionType.GET_ISSUER_INFO_SUCCESS:
            return {
                loading: false,
                payload: action.payload.issuer_info,
                //type: 'get',
            }

        /*case ActionType.SET_ISSUER_INFO_REQUEST:
            return {
                loading: true,
                type: 'set',
            }
        case ActionType.SET_ISSUER_INFO_ERROR:
            return {
                loading: false,
                payload: action.error,
                type: 'set',
            }
        case ActionType.SET_ISSUER_INFO_SUCCESS:
            return {
                loading: false,
                payload: action.payload,
                type: 'set',
            }*/
        case ActionType.CLEAR_ALL:
            return {
                loading: false,
                payload: undefined,
                //type: 'null',
            }

        default:
            return state
    }
}

export default issuerInfoReducer

