import { FormControl, Input, InputLabel, MenuItem, Select } from "@mui/material";

export default function DexxInput(props: {value: string, fun: Function, className?: string}) : JSX.Element {
    return <>
        <Input value={props.value} onChange={e => props.fun(e.target.value)}
            className={"text-primary "+props.className}/>
    </>
}

function checkFloat(value: string, fun: Function, max?: number) {
    if(!value){
        fun("0")
        return
    }
    let n = parseFloat(value.replace(",", "."))
    if(max && !isNaN(n) && n >= max){
        fun(max.toString().replace(".", ","))
        return
    }
    let v = value.replace('.', ',')
    let i = v.indexOf(',')
    //Non decimal
    if(i === -1){
        let a: number = Number(v)
        if (!isNaN(a))
            fun(a)
    }
    //Decimals
    else {
        let integerPart = Number(v.substring(0,i))
        let decimals = v.substring(i+1, i+3)
        let val = integerPart+","+decimals
        //Can't contain '.', needs to be a number and needs to have some decimals
        if(decimals.indexOf(".") === -1 && 
            (!isNaN(Number(decimals)) || decimals.length < 1))
            fun(val)
    }
}

function checkInt(value: string, fun: Function, max?: number) {
    if(!value){
        fun("0")
        return
    }
    let n = parseInt(value)
    if(max && !isNaN(n) && n >= max){
        fun(max.toString())
        return
    }
    
    let a: number = Number(value)
    if (!isNaN(a))
        fun(a)
    
}

export function DexxNumericInput(props: {value: number, fun: Function, 
        className?: string, max?: number, required?: boolean}) : JSX.Element {
    return <>
        <Input value={props.value} onChange={e => checkFloat(e.target.value, props.fun, props.max)}
            className={""+props.className} 
            required={props.required ? true : false}/>
    </>
}

export function DexxIntegerInput(props: {value: number, fun: Function, 
    className?: string, max?: number, required?: boolean}) : JSX.Element {
return <>
    <Input value={props.value} onChange={e => checkInt(e.target.value, props.fun, props.max)}
        className={""+props.className} 
        required={props.required ? true : false}/>
</>
}

export function DexxSelectInput(props: {value: any, fun: Function, items: any[], 
        itemText?: string[], label?: string, className?: string}) : JSX.Element {
    return <>
        <FormControl variant="standard" className="w-full">
            {props.label ? <InputLabel id="label">{props.label}</InputLabel> : <></>}
            <Select 
                className={""+props.className}
                labelId="label"
                label={props.label}
                value={props.value}
                onChange={e => props.fun(e.target.value)}
                autoWidth>
                {props.items.map((i: any, idx: number) => {
                        return <MenuItem value={i} key={idx}>
                            {props.itemText ? props.itemText[idx] : i}</MenuItem>
                    })}
            </Select>
        </FormControl>
    </>
}

export function DexxNumericInputNoBorder(props: {value: any, fun: Function,
        className?: string, max?: number}) : JSX.Element {
    return <>
        <input className='bg-transparent text-white text-2xl border-0
                            font-bold tracking-wider w-full'
                value={props.value} onChange={e => checkFloat(e.target.value, props.fun, props.max)}                           >
        </input>
    </>
}