import { UUID } from 'uuid-generator-ts';
import { Asset } from './AssetContext';
import { AMMTrade } from './components/TradeInterface';
import { PoolState, PrimarySaleSet, Token } from './PoolContext';
import { /*loginInfo,*/ strUserInvestor, strLoginInfo, UserAccount } from './UserContext'
import { addTimezoneOffset } from './utils';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

interface Token_ {
    uuid: string
    name: string
    description: string
    creatorID: string
}

interface MintingTransaction {
    token: Token_
    type: string
    ticker: string
    initialSupply: number
    precision: number
    mintAddress: string
}

export default class DexxClient {
    baseUrl: string
    realoading: boolean

    constructor() {
        this.baseUrl = process.env.REACT_APP_BASE_URL ? 
            process.env.REACT_APP_BASE_URL : "https://develop.api.dexx.finance/api/v1";
        this.realoading = false
    }

    setReloading(b: boolean) {
        this.realoading = b
    }

    async signInCallback(idToken: string) {
        return this._post("/cognito/callback/signin", {id_token: idToken})
    }

    async signOutCallback(idToken: string) {
        return this._post("/cognito/callback/signout", {id_token: idToken})
    }

    async transferTokens(senderAddress: string, recipientAddress: string) {
        return this._post('/transfer', {
            "to": recipientAddress,
            "from": senderAddress,
            "token": {
                "uuid": "123",
                "name": "testToken",
                "description": "blah blah"
            }
        })
    }

    async mintToken(timezoneOffset: number) {
        const creatorID: string = new UUID().toString()
        const tokenID: string = new UUID().toString()
        const tokenName: string = "Token A"
        const tokenDescription: string = "A great token"
        const token: Token_ = { uuid: tokenID, name: tokenName, description: tokenDescription, creatorID }
        const mintAddress: string = '0x4F1F64701bfEf0047c6882e495C09037cE895151'
        const mintingTrx: MintingTransaction = { token, type: "Type 1", ticker: "", initialSupply: 1, precision: 18, mintAddress }
        return this._post('/mint', {
            "dcrn": "Transaction::L1::FullTransaction",
            "header": {
                "txn_type": "TOKEN-MINT",
                "timestamp": addTimezoneOffset(new Date().getTime().toString(), timezoneOffset)
            },
            "payload": {
                "token-uuid": token.uuid,
                "type": mintingTrx.type,
                "token-name": token.name,
                "ticker": mintingTrx.ticker,
                "description": token.description,
                "creator": token.creatorID,
                "initial-supply": mintingTrx.initialSupply,
                "precision": mintingTrx.precision,
                "mint-address": mintAddress
            }
        })
    }
    
    async SignInUserAccount(accessingUser: strLoginInfo) {
        return this._post('/account/signin', {
            "email"       : accessingUser.username,
            "password"    : accessingUser.password,
        })
    }

    async SignOutUserAccount() {  
        return this._post('/account/signout')
    }

    async getBasicAccountProfile() {   // get basic account
        return this._get('/account/basic-info')
    }
    
    async getAccountDetails() {   // get detailed account
        return this._get('/account/details')
    }

    async getPairList() {
        return this._get('/pools/getAllPools')
    }

    async setPairList(newPool: PoolState) {
        return this._post('/pools/setPool', {newPool})
    }

    /*async getPoolTokenList() {
        return this._get('/pools/getAllPoolTokens')
    }*/

    async updatePool(newPool: PoolState) {
        return this._post('/pools/updatePool', {
            'poolID': newPool.uuid,
            'amountA': newPool.amount_a, 
            'amountB': newPool.amount_b
        })
    }

    async getPool(poolID: string) {
        return this._post('/pools/getPool', {
            'poolID': poolID
        })
    }

    async sendAMMTrade(trade: AMMTrade) {
        return this._post('/pools/tradePool', trade)
    }

    async createUserAccount(newUser: UserAccount) {
        return this._post('/account/signup', {
            'email'          : newUser.email,
            'password'       : newUser.password,
            'confirm_password' : newUser.confirm_password

            // need to add flag "i would also issue"
        })
    }
    
    async emailVerification() {
        return this._get('/account/verify-email')
    }
    
    async setAccountDetails(newUser: any) {   // new api for additional info in signup process     
        return this._post('/account/details', newUser)
    }

    async updateAccountDetails(newUser: any) {   // edit additional info in signup process     
        return this._post('/account/details', newUser)
    }

    async setInvestorPermissions() {   // new api to set investor permissions     
        return this._post('/investor/permission')
    }

    async setIssuerPermissions() {   // new api to set issuer permissions     
        return this._post('/issuer/permission')
    }

    async getIssuerKYCDetails() {   // new api for issuer kyc data
        return this._get('/issuer/info')
    }
    
    
    async editInvestorProfile(newUser: strUserInvestor) {   // using in profile page but to be removed
        return this._put('/account/profile', {
            'first_name'     : newUser.firstName,
            'middle_name'    : newUser.middleName,
            'last_name'      : newUser.lastName,
            'street_number'  : newUser.streetNumber,
            'street_name'    : newUser.streetName,
            'city'           : newUser.city,
            'date_of_birth'  : newUser.dateOfBirth,
            'country'        : newUser.country,
            'postal_code'    : newUser.postalCode,
            'telephone'      : newUser.telephone,
            'tax_residence'  : newUser.taxResidence,
            'username'       : newUser.email,
            'email'          : newUser.email,
            'password'       : newUser.password,
        })
    }

    async getAssetInfo(uuid: string, isLogged: boolean | undefined) {
        let endpoint = (isLogged || isLogged === undefined) ? "/asset" : "/public_asset"
        return this._get(endpoint, 'uuid='+uuid)
    }

    async getAllAssetCategories() {
        return this._get('/asset/categories')
    }

    async getAssetTypesByCategory(name: string) {
        return this._get('/asset/category/types', 'category='+name)
    }

    async getAssetCategoryByName(name: string) { // api to be created
        return this._get('/asset/category', 'category_name='+name)
    }

    async getAssetTypeByName(name: string) { // api to be created 
        return this._get('/asset/category/type', 'type_name='+name)
    }
    
    async getAssetCurrencies() {
        return this._get('/currency_cds')
    }

    async getTokenInfo(uuid:string, isLogged: boolean | undefined) {
        let endpoint = (isLogged || isLogged === undefined) ? "/token" : "/public_token"
        return this._get(endpoint, 'asset_uuid='+uuid)
    }

    async getTokenInfoByTokenUUID(uuid:string, isLogged: boolean | undefined) {
        let endpoint = (isLogged || isLogged === undefined) ? "/token" : "/public_token"
        return this._get(endpoint, 'token_uuid='+uuid)
    }

    async getTransactions() {
        return this._get('/investor/transactions')
    }

    async getIssuerTransactions() {
        return this._get('/issuer/transactions')
    }

    async getBalance() {
        return this._get('/investor/balance')
    }

    async getIssuerBalance() {
        return this._get('/issuer/balance')
    }

    async getAllAssets() {
        return this._get('/tokens')
    }

    async getMediaFile(hashname: string) {
        return this._get("/aws-download", 'filename='+hashname) // nome + symbol
    }

    async getMediaUploadLink(hashname: string) {
        return this._get("/aws-upload", 'filename='+hashname)
    }

    async getAssetDocument(uuid: string, isLogged: boolean | undefined) {
        let endpoint = (isLogged || isLogged === undefined) ? "/asset/medias" : "/asset/public_medias"
        return this._get(endpoint, "asset_uuid="+uuid)
    }

    async getIssuedTokens() {
        return this._get("/issuer/issued-tokens")
    }

    async getIssuedAssets() {
        return this._get("/issuer/issued-assets")
    }

    async createAsset(asset: Asset) {
        return this._post("/asset/save", asset)
    }

    async submitAsset(asset: Asset) {
        return this._post("/asset/submit", asset)
    }

    async createToken(token: Token) {
        return this._post("/token", token)
    }

    /*async submitToken(token: Token) {
        return this._post("/token", token)
    }*/
    
    async getAllPrimarySales() {
        return this._get('/primary_sales', "active="+false)
    }

    async getIssuerPrimarySales() {
        return this._get('/issuer/primary_sales')
    }

    async getPrimarySale(param: string) {
        return this._get('/primary_sale', param)
    }

    async submitPrimarySale(primarySaleSet: PrimarySaleSet) {
        return this._post("/primary_sale", primarySaleSet)
    }

    async startPrimarySale(uuid: string) {
        return this._post('/primary_sale/start', {"uuid":uuid})
    }

    async pausePrimarySale(uuid: string) {
        return this._post('/primary_sale/pause', {"uuid":uuid})
    }

    async resumePrimarySale(uuid: string) {
        return this._post('/primary_sale/resume', {"uuid":uuid})
    }

    async stopPrimarySale(uuid: string) {
        return this._post('/primary_sale/end', {"uuid":uuid})
    }

    async uploadFile(path: string, file: File) {
        return this._put_aws(path, file)
    }

    async setAssetMedia(media: any) {
        return this._post("/asset/media", media)
    }

    async deleteAssetMedia(filename: string) {
        return this._delete("/asset/media", "filename="+filename)
    }

    async getAllMediaTypes() {
        return this._get("/media_types")
    }

    async getKYCAccessToken(user: string) {
        return this._get("/users/"+user+"/token", undefined, "https://sandbox.instance.kyc-chain.com/integrations/v2")
    }

    async getBlockchainTransactionDetails(trx_identifier: string) {
        return this._get("/blockchain/trx_details", "trx_identifier="+trx_identifier)
    }

    async getDocumentGroups() {
        return this._get("/document_groups")
    }

    async bankDeposit(amount : string) {
        return this._post("/bank/deposit", {"amount" : amount})
    }

    async bankWithdraw(info : any) {
        return this._post("/bank/withdraw", info)
    }

    async submitBuyPS(ps: any){
        return this._post("/primary_sale/buy", {
            primary_sale_uuid: ps.uuid,
            quantity: ps.amount
        })
    }
    
    async getBankTransactions() {
        return this._get("/bank/list-transactions")
    }

    async getFeesFromCategory(category: string) {
        return this._get("/primary_sale/fees", "category=" + category)
    }

    async getTokenStats(uuid: string) {
        return this._get("/issuer/token", "token_uuid=" + uuid)
    }
    
    async getIssuerInfo() {
        return this._get("/issuer/info")
    }

    async setIssuerInfo(info: any) {
        return this._post("/issuer/info", {
            name: info.issuerName,
            description: info.issuerDescr,
            web_site: info.webSite
        })
    }

    async getSignupEnabled() {
        return this._get("/cognito/signup/enabled")
    }

    async sendLiquidationPrice(info: {token_uuid: string, price: number}) {
        return this._post("/buyback", info)
    }

    async createLiquidityPool(info: any) {
        return this._post("/liquidity_pool", info)
    }

    async getAllLiquidityPools() {
        return this._get('/earn/get-approved-lps')
    }

    async getIssuerLiquidityPools(uuid: string) {
        if(uuid === "") return this._get('/issuer/liquidity_pools')
        else return this._get('/issuer/liquidity_pools', "token_uuid="+uuid)
    }

    async getLiquidityPool(uuid: string) {
        return this._get('/earn/get-details-lp', "lp_uuid="+uuid)
    }

    async addLiquidityPool(info: any) {
        return this._post("/liquidity_pool/add", info)
    }

    async removeLiquidityPool(info: any) {
        return this._deleteBody("/liquidity_pool/remove", info)
    }

    async getDetailsLiquidityPool(uuid: string) {
        return this._get("/earn/get-details-lp", "lp_uuid=" + uuid)
    }

    async destroyLiquidityPool(uuid: string){
        return this._delete("/liquidity_pool", "lp_uuid="+uuid)
    }


    async getInvestorShares(lp_uuid:string, only_logged_user:boolean) {
        if(lp_uuid === "") return this._get('/issuer/get-lp-shares', "only_logged_user="+only_logged_user)
        else return this._get('/issuer/get-lp-shares', "lp_uuid="+lp_uuid+"&only_logged_user="+only_logged_user)
    }
  
    async swapTokens(info: any) {
        return this._post("/liquidity_pool/swap", info)
    }

    async getLPQuote(r: any) {
        return this._get("/liquidity_pool/quote", 
            "lp_uuid=" + r.lp_uuid +"&"+
            "token_a_uuid=" + r.token_a_uuid +"&"+
            "amount_a=" + r.amount_a +"&"+
            "is_buy=" + r.is_buy)
    }

    async getTradeMetricsPrimarySale(uuid: string, last_days: string) {
        return this._get("/trade_metrics/ps-ohlcv", 
            "ps_uuid=" + uuid + "&" +
            "last_days=" + last_days)
    }

    async getTradeMetricsLiquidityPool(uuid: string, last_days: string) {
        return this._get("/trade_metrics/lp-ohlcv", 
            "lp_uuid=" + uuid + "&" +
            "last_days=" + last_days)
    }

    async getTradeMetricsP2P(uuid: string, last_days: string) {
        return this._get("/trade_metrics/p2p-ohlcv", 
            "p2p_uuid=" + uuid + "&" +
            "last_days=" + last_days)
    }

    async getAllLiquidityPoolRequests(params?: any) {
        return this._post("/liquidity_pool/get-all-lps", params ? params : {})
    }

    async getTradeMetricsToken(uuid: string, last_days: string) {
        return this._get("/trade_metrics/token-ohlcv",
            "token_uuid="+uuid + "&" +
            "last_days=" + last_days)
    }

    async getP2P(params: any) {
        return this._post("/p2p/get-p2p", params)
    }

    async buyP2P(params: {uuid: string, amount: string | number}) {
        return this._post("/p2p/fill", params)
    }

    async createP2POffer(params: any) {
        return this._post("/p2p/create", params)
    }

    async createP2PBidOffer(params: any) {
        return this._post("/p2p/create-bid", params)
    }

    async createP2PAskOffer(params: any) {
        return this._post("/p2p/create-ask  ", params)
    }

    async deleteNotApprovedAsset(uuid: string) {
        return this._delete("/asset/delete", "uuid="+uuid)
    }

    async deleteNotApprovedToken(uuid: string) {
        return this._delete("/token/delete", "uuid="+uuid)
    }

    async getBuyersPrimarySale(uuid: string) {
        return this._get("/primary_sale/get-buyers", "uuid=" + uuid)
    }

    async getTransactionsPrimarySale(uuid: string) {
        return this._get("/primary_sale/get-buyers-trx", "uuid=" + uuid)
    }

    async deleteP2POffer(p2p_uuid: string){
        return this._post("/p2p/delete",  {
            p2p_uuid: p2p_uuid
        })
    }

    async getPayByLink(info : any) {
        return this._post("/payment/pay-by-link", info)
    }
    
    async getAllSecondaryMarket() {
        return this._get("/secondary-market/tokens")
    }

    async getAllPaymentFees(amount: number) {
        return this._get("/payment-methods/get-all-payment-methods", "amount=" + amount)
    }

    async _get(path: string, params?: string, baseUrl?: string) {
        let url = (baseUrl ? baseUrl : this.baseUrl) + path;
        if (params) {
            let p = new URLSearchParams(`bin=${encodeURIComponent(btoa(params))}`).get('bin')
            url += '?'
            if(p) url+= atob(p);
        }
        const response = await fetch(url, {
            mode: 'cors',
            credentials: 'include',
            headers: {
                "Authorization": "Bearer " + cookies.get("token_id")
            }
        });
        switch(response.status) {
            case 200:
                return [response.status, await response.json()]
            case 403:
                let r = await response.json()
                if(r.error === "Invalid access token") {
                    if(cookies.get("token_id") && (cookies.get("token_id") !== "expired" 
                            && cookies.get("logged_in"))) {
                        cookies.set("token_id", "expired", {path: "/", sameSite: "strict"})
                        cookies.set("logged_in", false, {path: "/", sameSite: "strict"})
                        cookies.set("expiredPU", true, {path: "/", sameSite: "strict"})
                        cookies.set("session_expired", true, {path: "/", sameSite: "strict"})
                        cookies.remove("username", {path: "/", sameSite: "strict"})
                        cookies.remove("user_email", {path: "/", sameSite: "strict"})
                        cookies.remove("new_user", {path: "/", sameSite: "strict"})
                        window.location.reload();
                    }
                }
                return [response.status, await response.json()]
            default:
                return [response.status, null]
        }
    }

    async _post(path: string, data?: Object) {
        const url = this.baseUrl + path;
        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + cookies.get("token_id")
            },
            body: JSON.stringify(data)
        });
        switch(response.status) {
            case 200:
            case 400:
                return [response.status, await response.json()]
            case 403:
                let r = await response.json()
                if(r.error === "Invalid access token") {
                    if(cookies.get("token_id") && (cookies.get("token_id") !== "expired" 
                            && cookies.get("logged_in"))) {
                        cookies.set("token_id", "expired", {path: "/", sameSite: "strict"})
                        cookies.set("logged_in", false, {path: "/", sameSite: "strict"})
                        cookies.set("expiredPU", true, {path: "/", sameSite: "strict"})
                        cookies.set("session_expired", true, {path: "/", sameSite: "strict"})
                        cookies.remove("username", {path: "/", sameSite: "strict"})
                        cookies.remove("new_user", {path: "/", sameSite: "strict"})
                        window.location.reload();
                    }
                }
                return [response.status, await response.json()]
            default:
                return [response.status, null]
        }
    }

    async _put(path: string, data: Object) {
        const url = this.baseUrl + path;
        const response = await fetch(url, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + cookies.get("token_id")
            },
            body: JSON.stringify(data)
        });
        switch(response.status) {
            case 200:
                return [response.status, await response.json()]
            case 403:
                let r = await response.json()
                if(r.error === "Invalid access token") {
                    if(cookies.get("token_id") && (cookies.get("token_id") !== "expired" 
                            && cookies.get("logged_in"))) {
                        cookies.set("token_id", "expired", {path: "/", sameSite: "strict"})
                        cookies.set("logged_in", false, {path: "/", sameSite: "strict"})
                        cookies.set("expiredPU", true, {path: "/", sameSite: "strict"})
                        cookies.set("session_expired", true, {path: "/", sameSite: "strict"})
                        cookies.remove("username", {path: "/", sameSite: "strict"})
                        cookies.remove("new_user", {path: "/", sameSite: "strict"})
                        window.location.reload();
                    }                }
                return [response.status, await response.json()]
            default:
                return [response.status, null]
        }
    }

    async _put_aws(path: string, data: any) {
        let contentType = 'application/octet-stream'
        if(data.type === 'image/svg+xml') contentType = 'image/svg+xml'
        else if(data.type === 'application/pdf') contentType = 'application/pdf'
        const response = await fetch(path, {
            method: 'PUT',
            //mode: 'cors',
            //credentials: 'include',
            headers: {
                'Content-Type': contentType
            },
            body: data
        });
        if (response.status === 200) {
            return [response.status, response];
        }
        return [response.status, null];
    }

    async _delete(path: string, params?: string) {
        let url = this.baseUrl + path;
        if (params) {
            let p = new URLSearchParams(`bin=${encodeURIComponent(btoa(params))}`).get('bin')
            url += '?'
            if(p) url+= atob(p);
        }
        const response = await fetch(url, {
            method: 'DELETE',
            mode: 'cors',
            credentials: 'include',
            headers: {
                //contentType: 'application/json',
                Authorization: "Bearer " + cookies.get("token_id")
            }
        });
        switch(response.status) {
            case 200:
                return [response.status, await response.json()]
            case 403:
                let r = await response.json()
                if(r.error === "Invalid access token") {
                    if(cookies.get("token_id") && (cookies.get("token_id") !== "expired") 
                            && cookies.get("logged_in")) {
                        cookies.set("token_id", "expired", {path: "/", sameSite: "strict"})
                        cookies.set("logged_in", false, {path: "/", sameSite: "strict"})
                        cookies.set("expiredPU", true, {path: "/", sameSite: "strict"})
                        cookies.set("session_expired", true, {path: "/", sameSite: "strict"})
                        cookies.remove("username", {path: "/", sameSite: "strict"})
                        cookies.remove("new_user", {path: "/", sameSite: "strict"})
                        window.location.reload();
                    }                }
                return [response.status, await response.json()]
            default:
                return [response.status, null]
        }
    }

    async _deleteBody(path: string, data?: Object) {
        const url = this.baseUrl + path;
        const response = await fetch(url, {
            method: 'DELETE',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + cookies.get("token_id")
            },
            body: JSON.stringify(data)
        });
        switch(response.status) {
            case 200:
                return [response.status, await response.json()]
            case 403:
                let r = await response.json()
                if(r.error === "Invalid access token") {
                    if(cookies.get("token_id") && (cookies.get("token_id") !== "expired") 
                            && cookies.get("logged_in")) {
                        cookies.set("token_id", "expired", {path: "/", sameSite: "strict"})
                        cookies.set("logged_in", false, {path: "/", sameSite: "strict"})
                        cookies.set("expiredPU", true, {path: "/", sameSite: "strict"})
                        cookies.set("session_expired", true, {path: "/", sameSite: "strict"})
                        cookies.remove("username", {path: "/", sameSite: "strict"})
                        cookies.remove("new_user", {path: "/", sameSite: "strict"})
                        window.location.reload();
                    }                }
                return [response.status, await response.json()]
            default:
                return [response.status, null]
        }
    }
}

export const client = new DexxClient();
