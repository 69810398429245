import arrow from "../media/arrow3.png"
import arrowWarning from "../media/arrow-warning.png"
import IconProfile from "../media/IconProfile.png"
import { useState } from "react"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { State } from "../state"
import { getProfileUrl } from "../utils"
import { DexxButton } from "../components/light/LightButton"
import { PageDescription, TitleColorWarning } from '../components/typography/Typogtaphy'
import { useHistory  } from 'react-router-dom'

export default function MenuRoute(): JSX.Element {
    
    let history = useHistory()
    const descriptions = [
        "Here you can see a recap of your account information.",
        "Here you can manage and create assets and tokens.",
        "Here you will fund your wallet and see a summary of your investment portfolio holdings.",
        "Here you will use your wallet funds to purchase one or more available tokens.",
        "Here you can find useful information about account set-up, funding, etc.",
        "Look here for answers to the most frequently asked questions."]

    const profileDescriptionIfNoProfile = <>
            <div className="grid ">
                <div className="grid justify-items-center px-5">
                    <div className="font-roboto py-2 tracking-wider right-text">
                        <img src={IconProfile} className={"h-16 ml-5"} alt="Complete my Profile" />
                        <PageDescription title={"Please, complete your profile!"} 
                            description={"We need to capture certain information to grant your Investor request."} 
                            titleColor={"warning"} titleSize="4xl" descrSize="base" />
                        <div>
                            <label className="w-full text-tertiary text-base font-light py-4">
                            Once you complete your profile we can proceed to the Know Your Customer or KYC next step!<br/>
                            Contact <a className="text-warning" href="mailto:info@dexx.finance">info@dexx.finance</a> with any questions on creating your Investor profile.</label>
                        </div>
                        <div  className="flex justify-center flex-wrap mt-6 text-tertiary">
                            <div className="">
                                <DexxButton onClick={() => {history.push("/account/additional-info")}} className="rounded-full px-5 py-2"
                                label="Complete my Profile" 
                                color1="navy_blue" color2="warning"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    const profileDescriptionIfNotKYC = <>
        <div className="grid ">
            <div className="grid justify-items-center px-5">
                <div className="font-roboto py-2 tracking-wider right-text">
                    <img src={IconProfile} className={"h-16 ml-5"} alt="Complete The KYC" />
                    <PageDescription title={"Almost done!"} 
                        description={"Regulations require that we collect this information to confirm your identity"}
                        titleColor={"warning"} titleSize="4xl" descrSize="base" />
                    <div>
                        <TitleColorWarning className="text-xl">Know Your Customer</TitleColorWarning>
                        <label className="w-full text-tertiary text-base font-light py-4">
                            To complete the KYC you will need your passport.<br/>
                            This will take about 5 minutes and must be done in a single sitting.</label>
                    </div>
                    <div  className="flex justify-center flex-wrap mt-6 text-tertiary">
                        <div className="">
                            <DexxButton onClick={() => {history.push("/kyc")}} className="rounded-full px-5 py-2"
                            label="Begin the KYC process" 
                            color1="navy_blue" color2="warning" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>

    const [current, setCurrent] = useState(0)
    const basicAccountState = useSelector((state: State) => state.basicAccount)
    const detailAccountState = useSelector((state: State) => state.detailAccount)

    var isInvestor = basicAccountState.payload ? basicAccountState.payload.is_investor : false
    var isIssuer = basicAccountState.payload ? basicAccountState.payload.is_issuer : false
    var profileDoneMissingKYC = detailAccountState.payload ? detailAccountState.payload.last_name !== "" && detailAccountState.payload.last_name !== undefined ? true : false : false
    var kycSubmitted = basicAccountState.payload ? basicAccountState.payload.kyc_submitted : false
    
    const profileUrl = getProfileUrl(basicAccountState, detailAccountState)
    const [arrows, setArrows] = useState([false, false, false, false, false])

    function showArrow(idx: number) {
        var tmp = [false, false, false, false, false]
        tmp[idx] = true
        setArrows(tmp)
    }

    function hideArrow() {
        var tmp = [false, false, false, false, false]
        setArrows(tmp)
    }

    function Loading() : JSX.Element {
        return <>
        <div className="grid md:grid-cols-2 font-roboto">
            <div className="pr-14">
                <ul className=" font-light text-5xl md:text-right leading-10 tracking-wide list-none">
                    <li className="text-gray flex md:justify-end align-middle items-center my-6">My Profile </li>
                    <li className="text-gray flex md:justify-end align-middle items-center my-6">Trade </li>
                    <li className="text-gray flex md:justify-end align-middle items-center my-6">F.A.Q. </li>
                    <li className="text-gray flex md:justify-end align-middle items-center my-6">My investor portfolio </li>
                    <li className="text-gray flex md:justify-end align-middle items-center my-6">Loyalty Program </li>
                    <li className="text-gray flex md:justify-end align-middle items-center my-6">Education </li>
                </ul>
            </div>
        </div>
    </>
    }

    function KycDone() : JSX.Element {

        function ListItem(props: {
            n: number, link: string, label: string
            disabled?: boolean, hide?: boolean
        }): JSX.Element {
            if (props.hide)
                return <></>
            return <li onClick={() => setCurrent(props.n)}
                className={"align-middle text-tertiary font-light grid md:grid-cols-2"}>
                {props.disabled ?
                    <div className="my-2 text-gray flex md:justify-end align-middle 
                            items-center md:text-5xl mr-7 md:text-right text-2xl">
                        {props.label}
                    </div> : <>
                        <Link to={props.link} className={" flex md:justify-end align-middle " + 
                            "hover:text-succ2 hover:underline hover:underline-offset-8 md:text-5xl " + 
                            "text-2xl my-2 md:text-right items-center"}
                            onMouseEnter={() => { showArrow(props.n); setCurrent(props.n) }}
                            onMouseLeave={() => hideArrow()}>
                            {props.label}<img src={arrow} className={"h-6 ml-1 " +
                                (arrows[props.n] ? " animate-arrow visible" : " invisible")} alt="->" />
                        </Link>
                        <div className="md:ml-12 align-middle flex items-center md:text-xl font-normal">
                            {current === props.n ? descriptions[props.n] : <></>}
                        </div>
                    </>
                }
            </li>
        }

        return <>
        <div className="grid font-roboto">
            <div className="">
                <ul>
                    <ListItem n={0} link={profileUrl} label={"My Profile"} />
                    <ListItem n={1} link={"/issuer/portfolio"} label={"My issuer portfolio"} hide={!isIssuer} />
                    <ListItem n={2} link={"/portfolio"} label={"My investor portfolio"} hide={!isInvestor} />
                    <ListItem n={3} link={"/invest"} label={"Trade"} />
                    <ListItem n={4} link={"/earn"} label={"Earn"} hide={!(isInvestor || isIssuer)} />
                    <ListItem n={5} link={"/support"} label={"F.A.Q."} />
                    <ListItem n={2} link={""} label={"My investor portfolio"} disabled={!isInvestor} hide={isInvestor} />
                    <ListItem n={6} link={""} label={"Loyalty Program"} disabled={true} />
                    <ListItem n={7} link={"/support"} label={"Education"} disabled={true} />
                </ul>
            </div>

        </div>
    </>
    }

    function KycMissing() : JSX.Element {

        function ListItem(props: {n: number, link: string, label: string}): JSX.Element {
            let color = props.n === 0 && !kycSubmitted ? "warning" : "succ2"
            return <li onClick={() => setCurrent(props.n)} className="align-middle">
                <Link to={props.link} className={"text-tertiary flex md:justify-end align-middle items-center py-3" +
                    " hover:text-"+color+" hover:underline hover:underline-offset-8"}
                    onMouseEnter={() => { {showArrow(props.n); setCurrent(props.n)}}} 
                    onMouseLeave={() => { {hideArrow(); showArrow(0); setCurrent(0)}}}>
                    {props.label}<img src={props.n === 0 ? arrowWarning : arrow} 
                    className={"h-6 ml-1 "+(arrows[props.n] ? " animate-arrow visible" : " invisible")} alt="->"/>
                </Link>
            </li>
        }

        return <>
        <div className="grid md:grid-cols-2 font-roboto">
            <div className="pr-8">
                <ul className=" font-light text-5xl md:text-right leading-10 tracking-wide list-none">
                    <ListItem n={0} link={profileUrl} label="My Profile " />
                    <ListItem n={3} link={"/invest"} label={"Trade "}/>
                    <ListItem n={5} link={"/support"} label={"F.A.Q. "}/>
                    <li className="text-gray flex md:justify-end align-middle items-center mb-6 mt-3">
                        My investor portfolio <img src={arrow} className={false ? "animate-arrow visible" : "invisible"} alt="->"/></li>
                    <li className="text-gray flex md:justify-end align-middle items-center my-6">
                        Loyalty Program <img src={arrow} className={false ? "animate-arrow visible" : "invisible"} alt="->"/></li>
                    <li className="text-gray flex md:justify-end align-middle items-center my-6">
                        Education <img src={arrow} className={false ? "animate-arrow visible" : "invisible"} alt="->"/></li>
                </ul>
            </div>
            <div className="flex items-center align-middle p-8">
                <div className="flex flex-col">
                    <p className="text-tertiary text-xl">
                        {current === 0 && !profileDoneMissingKYC  ? 
                            profileDescriptionIfNoProfile 
                        : 
                        current === 0 && profileDoneMissingKYC && !kycSubmitted  ?  
                            profileDescriptionIfNotKYC 
                        : descriptions[current]}
                    </p>
                </div>
            </div>
        </div>
    </>
    }

    return basicAccountState.loading || detailAccountState.loading ? <Loading /> : 
                profileDoneMissingKYC && kycSubmitted ? <KycDone /> : <KycMissing />    
    
}