import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select'

export function SelectFilter(props: {label: String, value: String, values: any[], onChange?: any, className?: string}): JSX.Element {
    return <>
        <Box className="min-w-20 ml-5">
            <FormControl className="">
            <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.value}
                label="Type"
                onChange={props.onChange}
            >
                {props.values.map(({name, val}, i) => <MenuItem key={i} value={val}>{name}</MenuItem>)}
            </Select>
            </FormControl>
        </Box>
    </>
}

export function InputFilter(props: {label: String, value: String, onChange?: any, className?: string}): JSX.Element {
    return <>
        <Box
            component="form"
            //sx={{'& > :not(style)': { m: 1, width: '25ch' },}}
            className={props.className}
            noValidate
            autoComplete="off"
            >
            <TextField id="outlined-basic" label={props.label} variant="outlined" value={props.value}
                onChange={props.onChange} 
                /*onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    props.onChange(event.target.value);
                }}*/ />
        </Box>
    </>
}