import { FaqQuestion, FaqAnswer } from "../components/typography/Typogtaphy";

export default function DisclaimerRoute() : JSX.Element {
    return <>
        <div className="md:pl-20 pl-5 md:pr-20 pr-5">
            <div className="font-roboto font-thin text-left">
                <div className="text-succ2 text-6xl text-center mt-10 mb-10">DISCLAIMER</div>

                <FaqQuestion content="REVERSE SOLICITATION" />

                <div className="text-tertiary text-xl mt-3">
                DEXX S.A. (“<b>DEXX</b>” or the “<b>Company</b>”) is licensed by the Self Regulated Body SoFIT to operate as a financial intermediary. The company is not subject to FINMA supervision and can accept assets only for a limited amount (maximum CHF 1 Mio or maximum for 60 days). No interests are paid and the assets within DEXX SA are not protected by the Swiss deposit guarantee in case of bankruptcy.
                </div>

                <div className="text-tertiary text-xl mt-3">
                The Company does not actively offer its products or services in any country of the European Economic Area (“<b>EEA</b>”). It does not actively offer tokens qualifying as financial instruments in accordance with Directive 2014/65/EU (“<b>MiFID2</b>”) and is not authorized to operate in accordance with the MiFID2 framework. DEXX is not licensed or registered to operate as virtual asset service provider (VASP) pursuant to the national laws of EEA Member States, and does not hold any other license or registration in accordance with the laws of any such EEA jurisdiction.
                </div>
                
                <div className="text-tertiary text-xl mt-3">
                The Company does not perform processing activities that are related to the offer any goods or services to data subjects in the European Union, or the monitoring of their behaviour as far as their behaviour takes place within the European Union. The personal data of the users will be processed in accordance with the Swiss Federal Data Protection Act.
                </div>

                <div className="text-tertiary text-xl mt-3">
                Users that are resident in the EEA may become customers of DEXX on a reverse enquiry basis, in the absence of any promotional, marketing or offering activities carried out directly or indirectly by DEXX in the EEA.
                </div>

                <FaqQuestion content="FIMART Euro Voucher" />

                <div className="text-tertiary text-xl mt-3">
                FIMART Euro Voucher can only be used to purchase the tokens marketed and traded on the platform.
                </div>

                {/*<FaqQuestion content="DISCLAIMER 2 – NO REVERSE SOLICITATION IN SOME EEA JURISDICTIONS" />

                <div className="text-tertiary text-xl mt-3">
                DEXX S.A. (“<b>DEXX</b>” or the “<b>Company</b>”) is licensed by FINMA to operate as [•].
                </div>

                <div className="text-tertiary text-xl mt-3">
                Except for [name of the jurisdictions], the Company does not actively offer its products or services in any country of the European Economic Area (“<b>EEA</b>”). It does not actively offer tokens qualifying as financial instruments in accordance with Directive 2014/65/EU (“<b>MiFID2</b>”) and is not authorized to operate in accordance with the MiFID2 framework. DEXX is not licensed or registered to operate as virtual asset service provider (VASP) pursuant to the national laws of EEA Member States, and does not hold any other license or registration in accordance with the laws of any such EEA jurisdiction.
                </div>

                <div className="text-tertiary text-xl mt-3">
                As far as [name of the jurisdictions] is concerned, [•]. [Note: to be completed with a description of the regime applying in the relevant jurisdiction.] 
                </div>

                <div className="text-tertiary text-xl mt-3">
                Users that are resident in the EEA may become customers of DEXX on a reverse enquiry basis, in the absence of any promotional, marketing or offering activities carried out directly or indirectly by DEXX in the EEA.
                </div>

                <div className="text-tertiary text-xl mt-3">
                Due to the fact that the Company actively offer its services in [name of the jurisdictions], DEXX is subject to the obligation to treat the personal data of its users in accordance with the Regulation (EU) No. 679/2016 (the “<b>GDPR</b>”). The privacy policy of DEXX – which is available at this link – provides information, among others, on the data that are processed by the Company, the purposes and legal basis of the processing, the recipients or categories of recipients of the personal data, any transfer of personal data to a third country, the period for which the personal data will be stored and the rights of the data subject in relation to the data processed by the Company.
                </div>*/}
            </div>
        </div>
    </>
}