import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux"
import { LiquidityPool } from "../../PoolContext"
import { FileSrc } from "../../AssetContext"
import { actionCreators } from "../../state"
import { euroFormat, quantityFormat, euroFeesFormat } from "../../utils"
import { DexxButton } from "../light/LightButton"
import Popup, { DexxPopup } from "../Popup"
import LabelAndInput from '../LabelAndInput'

export default function SwapLPPopup(props: { tokenName: string, tokenUuid?: string,
        buyLP: LiquidityPool, sellLP: LiquidityPool, closeFunction: Function, isBuy: boolean }): JSX.Element {

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)

    const [maxTokenA, setMaxTokenA] = useState<number>(0)
    const [balance, setBalance] = useState<any>()
    const [quantity, setQuantity] = useState<number>(0)
    const [request, setRequest] = useState<any[]>([])
    const isBuy = props.isBuy
    const [getQuote, setGetQuote] = useState<boolean>(true)
    const [quote, setQuote] = useState<any>()
    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [showConfirmPopUp, setConfirmShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")

    const [effectivePrice, setEffectivePrice] = useState<number>(0)
    const [estValue, setEstValue] = useState<number>(0)
    const [dexxFees, setDexxFees] = useState<number>(0)
    const [amountB, setAmountB] = useState<number>(0)
    const [grossAmount, setGrossAmount] = useState<number>(0)

    const [owningToken, setOwningToken] = useState<boolean>(false)
    const [coOwnership, setCoOwnership] = useState<boolean>(false)
    const [prospectus, setProspectus] = useState<boolean>(false)
    const [coOwnDoc, setCoOwnDoc] = useState<FileSrc>()
    const [prospectusDoc, setProspectusDoc] = useState<FileSrc>()
    const[media, setMedia] = useState<any>([])
    const[docSrc, setDocSrc] = useState<{url:string, filename: string}[]>([])
    const [quoted, setQuoted] = useState<boolean>(false)

    let balancePT = balance?.payment_tokens?.available_total

    let lp = props.isBuy ? props.buyLP : props.sellLP
    let tokenA = lp ? lp.token_a_ticker : ""
    let color = isBuy ? "succ1" : "succ8"

    useEffect(() => {
        actions.getBalance(setBalance)
        //console.log(lp)
    }, [])

    useEffect(() => {
        actions.getBalance(setBalance)
        if (lp && getQuote) {
            let r = {
                lp_uuid: lp.uuid,
                token_a_uuid: lp.token_a_uuid,
                amount_a: quantity ? quantity : 0,
                is_buy: isBuy
            }
            actions.getLPQuote(r, setQuote)
            setGetQuote(false)
            setQuoted(true)
        }
    }, [lp, getQuote])

    useEffect(() => {
        if(quote) {
            if(quote[0] === 200) {
                let q = quote[1]
                setEffectivePrice(q ? q.effective_price : effectivePrice)
                setEstValue(effectivePrice * (q ? parseInt(q.amount_a) : 1))
                setDexxFees(q ? q.dexx_fees : 0)
                setAmountB(q ? quantity * q.effective_price : amountB)
                setGrossAmount(q ? q.gross_amount : grossAmount)

            } else {
                if(quote[0] === 500 && quote[1]) {
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("Please adjust your quantity.")
                    setShowPopUp(true)
                    setConfirmShowPopUp(false)
                }
            }
        }
    }, [quote])

    useEffect(() => {
        if(request && request.length > 0) {
            if(request[0] === 200 || request[0] === 204) {
                setTitlePopUp("Success")
                let operation = props.isBuy ? "purchased" : "sold"
                setMsgPopUp("You have " + operation + " asset tokens.")
                setShowPopUp(true)
                props.closeFunction()
            } else {
                setTitlePopUp("Sorry!")
                setMsgPopUp(request[1]?.error ? request[1].error : "There was an error while serving the request")
                setShowPopUp(true)
            }
        }
    }, [request])

    useEffect(() => {
        let securityEntries = balance?.balance?.security_entries
        let tokensByCat = securityEntries ? securityEntries.map((t: any) => t.class_balance) : []
        let Alltokens = [].concat.apply([], tokensByCat)
        let tokens: any[] = Alltokens.filter((t: any) => t.symbol === tokenA)
        let newMax = tokens.length > 0 ? tokens[0].available_quantity : 0
        setMaxTokenA(newMax)
    }, [balance])

    useEffect(() => {
        if(balance){
            let sec_token_same_class = balance?.balance?.security_entries?.filter((c: any) => c.class === lp.asset_a_category)
            sec_token_same_class[0]?.class_balance?.forEach((class_token: any) => {
                if(class_token.token_uuid === lp.token_a_uuid){
                    if(class_token.quantity > 0){
                        setOwningToken(true)  
                        setCoOwnership(true)
                        setProspectus(true)
                    }
                }
            });
        }
    }, [lp, balance])
    
    useEffect(() => {
        if(props.isBuy && lp){
            if(lp.asset_a_uuid !== undefined && lp.asset_a_uuid !== "" && lp.asset_a_uuid !== "0")
                actions.getAssetMedia(lp.asset_a_uuid, setMedia)
        }
    }, [lp, props])

    useEffect(() => {
        if(media)
            actions.getDocuments(media, setDocSrc)
    }, [media])
    
    useEffect(() => {
        if(docSrc){
            let tmp:any = []
            docSrc.map((u) => {
                if(u.filename && u.filename.includes("coownership"))
                    tmp.push({name: u.filename, uploaded: true, src: u.url})
            })
            setCoOwnDoc(tmp[0])

            let tmp2:any = [] 
            docSrc.map((u) => {
                if(u.filename && u.filename.includes("prospectus"))
                    tmp2.push({name: u.filename, uploaded: true, src: u.url})
            })
            setProspectusDoc(tmp2[0])
        }

    }, [docSrc])
    
    function labelAndValue(label: string, value: any, colorVal?: string, colorLabel?:string) {
        
        let cV = "gray-5"
        let cL = "primary"
        if(colorVal) cV = colorVal
        if(colorLabel) cL = colorLabel
        return <>
            <div className={"text-" + cL + " font-semibold text-sm"}>
                {label}
                <div className={"font-normal text-sm text-" + cV}>{value}</div>
            </div>
        </>
    }
    
    /*
    if buyng    -> max is the lp_amount_a -2 (or the maximum you can buy with your XEUR)
    if selliing -> max is entire wallet
    
    
    OLD VERSION: 
    
    let max = Math.floor(
        
        isBuy ? 
                (Math.min(balancePT / effectivePrice, (parseInt(lp.amount_a)-2))) 
        :
                (((maxTokenA * effectivePrice) > parseInt(lp.amount_b)) ?
                    Math.floor((parseInt(lp.amount_b)-2) / effectivePrice) : Math.floor(maxTokenA)))*/


    let max = Math.floor(
        isBuy ? 
            effectivePrice && effectivePrice > 0 && balancePT / effectivePrice > 1 ?   // need to check this control
                (Math.min(balancePT / effectivePrice, (parseInt(lp.amount_a)-2))) 
                :
                (parseInt(lp.amount_a)-2)
        :
            maxTokenA
    )

    //console.log("balancePT: " + balancePT + " - effectivePrice:" + effectivePrice + " - div: " + balancePT / effectivePrice + " - lp.amount_a:" + lp.amount_a)
    
    let tolerance : number = (grossAmount / 100)

    function handleSwap() {
        //let amountB = quantity * effectivePrice
        let tolerance = (grossAmount / 100)
        
        let r = {
            lp_uuid: lp.uuid,
            token_a_uuid: lp.token_a_uuid,
            token_b_uuid: lp.token_b_uuid,
            amount_a: quantity,
            amount_b: quantity * effectivePrice,
            is_buy: isBuy,
            min_max_amount_b: isBuy ? grossAmount-0+tolerance : grossAmount-tolerance
        }

        if(coOwnership === false && isBuy){
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please read and agree to the co-ownership agreement before proceeding.")
            setShowPopUp(true)
        } else if(prospectus === false && isBuy){
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please read and agree the asset token prospectus before proceeding.")
            setShowPopUp(true)
        } else if(quantity <= 0){
            setTitlePopUp("Sorry!")
            setMsgPopUp("Please enter a quantity greater than zero.")
            setShowPopUp(true)
        } else if(!isBuy && maxTokenA === 0){
            setTitlePopUp("Sorry")
            setMsgPopUp("You don't own this token.")
            setShowPopUp(true)
        } else
            actions.swapTokens(r, setRequest)
        setConfirmShowPopUp(false)
    }

    function handleChangeQty(v: string){
        setQuantity(parseInt(v ? v : "0"))
        setEffectivePrice(0)
        setEstValue(0)
        setDexxFees(0)
        setAmountB(0)
        setGrossAmount(0)
    }

    return <>
        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={() => {setShowPopUp(false)}} />
        <DexxPopup show={showConfirmPopUp} title={"Confirm"}
            msg={<>
                <div className="font-normal text-center py-5">
                    <div>{"Operation: " + (isBuy ? "Buy" : "Sell")}</div>
                    <div>{lp.token_a_ticker + ": " + quantity}</div>
                    <div>{isBuy ? ("Max XEUR: "+
                            ((grossAmount-0+tolerance) ? euroFormat(grossAmount-0+tolerance) : "0")) : 
                            "Min XEUR: "+
                            ((grossAmount-tolerance) ? euroFormat(grossAmount-tolerance, true) : "0")}
                    </div>
                </div>
            </>} btnOk={true} btnConfirm={true}
            close={() => { setConfirmShowPopUp(false); setQuantity(0); }} handleConfirm={() => { handleSwap() }} />
        
        <div className="w-128 h-full font-roboto mt-4">

            <div className="pl-10 grid grid-cols-3 mb-2">
                <div className="col-span-2">
                    <div className={"text-" + color + " text-sm font-medium"}>
                        My Balance</div>
                </div>
            </div>
            <div className="pl-10">
                <div className="grid grid-cols-2">
                    {labelAndValue(lp.token_a_ticker+":", quantityFormat(maxTokenA), "blue", "blue")}
                    {labelAndValue("XEUR:", euroFormat(balancePT), "blue", "blue")}
                </div>
            </div>

            <div className="bg-gray4lines h-px w-full my-2"/>

            <div className="pl-10">
                <div className="grid grid-cols-2">
                    {labelAndValue("Asset Name:", props.tokenName, "gray4lines", "gray4lines")}
                    {labelAndValue("Ticker:", lp.token_a_ticker, "gray4lines", "gray4lines")}
                </div>
            </div>

            <div className="bg-gray4lines h-px w-full my-2"/>

            <div className="pl-10 grid grid-cols-3 mb-2">
                <div className="col-span-2">
                    <div className={"text-" + color + " text-sm font-medium"}>
                        Reserves of {lp.lp_name}</div>
                </div>
            </div>

            <div className="pl-10">
                <div className="grid grid-cols-2">
                    {labelAndValue(lp.token_a_ticker+":", quantityFormat(lp.amount_a), "blue", "blue")}
                    {labelAndValue("XEUR:", quantityFormat(lp.amount_b), "blue", "blue")}
                </div>
            </div>

            <div className="bg-gray4lines h-px w-full mt-2 " />

            <div className={"text-sm pl-10 pt-5 pb-4 bg-gradient-to-r from-" + color + " to-transparent"}>

                <div className="grid grid-cols-2 mb-2">
                    <div className="font-semibold text-xl text-blue">Quantity *
                        <LabelAndInput label={""} value={quantity} fun={(v: string) => /*setQuantity(parseInt(v ? v : "0"))*/handleChangeQty(v ? v : "0")} 
                            color="blue" type="textButNumber" className="mr-8 text-xl" size='md'/>
                    </div>
                    <div>
                        {labelAndValue("Calculated Price:", euroFormat(effectivePrice)+"€", "navy_blue")}
                        <DexxButton onClick={() => {setGetQuote(true)}} label={"Quote Price"} fontClasses="text-sm font-medium"
                                className={"rounded-full px-4 py-2 mt-2 bg-" + color + " drop-shadow-n"} color1='gray5' color2='white'/>
                    </div>
                </div>
                <div className="grid grid-cols-2">
                    {labelAndValue("Max Amount:", quantityFormat(max), "navy_blue")}
                    {labelAndValue("Calculated Cost:", euroFormat(amountB)+"€", "navy_blue")}
                </div>    
            </div>

            <div className="bg-gray4lines h-px w-full mb-1"/>

            <div className="pl-10 mb-2 font-roboto">
                <div className={"text-" + color + " text-sm font-medium"}>
                    Review</div>
                <div className="text-primary text-sm font-urbanist font-normal">
                    {props.isBuy ? "Estimated Purchase Amount" : "Net Proceeds from Trade"}:
                    <strong>{grossAmount ? " €"+euroFormat(grossAmount) : ""}</strong>
                </div>
                <div className="text-primary text-sm font-urbanist font-normal">
                    Estimated Fees:
                    <strong>{dexxFees ? " €"+euroFeesFormat(dexxFees) : ""}</strong>
                </div>
            </div>

            { props.isBuy ? (
            <div className="grid grid-cols-8 pl-5 mb-2">

                { owningToken ? (
                    <input type="checkbox" checked={true} className="w-10 py-4 mt-5 h-5 
                        text-black text-xl font-roboto leading-none col-span-1" disabled={true} />
                ) :   (
                    <input type="checkbox" checked={coOwnership} className="w-10 py-4 mt-5 h-5 
                        text-black text-xl font-roboto leading-none col-span-1"
                        onChange={() => { setCoOwnership(!coOwnership) }} />
                ) }

                <label className="w-full text-black text-sm py-4 pr-10 col-span-7 font-semibold">
                    I have read and I agree to the terms and conditions of the <a href={coOwnDoc ? coOwnDoc.src : ""} target="_blank" className={'text-' + color }>co-ownership agreement</a>.
                </label>
            </div> ) : "" }

            { props.isBuy ? (
            <div className="grid grid-cols-8 pl-5 mb-2">

                { owningToken ? (
                    <input type="checkbox" checked={true} className="w-10 py-4 mt-5 h-5 
                        text-black text-xl font-roboto leading-none col-span-1" disabled={true} />
                ) :   (
                    <input type="checkbox" checked={prospectus} className="w-10 py-4 mt-5 h-5 
                        text-black text-xl font-roboto leading-none col-span-1"
                        onChange={() => { setProspectus(!prospectus) }} />
                ) }

                <label className="w-full text-black text-sm py-4 pr-10 col-span-7 font-semibold">
                I have read the <a href={prospectusDoc ? prospectusDoc.src : ""} target="_blank" className={'text-' + color }>prospectus</a> on this asset token.
                </label>
            </div> ) : "" }

            <div className="flex justify-between px-6 pb-2 mt-5">
                <DexxButton onClick={() => { props.closeFunction(); setQuantity(0); }} label={"close"} fontClasses="text-sm font-medium"
                    className="rounded-full px-8 py-3 bg-white drop-shadow-n" color1='white' color2='gray5' />
                <DexxButton onClick={() => { if(quantity > 0 && coOwnership) {setConfirmShowPopUp(true); setGetQuote(true) }}} label={"confirm"}
                    fontClasses="text-sm  font-medium" className={"rounded-full px-16 bg-white py-3 " + 
                        (/*(!isBuy && (!maxTokenA || maxTokenA === 0)) ||*/ (quantity > 0 && coOwnership) ? " " : " bg-white ")}
                        color1='white' color2='primary' />
            </div>
        </div>
    </>
}