import { Link } from 'react-router-dom'
import { euroFormat, euroQtyFormat, formatDate } from "../utils";
import LightTable from "./light/LightTable";

export default function PrimarySaleDescriptionTable(props: {primarySalesList: {primary_sales : any[]}, psLinks?: boolean, timezone: number}): JSX.Element {

    const headers = ["CAT", "TYPE", "Ticker", "Name", "Start Date", "End Date", "Supply", "AVAILABLE TOKENS", "Price", "Status"]

    function psList2list(l: any[]) {
        if(l) {
            var tmp = l.map((ps) => [ps.asset_category, ps.asset_type, ps.token_ticker, ps.asset_name,
                formatDate(ps.date_start, props.timezone), formatDate(ps.date_end, props.timezone),
                euroQtyFormat(ps.total_supply), euroQtyFormat(ps.total_supply-ps.shares_issued), euroFormat(ps.price), ps.status])
            return props.psLinks ? tmp.map((a, i) => a.map((b) => <Link to={"/primary_sale/"+l[i].uuid}>{b}</Link>)) : tmp.map((a, i) => a.map((b) => b))
        }
        return []
    }

    return <>
        <div className="py-1">
            <LightTable headers={headers} rows={psList2list(props.primarySalesList.primary_sales)} 
                colorHd="primary text-tertiary" noBorders={true}></LightTable>
        </div>
    </>
}