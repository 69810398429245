import { bindActionCreators } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AifIrr, AifOther, AifOverview, AifFundTerms, AifPrivateEquityCharacteristics, AifInvestmentLimits, AifPrivateEquityExtraInfo, defaultAifPrivateEquityExtraInfo, FileSrc } from "../../AssetContext";
import { actionCreators } from "../../state";
//import { MediaFile } from "../../state/action-creators";
import LabelAndInput from "../LabelAndInput";
import { TitleColor1 } from "../typography/Typogtaphy";
import UploadFilesForm, {UploadTokenFilesForm} from "../UploadFilesForm";
import { OptimizedImage, SortedImages, sortImages } from "../../functions/images"
import {sanitizeInputField} from "../../utils"
import UploadCardImage from "../UploadCardImage";

export default function ExtraInfoPrivateEquityForm(props: {assetInfo: any, setUpdateFun: any, media: any,
        tbr: any[], setTbr: any, tbu: any[], setTbu: any,
        coownTBR: any[], setCoownTBR: any, coownTBU: any[], setCoownTBU: any,
        prospTBR: any[], setProspTBR: any, prospTBU: any[], setProspTBU: any}): JSX.Element {
    
    const dispatch = useDispatch() 
    const actions = bindActionCreators(actionCreators, dispatch)
    
    // Asset ExtraInfo fields
    const[assetName, setAssetName] = useState<string>("")
    //irr
    const[targetMoic, setTargetMoic] = useState<string>("")
    const[targetGrossIrr, setTargetGrossIrr] = useState<string>("")
    //const[targetCashYield, setTargetCashYield] = useState<string>("")
    const[distributionPolicy, setDistributionPolicy] = useState<string>("")
    //other
    const[aifm, setAifm] = useState<string>("")
    const[auditor, setAuditor] = useState<string>("")
    const[custodian, setCustodian] = useState<string>("")
    const[reporting, setReporting] = useState<string>("")
    const[distributor, setDistributor] = useState<string>("")
    const[gpClawback, setGpClawback] = useState<string>("")
    const[hurdleRate, setHurdleRate] = useState<string>("")
    const[administrator, setAdministrator] = useState<string>("")
    const[navFrequency, setNavFrequency] = useState<string>("")
    const[shareClasses, setShareClasses] = useState<string>("")
    const[incentiveFees, setIncentiveFees] = useState<string>("")
    const[generalPartner, setGeneralPartner] = useState<string>("")
    const[legalCounselor, setLegalCounselor] = useState<string>("")
    const[legalStructure, setLegalStructure] = useState<string>("")
    const[managementFees, setManagementFees] = useState<string>("")
    const[subDistributor, setSubDistributor] = useState<string>("")
    const[portfolioManager, setPortfolioManager] = useState<string>("")
    const[typeOfWaterfall, setTypeOfWaterfall] = useState<string>("")
    const[subsequentSubscriptions, setSubsequentSubscriptions] = useState<string>("")
    //overview
    const[dealType, setDealType] = useState<string>("")
    const[fundName, setFundName] = useState<string>("")
    const[targetFundSize, setTargetFundSize] = useState<string>("")
    //const[overviewInvestmentPeriod, setOverviewInvestmentPeriod] = useState<string>("")
    const[riskReturnCharacteristics, setRiskReturnCharacteristics] = useState<string>("")
    const[targetAllocation, setTargetAllocation] = useState<string>("")
    const[investmentStrategyDescription, setInvestmentStrategyDescription] = useState<string>("")
    //fund terms
    const[rampUp, setRampUp] = useState<string>("")
    const[fundTerm, setFundTerm] = useState<string>("")
    const[finalClose, setFinalClose] = useState<string>("")
    const[firstClose, setFirstClose] = useState<string>("")
    const[capitalCalls, setCapitalCalls] = useState<string>("")
    const[fundTermsInvestmentPeriod, setFundTermsInvestmentPeriod] = useState<string>("")
    const[capitalRestitutions, setCapitalRestitutions] = useState<string>("")
    //characteristics
    const[warranty, setWarranty] = useState<string>("")
    const[coInvestment, setCoInvestment] = useState<string>("")
    const[dealCurrency, setDealCurrency] = useState<string>("")
    const[fundCurrency, setFundCurrency] = useState<string>("")
    const[targetSectors, setTargetSectors] = useState<string>("")
    const[targetDealSize, setTargetDealSize] = useState<string>("")
    const[numberOfDeals, setNumberOfDeals] = useState<string>("")
    const[primarySecondary, setPrimarySecondary] = useState<string>("")
    const[speedOfDeployment, setSpeedOfDeployment] = useState<string>("")
    const[targetCompanySize, setTargetCompanySize] = useState<string>("")
    const[portfolioCompanyProfile, setPortfolioCompanyProfile] = useState<string>("")
    const[typicalStakeInPortfolioCompanies, setTypicalStakeInPortfolioCompanies] = useState<string>("")
    //Investment restrictions
    const[leverage, setLeverage] = useState<string>("")
    const[derivatives, setDerivatives] = useState<string>("")
    const[geographicArea, setGeographicArea] = useState<string>("")
    const[maxIssuerLimit, setMaxIssuerLimit] = useState<string>("")
    const[maxSectorLimit, setMaxSectorLimit] = useState<string>("")
    const[esgConsiderations, setEsgConsiderations] = useState<string>("")
    const[excludedProfiles, setExcludedProfiles] = useState<string>("")
    const[maxCountryLimit, setMaxCountryLimit] = useState<string>("")
    const[interestCoverageRatio, setInterestCoverageRatio] = useState<string>("")

    const[videosSrc, setVideosSrc] = useState<{url:string, filename: string}[]>([])
    const[documentsSrc, setDocumentsSrc] = useState<{url:string, filename: string}[]>([])
    const [images2, setImages2] = useState<FileSrc[]>([])
    const[videos, setVideos] = useState<FileSrc[]>([])
    const [sortedImages, setSortedImages] = useState<OptimizedImage[]>([])
    const [unsortedImages, setUnsortedImages] = useState<OptimizedImage[]>([])
    const[documents, setDocuments] = useState<FileSrc[]>([])
    const[coownership, setCoownership] = useState<FileSrc[]>([])
    const[prospectus, setProspectus] = useState<FileSrc[]>([])
    const[download, setDownload] = useState<boolean>(true)

    const[isApprovedAndTokenized, setIsApprovedAndTokenized] = useState<boolean>(false)
    const[isSubmitted, setIsSubmitted] = useState<boolean>(false)

    var newIrrInfo: AifIrr = {
        target_moic : sanitizeInputField(targetMoic),
        target_gross_irr : sanitizeInputField(targetGrossIrr),
        //target_cash_yield : targetCashYield,
        distribution_policy : (distributionPolicy)
    }

    var newOtherInfo: AifOther = {
        AIFM : sanitizeInputField(aifm),
        auditor : sanitizeInputField(auditor),
        custodian : sanitizeInputField(custodian),
        reporting : (reporting),
        distributor : sanitizeInputField(distributor),
        gp_clawback : sanitizeInputField(gpClawback),
        hurdle_rate : sanitizeInputField(hurdleRate),
        administrator : sanitizeInputField(administrator),
        nav_frequency : sanitizeInputField(navFrequency),
        share_classes : sanitizeInputField(shareClasses),
        incentive_fees : sanitizeInputField(incentiveFees),
        general_partner : sanitizeInputField(generalPartner),
        legal_counselor : sanitizeInputField(legalCounselor),
        legal_structure : sanitizeInputField(legalStructure),
        management_fees : sanitizeInputField(managementFees),
        sub_distributor : sanitizeInputField(subDistributor),
        portfolio_manager : sanitizeInputField(portfolioManager),
        type_of_waterfall : sanitizeInputField(typeOfWaterfall),
        subsequent_subscriptions : (subsequentSubscriptions)
    }
    
    var newOverviewInfo: AifOverview = {
        deal_type : sanitizeInputField(dealType),
        fund_name : sanitizeInputField(fundName),
        target_fund_size : sanitizeInputField(targetFundSize),
        //investment_period : overviewInvestmentPeriod,
        risk_return_characteristics : (riskReturnCharacteristics),
        target_allocation : sanitizeInputField(targetAllocation),
        investment_strategy_description : (investmentStrategyDescription)
    }
    
    var newfund_termsrmsInfo: AifFundTerms = {
        ramp_up : sanitizeInputField(rampUp),
        fund_term : sanitizeInputField(fundTerm),
        final_close : sanitizeInputField(finalClose),
        first_close : sanitizeInputField(firstClose),
        capital_calls : sanitizeInputField(capitalCalls),
        investment_period : sanitizeInputField(fundTermsInvestmentPeriod),
        capital_restitutions : sanitizeInputField(capitalRestitutions)
    }

    var newCharacteristicsInfo: AifPrivateEquityCharacteristics = {
        warranty : warranty,
        co_investment : sanitizeInputField(coInvestment),
        deal_currency : sanitizeInputField(dealCurrency),
        fund_currency : sanitizeInputField(fundCurrency),
        target_sectors : (targetSectors),
        number_of_deals : sanitizeInputField(numberOfDeals),
        target_deal_size : sanitizeInputField(targetDealSize),
        primary_secondary : sanitizeInputField(primarySecondary),
        speed_of_deployment : sanitizeInputField(speedOfDeployment),
        target_company_size : (targetCompanySize),
        portfolio_company_profile : (portfolioCompanyProfile),    
        typical_stake_in_portfolio_companies : sanitizeInputField(typicalStakeInPortfolioCompanies),
        
    }

    var newInvestmentLimitsInfo: AifInvestmentLimits = {
        leverage : sanitizeInputField(leverage),
        derivatives : sanitizeInputField(derivatives),
        geographic_area : sanitizeInputField(geographicArea),
        max_issuer_limit : sanitizeInputField(maxIssuerLimit),
        max_sector_limit : sanitizeInputField(maxSectorLimit),
        esg_considerations : (esgConsiderations),
        excluded_profiles : (excludedProfiles),
        max_country_limit : sanitizeInputField(maxCountryLimit),
        interest_coverage_ratio: sanitizeInputField(interestCoverageRatio)
    }
    
    var newPrivateEquityInfo: AifPrivateEquityExtraInfo = {
        irr: newIrrInfo,
        other: newOtherInfo,
        overview: newOverviewInfo,
        fund_terms: newfund_termsrmsInfo,
        characteristics: newCharacteristicsInfo,
        investment_limits: newInvestmentLimitsInfo
    }

    useEffect(() => {
        if(props.media && download) {
            actions.getDocuments(props.media, setDocumentsSrc)
            actions.getVideos(props.media, setVideosSrc)
            setDownload(false)
        }
    }, [props.media])

    useEffect(() => {
        if (props.media) {
            let imgs: SortedImages = sortImages(
                props.media.filter((m: any) => m.media_type_name === "Image"))
            actions.getAllImagesURL(imgs.sorted_images, setSortedImages)
            actions.getAllImagesURL(imgs.others.map((i) => {
                return { name: i, url_small: "", url_big: i, filename_big: "", filename_small: "" }
            }), setUnsortedImages)
        }
    }, [props.media])

    useEffect(() => {
        let i = [...sortedImages, ...unsortedImages].map((im) => {
            return { name: im.name, uploaded: true, src: im.url_big, small: im.url_small,
                filename_big: im.filename_big, filename_small: im.filename_small
             }
        })
        setImages2(i)
    }, [sortedImages, unsortedImages])

    useEffect(() => {
        setVideos(videosSrc.map((u) => {return {name: u.filename, uploaded: true, src: u.url}}))
    }, [videosSrc])

    useEffect(() => {
        let tmp:any = []
        documentsSrc.map((u) => {
            if(!u.filename.includes("coownership___") && !u.filename.includes("prospectus___"))
                tmp.push({name: u.filename, uploaded: true, src: u.url}) 
        })
        setDocuments(tmp)

        let tmp2:any = []
        documentsSrc.map((u) => {
            if(u.filename && u.filename.includes("coownership"))
                tmp2.push({name: u.filename, uploaded: true, src: u.url})
        })
        setCoownership(tmp2)

        let tmp3:any = [] 
        documentsSrc.map((u) => {
            if(u.filename && u.filename.includes("prospectus"))
                tmp3.push({name: u.filename, uploaded: true, src: u.url})
        })
        setProspectus(tmp3)
    }, [documentsSrc])

    
    useEffect(() => {

        if(props.assetInfo.payload && props.assetInfo.payload.extra_info) {
            let aifPrivateEquity = defaultAifPrivateEquityExtraInfo
            if(props.assetInfo.payload.extra_info.private_equity){
                aifPrivateEquity = props.assetInfo.payload.extra_info.private_equity.sub_assets ? 
                    props.assetInfo.payload.extra_info.private_equity.sub_assets[0] : 
                    defaultAifPrivateEquityExtraInfo
            }
            if(props.assetInfo.payload.asset_status_name !== "In Process" && props.assetInfo.payload.asset_status_name !== "Rejected") 
                setIsApprovedAndTokenized(true)
            if(props.assetInfo.payload.asset_status_name === "Submitted")  
                setIsSubmitted(true)

            setAssetName(props.assetInfo.payload.name)
            if(aifPrivateEquity) {
                
                setTargetMoic(aifPrivateEquity.irr.target_moic ? aifPrivateEquity.irr.target_moic : "")
                setTargetGrossIrr(aifPrivateEquity.irr.target_gross_irr)
                //setTargetCashYield(aifPrivateEquity.irr.target_cash_yield)
                setDistributionPolicy(aifPrivateEquity.irr.distribution_policy)
                
                setAifm(aifPrivateEquity.other.AIFM)
                setAuditor(aifPrivateEquity.other.auditor)
                setCustodian(aifPrivateEquity.other.custodian)
                setReporting(aifPrivateEquity.other.reporting)
                setDistributor(aifPrivateEquity.other.distributor)
                setGpClawback(aifPrivateEquity.other.gp_clawback ? aifPrivateEquity.other.gp_clawback : "")
                setHurdleRate(aifPrivateEquity.other.hurdle_rate ? aifPrivateEquity.other.hurdle_rate : "")
                setAdministrator(aifPrivateEquity.other.administrator)
                setNavFrequency(aifPrivateEquity.other.nav_frequency)
                setShareClasses(aifPrivateEquity.other.share_classes)
                setIncentiveFees(aifPrivateEquity.other.incentive_fees)
                setGeneralPartner(aifPrivateEquity.other.general_partner ? aifPrivateEquity.other.general_partner : "")
                setLegalCounselor(aifPrivateEquity.other.legal_counselor ? aifPrivateEquity.other.legal_counselor : "")
                setLegalStructure(aifPrivateEquity.other.legal_structure ? aifPrivateEquity.other.legal_structure : "")
                setManagementFees(aifPrivateEquity.other.management_fees)
                setSubDistributor(aifPrivateEquity.other.sub_distributor)
                setPortfolioManager(aifPrivateEquity.other.portfolio_manager)
                setTypeOfWaterfall(aifPrivateEquity.other.type_of_waterfall ? aifPrivateEquity.other.type_of_waterfall : "")
                setSubsequentSubscriptions(aifPrivateEquity.other.subsequent_subscriptions)
                
                setDealType(aifPrivateEquity.overview.deal_type)
                setFundName(aifPrivateEquity.overview.fund_name)
                setTargetFundSize(aifPrivateEquity.overview.target_fund_size)
                //setOverviewInvestmentPeriod(aifPrivateEquity.overview.investment_period)
                setRiskReturnCharacteristics(aifPrivateEquity.overview.risk_return_characteristics)
                setTargetAllocation(aifPrivateEquity.overview.target_allocation)
                setInvestmentStrategyDescription(aifPrivateEquity.overview.investment_strategy_description ? 
                    aifPrivateEquity.overview.investment_strategy_description : "")
                
                setRampUp(aifPrivateEquity.fund_terms.ramp_up)
                setFundTerm(aifPrivateEquity.fund_terms.fund_term)
                setFinalClose(aifPrivateEquity.fund_terms.final_close)
                setFirstClose(aifPrivateEquity.fund_terms.first_close)
                setCapitalCalls(aifPrivateEquity.fund_terms.capital_calls)
                setFundTermsInvestmentPeriod(aifPrivateEquity.fund_terms.investment_period)
                setCapitalRestitutions(aifPrivateEquity.fund_terms.capital_restitutions)

                setWarranty(aifPrivateEquity.characteristics.warranty)
                setCoInvestment(aifPrivateEquity.characteristics.co_investment)
                setDealCurrency(aifPrivateEquity.characteristics.deal_currency)
                setFundCurrency(aifPrivateEquity.characteristics.fund_currency)
                setTargetSectors(aifPrivateEquity.characteristics.target_sectors)
                setTargetDealSize(aifPrivateEquity.characteristics.target_deal_size)
                setNumberOfDeals(aifPrivateEquity.characteristics.number_of_deals)
                setPrimarySecondary(aifPrivateEquity.characteristics.primary_secondary)
                setSpeedOfDeployment(aifPrivateEquity.characteristics.speed_of_deployment)
                setTargetCompanySize(aifPrivateEquity.characteristics.target_company_size)
                setPortfolioCompanyProfile(aifPrivateEquity.characteristics.portfolio_company_profile)
                setTypicalStakeInPortfolioCompanies(aifPrivateEquity.characteristics.typical_stake_in_portfolio_companies)

                setLeverage(aifPrivateEquity.investment_limits.leverage)
                setDerivatives(aifPrivateEquity.investment_limits.derivatives)
                setGeographicArea(aifPrivateEquity.investment_limits.geographic_area)
                setMaxIssuerLimit(aifPrivateEquity.investment_limits.max_issuer_limit)
                setMaxSectorLimit(aifPrivateEquity.investment_limits.max_sector_limit)
                setEsgConsiderations(aifPrivateEquity.investment_limits.esg_considerations)
                setExcludedProfiles(aifPrivateEquity.investment_limits.excluded_profiles)
                setMaxCountryLimit(aifPrivateEquity.investment_limits.max_country_limit)
            }
        }
    }, [props.assetInfo.payload])

    function updateEI() {
        var subAsset :any[] = []
        subAsset.push(newPrivateEquityInfo)
        const assetExtraInfo = {
            private_equity: {
                sub_assets: subAsset
            }
        }
        return assetExtraInfo 
    }

    useEffect(() => {
        props.setUpdateFun(updateEI)
    }, [assetName,targetMoic,targetGrossIrr,distributionPolicy,aifm,auditor,custodian,reporting,distributor,
        gpClawback,hurdleRate,administrator,navFrequency,shareClasses,incentiveFees,generalPartner,legalCounselor,
        legalStructure,managementFees,subDistributor,portfolioManager,typeOfWaterfall,subsequentSubscriptions,dealType,
        fundName,targetFundSize,riskReturnCharacteristics,targetAllocation,investmentStrategyDescription,rampUp,fundTerm,
        finalClose,firstClose,capitalCalls,fundTermsInvestmentPeriod,capitalRestitutions,warranty,coInvestment,
        dealCurrency,fundCurrency,targetSectors,targetDealSize,numberOfDeals,primarySecondary,speedOfDeployment,
        targetCompanySize,portfolioCompanyProfile,typicalStakeInPortfolioCompanies,leverage,derivatives,geographicArea,
        maxIssuerLimit,maxSectorLimit,esgConsiderations,excludedProfiles,maxCountryLimit])

    return <>
        <div className="grid md:px-20 px-5 grid-cols-12 mt-10" >
            <LabelAndInput label={"Asset Name"} value={assetName} fun={setAssetName} type={"disabled"} className={"col-span-12"} />
            
            <TitleColor1 className="text-lg mt-5 col-span-12">/ Overview</TitleColor1>

            <LabelAndInput id={"fundName"} label={"Fund Name *"} value={fundName} fun={setFundName} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"investmentStrategyDescription"} label={"Investment Strategy Description *"} value={investmentStrategyDescription} fun={setInvestmentStrategyDescription} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"dealType"} label={"Deal Type *"} value={dealType} fun={setDealType} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"targetAllocation"} label={"Target Allocation Seniority *"} value={targetAllocation} fun={setTargetAllocation} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"riskReturnCharacteristics"} label={"Main Risk and Return Characteristics *"} value={riskReturnCharacteristics} fun={setRiskReturnCharacteristics} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"targetFundSize"} label={"Target Fund Size *"} value={targetFundSize} fun={setTargetFundSize} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            {//<LabelAndInput label={"Investment Period *"} value={overviewInvestmentPeriod} fun={setOverviewInvestmentPeriod} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} />
            }

            <TitleColor1 className="text-lg mt-5 col-span-12">/ Characteristics</TitleColor1>

            <LabelAndInput id={"portfolioCompanyProfile"} label={"Portfolio Company Profile *"} value={portfolioCompanyProfile} fun={setPortfolioCompanyProfile} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"primarySecondary"} label={"Primary Or Secondary *"} value={primarySecondary} fun={setPrimarySecondary} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"targetCompanySize"} label={"Target Company Size *"} value={targetCompanySize} fun={setTargetCompanySize} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"targetSectors"} label={"Target Sectors *"} value={targetSectors} fun={setTargetSectors} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"typicalStakeInPortfolioCompanies"} label={"Typical Stake In Portfolio Companies *"} value={typicalStakeInPortfolioCompanies} fun={setTypicalStakeInPortfolioCompanies} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required />
            <LabelAndInput id={"warranty"} label={"Warranty *"} value={warranty} fun={setWarranty} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"dealCurrency"} label={"Deal Currency *"} value={dealCurrency} fun={setDealCurrency} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required />
            <LabelAndInput id={"fundCurrency"} label={"Fund Currency *"} value={fundCurrency} fun={setFundCurrency} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"targetDealSize"} label={"Target Deal Size *"} value={targetDealSize} fun={setTargetDealSize} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"numberOfDeals"} label={"Number Of Deals *"} value={numberOfDeals} fun={setNumberOfDeals} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"coInvestment"} label={"Co Investment *"} value={coInvestment} fun={setCoInvestment} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required />                    
            <LabelAndInput id={"speedOfDeployment"} label={"Speed Of Deployment *"} value={speedOfDeployment} fun={setSpeedOfDeployment} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>

            <TitleColor1 className="text-lg mt-5 col-span-12">/ Investment Restrictions</TitleColor1>

            <LabelAndInput id={"geographicArea"} label={"Geographic Area *"} value={geographicArea} fun={setGeographicArea} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"maxCountryLimit"} label={"Max Country Limit *"} value={maxCountryLimit} fun={setMaxCountryLimit} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"maxSectorLimit"} label={"Max Sector Limit *"} value={maxSectorLimit} fun={setMaxSectorLimit} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"maxIssuerLimit"} label={"Max Portfolio Company Weight *"} value={maxIssuerLimit} fun={setMaxIssuerLimit} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"excludedProfiles"} label={"Excluded Profiles *"} value={excludedProfiles} fun={setExcludedProfiles} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"esgConsiderations"} label={"ESG Consideration *"} value={esgConsiderations} fun={setEsgConsiderations} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"leverage"} label={"Leverage *"} value={leverage} fun={setLeverage} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"derivatives"} label={"Derivatives *"} value={derivatives} fun={setDerivatives} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required />

            <TitleColor1 className="text-lg mt-5 col-span-12">/ IRR</TitleColor1>
                    
            <LabelAndInput id={"targetGrossIrr"} label={"Target Gross IRR *"} value={targetGrossIrr} fun={setTargetGrossIrr} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"targetMoic"}  label={"Target Moic *"} value={targetMoic} fun={setTargetMoic} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required />
            {//<LabelAndInput label={"Target Chash Yield *"} value={targetCashYield} fun={setTargetCashYield} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"}/>
            }
            <LabelAndInput id={"distributionPolicy"} label={"Distribution Policy *"} value={distributionPolicy} fun={setDistributionPolicy} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required/>
                
            <TitleColor1 className="text-lg mt-5 col-span-12">/ Fund Terms</TitleColor1>

            <LabelAndInput id={"firstClose"} label={"First Close *"} value={firstClose} fun={setFirstClose} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"finalClose"} label={"Final Close *"} value={finalClose} fun={setFinalClose} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required />
            <LabelAndInput id={"fundTerm"} label={"Fund Term *"} value={fundTerm} fun={setFundTerm} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"rampUp"} label={"Ramp Up *"} value={rampUp} fun={setRampUp} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"fundTermsInvestmentPeriod"} label={"Investment Period *"} value={fundTermsInvestmentPeriod} fun={setFundTermsInvestmentPeriod} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"capitalCalls"} label={"Capitals Calls *"} value={capitalCalls} fun={setCapitalCalls} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required />
            <LabelAndInput id={"capitalRestitutions"} label={"Capital Restitutions *"} value={capitalRestitutions} fun={setCapitalRestitutions} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>

            <TitleColor1 className="text-lg mt-5 col-span-12">/ Others</TitleColor1>

            <LabelAndInput id={"legalStructure"} label={"Legal Structure *"} value={legalStructure} fun={setLegalStructure} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"custodian"} label={"Custodian *"} value={custodian} fun={setCustodian} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"administrator"} label={"Administrator *"} value={administrator} fun={setAdministrator} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"auditor"} label={"Auditor *"} value={auditor} fun={setAuditor} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"legalCounselor"} label={"Legal Counseler *"} value={legalCounselor} fun={setLegalCounselor} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required />
            <LabelAndInput id={"generalPartner"} label={"General Partner *"} value={generalPartner} fun={setGeneralPartner} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"AIFM"} label={"AIFM *"} value={aifm} fun={setAifm} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required />
            <LabelAndInput id={"portfolioManager"} label={"Portfolio Manager *"} value={portfolioManager} fun={setPortfolioManager} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required />
            <LabelAndInput id={"managementFees"} label={"Management Fees *"} value={managementFees} fun={setManagementFees} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"incentiveFees"} label={"Incentive Fees *"} value={incentiveFees} fun={setIncentiveFees} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required />
            <LabelAndInput id={"hurdleRate"} label={"Hurdle Rate *"} value={hurdleRate} fun={setHurdleRate} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required />
            <LabelAndInput id={"typeOfWaterfall"} label={"Type Of Waterfall *"} value={typeOfWaterfall} fun={setTypeOfWaterfall} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"gpClawback"} label={"Gp Clawback *"} value={gpClawback} fun={setGpClawback} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"distributor"} label={"Distributor *"} value={distributor} fun={setDistributor} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required />
            <LabelAndInput id={"subDistributor"} label={"Sub Distributor *"} value={subDistributor} fun={setSubDistributor} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"shareClasses"} label={"Share Classes *"} value={shareClasses} fun={setShareClasses} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"subsequentSubscriptions"} label={"Subsequent Subscriptions *"} value={subsequentSubscriptions} fun={setSubsequentSubscriptions} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required />
            <LabelAndInput id={"navFrequency"} label={"Nav Frequency *"} value={navFrequency} fun={setNavFrequency} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"reporting"} label={"Reporting *"} value={reporting} fun={setReporting} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required/>

            <div className="col-span-12 grid mt-5">
                <UploadTokenFilesForm 
                    coownership={{names: coownership, setNames: setCoownership,
                        tbr: props.coownTBR, tbu: props.coownTBU, setTbr: props.setCoownTBR, setTbu: props.setCoownTBU}}
                    prospectus={{names: prospectus, setNames: setProspectus,
                        tbr: props.prospTBR, tbu: props.prospTBU, setTbr: props.setProspTBR, setTbu: props.setProspTBU}}
                    isSubmitted={isSubmitted}/>
            </div>

            <div className="col-span-12 mt-5">
                <UploadCardImage images={images2} assetName={assetName}/>
            </div>
            
            <div className="col-span-12 mt-5">
                <UploadFilesForm 
                    images={{names: images2, setNames: setImages2,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    videos={{names: videos, setNames: setVideos,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    documents={{names: documents, setNames: setDocuments,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    isSubmitted={isSubmitted}/>
            </div>
        </div>
    </>
}