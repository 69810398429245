import { bindActionCreators } from "@reduxjs/toolkit"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { AifFundTerms, AifInvestmentLimits, AifIrr, AifOther, AifOverview, AifPrivateDebtCharacteristics, AifPrivateDebtExtraInfo, defaultAifPrivateDebtExtraInfo, FileSrc } from "../../AssetContext"
import { actionCreators } from "../../state"
//import { MediaFile } from "../../state/action-creators"
import { TitleColor1 } from "../typography/Typogtaphy"
import UploadFilesForm, {UploadTokenFilesForm} from "../UploadFilesForm"
import LabelAndInput from "./../LabelAndInput"
import { OptimizedImage, SortedImages, sortImages } from "../../functions/images"
import {sanitizeInputField} from "../../utils"
import UploadCardImage from "../UploadCardImage"

export default function ExtraInfoPrivateDebtForm(props: {assetInfo: any, setUpdateFun: any, media: any,
        tbr: any[], setTbr: any, tbu: any[], setTbu: any,
        coownTBR: any[], setCoownTBR: any, coownTBU: any[], setCoownTBU: any,
        prospTBR: any[], setProspTBR: any, prospTBU: any[], setProspTBU: any}): JSX.Element {
    
    const dispatch = useDispatch() 
    const actions = bindActionCreators(actionCreators, dispatch)
    
    // Asset ExtraInfo fields
    const[assetName, setAssetName] = useState<string>("")
    //irr
    const[targetGrossIrr, setTargetGrossIrr] = useState<string>("")
    const[targetCashYield, setTargetCashYield] = useState<string>("")
    const[distributionPolicy, setDistributionPolicy] = useState<string>("")
    //other
    const[aifm, setAifm] = useState<string>("")
    const[auditor, setAuditor] = useState<string>("")
    const[custodian, setCustodian] = useState<string>("")
    const[reporting, setReporting] = useState<string>("")
    const[distributor, setDistributor] = useState<string>("")
    const[administrator, setAdministrator] = useState<string>("")
    const[navFrequency, setNavFrequency] = useState<string>("")
    const[shareClasses, setShareClasses] = useState<string>("")
    const[incentiveFees, setIncentiveFees] = useState<string>("")
    const[generalPartner, setGeneralPartner] = useState<string>("")
    const[legalStructure, setLegalStructure] = useState<string>("")
    const[managementFees, setManagementFees] = useState<string>("")
    const[subDistributor, setSubDistributor] = useState<string>("")
    const[portfolioManager, setPortfolioManager] = useState<string>("")
    //const[subsequentSubscriptions, setSubsequentSubscriptions] = useState<string>("")
    //overview
    const[dealType, setDealType] = useState<string>("")
    const[fundName, setFundName] = useState<string>("")
    const[targetFundSize, setTargetFundSize] = useState<string>("")
    const[overviewInvestmentPeriod, setOverviewInvestmentPeriod] = useState<string>("")
    const[riskReturnCharacteristics, setRiskReturnCharacteristics] = useState<string>("")
    const[targetAllocation, setTargetAllocation] = useState<string>("")
    //fund terms
    const[rampUp, setRampUp] = useState<string>("")
    const[fundTerm, setFundTerm] = useState<string>("")
    const[finalClose, setFinalClose] = useState<string>("")
    const[firstClose, setFirstClose] = useState<string>("")
    //const[capitalCalls, setCapitalCalls] = useState<string>("")
    const[fundTermsInvestmentPeriod, setFundTermsInvestmentPeriod] = useState<string>("")
    const[capitalRestitutions, setCapitalRestitutions] = useState<string>("")
    //characteristics
    const[covenant, setCovenant] = useState<string>("")
    const[dealCurrency, setDealCurrency] = useState<string>("")
    const[fundCurrency, setFundCurrency] = useState<string>("")
    const[averageRating, setAverageRating] = useState<string>("")
    const[loansOrBonds, setLoansOrBonds] = useState<string>("")
    const[minimumRating, setMinimumRating] = useState<string>("")
    const[numberOfDeals, setNumberOfDeals] = useState<string>("")
    const[borrowerProfile, setBorrowerProfile] = useState<string>("")
    const[targetDealSize, setTargetDealSize] = useState<string>("")
    const[floatingOrFixed, setFloatingOrFixed] = useState<string>("")
    const[typeOfCollateral, setTypeOfCollateral] = useState<string>("")
    const[speedOfDeployment, setSpeedOfDeployment] = useState<string>("")
    const[targetCompanySize, setTargetCompanySize] = useState<string>("")
    const[bulletOrAmortizing, setBulletOrAmortizing] = useState<string>("")
    const[primarySecondary, setPrimarySecondary] = useState<string>("")
    const[sponsorOrCorporate, setSponsorOrCorporate] = useState<string>("")
    const[typicalLoanToValue, setTypicalLoanToValue] = useState<string>("")
    const[expectedTermPerDeal, setExpectedTermPerDeal] = useState<string>("")
    const[maxAverageNetLeverage, setMaxAverageNetLeverage] = useState<string>("")
    const[maxTypicalTermPerDeal, setMaxTypicalTermPerDeal] = useState<string>("")
    //Investment restrictions
    const[leverage, setLeverage] = useState<string>("")
    const[derivatives, setDerivatives] = useState<string>("")
    const[geographicArea, setGeographicArea] = useState<string>("")
    const[maxIssuerLimit, setMaxIssuerLimit] = useState<string>("")
    const[maxSectorLimit, setMaxSectorLimit] = useState<string>("")
    const[esgConsiderations, setEsgConsiderations] = useState<string>("")
    const[excludedProfiles, setExcludedProfiles] = useState<string>("")
    const[maxCountryLimit, setMaxCountryLimit] = useState<string>("")
    const[interestCoverageRatio, setInterestCoverageRatio] = useState<string>("")

    const[videosSrc, setVideosSrc] = useState<{url:string, filename: string}[]>([])
    const[documentsSrc, setDocumentsSrc] = useState<{url:string, filename: string}[]>([])
    const [images2, setImages2] = useState<FileSrc[]>([])
    const[videos, setVideos] = useState<FileSrc[]>([])
    const [sortedImages, setSortedImages] = useState<OptimizedImage[]>([])
    const [unsortedImages, setUnsortedImages] = useState<OptimizedImage[]>([])
    const[documents, setDocuments] = useState<FileSrc[]>([])
    const[coownership, setCoownership] = useState<FileSrc[]>([])
    const[prospectus, setProspectus] = useState<FileSrc[]>([])
    const[download, setDownload] = useState<boolean>(true)

    const[isApprovedAndTokenized, setIsApprovedAndTokenized] = useState<boolean>(false)
    const[isSubmitted, setIsSubmitted] = useState<boolean>(false)

    var newIrrInfo: AifIrr = {
        //target_moic : targetMoic,
        target_gross_irr : sanitizeInputField(targetGrossIrr),
        target_cash_yield : sanitizeInputField(targetCashYield),
        distribution_policy : sanitizeInputField(distributionPolicy)
    }

    var newOtherInfo: AifOther = {
        AIFM : sanitizeInputField(aifm),
        auditor : sanitizeInputField(auditor),
        custodian : sanitizeInputField(custodian),
        reporting : (reporting),
        distributor : sanitizeInputField(distributor),
        //gp_clawback : gpClawback,
        //hurdle_rate : hurdleRate,
        administrator : sanitizeInputField(administrator),
        nav_frequency : sanitizeInputField(navFrequency),
        share_classes : sanitizeInputField(shareClasses),
        incentive_fees : (incentiveFees),
        general_partner : sanitizeInputField(generalPartner),
        //legal_counseler : legalCounseler,
        legal_structure : (legalStructure),
        management_fees : (managementFees),
        sub_distributor : sanitizeInputField(subDistributor),
        portfolio_manager : sanitizeInputField(portfolioManager),
        //type_of_waterfall : typeOfWaterfall,
        subsequent_subscriptions : ""//(subsequentSubscriptions)
    }
    
    var newOverviewInfo: AifOverview = {
        deal_type : sanitizeInputField(dealType),
        fund_name : sanitizeInputField(fundName),
        target_fund_size : sanitizeInputField(targetFundSize),
        investment_period : sanitizeInputField(overviewInvestmentPeriod),
        risk_return_characteristics : (riskReturnCharacteristics),
        target_allocation : (targetAllocation),
        //investment_strategy_description : investmentStrategyDescription
    }
    
    var newfund_termsrmsInfo: AifFundTerms = {
        ramp_up : sanitizeInputField(rampUp),
        fund_term : sanitizeInputField(fundTerm),
        final_close : sanitizeInputField(finalClose),
        first_close : sanitizeInputField(firstClose),
        capital_calls : "",//capitalCalls,
        investment_period : sanitizeInputField(fundTermsInvestmentPeriod),
        capital_restitutions : sanitizeInputField(capitalRestitutions)
    }

    var newCharacteristicsInfo: AifPrivateDebtCharacteristics = {
        covenant : sanitizeInputField(covenant),
        deal_currency : sanitizeInputField(dealCurrency),
        fund_currency : sanitizeInputField(fundCurrency),
        average_rating : sanitizeInputField(averageRating),
        loans_or_bonds : sanitizeInputField(loansOrBonds),
        minimum_rating : sanitizeInputField(minimumRating),
        number_of_deals : sanitizeInputField(numberOfDeals),
        borrower_profile : sanitizeInputField(borrowerProfile),
        target_deal_size : sanitizeInputField(targetDealSize),
        floating_or_fixed : sanitizeInputField(floatingOrFixed),
        type_of_collateral : (typeOfCollateral),
        speed_of_deployment : sanitizeInputField(speedOfDeployment),
        target_company_size : sanitizeInputField(targetCompanySize),
        bullet_or_amortizing : sanitizeInputField(bulletOrAmortizing),
        primary_or_secondary : sanitizeInputField(primarySecondary),
        sponsor_or_corporate : sanitizeInputField(sponsorOrCorporate),
        typical_loan_to_value : sanitizeInputField(typicalLoanToValue),
        expected_term_per_deal : sanitizeInputField(expectedTermPerDeal),
        max_average_net_leverage : sanitizeInputField(maxAverageNetLeverage),
        max_typical_term_per_deal : sanitizeInputField(maxTypicalTermPerDeal)
    }

    var newInvestmentLimitsInfo: AifInvestmentLimits = {
        leverage : sanitizeInputField(leverage),
        derivatives : sanitizeInputField(derivatives),
        geographic_area : sanitizeInputField(geographicArea),
        max_issuer_limit : sanitizeInputField(maxIssuerLimit),
        max_sector_limit : sanitizeInputField(maxSectorLimit),
        esg_considerations : (esgConsiderations),
        excluded_profiles : (excludedProfiles),
        max_country_limit : sanitizeInputField(maxCountryLimit),
        interest_coverage_ratio: sanitizeInputField(interestCoverageRatio)
    }
    
    var newPrivateDebtInfo: AifPrivateDebtExtraInfo = {
        irr: newIrrInfo,
        other: newOtherInfo,
        overview: newOverviewInfo,
        fund_terms: newfund_termsrmsInfo,
        characteristics: newCharacteristicsInfo,
        investment_limits: newInvestmentLimitsInfo
    }

    useEffect(() => {
        if(props.media && download) {
            actions.getDocuments(props.media, setDocumentsSrc)
            actions.getVideos(props.media, setVideosSrc)
            setDownload(false)
        }
    }, [props.media])

    useEffect(() => {
        if (props.media) {
            let imgs: SortedImages = sortImages(
                props.media.filter((m: any) => m.media_type_name === "Image"))
            actions.getAllImagesURL(imgs.sorted_images, setSortedImages)
            actions.getAllImagesURL(imgs.others.map((i) => {
                return { name: i, url_small: "", url_big: i, filename_big: "", filename_small: "" }
            }), setUnsortedImages)
        }
    }, [props.media])

    useEffect(() => {
        let i = [...sortedImages, ...unsortedImages].map((im) => {
            return { name: im.name, uploaded: true, src: im.url_big, small: im.url_small,
                filename_big: im.filename_big, filename_small: im.filename_small
             }
        })
        setImages2(i)
    }, [sortedImages, unsortedImages])

    useEffect(() => {
        setVideos(videosSrc.map((u) => {return {name: u.filename, uploaded: true, src: u.url}}))
    }, [videosSrc])

    useEffect(() => {
        let tmp:any = []
        documentsSrc.map((u) => {
            if(!u.filename.includes("coownership___") && !u.filename.includes("prospectus___"))
                tmp.push({name: u.filename, uploaded: true, src: u.url}) 
        })
        setDocuments(tmp)

        let tmp2:any = []
        documentsSrc.map((u) => {
            if(u.filename && u.filename.includes("coownership"))
                tmp2.push({name: u.filename, uploaded: true, src: u.url})
        })
        setCoownership(tmp2)

        let tmp3:any = [] 
        documentsSrc.map((u) => {
            if(u.filename && u.filename.includes("prospectus"))
                tmp3.push({name: u.filename, uploaded: true, src: u.url})
        })
        setProspectus(tmp3)
    }, [documentsSrc])

    useEffect(() => {

        if(props.assetInfo.payload && props.assetInfo.payload.extra_info) {
            let aifPrivateDebt = defaultAifPrivateDebtExtraInfo
            if(props.assetInfo.payload.extra_info.private_debt){
                aifPrivateDebt = props.assetInfo.payload.extra_info.private_debt.sub_assets ? 
                    props.assetInfo.payload.extra_info.private_debt.sub_assets[0] : 
                    defaultAifPrivateDebtExtraInfo
            }
            if(props.assetInfo.payload.asset_status_name !== "In Process" && props.assetInfo.payload.asset_status_name !== "Rejected") 
                setIsApprovedAndTokenized(true)
            if(props.assetInfo.payload.asset_status_name === "Submitted")  
                setIsSubmitted(true)
            
            setAssetName(props.assetInfo.payload.name)
            if(props) {
                
                //setTargetMoic(aifPrivateDebt.irr.target_moic)
                setTargetGrossIrr(aifPrivateDebt.irr.target_gross_irr)
                setTargetCashYield(aifPrivateDebt.irr.target_cash_yield ? aifPrivateDebt.irr.target_cash_yield : "")
                setDistributionPolicy(aifPrivateDebt.irr.distribution_policy)
                
                setAifm(aifPrivateDebt.other.AIFM)
                setAuditor(aifPrivateDebt.other.auditor)
                setCustodian(aifPrivateDebt.other.custodian)
                setReporting(aifPrivateDebt.other.reporting)
                setDistributor(aifPrivateDebt.other.distributor)
                //setGpClawback(aifPrivateDebt.other.gp_clawback)
                //setHurdleRate(aifPrivateDebt.other.hurdle_rate)
                setAdministrator(aifPrivateDebt.other.administrator)
                setNavFrequency(aifPrivateDebt.other.nav_frequency)
                setShareClasses(aifPrivateDebt.other.share_classes)
                setIncentiveFees(aifPrivateDebt.other.incentive_fees)
                setGeneralPartner(aifPrivateDebt.other.general_partner ? aifPrivateDebt.other.general_partner : "")
                //setLegalCounseler(aifPrivateDebt.other.legal_counseler)
                setLegalStructure(aifPrivateDebt.other.legal_structure ? aifPrivateDebt.other.legal_structure : "")
                setManagementFees(aifPrivateDebt.other.management_fees)
                setSubDistributor(aifPrivateDebt.other.sub_distributor)
                setPortfolioManager(aifPrivateDebt.other.portfolio_manager)
                //setTypeOfWaterfall(aifPrivateDebt.other.type_of_waterfall)
                //setSubsequentSubscriptions(aifPrivateDebt.other.subsequent_subscriptions)
                
                setDealType(aifPrivateDebt.overview.deal_type)
                setFundName(aifPrivateDebt.overview.fund_name)
                setTargetFundSize(aifPrivateDebt.overview.target_fund_size)
                setOverviewInvestmentPeriod(aifPrivateDebt.overview.investment_period ? aifPrivateDebt.overview.investment_period : "")
                setRiskReturnCharacteristics(aifPrivateDebt.overview.risk_return_characteristics)
                setTargetAllocation(aifPrivateDebt.overview.target_allocation)
                //setInvestmentStrategyDescription(aifPrivateDebt.overview.investment_strategy_description)
                
                setRampUp(aifPrivateDebt.fund_terms.ramp_up)
                setFundTerm(aifPrivateDebt.fund_terms.fund_term)
                setFinalClose(aifPrivateDebt.fund_terms.final_close)
                setFirstClose(aifPrivateDebt.fund_terms.first_close)
                //setCapitalCalls(aifPrivateDebt.fund_terms.capital_calls)
                setFundTermsInvestmentPeriod(aifPrivateDebt.fund_terms.investment_period)
                setCapitalRestitutions(aifPrivateDebt.fund_terms.capital_restitutions)

                setCovenant(aifPrivateDebt.characteristics.covenant)
                setDealCurrency(aifPrivateDebt.characteristics.deal_currency)
                setFundCurrency(aifPrivateDebt.characteristics.fund_currency)
                setAverageRating(aifPrivateDebt.characteristics.average_rating)
                setLoansOrBonds(aifPrivateDebt.characteristics.loans_or_bonds)
                setMinimumRating(aifPrivateDebt.characteristics.minimum_rating)
                setNumberOfDeals(aifPrivateDebt.characteristics.number_of_deals)
                setBorrowerProfile(aifPrivateDebt.characteristics.borrower_profile)
                setTargetDealSize(aifPrivateDebt.characteristics.target_deal_size)
                setFloatingOrFixed(aifPrivateDebt.characteristics.floating_or_fixed)
                setTypeOfCollateral(aifPrivateDebt.characteristics.type_of_collateral)
                setSpeedOfDeployment(aifPrivateDebt.characteristics.speed_of_deployment)
                setTargetCompanySize(aifPrivateDebt.characteristics.target_company_size)
                setBulletOrAmortizing(aifPrivateDebt.characteristics.bullet_or_amortizing)
                setPrimarySecondary(aifPrivateDebt.characteristics.primary_or_secondary)
                setSponsorOrCorporate(aifPrivateDebt.characteristics.sponsor_or_corporate)
                setTypicalLoanToValue(aifPrivateDebt.characteristics.typical_loan_to_value)
                setExpectedTermPerDeal(aifPrivateDebt.characteristics.expected_term_per_deal)
                setMaxAverageNetLeverage(aifPrivateDebt.characteristics.max_average_net_leverage)
                setMaxTypicalTermPerDeal(aifPrivateDebt.characteristics.max_typical_term_per_deal)

                setLeverage(aifPrivateDebt.investment_limits.leverage)
                setDerivatives(aifPrivateDebt.investment_limits.derivatives)
                setGeographicArea(aifPrivateDebt.investment_limits.geographic_area)
                setMaxIssuerLimit(aifPrivateDebt.investment_limits.max_issuer_limit)
                setMaxSectorLimit(aifPrivateDebt.investment_limits.max_sector_limit)
                setEsgConsiderations(aifPrivateDebt.investment_limits.esg_considerations)
                setExcludedProfiles(aifPrivateDebt.investment_limits.excluded_profiles)
                setMaxCountryLimit(aifPrivateDebt.investment_limits.max_country_limit)
                setInterestCoverageRatio(aifPrivateDebt.investment_limits.interest_coverage_ratio)
                
            }
        }                  
    }, [props.assetInfo.payload])

    function updateEI() {
        var subAsset :any[] = []
        subAsset.push(newPrivateDebtInfo)
        const assetExtraInfo = {
            private_debt: {
                sub_assets: subAsset
            }
        }
        return assetExtraInfo 
    }

    useEffect(() => {
        props.setUpdateFun(updateEI)
    }, [
        assetName, targetGrossIrr,targetCashYield,distributionPolicy, aifm,auditor,custodian,reporting,distributor,administrator,
        navFrequency,shareClasses,incentiveFees, generalPartner,legalStructure,managementFees,subDistributor,portfolioManager,
        /*subsequentSubscriptions,*/dealType,fundName,targetFundSize,overviewInvestmentPeriod,riskReturnCharacteristics,targetAllocation,
        rampUp,fundTerm,finalClose,firstClose, /*capitalCalls,*/fundTermsInvestmentPeriod,capitalRestitutions,covenant,dealCurrency,
        fundCurrency, averageRating,loansOrBonds, minimumRating,numberOfDeals,borrowerProfile,targetDealSize, floatingOrFixed,
        typeOfCollateral, speedOfDeployment, targetCompanySize, bulletOrAmortizing,primarySecondary, sponsorOrCorporate,
        typicalLoanToValue,expectedTermPerDeal,maxAverageNetLeverage,maxTypicalTermPerDeal,leverage,derivatives,geographicArea,
        maxIssuerLimit,maxSectorLimit,esgConsiderations,excludedProfiles, maxCountryLimit,interestCoverageRatio])

    return <>
        <div className="grid md:px-20 px-5 grid-cols-12 mt-10" >
            <LabelAndInput id={"assetName"} label={"Asset Name"} value={assetName} fun={setAssetName} type={"disabled"} className={"col-span-12"} required/>
            
            <TitleColor1 className="text-lg mt-5 col-span-12">/ Overview</TitleColor1>

            <LabelAndInput id={"fundName"} label={"Fund Name *"} value={fundName} fun={setFundName} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"overviewInvestmentPeriod"} label={"Investment Period *"} value={overviewInvestmentPeriod} fun={setOverviewInvestmentPeriod} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required />
            <LabelAndInput id={"dealType"} label={"Deal Type *"} value={dealType} fun={setDealType} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"targetAllocation"} label={"Target Allocation / Seniority *"} value={targetAllocation} fun={setTargetAllocation} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required />
            <LabelAndInput id={"riskReturnCharacteristics"} label={"Risk & Return Characteristics *"} value={riskReturnCharacteristics} fun={setRiskReturnCharacteristics} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"targetFundSize"} label={"Target Fund Size *"} value={targetFundSize} fun={setTargetFundSize} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>

            <TitleColor1 className="text-lg mt-5 col-span-12">/ Characteristics</TitleColor1>

            <LabelAndInput id={"borrowerProfile"} label={"Borrower Profile *"} value={borrowerProfile} fun={setBorrowerProfile} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required />
            <LabelAndInput id={"loansOrBonds"} label={"Loans Or Bonds *"} value={loansOrBonds} fun={setLoansOrBonds} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"bulletOrAmortizing"} label={"Bullet Or Amortizing *"} value={bulletOrAmortizing} fun={setBulletOrAmortizing} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"floatingOrFixed"} label={"Floating Or Fixed *"} value={floatingOrFixed} fun={setFloatingOrFixed} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"primaryOrSecondary"} label={"Primary Or Secondary *"} value={primarySecondary} fun={setPrimarySecondary} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"sponsorOrCorporate"} label={"Sponsor Or Corporate *"} value={sponsorOrCorporate} fun={setSponsorOrCorporate} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"covenant"} label={"Covenant *"} value={covenant} fun={setCovenant} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"targetCompanySize"} label={"Target Company Size *"} value={targetCompanySize} fun={setTargetCompanySize} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required />
            <LabelAndInput id={"averageRating"} label={"Average Rating *"} value={averageRating} fun={setAverageRating} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required />
            <LabelAndInput id={"minimumRating"} label={"Minimum Rating *"} value={minimumRating} fun={setMinimumRating} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"typicalLoanToValue"} label={"Typical Loan To Value *"} value={typicalLoanToValue} fun={setTypicalLoanToValue} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required />
            <LabelAndInput id={"typeOfCollateral"} label={"Type Of Collateral *"} value={typeOfCollateral} fun={setTypeOfCollateral} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"maxAverageNetLeverage"} label={"Max / Average Net Leverage *"} value={maxAverageNetLeverage} fun={setMaxAverageNetLeverage} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"dealCurrency"} label={"Deal Currency *"} value={dealCurrency} fun={setDealCurrency} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required />
            <LabelAndInput id={"fundCurrency"} label={"Fund Currency *"} value={fundCurrency} fun={setFundCurrency} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"maxTypicalTermPerDeal"} label={"Max / Typical Term Per Deal *"} value={maxTypicalTermPerDeal} fun={setMaxTypicalTermPerDeal} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required />
            <LabelAndInput id={"expectedTermPerDeal"} label={"Expected Term Per Deal *"} value={expectedTermPerDeal} fun={setExpectedTermPerDeal} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"targetDealSize"} label={"Target Deal Size *"} value={targetDealSize} fun={setTargetDealSize} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"numberOfDeals"} label={"Number Of Deals *"} value={numberOfDeals} fun={setNumberOfDeals} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"speedOfDeployment"} label={"Speed Of Deployment *"} value={speedOfDeployment} fun={setSpeedOfDeployment} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>

            <TitleColor1 className="text-lg mt-5 col-span-12">/ Investment Restrictions</TitleColor1>

            <LabelAndInput id={"geographicArea"} label={"Geographic Area *"} value={geographicArea} fun={setGeographicArea} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"maxCountryLimit"} label={"Max Country Limit *"} value={maxCountryLimit} fun={setMaxCountryLimit} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"maxSectorLimit"} label={"Max Sector Limit *"} value={maxSectorLimit} fun={setMaxSectorLimit} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"maxIssuerLimit"} label={"Max Issuer Limit *"} value={maxIssuerLimit} fun={setMaxIssuerLimit} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"excludedProfiles"} label={"Excluded Profiles *"} value={excludedProfiles} fun={setExcludedProfiles} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"interestCoverageRatio"} label={"Interest Coverage Ratio *"} value={interestCoverageRatio} fun={setInterestCoverageRatio} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"esgConsiderations"} label={"ESG Consideration *"} value={esgConsiderations} fun={setEsgConsiderations} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"leverage"} label={"Leverage *"} value={leverage} fun={setLeverage} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"derivatives"} label={"Derivatives *"} value={derivatives} fun={setDerivatives} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required />

            <TitleColor1 className="text-lg mt-5 col-span-12">/ IRR</TitleColor1>

            <LabelAndInput id={"targetGrossIrr"} label={"Target Gross IRR *"} value={targetGrossIrr} fun={setTargetGrossIrr} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"targetCashYield"} label={"Target Chash Yield *"} value={targetCashYield} fun={setTargetCashYield} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"distributionPolicy"} label={"Distribution Policy *"} value={distributionPolicy} fun={setDistributionPolicy} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>

            <TitleColor1 className="text-lg mt-5 col-span-12">/ Fund Terms</TitleColor1>

            <LabelAndInput id={"firstClose"} label={"First Close *"} value={firstClose} fun={setFirstClose} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"finalClose"} label={"Final Close *"} value={finalClose} fun={setFinalClose} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required />
            <LabelAndInput id={"fundTerm"} label={"Fund Term *"} value={fundTerm} fun={setFundTerm} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"rampUp"} label={"Ramp Up *"} value={rampUp} fun={setRampUp} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"fundTermsInvestmentPeriod"} label={"Investment Period *"} value={fundTermsInvestmentPeriod} fun={setFundTermsInvestmentPeriod} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            {/*<LabelAndInput id={"capitalCalls"} label={"Capitals Calls *"} value={capitalCalls} fun={setCapitalCalls} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required />*/}
            <LabelAndInput id={"capitalRestitutions"} label={"Capital Restitutions *"} value={capitalRestitutions} fun={setCapitalRestitutions} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>

            <TitleColor1 className="text-lg mt-5 col-span-12">/ Others</TitleColor1>

            <LabelAndInput id={"legalStructure"} label={"Legal Structure *"} value={legalStructure} fun={setLegalStructure} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"custodian"} label={"Custodian *"} value={custodian} fun={setCustodian} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"administrator"} label={"Administrator *"} value={administrator} fun={setAdministrator} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"auditor"} label={"Auditor *"} value={auditor} fun={setAuditor} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"generalPartner"} label={"General Partner *"} value={generalPartner} fun={setGeneralPartner} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"AIFM"} label={"AIFM *"} value={aifm} fun={setAifm} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required />
            <LabelAndInput id={"portfolioManager"} label={"Portfolio Manager *"} value={portfolioManager} fun={setPortfolioManager} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required />
            <LabelAndInput id={"managementFees"} label={"Management Fees *"} value={managementFees} fun={setManagementFees} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required/>
            <LabelAndInput id={"incentiveFees"} label={"Incentive Fees *"} value={incentiveFees} fun={setIncentiveFees} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required />
            <LabelAndInput id={"distributor"} label={"Distributor *"} value={distributor} fun={setDistributor} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required />
            <LabelAndInput id={"subDistributor"} label={"Sub Distributor *"} value={subDistributor} fun={setSubDistributor} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            <LabelAndInput id={"shareClasses"} label={"Share Classes *"} value={shareClasses} fun={setShareClasses} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required/>
            {/*<LabelAndInput id={"subsequentSubscriptions"} label={"Subsequent Subscriptions *"} value={subsequentSubscriptions} fun={setSubsequentSubscriptions} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-12"} required />*/}
            <LabelAndInput id={"navFrequency"} label={"Nav Frequency *"} value={navFrequency} fun={setNavFrequency} type={isApprovedAndTokenized ? "disabled" : "text"} className={"col-span-6"} required/>
            <LabelAndInput id={"reporting"} label={"Reporting *"} value={reporting} fun={setReporting} type={isApprovedAndTokenized ? "disabled" : "textarea"} rows={3} className={"col-span-12"} required/>
            
            <div className="col-span-12 grid mt-5">
                <UploadTokenFilesForm 
                    coownership={{names: coownership, setNames: setCoownership,
                        tbr: props.coownTBR, tbu: props.coownTBU, setTbr: props.setCoownTBR, setTbu: props.setCoownTBU}}
                    prospectus={{names: prospectus, setNames: setProspectus,
                        tbr: props.prospTBR, tbu: props.prospTBU, setTbr: props.setProspTBR, setTbu: props.setProspTBU}}
                    isSubmitted={isSubmitted}/>
            </div>

            <div className="col-span-12 mt-5">
                <UploadCardImage images={images2} assetName={assetName}/>
            </div>
            
            <div className="col-span-12 mt-5">
                <UploadFilesForm 
                    images={{names: images2, setNames: setImages2,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    videos={{names: videos, setNames: setVideos,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    documents={{names: documents, setNames: setDocuments,
                            tbr: props.tbr, tbu: props.tbu, setTbr: props.setTbr, setTbu: props.setTbu}}
                    isSubmitted={isSubmitted}/>
            </div>

        </div>
    </>
}