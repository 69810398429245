import Navbar from './components/Navbar'
import Footer from './components/Footer'
import { Switch, Route, Redirect, useHistory  } from 'react-router-dom'
import './styles/index.css'
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators, State, persistor } from './state'
import { UserContext, defaultUserState, UserAccount } from './UserContext'
import { PoolListContext, defaultPoolListState, PoolState } from './PoolContext'
import ProfileRoute from './routes/Profile.route'
import AssetRoute from './routes/Asset.route'
import SecurityTokenRoute from './routes/SecurityToken.route'
import SecondaryMarketRoute from './routes/SecondaryMarket.route'
import SignInRoute from './routes/SignIn.route'
import HomeRoute from './routes/Home.route'
import PortfolioRoute from './routes/Portfolio.route'
import MenuRoute from './routes/Menu.route'
import AboutRoute from './routes/About.route'
import SupportRoute from './routes/Support.route'
import PrivPolRoute from './routes/PrivacyPolicy.route'
import AdditionalInfoRoute from './routes/AdditionalInfo.route'
import IssuerDashboard from './routes/IssuerDashboard.route'
import TokenDetailsRoute from './routes/IssuerTokenDetails.route'
import IssuerPortfolio from './routes/IssuerPortfolio.route'
import AlmostDoneRoute from './routes/AlmostDone.route'
import KYCRoute from './routes/KYC.route'
import VerifyEmailRoute from './routes/VerifyEmail.route'
import IssueNewRoute from './routes/IssueNew.route'
import IssueAssetRoute from './routes/IssueAsset.route'
import TokenizationRoute from './routes/Tokenization.route'
import NewAssetExtraInfoRoute from './routes/NewAssetExtraInfo.route'
import ReactGA from "react-ga4"
import Popup from './components/Popup'
import BlockchainLookup from './routes/BlockchainLookup.route'
import IssuerPrimarySaleRoute from './routes/IssuerPrimarySale.route'
import PrimarySaleRoute from './routes/PrimarySale.route'
import IssuerLiquidityPoolRoute from './routes/IssuerLiquidityPool.route'
import { Amplify } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react'
import awsmobiledevelop from './aws-exports-develop';
import awsconfigstaging from './aws-exports-staging';
import awsconfigprod from './aws-exports-production';
import DisclaimerRoute from './routes/DisclaimerRoute'
import Cookies from 'universal-cookie';
import background from "./media/background.png";
import { useLocation } from 'react-router'
import { Helmet } from "react-helmet";
import EarnRoute from './routes/Earn.route'
import Hotjar from '@hotjar/browser'
import LandingRoute from './routes/Landing.route'
import CookieBanner from './components/CookieBanner'

const cookies = new Cookies();
var siteId: number;

switch(process.env.REACT_APP_ENV) {
  case "develop":
    Amplify.configure(awsmobiledevelop);
    siteId = 3746976;
    cookies.set("env", "develop", {path: "/", sameSite: "strict"})
    break;
  case "staging":
    Amplify.configure(awsconfigstaging);
    siteId = 3746976;
    cookies.set("env", "staging", {path: "/", sameSite: "strict"})
    break;
  case "main":
    Amplify.configure(awsconfigprod);
    siteId = 3743478;
    cookies.set("env", "production", {path: "/", sameSite: "strict"})
    break;
  default:
    Amplify.configure(awsmobiledevelop);
    siteId = 3746976;
    cookies.set("env", "develop", {path: "/", sameSite: "strict"})
}

function App() {
  
  if(process.env.REACT_APP_ENV === "main") {
    ReactGA.initialize('G-M8HMYZQSMY');
  } else {
    ReactGA.initialize('G-8N05BSFZKY');
  }
  
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);
  let location = useLocation()

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname})
  }, [location])
  
  const dispatch = useDispatch()
  const actions = bindActionCreators(actionCreators, dispatch)

  let history = useHistory();

  ///////////OLD/////////////
  const userLoggingIn = useSelector((state: State) => state.login)
  const basicAccountState = useSelector((state: State) => state.basicAccount)
  const detailAccountState = useSelector((state: State) => state.detailAccount)
  const issuerInfoState = useSelector((state: State) => state.issuerInfo)
  const poolState = useSelector((state: State) => state.poolToken)
  const [poolList, setPoolList] = useState<PoolState[]>(defaultPoolListState)
  const [user, setUser] = useState<UserAccount>(defaultUserState)

  ///////////NEW/////////////
  const { authStatus } = useAuthenticator(context => [context.authStatus])
  const { signOut } = useAuthenticator((context) => [context.user])
  const logged = cookies.get("logged_in")
  const [session, setSession] = useState<string | null>(null)
  ///////////////////////////

  // isBasicAccountOrIsLoggedOut must be true on loggedOut user or on basicAccount user, must be false on detailedAccountUser
  var tmp: boolean | undefined = !logged || 
    (basicAccountState.payload && !basicAccountState.payload.is_issuer && 
      !basicAccountState.payload.is_investor && !basicAccountState.payload.kyc_submitted)
  var isBasicAccountOrIsLoggedOut = tmp ? tmp : false
  const [showPopUp, setShowPopUp] = useState<boolean>(false)
  const [titlePopUp, setTitlePopUp] = useState<string>("")
  const [msgPopUp, setMsgPopUp] = useState<string>("")
  
  function handleClosePopUp(){
      setTitlePopUp("")
      setMsgPopUp("")
      setShowPopUp(false) 
  }

  useEffect(() => {
    actions.getBasicAccountProfile()
    //actions.getAccountDetails()
    //actions.getIssuerInfo()
  }, [])

  useEffect(() => {
    cookies.set("signup_enabled", true, {sameSite:"strict", path: "/"})
    actions.getSignupEnabled(()=> {})
  }, [])

  useEffect(() => {   // signout
    if(userLoggingIn.loggingOut){
      persistor.purge()
      setUser(defaultUserState)
      history.push('/')
    }
    if(userLoggingIn.loggedIn){
      var entireUser = user
      //console.log(user)
      entireUser.logged_in = true
      setUser(entireUser)
    }
  }, [userLoggingIn.loggedIn])

  useEffect(() => {
    setSession(cookies.get("token_id"))
  }, [cookies.get("token_id")])
  
  useEffect(() => {
    if(session){
      actions.getBasicAccountProfile()
      actions.getAccountDetails()
      //actions.getIssuerInfo()
    }
  }, [session])

  useEffect(() => {
    if(userLoggingIn.loggedIn){
      var entireUser
      if (!basicAccountState.loading && !detailAccountState.loading /*&& !issuerInfoState.loading*/) {
        //console.log(basicAccountState.payload)
        if (basicAccountState.payload && detailAccountState.payload /*&& issuerInfoState.payload*/) {
            entireUser = {...user, ...basicAccountState.payload, ...detailAccountState.payload/*, ...issuerInfoState.payload*/};
            setUser(entireUser)
        }
        if(basicAccountState.payload?.is_issuer) actions.getIssuerInfo()
        //else console.log("not fetching")
      }
    }
  }, [basicAccountState.payload, detailAccountState.payload/*, issuerInfoState.payload*/])

  useEffect(() => {
    if(userLoggingIn.loggedIn){
      var entireUser
      if (!issuerInfoState.loading) {
        //console.log(issuerInfoState)
        if (issuerInfoState.payload) {
            entireUser = {...user,  ...issuerInfoState.payload};
            setUser(entireUser)
        }
      }
    }
  }, [issuerInfoState.payload])

  useEffect(() => {
    if (poolState.payload && poolState.payload  && poolState.payload.length) {
      setPoolList(poolState.payload)
    }
  }, [poolState.payload])

  function handleSignOut(e: React.MouseEvent) {
    cookies.remove("logged_in", {path: "/", sameSite: "strict"})
    cookies.remove("token_id", {path: "/", sameSite: "strict"})
    cookies.remove("expiredPU", {path: "/", sameSite: "strict"})
    cookies.remove("new_user", {path: "/", sameSite: "strict"})
    cookies.remove("username", {path: "/", sameSite: "strict"})
    cookies.remove("user_email", {path: "/", sameSite: "strict"})
    cookies.remove("session_expired", {path: "/", sameSite: "strict"})
    actions.SignOutUserInvestor()
    actions.signOutCallback(session ? session : "")
  }

  function LoggedRoute(props: {path: string, component: any, logged: boolean}): JSX.Element {
    
    return props.logged ?
      <Redirect from={props.path} to='/' /> :
      <Route path={props.path} component={props.component}/>
  }

  useEffect(() => {
    let t = cookies.get("token_id")
    if(t) {
      if((t === "expired") && ((cookies.get("expiredPU") === "true") ||  cookies.get("expiredPU"))) {
        signOut()
        cookies.set("expiredPU", false, {path: "/", sameSite: "strict"})
        setTitlePopUp("Sorry!")
        setMsgPopUp("Please log in again, your session has expired.")
        setShowPopUp(true)
        signOut()
        history.push("/account/signin")
      }
    }
  }, [authStatus, cookies.get("token_id"), session])

// HOTFIX: DEXX-969 - Duplicate declarations
//const siteId = 3743478;
//const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content="Dexx - Financial Digital Exchange"/>
      <title>Dexx</title>
    </Helmet>
    <style>
      @import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@300;400;500;700;800&display=swap');
      @import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;700;800&display=swap');
      @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;700;800&display=swap');
      @import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400;500;700;800&display=swap');
      @import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
      @import url('https://fonts.googleapis.com/css2?amily=Inria+Serif:ital,wght@0,100;0,200;0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
      @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

    </style>
    <div className="bg-spot-big"></div>
    <div className="bg-spot-small"></div>

    <PoolListContext.Provider value={poolList}>
        <UserContext.Provider value={user}>
          <div className="min-h-screen grid content-between w-full" >
            <img src={background} className='fixed z-neg10 w-100vw h-full max-w-full opacity-80'/>
            <div className="w-full">
              <Navbar handleSignOutInvestor={handleSignOut} showAssets={!isBasicAccountOrIsLoggedOut}></Navbar>
            </div>
            <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp}  ></Popup>
            <div className="px-10 w-100vw max-w-full">
              <Switch>
                
                {/* PUBLIC ROUTES */}
                <Route exact path="/" component={LandingRoute} />
                <Route path={"/account/signin"} component={SignInRoute} />
                <Route path={"/account/signup"} component={SignInRoute} />
                <Route path={"/about"} component={AboutRoute} />
                <Route path={"/support"} component={SupportRoute} />
                <Route path={"/privacy-policy"} component={PrivPolRoute} />
                <Route path={"/verify-email"} component={VerifyEmailRoute} />
                <Route path={"/disclaimer"} component={DisclaimerRoute} />
                <Route path={"/invest"} component={AssetRoute} />
                
                {/* LOGGED USERS ROUTES */}
                <LoggedRoute path={"/account/additional-info"} component={AdditionalInfoRoute} logged={!logged}/>
                <LoggedRoute path={"/account/almost-done"} component={AlmostDoneRoute} logged={!logged}/>
                <LoggedRoute path={"/menu"} component={MenuRoute} logged={!logged}/>
                <LoggedRoute path={"/kyc"} component={KYCRoute} logged={!logged}/>
                {/*<LoggedRoute path={"/invest"} component={AssetRoute} logged={!logged} />*/}

                <LoggedRoute path={"/profile"} component={ProfileRoute} logged={isBasicAccountOrIsLoggedOut} />
                <LoggedRoute path={"/issuer/dashboard"} component={IssuerDashboard} logged={isBasicAccountOrIsLoggedOut} />
                <LoggedRoute path={"/issuer/portfolio"} component={IssuerPortfolio} logged={isBasicAccountOrIsLoggedOut} />
                
                <LoggedRoute path={"/issuer/tokens/:uuid"} component={TokenDetailsRoute} logged={isBasicAccountOrIsLoggedOut} />
                <LoggedRoute path={"/issuer/primary-sale/:uuid"} component={IssuerPrimarySaleRoute} logged={isBasicAccountOrIsLoggedOut} />
                <LoggedRoute path={"/issuer/liquidity-pool/:uuid"} component={IssuerLiquidityPoolRoute} logged={isBasicAccountOrIsLoggedOut} />
                <LoggedRoute path={"/issuer/asset/new"} component={IssueNewRoute} logged={isBasicAccountOrIsLoggedOut} />

                <LoggedRoute path={"/issuer/asset/manage/:uuid"} component={IssueAssetRoute} logged={isBasicAccountOrIsLoggedOut} />
                <LoggedRoute path={"/issuer/asset/manage"} component={IssueAssetRoute} logged={isBasicAccountOrIsLoggedOut} />

                <LoggedRoute path={"/issuer/extra-info/:uuid"} component={NewAssetExtraInfoRoute} logged={isBasicAccountOrIsLoggedOut} />
                <LoggedRoute path={"/issuer/asset/tokenize/:uuid"} component={TokenizationRoute} logged={isBasicAccountOrIsLoggedOut} />

                {/*<LoggedRoute path={"/invest"} component={AssetRoute} logged={isBasicAccountOrIsLoggedOut} />*/}
                <LoggedRoute path={"/earn"} component={EarnRoute} logged={isBasicAccountOrIsLoggedOut} />
                <LoggedRoute path={"/portfolio"} component={PortfolioRoute} logged={isBasicAccountOrIsLoggedOut} />

                <LoggedRoute path={"/blockchain/transaction/:trx"} component={BlockchainLookup} logged={isBasicAccountOrIsLoggedOut} />
                <Route path={"/asset/:uuid"} component={SecurityTokenRoute} />
                <Route path={"/token/:uuid/:lp?"} component={SecondaryMarketRoute} />
                <Route path={"/primary_sale/:uuid"} component={PrimarySaleRoute} />

              </Switch>
              
            </div>
            
            <CookieBanner />
            
            {window.location.pathname === "/" ? <></> : <Footer></Footer>}
          </div>

        </UserContext.Provider>
      </PoolListContext.Provider></>
  )
}
export default App
