import { useEffect, useState} from "react"
import { useDispatch } from "react-redux"
import { bindActionCreators } from "redux";
import { actionCreators } from "../state";
import { useHistory  } from 'react-router-dom'
import { DexxButton } from "../components/light/LightButton"
import { PageDescription } from "../components/typography/Typogtaphy";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default function IssueNewRoute(): JSX.Element {
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    let history = useHistory()   

    //const isDev = cookies.get("env") !== "production"
    const isDev = cookies.get("env") === "develop"

    const [assetCategories, setAssetCategories] = useState<any>([])
   
    useEffect(() => {
        actions.getAllAssetCategories(setAssetCategories)
    }, [])

    function handleCreateIssue(category:any){
        history.push({
            pathname: '/issuer/asset/manage',
            state: { category : category }
        });
    }
    return (<>           
        <div className="flex place-content-center">
            <div className="grid w-fit">
                <div className="grid justify-center justify-items-center items-center">
                    <PageDescription title={<div>Issue A<br></br>New Asset</div>} 
                        description={"Which kind of asset do you want to create?"}
                        titleSize="5xl md:text-8xl font-light "
                        />
                </div>
                
                <div className="grid mt-10" >
                    <div className="font-roboto py-2">
                        <div  className="flex flex-col flex-wrap md:place-content-start place-content-center
                            gap-4 place-items-start">
                            {
                                (assetCategories === undefined || assetCategories.asset_categories === undefined) ? null 
                                    : assetCategories.asset_categories.map((cat: any, i: number) => {
                                        return (
                                            <div className="" key={i}>
                                                <DexxButton label={cat + " ASSET"}  onClick={() => {handleCreateIssue(cat)}}
                                                    color3="gray9" color4="gray10" 
                                                    className="rounded-full px-12 py-3 cursor-pointer w-72"/>
                                            </div>
                                        )
                                    }
                                )
                            }
                            <DexxButton label={"My Dashboard"} onClick={() => {history.push("/issuer/dashboard")}}
                                className="rounded-full px-12 py-3 cursor-pointer w-72 bg-gray9" color2="gray10" 
                                color3="transparent" color4="gray10"/>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
        
    </>)
}
