import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { HtmlTooltip } from "./HtmlTooltip";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import TimezoneSelect from "react-timezone-select";
import { DexxNumericInput } from "./utils/Input";
import '../styles/input-MUI.css'

export default function LabelAndInput(props: {id?: string, label: string, value: any, fun: any,
        type: string, className?: string, passwordTip?: any, infoPhoneTip?:any, helpTip?:string, step?: any, max?:any, required?: boolean,
        rows?: number, options?: {value: any, text: string}[], color?: string, size?: string, disabled?: boolean}): JSX.Element {
    function addDatePadding(n: string){
        return n.length < 2 ? "0"+n : n
    }

    function dateIsValid(date: any) {
        return !Number.isNaN(new Date(date).getTime());
    }

    function datePH() {
        var d = new Date(props.value)
        return (props.type==="date" || props.type==="date-disabled") && dateIsValid(d) ? d.toISOString().substr(0,10) : ""
    }

    const current = new Date()
    const month = addDatePadding((current.getMonth()+1).toString())
    const day = addDatePadding(current.getDate().toString())
    const date = (current.getFullYear()-18).toString()+"-"+month+"-"+day
    var color = " text-tertiary border-tertiary"
    var textSize = " text-base py-3"
    if(props.color)
        switch(props.color) {
            case "blue":
                color = " text-primary border-blue "
                break
            case "light blue":
                color = " text-wild_blue_yonder border-wild_blue_yonder "
                break
            case "gray":
                color = " text-gray5 border-gray5 "
                break
            case "white":
            default:
                color = " text-tertiary border-tertiary "
        }
    if(props.size) {
        switch(props.size) {
            case "sm":
                textSize = " text-sm "
                break
            case "md":
                textSize = " text-md py-3 "
                break
            case "xl":
                textSize = " text-2xl py-3 "
                break
            default:
                textSize = " text-base py-3"
        }
    }
    const inputClasses = "w-full bg-transparent border-t-0 border-l-0 border-r-0 border-solid border-b-1 "+color+
                    " font-roboto pl-2 leading-none "+textSize

    const phoneStyle = {
        width: "100%",
        backgroundColor : "transparent",
        border: "0px",
        fontFamily: "Roboto, sans-serif",
        fontSize: "0.875rem",
        paddingLeft: "3.5rem",
        paddingTop: "1.25rem",
        paddingBottom: "1.5rem",
        color: "#CFD6DB",
        lineHeight: "1.25rem",
        
        //borderColor: "rgb(118, 118, 118)",
        //borderColor: "#0A3C60",
    }

    let inputEl: JSX.Element = <></>

    function checkNumber(n: string) {
        let a: number = 0
        let b = false
        try {
            if(n.length > 0){
                a = (Number(n))
                b = true
            }
        } catch(e) {}
        if((a && b) || a === 0)
            props.fun(n)
    }

    function handleTextButNumber(n:string){
        const re = /^[0-9\b]+$/;
        if (n === '' || re.test(n)) {
            props.fun(n)
        }
    }

    switch(props.type) {
        case "date":
            inputEl = props.required ?
                <input id={props.id} className={inputClasses}
                    defaultValue={datePH()} onChange={e => props.fun(e.target.value)} type={props.type} 
                    min="1900-01-01" max={date} required /> :
                <input className={inputClasses}
                    defaultValue={datePH()} onChange={e => props.fun(e.target.value)} type={props.type} 
                    min="1900-01-01" max={date} />
            break;
        case "date-disabled":
            inputEl = 
                <input id={props.id} className={inputClasses}
                    defaultValue={datePH()} type={"date"} min="1900-01-01" disabled />
                
            break;
        case "datetime-local":
            inputEl = props.required ?
                <input id={props.id} className={inputClasses} 
                    value={props.value} onChange={e => props.fun(e.target.value)} type={props.type} 
                    min="1900-01-01T00:00" required /> :
                <input className={inputClasses}
                    value={props.value} onChange={e => props.fun(e.target.value)} type={props.type} 
                    min="1900-01-01T00:00" />
            break;
        case "checkbox":
            inputEl = props.required ?
                <input id={props.id} className="w-20 py-4 h-5 border-solid border-tertiary
                        text-tertiary text-xl font-roboto pl-2 leading-none"
                    value={props.value} onChange={e => props.fun(e.target.checked)} type={props.type} required/> :
                <div className={"w-5"+(props.value ? " bg-primary" : " ")}>
                    <div className={"w-8  h-8 border-solid"+
                        " text-xl font-roboto pl-2 leading-none border-2 mt-6 "+color+(props.value ? " bg-primary" : " ")}
                        onClick={() => { props.fun(!props.value)}}>
                    </div></div>
            break;
        case "number":
            inputEl = props.required ?
                <input id={props.id} className={inputClasses}
                    value={props.value} onChange={e => checkNumber(e.target.value)}
                    type="text" pattern="[0-9]*" inputMode="numeric" 
                    step={props.step ? props.step : "any"}
                    max={props.max ? props.max : ""} required/> :
                <input className={inputClasses}
                    value={props.value} onChange={e => checkNumber(e.target.value)} 
                    type="text" pattern="[0-9]*" inputMode="numeric" 
                    step={props.step ? props.step : "any"}
                    max={props.max ? props.max : ""}/>
            break;
        case "float":
            
            /*inputEl = props.required ?
                <input id={props.id} className={inputClasses}
                    value={tmp} onChange={e => checkFloat(e.target.value, tmp)} 
                    type="text"
                    step={props.step ? props.step : "any"} required/> :
                <input className={inputClasses}
                    value={tmp} onChange={e => checkFloat(e.target.value, tmp)} 
                    type="text"
                    step={props.step ? props.step : "any"}/>
            */
            inputEl = props.required ? 
                <DexxNumericInput value={props.value} fun={props.fun} 
                    className="mui-input w-full" required/> : 
                <DexxNumericInput value={props.value} fun={props.fun} 
                    className="mui-input w-full"/>
            break;
        case "textarea":
            inputEl = props.required ?
                <textarea id={props.id} className={inputClasses}
                    value={props.value} onChange={e => props.fun(e.target.value)} rows={props.rows ? props.rows: 3} required/> :
                <textarea className={inputClasses}
                value={props.value} onChange={e => props.fun(e.target.value)} rows={props.rows ? props.rows: 3} required/>
            break;
        case "select":
            inputEl = props.required ?
                <select id={props.id} value={props.value} onChange={e => props.fun(e.target.value)}
                    className={inputClasses + " pb-3"} required>
                    {props.options ? props.options.map(({value: v, text: t}, i) => {return <option value={v} key={i} className="bg-primary">{t}</option>}) 
                    : <></>}
                </select>
                 : <select value={props.value} onChange={e => props.fun(e.target.value)}
                    className={inputClasses + " pb-3"}>
                    {props.options ? props.options.map(({value: v, text: t}, i) => {return <option value={v} key={i} className="bg-primary">{t}</option>}) : <></>}
                </select>
            break;
        case "no-input":
            inputEl = <div className={"w-full py-4 bg-transparent font-roboto pl-2 leading-none "+color+textSize}>
                {props.value}
            </div>
            break;
        case "disabled":
            inputEl = <input className={inputClasses} value={props.value} type={"text"} disabled/>
            break;
        case "phone":
            inputEl = <PhoneInput
                        inputProps={{
                            id: props.id,
                            required: true,
                            //autoFocus: true
                        }}
                        country={'us'} 
                        placeholder=""
                        inputStyle={phoneStyle}
                        //containerStyle={{backgroundColor: 'trasparent', borderBottom: '1px solid rgb(118, 118, 118)'}}
                        containerStyle={{backgroundColor: 'trasparent', borderBottom: '1px solid #CFD6DB'}}
                        dropdownStyle={{backgroundColor: '#0A3C60', color:"#CFD6DB"}}
                        buttonStyle={{paddingTop: "0px", paddingBottom: "0px", backgroundColor: "transparent", 
                            border: "0px"}}
                value={props.value} onChange={props.fun} />
            break;
        case "phone-disabled":
                inputEl = <PhoneInput
                            inputProps={{
                                id: props.id,
                            }}
                            country={'us'} 
                            placeholder=""
                            inputStyle={phoneStyle}
                            //containerStyle={{backgroundColor: 'trasparent', borderBottom: '1px solid rgb(118, 118, 118)'}}
                            containerStyle={{backgroundColor: 'trasparent', borderBottom: '1px solid #CFD6DB'}}
                            dropdownStyle={{backgroundColor: '#0A3C60', color:"#CFD6DB"}}
                            buttonStyle={{paddingTop: "0px", paddingBottom: "0px", backgroundColor: "transparent", 
                                border: "0px"}}
                            disabled
                            //showDropdown={false}
                            inputClass="PhoneInput--readOnly"
                            
                            
                    value={props.value} onChange={props.fun} />
                break;
        case "datetime-local-disabled":
            inputEl = <input className={inputClasses} value={props.value} type={"datetime-local"} disabled/>
            break;
        case "timezone":
            inputEl = <TimezoneSelect value={props.value} onChange={props.fun} className={inputClasses+" overflow-auto "} isDisabled={props.disabled}
                styles={{
                    control: (baseStyles:any, state:any) => ({
                    ...baseStyles,
                    borderColor: "transparent",
                    backgroundColor: "transparent",
                    color: "#0A3C60",
                    cursor: "pointer",
                    }),
                    option: (styles:any, { isSelected }) => {
                        return {
                          ...styles,
                          backgroundColor: isSelected ? "#CFD6DB" : "#0A3C60",
                          color: isSelected ? "#0A3C60": "#CFD6DB",
                          cursor: 'pointer',
                    };},
                    singleValue: (baseStyles:any) => ({
                        ...baseStyles,
                        color: "#CFD6DB",
                    }),

                    dropdownIndicator: (baseStyles: any) => ({
                        ...baseStyles,
                        display: props.disabled ? "none" : "",
                    }),
                    indicatorSeparator: (baseStyles: any) => ({
                        ...baseStyles,
                        display: props.disabled ? "none" : "",
                    })
                }}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                    ...theme.colors,
                      text: '#0A3C60',
                      primary25: '#0A3C60',
                      primary: '#0A3C60',
                    }
            })}/>
            break
        
        case "textButNumber":
            inputEl = props.required ?
                <input id={props.id} className={inputClasses}
                    value={props.value} onChange={e => handleTextButNumber(e.target.value)} type={props.type} required/> :
                <input id={props.id} className={inputClasses}
                value={props.value} onChange={e => handleTextButNumber(e.target.value)} type={props.type} />
            break
        default:
            inputEl = props.required ?
                <input id={props.id} className={inputClasses}
                    value={props.value} onChange={e => props.fun(e.target.value)} type={props.type} required/> :
                <input className={inputClasses}
                value={props.value} onChange={e => props.fun(e.target.value)} type={props.type} />
    }

    return <>

        <div className={props.className+" font-roboto py-2 tracking-wider mx-1 "}>
            <label className={"w-full py-4"+ color+textSize}>{props.label}</label>
            {props.passwordTip ? <>
                <HtmlTooltip
                            placement="top-start"
                            title={
                                <div className="p-5 font-roboto font-semibold text-primary text-base">
                                    <div className=" font-bold text-primary text-lg">Password must meet complexity requirements:</div>
                                    <ul>
                                    <li>Not contain the users account name.</li>
                                    <li>Exceeded eight characters in length.</li>
                                    <li>Contain at least one character from at least three of four sets of characters:</li>
                                    <li className="ml-3"><em>A through Z.</em></li>
                                    <li className="ml-3"><em>a through z.</em></li>
                                    <li className="ml-3"><em>0 through 9.</em></li>
                                    <li className="ml-3"><em>Symbols such !@#$%^&*"</em></li>
                                    </ul>
                                </div>
                            }
                        >
                            <IconButton>
                                <InfoOutlinedIcon className="mb-2" sx={{ color: 'white' }} />
                            </IconButton>
                    </HtmlTooltip>
            </> : <></>}
            {props.infoPhoneTip ? <>
                <HtmlTooltip
                            placement="top-start"
                            title={
                                <div className="p-5 font-roboto font-semibold text-primary text-base">
                                    <div className=" font-bold text-primary text-lg">Phone must meet E. 164 requirements:</div>
                                    <ul>
                                    <li>Remove any spaces, parenthesis or other punctuation</li>
                                    <li>If the number begins with a zero, remove the leading zero</li>
                                    <li>Prepend the country code</li>
                                    <li>Prepend a ”+”</li>
                                    </ul>
                                </div>
                            }
                        >
                            <IconButton>
                                <InfoOutlinedIcon /*className="mb-2"*/ sx={{ color: 'white', margin: '-8px' }} />
                            </IconButton>
                    </HtmlTooltip>
            </> : <></>}
            {props.helpTip && props.helpTip !== "" ? <>
                <HtmlTooltip
                            placement="top-start"
                            title={
                                <div className="p-5 font-urbanist font-semibold text-blue text-base">
                                    <div className=" font-bold text-blue text-lg">{props.helpTip}</div>
                                </div>
                            }
                        >
                            <IconButton className="">
                                <InfoOutlinedIcon /*className="mb-2"*/ sx={{ color: 'white', margin: '-10px -8px 0px 0px', width: '0.7em', height: '0.7em' }} />
                            </IconButton>
                    </HtmlTooltip>
            </> : <></>}
            {inputEl}
        </div>
    </>
}