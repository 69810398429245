import { TitleColor1 } from "./typography/Typogtaphy"
import { LightButton } from "./light/LightButton"
import LabelAndInput from "./LabelAndInput"

export default function UpdatableStructuredTable(props: {title: string, state: any[], setState: Function, 
        emptyElement: any, objectStructure: {name: string, type: string}[], className?: string}): JSX.Element {

    function handleChange(newValue: any, name: string, index: number) {
        let tmp = [...props.state]
        tmp[index] = {...tmp[index], [name]: newValue}
        props.setState(tmp);
    }

    function removeRow(idx: number) {
        let tmp = [...props.state]
        let t = tmp.filter((_, jdx) => {return jdx!==idx})
        props.setState(t)
    }

    function addRow() {
        let tmp = [...props.state]
        tmp.push(props.emptyElement)
        props.setState(tmp)
    }

    return <>
        <div className={"col-span-12 "+props.className}>
            <TitleColor1 className=" text-lg">/ {props.title}</TitleColor1>
            {props.state?.map((i, idx) => {
                return <div className="flex justify-between" key={idx}>
                    <div className="flex flex-wrap sm:flex-nowrap w-full">
                        {props.objectStructure.map((j, jdx) => {
                            return <LabelAndInput label={j.name.charAt(0).toUpperCase() + j.name.slice(1)} 
                                value={i[j.name]} key={jdx} type={j.type}
                                fun={(e:any) => handleChange(e, j.name, idx)}
                                className="w-full" />
                        })}
                    </div>
                    <div className="flex place-content-center">
                        <button onClick={() => removeRow(idx)} 
                            className="bg-transparent text-white border-0 cursor-pointer">x</button>
                    </div>
                </div>
            })}
            <div className="w-full flex place-content-center col-span-12 my-5">
                <LightButton label={"Add Value"} onClick={addRow} className="rounded-xl px-10" alternate={true}/>
            </div>
        </div>
    </>
}

export function UpdatableStringTable(props: {title: string, state: any[], setState: Function,
        submitted: boolean, className?: string}): JSX.Element {

    function handleChange(newValue: any, index: number) {
        let tmp = [...props.state]
        tmp[index] = newValue
        props.setState(tmp);
    }

    function removeRow(idx: number) {
        let tmp = [...props.state]
        let t = tmp.filter((_, jdx) => {return jdx!==idx})
        props.setState(t)
    }

    function addRow() {
        let tmp = [...props.state]
        tmp.push("")
        props.setState(tmp)
    }

    return <>
        <div className={"col-span-12 "+props.className}>
            <TitleColor1 className=" col-span-12 text-lg">/ {props.title}</TitleColor1>
            {props.state?.map((i, idx) => {
                return <div className="flex justify-between">
                    <LabelAndInput label={""} value={i} className="w-full"
                        fun={(e:any) => handleChange(e, idx)} type={props.submitted ? "disabled" : ""}/>
                    <div className="flex place-content-center">
                        <button onClick={() => removeRow(idx)} 
                            className="bg-transparent text-white border-0 cursor-pointer">x</button>
                    </div>
                </div>
            })}
            <div className="w-full flex place-content-center col-span-12 my-5">
                <LightButton label={"Add Value"} onClick={addRow} className="rounded-xl px-10" alternate={true}/>
            </div>
        </div>
    </>
}