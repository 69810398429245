import { FileSrc } from "../AssetContext";
import LARF from "./ListAddRemoveFile";
//import ListAddRemoveOne from "./ListAddRemoveOne";
import { TitleColor1 } from "./typography/Typogtaphy";

type FileType = {
    names: FileSrc[]
    setNames: any,
    tbr: any[],
    setTbr: any,
    tbu: any[],
    setTbu: any
}


export default function UploadFilesForm(props: {images?: FileType, videos?: FileType, documents?: FileType, removeFileFunction?: any, isSubmitted?: boolean}): JSX.Element {
    
    return <>
        <div className="grid">
            {props.images ? <>
                <TitleColor1 className="text-lg">/ Pictures</TitleColor1>
                {<LARF names={props.images.names} setNames={props.images.setNames} type="img"
                    tbr={props.images.tbr} setTbr={props.images.setTbr}
                    tbu={props.images.tbu} setTbu={props.images.setTbu}
                    isSubmitted={props.isSubmitted ? props.isSubmitted : false}  />}
</> : <></>}
            {/*props.videos ? <>
                <TitleColor1 className="text-lg">/ Videos</TitleColor1>
                <LARF names={props.videos.names} setNames={props.videos.setNames} type="vid"
                    tbr={props.videos.tbr} setTbr={props.videos.setTbr}
                    tbu={props.videos.tbu} setTbu={props.videos.setTbu}
                    isSubmitted={props.isSubmitted ? props.isSubmitted : false}/>
</> : <></>*/}
            {props.documents ? <>
                <TitleColor1 className="text-lg">/ Other Documentation</TitleColor1>
                <LARF names={props.documents.names} setNames={props.documents.setNames} type="doc"
                    tbr={props.documents.tbr} setTbr={props.documents.setTbr}
                    tbu={props.documents.tbu} setTbu={props.documents.setTbu}
                    isSubmitted={props.isSubmitted ? props.isSubmitted : false} documents/>
</> : <></>}
        </div>
    </>
}

export function UploadTokenFilesForm(props: {coownership?: FileType, prospectus?: FileType, removeFileFunction?: any, isSubmitted?: boolean}): JSX.Element {
    return <>
        <div className="grid grid-cols-2 mt-10">
            {props.coownership ? 
            <div className="">
                <TitleColor1 className="text-lg">/ Co-Ownership Agreement</TitleColor1>
                {<LARF names={props.coownership.names} setNames={props.coownership.setNames} type="doc"
                    tbr={props.coownership.tbr} setTbr={props.coownership.setTbr}
                    tbu={props.coownership.tbu} setTbu={props.coownership.setTbu}
                    isSubmitted={props.isSubmitted ? props.isSubmitted : false}
                    onlyOne={true}  documents/>}
            </div> : <></>}
            {props.prospectus ? 
            <div className="">
                <TitleColor1 className="text-lg">/ Asset Token Prospectus</TitleColor1>
                <LARF names={props.prospectus.names} setNames={props.prospectus.setNames} type="doc"
                    tbr={props.prospectus.tbr} setTbr={props.prospectus.setTbr}
                    tbu={props.prospectus.tbu} setTbu={props.prospectus.setTbu}
                    isSubmitted={props.isSubmitted ? props.isSubmitted : false}
                    onlyOne={true} documents/>
            </div> : <></>}
        </div>
    </>
}