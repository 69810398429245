import React, { useContext, useEffect, useState } from "react"
import { PoolState, defaultPrimarySalesList, DistinctToken } from "../PoolContext"
import { UserContext } from '../UserContext'
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { actionCreators } from "../state";
import { TabTitle } from "../components/typography/Typogtaphy";
import CardsCollectionPrimaryMarket, { CardsCollectionInvestSecondaryMarket } from "../components/utils/CardsCollection";
import { BackButton, DexxButton } from "../components/light/LightButton";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
export interface AssetRouteProps {
    assetPool: PoolState
    toggleView: React.Dispatch<React.SetStateAction<boolean>>
}

export default function AssetRoute(props: {logged: boolean}): JSX.Element {

    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [tokens, setTokens] = useState<any[]>([])
    const [primarySalesList, setPrimarySalesList] = useState(defaultPrimarySalesList)
    const [shownList, setShownList] = useState<any[]>([])
    const [activeTab, setActiveTab] = useState<boolean[]>([true, false, false, false, false])
    const [hideCards, setHideCards] = useState<number[]>([])
    const [category, setCategory] = useState<string>("All")
    const [categoryID, setCategoryID] = useState<number>(0)
    const [distinctTokenList, setDistinctTokenList] = useState<any[]>([])
    let url = window.location.href
    let p = url.slice(url.lastIndexOf('#') + 1)
    const [tabLP, setTabLP] = useState<boolean>(p === "secondary")
    //const [isLogged, setIsLogged] = useState<boolean>(false)
    const isLogged = cookies.get("logged_in")

    //const userCtx = useContext(UserContext)

    const REFRESH_PERIOD = 20000; //20 SEC

    useEffect(() => {
        /*if(userCtx && userCtx.email !== "")
            setIsLogged(true)*/
        //actions.getAllAssets(setTokens)
        actions.getAllSecondaryMarket(setTokens)
        actions.getAllPrimarySales(setPrimarySalesList)
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            //actions.getAllAssets(setTokens)
            actions.getAllSecondaryMarket(setTokens)
            actions.getAllPrimarySales(setPrimarySalesList)
        }, REFRESH_PERIOD);
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        if(primarySalesList){
            setShownList(primarySalesList.primary_sales.filter(function(t){
                if(isLogged || (!isLogged && t.asset_category !== "AIF")){
                    if(t.status !== "Ended") return t
                    else {
                        var today = new Date()  
                        if(t.pct_sold < 100) {  // not sold out -> keep for 30d
                            var days30 = new Date(t.date_end)
                            days30.setDate(days30.getDate() + 30)
                            return today < days30

                        } else {                // sold out -> keep for 180d
                            var days180 = new Date(t.date_end)
                            days180.setDate(days180.getDate() + 1800)
                            return today < days180
                        }
                    }
                }
            }))
        }
    }, [primarySalesList])
    
    useEffect(() => {
        var all:any = []
        var tmp:DistinctToken = {
            token_uuid: "",
            token_ticker: "",
            token_category: "",
            token_name: ""
        }

        if(tokens){
            for (var i = 0; i < tokens.length; i++){
                if(isLogged || (!isLogged && tokens[i].asset_class !== "AIF")){
                    tmp = {
                        token_uuid: tokens[i].uuid,
                        token_ticker: tokens[i].ticker,
                        token_category: tokens[i].asset_class,
                        token_name: tokens[i].asset_name
                    }
                    all.push(tmp)
                }
            }
        }
        if(all)
            setDistinctTokenList(all)

    }, [tokens])

    function changeView(category: string, id: number) {
        setCategory(category)
        setCategoryID(id)
        let tmp = category === "All" ? [] : (
            
                !tabLP?
                    shownList.map((ps, i) => { 
                        return ps.asset_category !== category ? i : -1})
                :
                    distinctTokenList.map((sm, i) => {
                        return sm.token_category !== category ? i : -1})
            )
        tmp.filter((n) => n !== -1)
        setHideCards(tmp)
        var tmp2 = activeTab
        tmp2 = tmp2.map((_, i) => {return (i===id ? true : false)})
        setActiveTab(tmp2)
    }
    
    function Tab(props: {category: string, id: number}): JSX.Element {
        return <>
            <div onClick={() => {changeView(props.category, props.id)}} 
                className={"cursor-pointer mx-1 md:text-3xl " + 
                (activeTab[props.id] ? "text-succ1" : "")}>
                {props.category}</div>
        </>
    }

    useEffect(() => {
        changeView(category, categoryID)
        if(!tabLP)
            window.history.replaceState("", "","/invest")
        else
            window.history.replaceState("", "","/invest#secondary")
    }, [tabLP])

    let activeTabClasses = " bg-succ1 "
    let inactiveTabClasses = ""

    return (
        <>
            <div className="h-full">
                <div className="w-full px-10 pb-2">
                    <div className="flex gap-5 place-content-center mx-neg80 mt-2">
                        <DexxButton label={"primary market"} 
                            onClick={() => setTabLP(false)}
                            className={"rounded-full px-6 py-3 "+
                                (tabLP ? inactiveTabClasses : activeTabClasses)}
                            fontClasses=" font-normal md:text-sm text-xs"
                            color2={tabLP ? "white" : "primary"}
                            color1={tabLP ? "primary" : "white"}
                            noHoverOnSelected={!tabLP}/>
                        <DexxButton label={"secondary market"}
                            onClick={() => setTabLP(true)}
                            className={"rounded-full px-6 font-normal py-3 "+
                                (tabLP ? activeTabClasses : inactiveTabClasses)}
                            fontClasses="font-normal md:text-sm text-xs 
                                drop-shadow-xl shadow-black"
                            color2={tabLP ? "primary" : "white"}
                            color1={tabLP ? "white" : "primary"}
                            noHoverOnSelected={tabLP}/>
                    </div>
                    <TabTitle className="flex place-content-center mt-8 gap-2 z-10 flex-wrap
                            mx-neg80 md:mb-6 mb-neg40">
                        <Tab category="All" id={0}/>
                        <Tab category="ART" id={1}/>
                        <Tab category="SPORTEX" id={2}/>
                        {isLogged ? (<Tab category="AIF" id={3}/>) : <></>}
                        <Tab category="COLLECTABLES" id={4}/>
                    </TabTitle>

                    <div className="md:text-18xl text-9xl uppercase flex place-content-center
                            z-0 font-roboto font-normal text-darkblue whitespace-nowrap
                            mx-neg80 leading-48 overflow-clip mt-5 overflow-hidden
                            mb-neg80">
                        {tabLP ? "Secondary market" : "Primary sale"}
                    </div>
                    
                    {(!tabLP && shownList) ? <CardsCollectionPrimaryMarket tokensList={shownList} hide={hideCards} categoryName={category} isLogged={isLogged} /> : <></>}
                    {(tabLP && distinctTokenList) ? <CardsCollectionInvestSecondaryMarket uniqueTokens={distinctTokenList} hide={hideCards} categoryName={category} isLogged={isLogged} /> : <></>}
                </div>
                <div className="flex justify-center mt-5">
                    <BackButton />
                </div>
            </div>
        </>
    )
}