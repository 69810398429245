import { euroFormat } from "../../../utils";
import { AssetDescription, LabelAndInlineValue } from "../../LabelAndDescription";

export default function InvestmentLimitsTab(props: { tokenInfo: any, price: any }): JSX.Element {
    return <>
        <div className="col-span-4">
            <LabelAndInlineValue label={"MINIMUM INVESTMENT: "}
                value={"EUR " + euroFormat(props.price) + " (1 Token)"} />
            <AssetDescription alternate={true} label={""}
                description={props.tokenInfo.investment_limits}
                className="mt-5 text-xl font-roboto text-tertiary" />
        </div>
    </>
}