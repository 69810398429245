import { SecurityToken } from "../PoolContext";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { actionCreators, State } from "../state";
import { useEffect, useState } from "react"
import TabsMenu from "./TabsMenu";
import { euroFormat, euroQtyFormat, formatDate, formatDatetime } from "../utils";
import { LightButton, DexxButton, DisabledButton } from "./light/LightButton";
import LightTable from "./light/LightTable";
import PieAndList from "./PieAndList"
import { PrimarySaleGet, LiquidityPool } from "../PoolContext";
import { Link } from "react-router-dom"
import Cookies from 'universal-cookie';
import Popup, { DexxPopup } from '../components/Popup'
import LiquidationPopup from "../components/popup/LiquidationPopup"
import CreateLPPopup from "./popup/CreateLPPopup";
import DexxChart, { ChartData } from "./utils/DexxChart";

const cookies = new Cookies();

export default function IssuerTokenBody (props: {tokenInfo: SecurityToken, media: any}): JSX.Element {
    
    const dispatch = useDispatch()
    const actions = bindActionCreators(actionCreators, dispatch)
    const [tokenStats, setTokenStats] = useState<any>()
    const [issuedPrimarySales, setIssuedPrimarySales] = useState<PrimarySaleGet[]>([])
    const [issuedLiquidityPools, setIssuedLiquidityPools] = useState<LiquidityPool[]>([])
    const [filteredLP, setFilteredLP] = useState<LiquidityPool[]>([])
    const [tokenPSs, setTokenPSs] = useState<PrimarySaleGet[]>([])
    const hdsPrimarySales = ["ticker", "status", "start", "end", "listed supply", "circulating supply", "on sale", "price"]
    const hdsLiquidityPools = ["Pool Name", "Status", "Date created", "Token A", "Amount",  "Fimart Euro Voucher", "Amount","LP Token Supply"]
    
    const detailAccountState = useSelector((state: State) => state.detailAccount)
    const userCtx = detailAccountState.payload
    const offset = userCtx?.timezone_offset ? userCtx.timezone_offset : 0
    //const isDev = cookies.get("env") !== "production"
    const isDev = cookies.get("env") === "develop"

    const [liquidationPriceRequest, setLiquidationPriceRequest] = useState<any[]>([])
    const [liquidityPoolCreationRequest, setLiquidityPoolCreationRequest] = useState<any[]>([])

    const [showPopUp, setShowPopUp] = useState<boolean>(false)
    const [titlePopUp, setTitlePopUp] = useState<string>("")
    const [msgPopUp, setMsgPopUp] = useState<string>("")
    const [showLiquidationPopUp, setShowLiquidationPopUp] = useState<boolean>(false)
    const [showCreateLPPopUp, setShowCreateLPPopUp] = useState<boolean>(false)
    const [tradeChartData, setTradeChartData] = useState<ChartData[]>([])
    const [daysRange, setDaysRange] = useState<string>("30")
    const [nextSuffix, setNextSuffix] = useState<number>(0)

    let isLiquidated = !(props.tokenInfo.buyback_price && props.tokenInfo.buyback_price === "0")

    let tokensSold:any
    try {
        tokensSold = parseInt(props.tokenInfo.shares_issued)
    } catch(e) {tokensSold = 0}

    function handleClosePopUp(){
        setTitlePopUp("")
        setMsgPopUp("")
        setShowPopUp(false)
    }

    useEffect(() => {
        if(props.tokenInfo.uuid && props.tokenInfo.uuid !== "0")
            actions.getTradeMetricsToken(props.tokenInfo.uuid, daysRange, setTradeChartData)
    }, [props.tokenInfo.uuid])

    useEffect(() => {
        if(liquidationPriceRequest.length > 1) {
            setShowPopUp(true)
            switch (liquidationPriceRequest[0]) {
                case 200:
                case 204:
                    setTitlePopUp("Success")
                    setMsgPopUp("We are reviewing your Liquidation request.")
                    break;
                case 400:
                case 403:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("We encountered an error saving your data.")
                    break
                case 404:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("We encountered an error saving your data.")
                    break;
                default:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("Please try again, we encountered an error.")
                    break;
            }
        }
    }, [liquidationPriceRequest])

    useEffect(() => {
        if(liquidityPoolCreationRequest.length > 1) {
            setShowPopUp(true)
            switch (liquidityPoolCreationRequest[0]) {
                case 200:
                case 204:
                    setTitlePopUp("Success")
                    setMsgPopUp("We are reviewing your Liquidity Pool request.")
                    break;
                case 400:
                case 403:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("We encountered an error saving your data.")
                    break
                case 404:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("We encountered an error saving your data.")
                    break;
                default:
                    setTitlePopUp("Sorry!")
                    setMsgPopUp("We encountered an error saving your data.")
                    break;
            }
        }
    }, [liquidityPoolCreationRequest])
    
    useEffect(() => {
        if(props.tokenInfo.uuid && props.tokenInfo.uuid !== "0"){
            actions.getTokenStats(props.tokenInfo.uuid, setTokenStats)
            actions.getIssuerLiquidityPools(props.tokenInfo.uuid, setIssuedLiquidityPools)
        }
        actions.getIssuerPrimarySales(setIssuedPrimarySales)
        
    }, [props.tokenInfo])

    useEffect(() => {
        if(props.tokenInfo.uuid){
            const tmp = issuedPrimarySales.filter(v => v.token_uuid === props.tokenInfo.uuid)
            setTokenPSs(tmp)
        }
    }, [issuedPrimarySales])

    useEffect(() => {
        let filtered = [...issuedLiquidityPools]
        filtered = filtered.filter((i) => {return i.deleted === undefined || i.deleted === false})
        setFilteredLP(filtered)
        setNextSuffix(issuedLiquidityPools ? issuedLiquidityPools.length : 0)
    }, [issuedLiquidityPools])

    function ColoredTab(props: {label: string, color: string, index: number, headers: any, rows: any}): JSX.Element {
        return <div>
            <>
            {props.rows.length > 0 ?
                <LightTable headers={props.headers} rows={props.rows}
                    colorHd={"gradient-to-r from-"+props.color+" to-gray3"}
                    color1={"gradient-to-r from-"+props.color+"_lighter  to-gray1"}
                    color2={"gradient-to-r from-"+props.color+"_light to-gray2"} /> 
                :
                <div className="bg-white">
                    <div className={"font-roboto uppercase text-gray pl-10 py-2 bg-"+props.color+"_light"}>None</div>
                </div>}
                
            </>
        </div>
    }

    function primarySaleRows(l: any[], btnUrls: string[], btnLabels: string[]): any[] {
        return l ?
            l.map(t => addBtns([t.token_ticker, t.status, formatDate(t.date_start, offset), formatDate(t.date_end, offset), 
                euroQtyFormat(t.total_supply), euroQtyFormat(t.shares_issued), euroQtyFormat(t.total_supply - t.shares_issued), 
                euroFormat(t.price)],btnLabels, btnUrls, t.uuid)
            ) : []
    }

    function liquidityPoolRows(l: any[], btnUrls: string[], btnLabels: string[]): any[] {
        return l ?
            l.map(t => addBtns([t.lp_name, t.status_name, formatDate(t.date_created, offset), 
                t.token_a_ticker, euroQtyFormat(t.amount_a), 
                t.token_b_ticker, euroFormat(t.amount_b), 
                euroQtyFormat(t.lp_token_supply)],btnLabels, btnUrls, t.uuid)
            ) : []
    }

    function addBtns(row: any[], btnLabels: string[], btnUrls: string[], uuid: any) {
        let tmp = btnLabels.map((b, i) => {return <div key={i}><TableButton label={b} link={btnUrls[i] + uuid} key={i}/></div>})
        return [...row, ...tmp]
    }

    function TableButton(props: { label: string, link: string }): JSX.Element {
        return <>
            <Link className="grid justify-center" to={props.link}><LightButton label={props.label} className="px-2 rounded m-1 font-normal" /></Link>
        </>
    }

    useEffect(() => {
        // Call to get chart data
    }, [daysRange])

    function showIssuanceStatus() { 
        return (<>
            <div className="">
                <div className="grid grid-cols-2 cover my-10">
                    <div className="my-10 flex flex-col text-left">
                        <div className="font-urbanist uppercase text-tertiary text-4xl ml-10">
                                Asset History
                        </div>
                        <div className="mt-10 ml-20 text-left text-gray4 text-xl">
                            {
                                tokenStats ? (
                                    tokenStats.asset_history.map((s:any, i:number) => <div key={i}>
                                        <div className="text-succ2 mt-2">{s.status}</div>
                                        <div>{formatDatetime(s.timestamp)} {}</div>
                                    </div>

                                    )
                                ) : "No data"
                            }
                        </div>
                    </div>
                    <div className="my-10 text-4xl flex flex-col text-left">
                        <div className="font-urbanist uppercase text-tertiary text-4xl ml-10">
                                Token History
                        </div>
                        <div className="mt-10 ml-20 text-left text-gray4 text-xl">
                        {
                            tokenStats ? (
                                tokenStats.token_history.map((s:any, i:number) => <div key={i}>
                                    <div className="text-succ2 mt-2">{s.status}</div>
                                    <div>{formatDatetime(s.timestamp)} {}</div>
                                </div>

                                )
                            ) : "No data"
                        }
                        </div>
                    </div>
                </div>
                <div className="grid mt-10">
                    <div className="">
                        <div className="font-urbanist uppercase text-tertiary text-4xl ml-10 mb-5">
                            Primary Sales
                        </div>
                        <ColoredTab label={""} color={"succ4"} index={0} headers={[...hdsPrimarySales,...[""]]} 
                                rows={primarySaleRows(tokenPSs ? tokenPSs : [], ["/issuer/primary-sale/"], ["PS details"])} />
                    </div>
                </div>
            </div>
        </>)
    }
    
    function showInvestorBreakdown() {

        return <>
            <div className="grid ml-10 mt-20">
                <div className="">
                    <div className="font-roboto font-bold text-2xl text-tertiary mb-5">
                        Investors from Primary Sales
                    </div>
                </div>
            </div>
            <PieAndList tokenStats={tokenStats && tokenStats.investor_breakdown ? tokenStats.investor_breakdown : {rows: []}} />
        </>
    }
    
    function showCapTable() {
        return <>
        <div className="grid ml-10 mt-20">
            <div className="">
                <div className="font-roboto font-bold text-2xl text-tertiary mb-5">
                    Actual Token's Holders
                </div>
            </div>
        </div>
        <PieAndList tokenStats={tokenStats && tokenStats.cap_table ? tokenStats.cap_table : {rows: []}} />
    </>
    }

    function showSMStatistics() { 
        const btnsClasses = "border-solid border-succ1 border-3 " +
            " bg-white rounded-md px-2 py-1 cursor-pointer"
        return <>
            <div className="grid grid-cols-6 mx-20">
                <div></div>
                <div className="col-span-4 flex-col flex justify-center center">
                    <DexxChart serie={tradeChartData ? tradeChartData : []} />
                    <div className="uppercase text-primary flex justify-center
                            text-sm mt-2 gap-2">
                        <div 
                            onClick={() => {setDaysRange("7")}} 
                            className={btnsClasses}>
                            1 week</div>
                        <div 
                            onClick={() => {setDaysRange("30")}} 
                            className={btnsClasses}>
                            1 month</div>
                        <div 
                            onClick={() => {setDaysRange("365")}} 
                            className={btnsClasses}>
                            1 year</div>
                    </div>
                </div>
                <div></div>
            </div>
        </>
    }

    function showLP() {
        return (
        <div className="">

            <div className="grid mt-10">
                <div className="">
                    <div className="font-urbanist uppercase text-tertiary text-4xl ml-10 mb-5">
                        Liquidity Pools
                    </div>
                    <ColoredTab label={""} color={"succ4"} index={0} headers={[...hdsLiquidityPools,...[""]]} 
                            rows={liquidityPoolRows(filteredLP ? filteredLP : [], ["/issuer/liquidity-pool/"], ["LP details"])} />
                </div>
            </div>

            <div className="font-urbanist uppercase text-tertiary text-4xl mt-10 ml-10  grid grid-cols-3">
                <div className="">Create a Liquidity pool</div>
                <div className="">
                    
                <DexxPopup show={showCreateLPPopUp} title={"Create Liquidity Pool"} 
                            msg={<CreateLPPopup 
                                   closeFunction={function() {setShowCreateLPPopUp(false); 
                                        actions.getIssuerLiquidityPools(props.tokenInfo.uuid, setIssuedLiquidityPools)}} 
                                   setLiquidityPoolCreationRequest={setLiquidityPoolCreationRequest} 
                                   tokenA={props.tokenInfo}
                                   lpNum={nextSuffix} />}
                            helpMsg={
                                <div className="p-5 font-roboto font-semibold text-primary text-base">
                                    <div className=" font-bold text-primary text-lg mb-5">
                                    For creation of a liquidity pool select the desired initial price of the token and then the XEUR you will allocate.
                                    </div>
                                    <div className=" font-bold text-primary text-lg mb-5">
                                    The number of asset tokens required will be calculated for you.
                                    </div>
                                    <div className=" font-bold text-primary text-lg">
                                    For more detailed information <Link to={"/support"} className="text-succ2">click here</Link>.
                                    </div>
                                </div>
                            }
                            btnConfirm={false} close={() => { setShowCreateLPPopUp(false) } } btnOk={false} />

                <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp}></Popup>
                    

        
                {/*!props.tokenInfo.buyback*/ 
                <div className="flex justify-center mb-4">
                    {props.tokenInfo.lp_flag ? 
                    <DexxButton label={"create"} className="px-5 bg-succ2 rounded-full font-semibold " 
                        color1="succ2" color2="primary" onClick={() => {
                            setShowCreateLPPopUp(true)
                        }}/>
                    :
                    <DisabledButton label={"create"} className="rounded-full px-16 py-2 mr-10 font-bold text-xl bg-succ2 z-50" />
                    }
                </div>
                }

                    {/*!props.tokenInfo.buyback ? <div className="flex justify-center mb-4">
                        <DexxButton label={"Create"} className="px-5 bg-gray rounded-full font-semibold " 
                            onClick={() => {
                                setShowLiquidationPopUp(true)
                            }}/>
                    </div> : <></>*/}
                </div>
                <div></div>
            </div>
        </div>
        )
    }

    function showTokenControlFunction() {

        return (<>
            <div className="">
                <div className="grid  mt-20">
                    <div className="col-span-4">
                        <div className="font-urbanist uppercase text-tertiary text-4xl ml-10 grid grid-cols-3">
                            <div className="">Liquidation</div>
                            <div className="">
                                {!props.tokenInfo.buyback ? <div className="flex justify-center mb-4">
                                    <DexxButton label={"liquidation"} className="px-5 bg-succ2 rounded-full font-semibold " 
                                        color1="succ2" color2="primary" onClick={() => {
                                            setShowLiquidationPopUp(true)
                                        }}/>
                                </div> : <></>}
                            </div>
                            <div></div>
                        </div>
                        {/*<div className="font-urbanist text-tertiary text-xl ml-10">
                            Actual Market Price: &euro;1.32 </div>
                        <div className="font-urbanist text-tertiary text-xl ml-10 mb-5">
                            Percentage buyback: 5%</div>
                        
                        <ColoredTab label={""} color={"succ4"} index={0} headers={[...hdsBuyBack]} 
                            rows={[valueBuyBack]} />*/}

                        <DexxPopup show={showLiquidationPopUp} title={"Liquidation"} 
                            msg={<LiquidationPopup tokensSold={tokensSold} price={parseFloat(props.tokenInfo.price)} uuid={props.tokenInfo.uuid}
                                setLiquidationPriceRequest={setLiquidationPriceRequest} closeFunction={() => setShowLiquidationPopUp(false)}/>}
                            btnConfirm={false} close={() => { setShowLiquidationPopUp(false) } } btnOk={false} />

                        <Popup show={showPopUp} title={titlePopUp} msg={msgPopUp} btnOk={true} close={handleClosePopUp}></Popup>
                        
                        

                        <div className="font-urbanist uppercase text-tertiary text-4xl mt-10 ml-10  grid grid-cols-3">
                            <div className="">Deliver A Dividend</div>
                            <div className="">
                                {!props.tokenInfo.buyback ? <div className="flex justify-center mb-4">
                                    <DexxButton label={"deliver"} className="px-5 bg-gray rounded-full font-semibold " 
                                        /*onClick={() => {
                                            setShowLiquidationPopUp(true)
                                        }}*//>
                                </div> : <></>}
                            </div>
                            <div></div>
                        </div>

                        {/*<div className="font-urbanist uppercase text-tertiary text-4xl mt-10 ml-10">
                            Pay Fees
                        </div>*/}

                        <div className="font-urbanist uppercase text-tertiary text-4xl mt-10 ml-10 mb-5 grid grid-cols-3">
                            <div className="">Freeze / Unlock Tokens</div>
                            <div className="">
                                {!props.tokenInfo.buyback ? <div className="flex justify-center mb-4">
                                    <DexxButton label={"freeze / unlock"} className="px-5 bg-gray rounded-full font-semibold " 
                                        /*onClick={() => {
                                            setShowLiquidationPopUp(true)
                                        }}*//>
                                </div> : <></>}
                            </div>
                            <div></div>
                        </div>
                        {/*<ColoredTab label={""} color={"succ4"} index={0} headers={[...hdsLocked, ...[]]} 
                                rows={[]} />*/}
                    </div>
                </div>
            </div>
        </>)
    }

    function showPerformanceDetails() { 
        return (
            <div className="font-roboto uppercase text-tertiary text-center text-2xl pl-20 pb-20 mt-10">
                no data available yet
            </div> 
        )
    }

    const headers = [...["Issuance Status","Investor breakdown","Cap table"], 
        ...(isDev ? [...["Market statistics","Liquidity pool"],...
                        (isLiquidated ? [] : ["Token control functions"]), ...["Performance details"]] : 
                    [...["Liquidity pool"],...(isLiquidated ? [] : ["Token control functions"])])]
    return <>
        <div className="">
            <TabsMenu 
                headers={headers} 
                contents={[...[showIssuanceStatus(), showInvestorBreakdown(), showCapTable()], 
                    ...isDev ? [...[showSMStatistics(), showLP()], ...
                        (isLiquidated ? [] : [showTokenControlFunction()]), ...[showPerformanceDetails()]] : 
                        [...[showLP()], ...(isLiquidated ? [] : [showTokenControlFunction()])]]} />
        </div>
    </>
}