import {formatDatetime} from "../utils"

export default function LabelAndDescription(props: {label: string, description: string, className?: string, alternate?: boolean}) : JSX.Element {

    return props.description ?
        <div className={props.className + (props.alternate ? " text-background" : " text-blue")}>
            
            <div className="uppercase font-code-pro font-bold text-lg leading-7 tracking-wider">
                {props.label}
            </div>
            <div className="uppercase font-code-pro text-lg leading-7 tracking-wider">
                {props.description}
            </div>
        </div>
        : <></>
}

export function AssetDescription(props: {label: string, description: string | any, className?: string, alternate?: boolean, liquidation_price?:string, liquidation_date?:string}) : JSX.Element {

    let liquidation = ""
    if(props.liquidation_date && props.liquidation_date !== "0001-01-01T01:01:01Z" && props.liquidation_price && props.liquidation_price !== "0"){
        liquidation = "<br/>LIQUIDATION DATE: "+formatDatetime(props.liquidation_date)+"<br/><br/>LIQUIDATION PRICE: "+props.liquidation_price+""
    }
    
    
    return props.description ?
        <div className={props.className + (props.alternate ? " text-background" : " text-blue")}>
            
            {props.label ? 
            <div className="uppercase font-code-pro font-liqht text-2xl leading-7 tracking-wider mb-10">
                {props.label}
            </div>
            : <></>}
            <div className="font-extralight tracking-wider whitespace-pre-wrap">
                <div dangerouslySetInnerHTML={{__html: props.description}}></div>
                <div dangerouslySetInnerHTML={{__html: liquidation}}></div>
            </div>
        </div>
        : <></>
}

export function LabelAndInlineValue(props: {label: any, value: any, colorVal?: string, colorLabel?:string}) {
        
    let cV = "background"
    let cL = "background"
    if(props.colorVal) cV = props.colorVal
    if(props.colorLabel) cL = props.colorLabel
    return <>
        <div className={"text-" + cL + " font-light text-2xl mt-10 mb-20"}>
            {props.label}<span className={"font-semibold text-" + cV}>{props.value}</span>
        </div>
    </>
}