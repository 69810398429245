export class Countdown {
    date : Date;
    endMessage : string;

    constructor(date: Date, endMessage: string) {
        this.date = date;
        this.endMessage = endMessage;
    }

    getCountdown(): string {
        let time = this.date.getTime()
        let now = new Date().getTime()
        let distance = time-now
        if(distance <= 0) {
            return this.endMessage
        }
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        if((!days && !hours && !minutes && !seconds))
            return "Loading..."
        return days+"d "+hours+"h "+minutes+"m "+seconds+"s"
    }

    getCountdownNoSec(): string {
        let time = this.date.getTime()
        let now = new Date().getTime()
        let distance = time-now
        if(distance <= 0) {
            return this.endMessage
        }
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        if(!days && !hours && !minutes && !seconds)
            return "Loading..."
        else {
            if(!days && !hours && !minutes && seconds) return seconds+"s"
            if(!days && !hours && minutes) return minutes+"m "+seconds+"s"
            if(!days && hours ) return hours+"h "+minutes+"m "+seconds+"s"
            if(days) return days+"d "+hours+"h "+minutes+"m"
            return ""
        }
    }

}