import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { DexxButton, LightButton } from './light/LightButton';
import arrow from "../media/arrow2.png"
import { HtmlTooltip } from "./HtmlTooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from "@mui/material/IconButton";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Popup(props: {
  show: boolean, title: string, msg: string | JSX.Element, btnOk: boolean, close: any,
  type?: "success" | "warning" | "error", body?: JSX.Element, btnConfirm?: boolean, handleConfirm?: any,
  maxHeight?: string, contentCenter?: boolean
}): JSX.Element {

  let numBtn: number = 0;
  if (props.btnOk) numBtn++
  if (props.btnConfirm) numBtn++
  let colorClasses
  switch (props.type) {
    case "success":
      colorClasses = " text-succ1 "
      break;
    case "warning":
      colorClasses = " text-warning "
      break;
    case "error":
      colorClasses = " text-error1 "
      break;
    default:
      colorClasses = " text-primary "
      break;
  }

  return (
    <>
      <Dialog
        open={props.show}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.close}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              height: "100%",
              maxHeight: props.maxHeight ? props.maxHeight : "400px",
              maxWidth: "400px",
              borderRadius: "20px",
            },
          },
        }}
      >
        <div className={' h-111 grid '+( props.contentCenter ? '' : ' place-content-center ')
            +' text-center font-roboto'}>
          <div className='mt-10 px-4'>
            {props.title ?
              <div className={"text-2xl font-blue font-semibold " + colorClasses}>
                {props.title}
              </div> : <></>}
            {props.msg ?
              <div className="text-lg text-primary font-thin mt-2">
                {props.msg}
              </div>
              : <></>}
            <div className="">
              {props.body ? props.body : <></>}
            </div>
          </div>
          <div className={"mt-12 flex flex-wrap place-content-center justify-center gap-2 md:gap-4"}>
            {props.btnOk ?
              <DialogActions className={" " + (numBtn === 2 ? "flex-col" : "justify-center")}>
                <DexxButton onClick={props.close} label={"close"} fontClasses="text-xs font-medium"
                  className="rounded-full px-8 py-3 bg-white drop-shadow-n" 
                    color1='white' color2='gray5' /*noBorder noHoverOnSelected*//>
              </DialogActions>
              : <></>}
            {props.btnConfirm ?
              <DialogActions className={" " + (numBtn === 2 ? "flex-col" : "justify-center")}>
                <DexxButton onClick={props.handleConfirm} label={"confirm"} fontClasses="text-xs font-medium"
                  className="rounded-full px-16 py-3 bg-white" 
                  color1='white' color2='primary' /*noBorder*//>
              </DialogActions>
              : <></>}
          </div>
        </div>
      </Dialog>
    </>
  );
}

export function DexxPopup(props: {
  show: boolean, title: string, msg: string | JSX.Element, btnOk?: boolean, close: any,
  type?: "success" | "warning" | "error" | "alternate", body?: JSX.Element, 
  btnConfirm?: boolean, handleConfirm?: any, helpTip?: boolean, helpMsg?: JSX.Element
}): JSX.Element {

  let numBtn: number = 0;
  if (props.btnOk) numBtn++
  if (props.btnConfirm) numBtn++
  let colorClasses
  switch (props.type) {
    case "success":
      colorClasses = "succ1"
      break;
    case "warning":
      colorClasses = "warning"
      break;
    case "error":
      colorClasses = "error1"
      break;
    case "alternate":
      colorClasses = "succ8"
      break;
    default:
      colorClasses = "succ1"
      break;
  }

  return (
    <>
      <Dialog
        open={props.show}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.close}
        aria-describedby="alert-dialog-slide-description"
        id="DexxDialog"
      >
        {props.title ?
          <div className={"font-roboto text-xl uppercase font-semibold text-primary " +
            " rounded-full mt-neg30 pl-5 py-2 items-center m-0 " +
            " bg-gradient-to-r from-" + colorClasses + " to-white flex "}>
            <img src={arrow} className="rotate-45 transform" />
            <div>
              {props.title}

              {props.helpMsg ? (
                <HtmlTooltip
                  placement="top-start"
                  title={props.helpMsg}
                >
                  <IconButton>
                    <InfoOutlinedIcon sx={{ color: '#0A3C60', margin: '-6px 5px 0px 0px' }} />
                  </IconButton>
                </HtmlTooltip>
              ) : ""}
            </div>
          </div> : <></>}
        {props.msg ? <DialogContent className="bg-white p-0">
          <div className="font-roboto text-xl text-primary font-thin">
            {props.msg}
          </div>
        </DialogContent> : <></>}
        <div className="bg-white">
          {props.body ? props.body : <></>}
        </div>

        <div className={"flex justify-between pb-2 px-4"}>
          {props.btnOk ?
            <DialogActions className={"bg-white " + (numBtn === 2 ? "flex-col" : "justify-center")}>
              <DexxButton onClick={props.close} label={"close"} fontClasses="text-sm font-medium"
                className="rounded-full px-8 py-3 bg-white drop-shadow-n" color1='white' color2='gray5' />
            </DialogActions>
            : <></>}
          {props.btnConfirm ?
            <DialogActions className={"bg-white " + (numBtn === 2 ? "flex-col" : "justify-center")}>
              <DexxButton onClick={props.handleConfirm} label={"confirm"} fontClasses="text-sm  font-medium"
                className="rounded-full px-16 bg-white py-3" color1='white' color2='primary' />
            </DialogActions>
            : <></>}
        </div>
      </Dialog>
    </>
  );
}